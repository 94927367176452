@import '@standby/common-ui/color.scss';
.rNumberList {
  width: 100%;
  .rNumberRow {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 8px;
    }
    .number {
      color: $neutral800;
      text-align: center;
      font-size: 13px;
      font-weight: 400;
      width: 28px;
      height: 28px;
      border-radius: 4px;
      border: 1px solid $neutral300;
      background: $neutral100;
      line-height: 28px;
      margin-right: 6px;
    }
    .copyRnumber {
      min-width: 160px;
      position: relative;
      top: -1px;
    }
    .copyBtn {
      color: $main500;
      background-image: url('/images/copySmall_main400.svg');
      font-size: 13px;
      font-weight: 400;
      line-height: 14px;
      text-decoration: none;
    }
    .colorBtn {
      margin-left: 6px;
    }
    .lock {
      margin-left: 12px;
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .lockText {
        color: $neutral800;
        font-size: 13px;
        font-weight: 400;
        line-height: 100%;
        background-image: url('/images/lockIcon.svg');
        padding-right: 15px;
        background-repeat: no-repeat;
        background-position: right top;
        display: inline-block;
        height: 14px;
        position: relative;
        top: 1px;
      }
      &.notLocked {
        .lockText {
          background-image: url('/images/unLockIcon.svg');
        }
      }
    }
  }
  .comment {
    margin-top: 6px;
  }
  .colorInput {
    border-radius: 4px;
    border: 1px solid $neutral400;
    background: $main000;
    width: 160px;
    height: 28px;
    color: $neutral500;
    font-size: 13px;
    font-weight: 400;
    padding-left: 8px;
    &.notColor {
      background-color: $neutral100;
      border-color: $neutral400;
      color: $neutral800;
    }
    & + button {
      margin-left: 6px;
    }
  }
  .colorBtn {
    width: 72px;
    height: 28px;
    border-radius: 4px;
    background: $main400;
    color: $neutral100;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 100%;
    &.notImportant {
      border: 1px solid $neutral400;
      background-color: $neutral100;
      color: $neutral600;
    }
  }
}
