@import '@standby/common-ui/color.scss';

.meetingScheduleContent {
  position: relative;
  border-top: 1px solid $neutral300;
  border-bottom: 1px solid $neutral300;
  .meetingSchedule {
    padding: 14px 0 16px 8px;
    border-top: 1px solid $neutral300;
    &:first-child {
      border-top: none;
    }
    .meetingTitle {
      color: $neutral800;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .agendaBox,
    .placeBox,
    .decisionAtBox {
      display: flex;
      &.agendaBox {
        margin-top: 8px;
      }
      &.placeBox,
      &.decisionAtBox {
        margin-top: 6px;
      }
      .meetingSubTitle {
        width: 26px;
        flex-shrink: 0;
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
      .agenda,
      .place,
      .decisionAt {
        &.agenda {
          li {
            margin-top: 6px;
            &:first-child {
              margin-top: 0;
            }
            position: relative;
            padding-left: 20px;
            > span {
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
        margin-left: 16px;
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
      }
    }
    .representative {
      position: relative;
      padding-left: 10px;
      margin-top: 8px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      letter-spacing: -0.28px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
  }
  .arrowBtn {
    &.changeBtn {
      left: 0;
    }
    &.agendaViewBtn {
      left: 140px;
    }
    position: absolute;
    bottom: -25px;
    background-color: transparent;
    > span {
      padding-right: 14px;
      color: $main300;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.28px;
      text-decoration: underline;
      background-image: url('/images/arrowRight_main300.svg');
      background-repeat: no-repeat;
      background-position: right top 4px;
    }
  }
}
.moreViewBtn {
  margin: 0 auto;
  margin-top: 8px;
  display: block;
  background-color: transparent;
  > span {
    padding-right: 16px;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-decoration: underline;
    background-image: url('/images/moreView_close.svg');
    background-repeat: no-repeat;
    background-position: right center;
    &.open {
      background-image: url('/images/moreView_open.svg');
    }
  }
}
