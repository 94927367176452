@import '@standby/common-ui/color.scss';

.termsContent {
  width: 620px;
  padding: 16px 20px 20px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: $neutral900;
}
