@use '@standby/dike_ui/design-token/color.module.scss' as color;

.cell {
  .row {
    display: flex;
    align-items: center;
    gap: 2px;

    .iconButton {
      background: none;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  .editInput {
    display: flex;
    align-items: center;
    position: relative;

    .input {
      width: 100%;
    }

    .buttonContainer {
      position: absolute;
      right: 0;
      top: calc(100% + 4px);

      display: flex;
      gap: 4px;

      .button {
        padding: 8px 10px;
      }
    }
  }
}
