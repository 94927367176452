.agendaList {
  li {
    display: flex;
    .important {
      font-weight: 700;
    }
    .content {
      margin-left: 4px;
    }
  }
}
