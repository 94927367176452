@import '@standby/common-ui/color.scss';

.mainTitle {
  margin-top: 90px;
  color: $neutral900;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  .bold {
    color: $primary800;
  }
}
.mainContent {
  margin-top: 36px;
}
