@import '@standby/common-ui/color.scss';

.table {
  margin-top: 13px;
  width: 940px;
  border-bottom: none !important;
  th {
    &:nth-child(1) {
      padding-left: 36px;
    }
    &:nth-child(2) {
      text-align: center;
    }
    &:nth-child(3) {
      text-align: center;
    }
    &:nth-child(4) {
      text-align: left;
    }
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      text-align: right;
    }
  }
  td {
    background-color: $neutral100;
  }
  .number {
    width: 28px;
    display: inline-block;
    flex-shrink: 0;
  }
  .name {
    font-weight: 700;
  }
  .sum {
    td {
      background-color: transparent;
      text-align: right;
      .important {
        font-weight: 700;
      }
    }
  }
}
