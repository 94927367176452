@import '@standby/common-ui/color.scss';

.mainTitle {
  margin-top: 68px;
  color: $neutral900;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
}
.finalContent {
  margin-top: 30px;
  position: relative;
  .completedYet {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(68, 68, 68, 0.6);
    text-align: center;
    > .infoText {
      margin-top: 136px;
      color: $neutral100;
      font-size: 22px;
      font-weight: 400;
      text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
      line-height: 150%;
    }
    > .infoSub {
      margin-top: 6px;
      color: $neutral100;
      text-align: center;
      text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.32px;
    }
  }
  .successNotice {
    height: 58px;
    border-radius: 10px;
    background-color: $sub50;
    font-size: 18px;
    font-weight: 400;
    line-height: 56px;
    color: $neutral800;
    padding-left: 54px;
    background-image: url('/images/check_circle_final.svg');
    background-repeat: no-repeat;
    background-position: left 20px center;
    > .important {
      color: $main500;
    }
  }
  > .content {
    margin-top: 24px;
    > .title {
      background-repeat: no-repeat;
      background-position: left top;
      color: $neutral800;
      font-size: 18px;
      font-weight: 400;
      padding-left: 32px;
      min-height: 24px;
      line-height: 23px;
    }
    &.registered {
      > .title {
        background-image: url('/images/registered_title_icon.svg');
      }
      .contentRow {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        &:nth-child(2) {
          margin-top: 16px;
        }
      }
      .progressSummary {
        width: 100%;
        border-radius: 10px;
        border: 1px solid $neutral300;
        height: 206px;
        display: flex;
        justify-content: center;
        align-items: center;
        > .inside {
          display: inline-block;
          > .texts {
            width: 694px;
            display: flex;
            text-align: center;
            justify-content: space-between;
            &.alreadyProgressed {
              width: 560px;
              > .item {
                width: 80px;
                > .subText {
                  color: $neutral600;
                  text-align: center;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 130%;
                  margin-top: 3px;
                }
                > .text {
                  letter-spacing: -1px;
                  margin-top: 0;
                }
                &:nth-last-child(1) {
                  .text {
                    color: $main500;
                  }
                }
              }
            }
            > .item {
              width: 80px;
              > .text {
                color: $neutral700;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 130%;
                margin-top: 8px;
                &.important {
                  color: $main500;
                }
              }
              > .date {
                margin-top: 4px;
                color: $neutral600;
                text-align: center;
                font-size: 13px;
                font-weight: 400;
                line-height: 130%;
                letter-spacing: -0.26px;
              }
            }
          }
        }
      }
      .fileContent {
        width: 510px;
        height: 215px;
        border-radius: 12px;
        background-color: $main000;
        padding-top: 16px;
        padding-left: 20px;
        padding-right: 20px;
        > .title {
          color: $neutral800;
          font-size: 18px;
          font-weight: 400;
          line-height: 23px;
          min-height: 24px;
          background-repeat: no-repeat;
          background-position: left top;
          padding-left: 32px;
          &.manualUpdateIcon {
            background-image: url('/images/manualUpdateIcon.svg');
          }
          &.autoUpdateIcon {
            background-image: url('/images/autoUpdateIcon.svg');
          }
        }
        > .fileList {
          width: 100%;
          min-height: 143px;
          background-color: $neutral100;
          border-radius: 12px;
          margin-top: 12px;
          padding: 0 20px;
          > .file {
            border-bottom: 1px solid $neutral300;
            display: flex;
            justify-content: space-between;
            height: 48px;
            align-items: center;
            &:nth-child(3) {
              border: none;
            }
            > .fileExText {
              color: $neutral600;
              font-size: 14px;
              font-weight: 400;
              line-height: 130%;
              &:before {
                content: '* ';
                position: relative;
                top: 3px;
              }
            }
            > .fileTitle {
              color: $neutral800;
              font-size: 16px;
              font-weight: 400;
              line-height: 100%;
              letter-spacing: -0.5px;
              background-position: left top;
              background-repeat: no-repeat;
              padding-left: 22px;
              &.receiptIcon {
                background-image: url('/images/receiptIcon_main300.svg');
              }
              &.registerIcon {
                background-image: url('/images/registerIcon_main300.svg');
              }
              &.aoaIcon {
                background-image: url('/images/aoaIcon_main300.svg');
              }
              &.shareholderIcon {
                background-image: url('/images/shareholderIcon_main300.svg');
              }
            }
            > .downloadBtn {
              background-color: transparent;
              background-image: url('/images/downloadIcon_main300.svg');
              padding-left: 20px;
              background-position: left top 2px;
              background-repeat: no-repeat;
              span {
                color: $main300;
                font-size: 14px;
                font-weight: 400;
                line-height: 100%;
                text-decoration: underline;
              }
            }
            > .link {
              background-image: url('/images/arrowRight_main300.svg');
              padding-right: 18px;
              background-position: right top 3px;
              background-repeat: no-repeat;
              span {
                color: $main300;
                font-size: 14px;
                font-weight: 400;
                line-height: 100%;
                letter-spacing: -0.28px;
                text-decoration: underline;
              }
            }
            > .empty {
              color: $neutral500;
              text-align: right;
              font-size: 14px;
              font-weight: 400;
              letter-spacing: -0.28px;
            }
          }
        }
      }
    }
    &.meetingSchedule {
      > .title {
        background-image: url('/images/meetingSchedule_title_icon.svg');
      }
      > .regAlarm {
        border-radius: 10px;
        border: 1px solid $neutral400;
        color: $neutral900;
        font-size: 16px;
        font-weight: 700;
        line-height: 130%;
        letter-spacing: -0.16px;
        background-image: url('/images/loudSpeakerIcon.svg');
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: left 18px top 16px;
        padding: 15px 0;
        padding-left: 44px;
      }
      > .memo {
        border-radius: 10px;
        border: 1px solid $neutral400;
        margin-bottom: 24px;
        > .title {
          color: $neutral900;
          font-size: 16px;
          font-weight: 700;
          line-height: 130%;
          padding-left: 26px;
          letter-spacing: -0.16px;
          background-image: url('/images/memoTitle_icon.svg');
          background-repeat: no-repeat;
          background-position: left top 1px;
          margin: 0 18px;
          margin-top: 16px;
        }
        > .memoContent {
          color: $neutral800;
          font-size: 13px;
          font-weight: 400;
          line-height: 155%;
          padding: 0 20px;
          padding-top: 8px;
          padding-bottom: 13px;
          b {
            font-weight: 700;
          }
        }
      }
    }
  }
}
