@import '@standby/common-ui/color.scss';

.content {
  margin-left: 215px;
  width: calc(100% - 215px);
  .title {
    margin: 0 auto;
    padding-top: 72px;
    padding-left: 58px;
    width: 680px;
    color: $neutral800;
    font-size: 22px;
    font-weight: 400;
    line-height: 48px;
    background-image: url('/images/documentTitle_icon.svg');
    background-position: left top 72px;
    background-repeat: no-repeat;
  }
  .documentBox {
    margin: 0 auto;
    margin-top: 16px;
    width: 680px;
    height: 732px;
    border: 1px solid $neutral400;
    border-radius: 4px;
    .iframe {
      width: 100%;
      height: calc(100% / 0.618);
      transform: scale(0.618);
      transform-origin: top left;
      .document {
        width: 1100px;
        height: 100%;
      }
    }
  }
  .btnArea {
    margin-top: 24px;
    padding-bottom: 40px;
    .agreeBtn {
      margin: 0 auto;
      width: 336px;
    }
  }
}
