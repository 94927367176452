@import '@standby/common-ui/color.scss';
.tooltipContent {
  padding: 13px 16px 16px 16px;
  width: 460px;
  .sampleList {
    display: flex;
    justify-content: space-between;
  }
  .exTitle {
    color: $main500;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    background-color: $main000;
    padding: 4px 6px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 6px;
  }
  ul.exList {
    font-size: 14px;
    font-weight: 400;
    color: $neutral700;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    > li {
      padding-left: 8px;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        background-color: $neutral600;
        border-radius: 3px;
        width: 3px;
        height: 3px;
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
    li {
      line-height: 1.2;
      margin-top: 4px;
      &:nth-child(1) {
        margin-top: 0;
      }
      .important {
        font-weight: 500;
      }
      .stepList {
        margin-left: -8px;
        li {
          margin-top: 8px;
          display: flex;
          .step {
            color: $main400;
            margin-right: 5px;
          }
          .content {
            .fileBtn {
              margin-bottom: 6px;
              margin-top: 8px;
              button {
                width: 284px;
              }
            }
          }
        }
      }
    }
  }
}
.textarea {
  div,
  textarea {
    width: 100%;
  }
}
.ex {
  color: $neutral600;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  margin-top: 6px;
  &:before {
    content: '* ';
    position: relative;
    top: 4px;
  }
}
