@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/stockHomeTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.contentBox {
  padding: 0;
  .content {
    margin-top: 54px;
    border-top: 1px solid #d6d8da;
    padding: 0 50px;
    padding-top: 36px;
  }
}
