@import '@standby/common-ui/color.scss';

.onlineMusterPopup {
  padding: 50px;
  width: 958px;
  text-align: center;
  .onlineMuster {
    display: flex;
    justify-content: space-between;
    margin-top: 38px;
    .direct,
    .beforehand {
      padding: 24px;
      width: 420px;
      height: 248px;
      border-radius: 6px;
      box-shadow: 1px 1px 2px 1px rgba(153, 153, 153, 0.16);
      &:hover {
        background-color: $main000;
        &.direct {
          .btn {
            color: $neutral100;
            background-color: $main400;
          }
        }
      }
      &.direct {
        border: 1px solid $neutral400;
        .title {
          display: inline-block;
          position: relative;
          padding-top: 58px;
          color: $neutral800;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.72px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 44px;
            width: 46px;
            height: 46px;
            background-image: url('/images/online_muster_direct_icon.svg');
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        .explain {
          margin-top: 4px;
          color: $neutral600;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.6px;
        }
        .btn {
          cursor: pointer;
          display: inline-block;
          margin-top: 43px;
          padding: 16px 0 18px;
          width: 372px;
          height: 48px;
          border: 1px solid $main400;
          border-radius: 30px;
          background-color: $neutral100;
          color: $main500;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
        }
      }
      &.beforehand {
        position: relative;
        border: 1px solid $main400;
        &:before {
          content: '추천';
          display: inline-block;
          position: absolute;
          top: 0;
          right: -1px;
          padding: 6px 9px;
          color: $neutral100;
          font-size: 13px;
          font-weight: 400;
          line-height: 1;
          background-color: $main400;
          border-radius: 0 6px;
        }
        .title {
          display: inline-block;
          position: relative;
          padding-top: 58px;
          color: $main500;
          font-size: 18px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.72px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 144px;
            width: 46px;
            height: 46px;
            background-image: url('/images/online_muster_beforehand_icon.svg');
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        .explain {
          margin-top: 4px;
          color: $neutral700;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.6px;
        }
        .btn {
          cursor: pointer;
          display: inline-block;
          margin-top: 24px;
          padding: 16px 0 18px;
          width: 372px;
          height: 48px;
          border: 1px solid $main400;
          border-radius: 30px;
          background-color: $main400;
          color: $neutral100;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
        }
      }
    }
  }
}
