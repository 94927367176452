@import '@standby/common-ui/color.scss';

.login {
  padding-left: 68px;
  padding-right: 68px;
  width: 560px;
  text-align: center;
  .loginImg {
    margin-top: 24px;
  }
  .name {
    margin-top: 6px;
    color: $neutral900;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.011em;
  }
  .companyInfo {
    margin-top: 8px;
    padding-left: 45px;
    padding-right: 45px;
    color: $neutral800;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.02em;
    text-align: left;
    .bold {
      color: $main500;
    }
  }
  .btnArea {
    margin-top: 24px;
    .progressBtn {
      margin: 0 auto;
      width: 424px;
    }
  }
  .explain {
    margin-top: 8px;
    padding-bottom: 36px;
    color: $neutral600;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.03em;
  }
}
