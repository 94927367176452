@import '@standby/common-ui/color.scss';

.meetingDetailBox {
  border: 1px solid $neutral300;
  position: relative;
  display: flex;
  width: 100%;
  min-height: 96px;
  margin-top: 30px;
  border-radius: 4px;
  &:nth-child(2) {
    margin-top: 16px;
  }
  &:nth-child(n + 3) {
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -28px;
      left: 49px;
      width: 60px;
      height: 26px;
      background-image: url('/images/date_arrow_icon.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .toastMessage {
    position: absolute;
    top: 72px;
    left: 180px;
    padding: 6px 7px;
    color: $neutral800;
    font-size: 11px;
    font-weight: 400;
    line-height: 14.3px;
    letter-spacing: -0.22px;
    border: 1px solid $main200;
    border-radius: 2px;
    background-color: $neutral100;
    z-index: 500;
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 4px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid transparent;
      border-bottom: 5px solid $main200;
    }
    &:after {
      content: '';
      position: absolute;
      top: -8px;
      left: 5px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid transparent;
      border-bottom: 4px solid $neutral100;
    }
    animation: fadeout 3s ease;
    opacity: 0;
    @keyframes fadeout {
      0% {
        opacity: 0;
      }
      25% {
        opacity: 1;
      }
      75% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
  .titleContent {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 159px;
    padding: 0 15px;
    padding-left: 47px;
    z-index: 50;
    background-color: $neutral200;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-repeat: no-repeat;
    background-position: left 15px center;
    &[data-type='주주총회_개최_대표결정'],
    &[data-type='정기주주총회_개최_대표결정'],
    &[data-type='주주총회_개최_이사회'],
    &[data-type='이사회'] {
      background-image: url('/images/boardOfDirectors_icon.svg');
    }
    &[data-type='주주총회_소집통지'],
    &[data-type='정기주주총회_소집통지'],
    &[data-type='주주전원의_서면결의'],
    &[data-type='신주통지'],
    &[data-type='신주발행통지'],
    &[data-type='신주공고통지'] {
      background-image: url('/images/document_icon.svg');
    }
    &[data-type='주주총회'],
    &[data-type='정기주주총회'] {
      background-image: url('/images/shareholders_icon.svg');
    }
    &[data-type='대표결정'] {
      background-image: url('/images/representative_icon.svg');
    }
    .title {
      position: relative;
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      white-space: pre-wrap;
    }
    .toolTip {
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
  .info {
    position: relative;
    min-width: 547px;
    .detailInfo {
      display: flex;
      padding-top: 30px;
      .decision {
        margin-left: 21px;
        .dateAndTime {
          display: flex;
          flex-grow: 1;
          .date {
            position: relative;
            .dateGuide {
              min-width: 368px;
              position: absolute;
              top: 70px;
              left: 0;
              padding: 4px;
              color: $neutral100;
              font-size: 13px;
              font-weight: 400;
              line-height: 13px;
              letter-spacing: -0.02em;
              background-color: $sub200;
              border-radius: 2px;
              &:before {
                content: '';
                display: inline-block;
                width: 7px;
                height: 126px;
                border: 1px solid $sub200;
                position: absolute;
                top: -52px;
                left: -7px;
                z-index: 150;
                border-right: none;
              }
              &:after {
                content: '';
                display: inline-block;
                width: 7px;
                height: 1px;
                background-color: $sub200;
                position: absolute;
                top: 11px;
                left: -7px;
                z-index: 150;
              }
            }
            .datePicker {
              position: relative;
              input {
                width: 140px;
                height: 36px;
              }
              &.highLight {
                input {
                  border: 1px solid $sub200;
                }
              }
            }
          }
          .time {
            margin-left: 8px;
            .timePicker {
              input {
                width: 72px;
                height: 36px;
              }
              &.highLight {
                input {
                  border: 1px solid $sub200;
                }
              }
            }
          }
        }
      }
      .location {
        display: flex;
        margin-left: 12px;
        width: 294px;
        .title {
          margin-top: 11px;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
        }
        .locationSelect {
          margin-left: 6px;
        }
        .place {
          display: block;
          margin-top: 8px;
          margin-left: 6px;
          margin-bottom: 30px;
        }
      }
    }
    .detailInfoExplain {
      // position: absolute;
      // top: 66px;
      // left: 0;
      display: inline-block;
      padding-bottom: 9px;
      margin-left: 21px;
      .explain {
        position: relative;
        margin-top: 5px;
        padding-left: 20px;
        color: $neutral600;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: -0.26px;
        .icon {
          position: absolute;
          top: 0;
          left: 0;
        }
        .explainSub {
          color: $neutral600;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.02em;
        }
        .bold {
          font-weight: 500;
        }
        &.important {
          color: $warning200;
          background-image: url('/images/warning100_icon.svg');
          background-repeat: no-repeat;
          background-position: top left;
          .bold {
            font-weight: 700;
          }
        }
      }
      .tooltipContent {
        padding: 12px 16px;
        .tooltipExplain {
          position: relative;
          padding-left: 10px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 0;
          }
        }
      }
      .newShareholderExplain {
        position: relative;
        margin-top: 4px;
        padding-left: 10px;
        color: $neutral600;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        &:before {
          content: '*';
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 0;
        }
      }
    }
  }
  .agendaList {
    position: relative;
    display: flex;
    align-items: center;
    padding: 12px;
    &.more {
      padding-bottom: 33px;
    }
    width: 232px;
    counter-reset: number 0;
    border-left: 1px solid $neutral300;
    .agenda {
      position: relative;
      padding-left: 20px;
      color: $neutral800;
      font-size: 13px;
      font-weight: 400;
      line-height: 15px;
      margin-top: 3px;
      &:first-child {
        margin-top: 0;
      }
      > span {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .moreView {
      position: absolute;
      bottom: 12px;
      right: 12px;
      padding-right: 16px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      text-decoration: underline;
      background-color: transparent;
      background-image: url('/images/moreView_close.svg');
      background-repeat: no-repeat;
      background-position: right center;
      &.open {
        background-image: url('/images/moreView_open.svg');
      }
    }
  }
}

.representativeExplain {
  position: relative;
  margin-top: 8px;
  padding-left: 10px;
  color: $neutral600;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  &:before {
    content: '*';
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 0;
  }
}
