@import '@standby/common-ui/color.scss';

.table {
  margin-top: 12px;
  width: 460px;
  th,
  td {
    &:nth-last-child(1) {
      text-align: right;
    }
  }
  td {
    background-color: $neutral100;
  }
  .new {
    color: $neutral100;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    display: inline-block;
    letter-spacing: -0.24px;
    padding: 0 6px;
    height: 18px;
    background-color: $main200;
    border-radius: 2px;
    margin-left: 6px;
  }
}
