@import '@standby/common-ui/color.scss';

.titleSubInfo {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: $neutral600;
  margin-top: 6px;
  &::before {
    content: '*';
    position: relative;
    top: 3px;
    margin-right: 2px;
  }
}
