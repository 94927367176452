@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/companyInfoTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.contentBox {
  position: relative;
  margin-top: 24px;
  padding: 0;
  .floatBtnArea {
    position: absolute;
    top: 12px;
    right: 20px;
    display: flex;
    .addBankbookBtn {
      width: 147px;
      height: 31px;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.56px;
      &.addBankbookBtn {
        margin-left: 8px;
      }
    }
  }
  .content {
    margin-top: 54px;
    border-top: 1px solid $neutral300;
    padding: 36px 50px;
    padding-right: 24px;
    padding-bottom: 24px;
    .title {
      color: $neutral900;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }

    .bankbookBox {
      margin-top: 16px;
      .header {
        display: flex;
        align-items: center;
        padding: 0 8px;
        width: 940px;
        height: 35px;
        color: $neutral700;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        background-color: $neutral200;
        border-top: 1px solid $neutral300;
        border-bottom: 1px solid $neutral300;
        > span {
          &:nth-child(1) {
            flex-shrink: 0;
            width: 46px;
          }
          &:nth-child(2) {
            flex-shrink: 0;
            width: 122px;
          }
          &:nth-child(3) {
            width: calc(340px - 168px);
          }
        }
      }
    }
    .btnArea {
      margin-top: 36px;
      padding-top: 24px;
      border-top: 1px solid $neutral300;
      .sendBtn {
        margin: 0 auto;
        width: 300px;
      }
    }
  }
}

li.bankbookInfo {
  display: flex;
  flex-flow: column;
  .bankbookInfoContent {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 964px;
    padding-right: 32px;
  }
  .line {
    background-color: $neutral300;
    width: 940px;
    height: 1px;
    flex-shrink: 0;
    padding: 0;
  }
  .left {
    display: inline-flex;
    .fixedWidth {
      width: 340px;
      flex-shrink: 0;
    }
    .accountUsage {
      color: $neutral800;
      font-size: 15px;
      font-weight: 400;
      line-height: 18px; /* 120% */
    }
  }
  > div {
    display: inline-block;
    padding: 10px 8px;
  }
  .index {
    display: inline-block;
    width: 46px;
  }
  .bankName {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    width: 122px;
    font-size: 15px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1px;
      left: 0;
      width: 22px;
      height: 22px;
      background-repeat: no-repeat;
      background-image: url('/images/bankLogo_medium.svg');
    }
    &.신한은행:before {
      background-position: left 0 top 0;
    }
    &.국민은행:before {
      background-position: left -142px top 0;
    }
    &.기업은행:before {
      background-position: left -284px top 0;
    }
    &.농협은행:before {
      background-position: left -426px top 0;
    }
    &.우리은행:before {
      background-position: left 0 top -44px;
    }
    &.하나은행:before {
      background-position: left -142px top -44px;
    }
    &.산업은행:before {
      background-position: left -284px top -44px;
    }
    &.수협은행:before {
      background-position: left -426px top -44px;
    }
    &.신협은행:before {
      background-position: left 0 top -88px;
    }
    &.부산은행:before {
      background-position: left -142px top -88px;
    }
    &.전북은행:before {
      background-position: left -284px top -88px;
    }
    &.광주은행:before {
      background-position: left -426px top -88px;
    }
    &.대구은행:before {
      background-position: left 0 top -132px;
    }
    &.새마을금고:before {
      background-position: left -142px top -132px;
    }
    &.SC은행:before {
      background-position: left -284px top -132px;
    }
    &.경남은행:before {
      background-position: left -426px top -132px;
    }
    &.한국씨티은행:before {
      background-position: left 0 top -176px;
    }
    &.우체국:before {
      background-position: left -142px top -176px;
    }
    &.카카오뱅크:before {
      background-position: left -284px top -176px;
    }
    &.토스뱅크:before {
      background-position: left -426px top -176px;
    }
    &.케이뱅크:before {
      background-position: left 0 top -220px;
    }
    &.SBI저축은행:before {
      background-position: left -142px top -220px;
    }
    &.산림조합:before {
      background-position: left -284px top -220px;
    }
  }

  .accountNumber {
    text-decoration: none;
    color: $neutral800;
    font-size: 15px;
  }
  .accountNumberCopyMessage {
    top: -35px;
  }
  .accoutBox {
    display: flex;
    align-items: center;
    .bankbookBtnArea {
      display: inline-flex;
      margin-left: 19px;
      width: 265px;
      .toastMessageBtnBox {
        display: inline-block;
        position: relative;
        .toastMessage {
          position: absolute;
          top: -33px;
          left: 50%;
          transform: translateX(-50%);
        }
        .accountInfoCopyBtn {
          width: 108px;
          height: 36px;
          background-color: $neutral100;
          border: 1px solid $main400;
          border-radius: 4px;
          color: $main500;
          font-size: 13px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.56px;
        }
      }
      .bankbookDownloadBtn {
        display: inline-block;
        margin-left: 4px;
        width: 145px;
        height: 36px;
        background-color: $main400;
        border-radius: 4px;
        color: $neutral100;
        font-size: 13px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.56px;
        .text {
          padding-left: 17px;
          background-image: url('/images/bankbook_download_icon.svg');
          background-repeat: no-repeat;
          background-position: left 0 center;
        }
      }
    }
    .fixBtnArea {
      position: relative;
      display: inline-flex;
      margin-left: 12px;
      .fixBtn,
      .deleteBtn {
        width: 60px;
        height: 36px;
        border: 1px solid $neutral400;
        border-radius: 4px;
        background-color: $neutral100;
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        &.fixBtn {
          color: $neutral800;
        }
        &.deleteBtn {
          margin-left: 4px;
          color: $warning200;
        }
      }
    }
  }
  .dragBtn {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 0px;
    width: 24px;
    height: 22px;
    background-color: transparent;
    background-image: url('/images/drag_icon.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.deleteBankInfo {
  margin-top: 14px;
  .bankInfo {
    padding: 12px 0;
    width: 100%;
    height: 48px;
    background-color: $neutral200;
    display: flex;
    align-items: center;
    justify-content: center;
    .bankName {
      position: relative;
      padding-left: 28px;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 0;
        width: 22px;
        height: 22px;
        background-repeat: no-repeat;
        background-image: url('/images/bankLogo_medium.svg');
      }
      &.신한은행:before {
        background-position: left 0 top 0;
      }
      &.국민은행:before {
        background-position: left -142px top 0;
      }
      &.기업은행:before {
        background-position: left -284px top 0;
      }
      &.농협은행:before {
        background-position: left -426px top 0;
      }
      &.우리은행:before {
        background-position: left 0 top -44px;
      }
      &.하나은행:before {
        background-position: left -142px top -44px;
      }
      &.산업은행:before {
        background-position: left -284px top -44px;
      }
      &.수협은행:before {
        background-position: left -426px top -44px;
      }
      &.신협은행:before {
        background-position: left 0 top -88px;
      }
      &.부산은행:before {
        background-position: left -142px top -88px;
      }
      &.전북은행:before {
        background-position: left -284px top -88px;
      }
      &.광주은행:before {
        background-position: left -426px top -88px;
      }
      &.대구은행:before {
        background-position: left 0 top -132px;
      }
      &.새마을금고:before {
        background-position: left -142px top -132px;
      }
      &.SC은행:before {
        background-position: left -284px top -132px;
      }
      &.경남은행:before {
        background-position: left -426px top -132px;
      }
      &.한국씨티은행:before {
        background-position: left 0 top -176px;
      }
      &.우체국:before {
        background-position: left -142px top -176px;
      }
      &.카카오뱅크:before {
        background-position: left -284px top -176px;
      }
      &.토스뱅크:before {
        background-position: left -426px top -176px;
      }
      &.케이뱅크:before {
        background-position: left 0 top -220px;
      }
      &.SBI저축은행:before {
        background-position: left -142px top -220px;
      }
      &.산림조합:before {
        background-position: left -284px top -220px;
      }
    }
    .accountNumber {
      margin-left: 12px;
    }
  }
  .viewHistory {
    margin-top: 12px;
    .title {
      color: $neutral700;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
    .userList {
      > li {
        margin-top: 6px;
        position: relative;
        padding-left: 10px;
        color: $neutral700;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 8px;
          left: 0;
          width: 3px;
          height: 3px;
          background-color: $neutral700;
          border-radius: 50%;
        }
      }
    }
  }
}

.dragged {
  list-style: none;
  background-color: $neutral100;
}
