@use '@standby/dike_ui/design-token/color.module.scss' as color;

.box {
  min-width: 336px;
  border-radius: 8px;
  border: 1px solid color.$grayScale300;
  background: color.$grayScale000;

  .divider {
    border-top: 1px solid color.$grayScale200;
    width: 100%;
  }

  .type {
    padding: 16px 20px;

    .radioWrap {
      display: flex;
      gap: 16px;
      margin-top: 8px;
    }

    .executiveWrap {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-top: 8px;

      .select {
        color: color.$grayScale500;

        option {
          color: color.$grayScale800;
        }
        option:disabled {
          color: color.$grayScale500;
        }
      }

      .info {
        width: 100%;
      }
    }
  }

  .alias {
    padding: 16px 20px;

    .input {
      margin-top: 2px;
    }
  }
}
