@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/companyInfoTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.imgBox {
  position: relative;
  .explain {
    position: absolute;
    top: 136px;
    left: 50%;
    transform: translateX(-50%);
    color: $neutral100;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-shadow: 2px 2px 6px rgba(68, 68, 68, 0.4);
    .subExplain {
      margin-top: 6px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.32px;
    }
  }
}
