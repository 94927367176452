@import '@standby/common-ui/color.scss';
.changeItem {
  font-size: 13px;
  line-height: 100%;
  & + .changeItem {
    margin-left: 25px;
    position: relative;
    &::before {
      content: '→';
      display: inline-block;
      position: absolute;
      top: 0;
      left: -18px;
      color: $neutral600;
      font-weight: 400;
    }
  }
  .prefix {
    color: $neutral600;
    font-weight: 400;
    margin-right: 3px;
  }
  .item {
    color: $neutral800;
    font-weight: 700;
    &.has {
      color: $main500;
    }
    &.notHas {
      color: $warning200;
    }
  }
}
.content {
  padding-top: 13px;
  padding-bottom: 13px;
}
