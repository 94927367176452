@import '@standby/common-ui/color.scss';
.changeItem {
  font-size: 13px;
  line-height: 100%;
  & + .changeItem {
    margin-left: 25px;
    position: relative;
    &::before {
      content: '→';
      display: inline-block;
      position: absolute;
      top: 0;
      left: -18px;
      color: $neutral600;
      font-weight: 400;
    }
  }
  .prefix {
    color: $neutral600;
    font-weight: 400;
    margin-right: 3px;
  }
  .item {
    color: $neutral800;
    font-weight: 700;
    .won {
      font-weight: 400;
    }
    .plus {
      color: $main500;
      font-weight: 700 !important;
      margin-left: 6px;
      font-weight: 400;
      line-height: 15px;
      background-image: url('/images/copySmall_netural600.svg');
      text-decoration: none;
    }
  }
  .parValue {
    margin-left: 6px;
  }
}
.content {
  padding-top: 13px;
  padding-bottom: 13px;
}
