@use '@standby/dike_ui/design-token/color.module.scss' as color;

.notifyWrap {
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(color.$grayScale700, 0);
  z-index: 4000;
  visibility: hidden;
  transition: 0.3s;

  .notifyRelative {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .notifyBox {
      width: 464px;
      background-color: color.$grayScale000;
      position: absolute;
      top: 0;
      right: -464px;
      bottom: 0;
      box-shadow: -3px 0px 4px 1px rgba(0, 0, 0, 0.12);
      transition: 0.3s;
      transition-timing-function: ease-in;

      .title {
        height: 58px;
        padding: 16px 24px;
        color: color.$grayScale900;
      }

      .gap {
        height: 4px;
        border-top: 1px solid color.$grayScale200;
        background-color: color.$grayScale100;
      }

      .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: color.$grayScale500;
      }

      .list {
        padding: 0 0 24px 24px;

        .inside {
          padding-right: 24px;
        }

        .scrollArea {
          height: calc(100vh - 153px);
        }
      }
    }
  }

  &.open {
    background-color: rgba(color.$grayScale700, 0.7);
    visibility: visible;

    .notifyRelative {
      .notifyBox {
        right: 0;
      }
      .background {
        width: calc(100% - 400px);
        top: 0;
        position: absolute;
        bottom: 0;
        cursor: pointer;
      }
    }
  }
}
