@import '@standby/common-ui/color.scss';

.mainTitle {
  margin-top: 90px;
  color: $primary800;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
}
.statue {
  height: 152px;
  background-image: url('/images/awsCredit_result_back.svg');
  margin-top: 36px;
  padding: 28px 40px;
  .title {
    color: $neutral100;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
  .sub {
    color: $main000;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-top: 4px;
  }
  .arrowLink {
    background-color: transparent;
    color: $main100;
    font-size: 12px;
    font-weight: 400;
    line-height: 100%;
    text-decoration-line: underline;
    padding-right: 14px;
    background-image: url('/images/arrow_main100.svg');
    background-repeat: no-repeat;
    background-position: right top;
    margin-top: 20px;
  }
  &.provideComplete {
    height: 236px;
    background-image: url('/images/awsCredit_result_back_provideComplete.svg');
    padding: 0 40px;
    .title {
      font-weight: 400;
      padding-top: 96px;
      .bold {
        font-weight: 700;
      }
    }
    .arrowLink {
      margin-top: 30px;
    }
  }
  &.provideDifficult {
    height: 258px;
    background-image: url('/images/awsCredit_result_back_provideDifficult.svg');
    padding: 0 40px;
    .title {
      padding-top: 96px;
    }
    .sub {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.56px;
      margin-top: 8px;
      color: $main100;
      .bold {
        color: $main000;
        font-weight: 500;
      }
    }
    .links {
      margin-top: 24px;
      .arrowLink {
        display: inline-block;
        margin-top: 0;
      }
      .arrowLink + .arrowLink {
        margin-left: 12px;
      }
    }
  }
}
.mainContent {
  .row {
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    padding-right: 80px;
    margin-top: 48px;
    .listContent {
      > .title {
        color: $primary900;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        padding-left: 12px;
        position: relative;
        &::before {
          position: absolute;
          content: '';
          display: inline-block;
          top: 4px;
          left: 0;
          width: 4px;
          height: 18px;
          background-color: $primary800;
        }
      }
      > .sub {
        margin-top: 8px;
        color: $neutral800;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
      > .list {
        width: 462px;
        margin-top: 12px;
        li {
          color: $neutral700;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          padding: 9px 6px;
          border-bottom: 1px dashed $neutral300;
          &:first-child {
            border-top: 1px dashed $neutral300;
          }
          a {
            color: $main200;
            text-decoration: underline;
          }
        }
      }
      .advertisementCards {
        display: flex;
        gap: 12px;
        margin-top: 16px;
        li {
          .card {
            border-radius: 8px;
            display: inline-block;
            width: 312px;
            height: 285px;
            background-color: #f3f4f5;
            border: 1px solid $neutral300;
            overflow: hidden;
            position: relative;
            &.full {
              display: inline-flex;
              flex-flow: column;
              text-align: left;
              border: none;
              padding: 24px 20px;
              .title {
                color: $neutral800;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                margin-top: 24px;
              }
              .info {
                margin-top: 12px;
                color: $neutral600;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
              }
            }
            .image {
              height: 108px;
              background-color: $neutral100;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .bottomContent {
              padding: 16px 24px;
              .hashtag {
                display: flex;
                gap: 4px;
                li {
                  color: $main500;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                }
              }
              .title {
                color: $neutral800;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                margin-top: 4px;
                .sub {
                  color: $neutral600;
                }
              }
              .circlrList {
                margin-top: 12px;
                li {
                  color: $neutral600;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 20px;
                  padding-left: 8px;
                  position: relative;
                  &:before {
                    position: absolute;
                    display: inline-block;
                    content: '';
                    width: 2px;
                    height: 2px;
                    border-radius: 2px;
                    background-color: $neutral600;
                    left: 1px;
                    top: 9px;
                  }
                  &:not(:first-child) {
                    margin-top: 4px;
                  }
                }
              }
            }
            .mailIcon {
              width: 60px;
              height: 60px;
              border-radius: 8px;
              background-color: $sub200;
              padding-top: 17px;
              padding-left: 14px;
            }
            .guide {
              position: absolute;
              right: 20px;
              bottom: 20px;
            }
          }
        }
      }
    }
    .downloadQuestion {
      width: 393px;
      height: 242px;
      border-radius: 8px;
      border: 1px solid $neutral300;
      display: flex;
      flex-flow: column;
      text-align: center;
      align-items: center;
      overflow: hidden;
      .title {
        color: $neutral700;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin-top: 16px;
      }
      .download {
        width: 116px;
        margin-top: 8px;
      }
    }
    .inviteLink {
      width: 393px;
      height: 150px;
      border-radius: 8px;
      border: 1px solid $neutral300;
      background-color: $neutral200;
      text-align: center;
      background-image: url('/images/linkBoxIcon.svg');
      background-repeat: no-repeat;
      background-position: top 18px center;
      .title {
        margin-top: 46px;
        color: $neutral700;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      .copyArea {
        background-color: $neutral100;
        width: 353px;
        height: 48px;
        margin-top: 16px;
        border-radius: 4px;
        border: 1px solid $neutral300;
        background-image: none;
        padding-right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration-line: none;
        .copyText {
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          text-decoration: underline;
        }
        .likeBtn {
          color: $neutral100;
          font-size: 12px;
          font-weight: 500;
          border-radius: 4px;
          line-height: 18px;
          background-color: $main400;
          padding: 7px 15px;
          margin-left: 12px;
        }
      }
      .copyMessage {
        top: -20px;
        right: 0;
      }
    }
    .outlinkCards {
      display: flex;
      justify-content: space-between;
      width: 100%;
      li {
        .card {
          width: 474px;
          height: 163px;
          border: 1px solid $neutral300;
          display: inline-block;
          border-radius: 8px;
          background-color: $neutral100;
          position: relative;
          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 26px;
            height: 26px;
            border-radius: 50%;
            top: 20px;
            left: 24px;
            background-color: $neutral200;
            background-image: url('/images/blogLightIcon.svg');
            background-position: center;
            background-repeat: no-repeat;
          }
          .title {
            color: $primary900;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            padding-top: 21px;
            padding-left: 58px;
          }
          .content {
            color: $neutral700;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 9px;
            padding: 0 24px;
            letter-spacing: -0.2px;
          }
          .moreViewGuide {
            position: absolute;
            color: $neutral500;
            font-size: 13px;
            font-weight: 400;
            background-image: url('/images/moreView_icon.svg');
            background-repeat: no-repeat;
            background-position: right center;
            padding-right: 16px;
            left: 24px;
            bottom: 20px;
          }
        }
      }
    }
  }
  .moreView {
    margin-top: 60px;
    padding: 0 40px;
    > .title {
      color: $neutral800;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      .bold {
        color: $primary800;
      }
    }
    > .sub {
      color: $neutral800;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      margin-top: 4px;
    }
    .viewList {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      .item {
        display: inline-flex;
        width: 231px;
        height: 116px;
        border-radius: 4px;
        border: 1px solid $neutral300;
        background-color: $neutral100;
        position: relative;
        flex-flow: column;
        .icon {
          position: absolute;
          top: 12px;
          left: 16px;
          width: 40px;
          height: 40px;
          border-radius: 4px;
          background: $neutral200;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .guide {
          position: absolute;
          right: 20px;
          top: 18px;
        }
        .sub {
          color: $neutral500;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          margin-top: 60px;
          margin-left: 16px;
        }
        .title {
          color: $neutral800;
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          margin-left: 16px;
        }
      }
      button.item .guide {
        background-image: url('/images/download_icon.svg');
        background-position: left top 2px;
        padding-right: 0;
        padding-left: 19px;
      }
    }
  }
}

.guide {
  background-color: transparent;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.26px;
  text-decoration: underline;
  color: $main300;
  background-image: url('/images/arrowRight_main300.svg');
  background-repeat: no-repeat;
  background-position: right top 3px;
  padding-right: 14px;
  display: inline-block;
  line-height: 19px;
}
