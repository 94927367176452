@use '@standby/dike_ui/design-token/color.module.scss' as color;
@use '@src/common/standby-color.scss' as standbyColor;

.header {
  width: 100%;
  height: 48px;
  background-color: color.$primary900;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 60px;
  z-index: 3000;
}

.side {
  width: 216px;
  height: calc(100vh - 48px);
  position: fixed;
  top: 48px;
  left: 0;
  background-color: standbyColor.$shCg700;
  z-index: 1;

  .companyName {
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 40px;
    height: 48px;
    border-bottom: 1px solid color.$grayScale900;
    position: relative;
    color: color.$grayScale000;
    background-image: url('/images/imgSelectClose.svg');
    background-repeat: no-repeat;
    background-position: right 17px center;
  }

  .joinStandby {
    padding-top: 12px;
    text-align: center;
    .joinStandbyLink {
      cursor: pointer;
      display: inline-flex;
      gap: 2px;
      width: 192px;
      height: 48px;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background-color: color.$grayScale000;
      border: 1px solid color.$primary400;

      &:active {
        position: relative;
        top: 1px;
        left: 1px;
      }
    }
  }

  .scrollArea {
    height: calc(100vh - 250px) !important;

    nav {
      padding: 12px;
    }
  }

  .blogWrap {
    position: absolute;
    left: 12px;
    bottom: 12px;
    padding-top: 13px;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 28px;
      height: 28px;
      border-radius: 50%;
      top: 0;
      left: 16px;
      background-color: color.$grayScale000;
      background-image: url('/images/blogLightIcon.svg');
      background-position: center;
      background-repeat: no-repeat;
    }

    .blogContent {
      display: flex;
      flex-direction: column;
      width: 192px;
      height: 70px;
      padding: 12px 16px;
      background-color: color.$grayScale900;
      border-radius: 8px;

      .guideTitle {
        color: color.$grayScale400;
        margin-top: 6px;
      }

      .guideText {
        background-image: url('/images/blogOutLink.svg');
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 18px;
        color: color.$grayScale000;
      }
    }
  }
}
