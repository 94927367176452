@import '@standby/common-ui/color.scss';

.modalContent {
  padding: 36px 50px;
  width: 600px;
  .title {
    color: $neutral800;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
  .processContent {
    margin-top: 16px;
    padding: 24px 73px;
    border: 1px solid $neutral300;
    border-radius: 4px;
    ul {
      li {
        padding-left: 32px;
        margin-top: 16px;
        &:first-child {
          margin-top: 0;
        }
        color: $neutral800;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: -0.28px;
        .light {
          font-weight: 400;
        }
        background-repeat: no-repeat;
        background-position: left center;
        &:nth-child(1) {
          background-image: url('/images/finalTitle_section5_icon.svg');
        }
        &:nth-child(2) {
          background-image: url('/images/finalTitle_section3_icon.svg');
        }
        &:nth-child(3),
        &:nth-child(4) {
          background-image: url('/images/finalTitle_section2_icon.svg');
        }
      }
    }
  }
  .explianBox {
    margin-top: 8px;
    padding: 12px;
    padding-left: 38px;
    background-color: $main000;
    border-radius: 4px;
    color: $neutral800;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.9px;
    text-align: left;
    background-image: url('/images/exclamationMark_main400.svg');
    background-repeat: no-repeat;
    background-position: top 12px left 12px;
  }
  .btnArea {
    margin-top: 24px;
    text-align: center;
    .applyBtn {
      margin: 0 auto;
      width: 300px;
    }
    .explain {
      display: inline-block;
      position: relative;
      margin-top: 8px;
      padding-left: 10px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 4px;
        left: 0;
      }
    }
  }
}
