@import '@standby/common-ui/color.scss';

.content {
  padding: 36px 16px 16px;
  border: 1px solid $neutral300;
  border-radius: 10px;
  text-align: center;
  .title {
    color: $neutral800;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.5px;
    .important {
      color: $main500;
      font-weight: 500;
    }
  }
  .img {
    margin-top: 20px;
  }
  .explain {
    margin-top: 20px;
    padding: 12px;
    padding-left: 38px;
    width: 100%;
    background-color: $main000;
    border-radius: 4px;
    color: $neutral800;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.9px;
    text-align: left;
    background-image: url('/images/exclamationMark_main400.svg');
    background-repeat: no-repeat;
    background-position: top 12px left 12px;
  }
}
