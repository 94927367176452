@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/stockHomeTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}
.contentBox {
  padding: 0;
  .content {
    margin-top: 54px;
    border-top: 1px solid $neutral300;
    padding: 0 50px;
    padding-top: 36px;
    .inputRow {
      display: flex;
      align-items: flex-start;
      margin-top: 24px;
      &:nth-child(2) {
        margin-top: 0;
      }
      &.verticalTop {
        .title {
          margin-top: 0;
        }
      }
      .title {
        margin-top: 11px;
        width: 160px;
        flex-shrink: 0;
        color: $neutral900;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.2;
        .explain {
          margin-top: 8px;
          color: $neutral600;
          font-size: 13px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: -0.2px;
        }
      }
      .kssnBox {
        .split {
          display: inline-block;
          margin: 0 5px;
          position: relative;
          top: -5px;
          width: 10px;
          height: 1px;
          background-color: $neutral600;
        }
      }
      .birthBox {
        display: flex;
        .month,
        .day {
          margin-left: 8px;
        }
        .subText {
          display: inline-block;
          margin-left: 6px;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
        }
      }
      .representativeInfo {
        margin-top: 8px;
        &:first-child {
          margin-top: 0;
        }
        &.last {
          padding-bottom: 10px;
          border-bottom: 1px solid $neutral300;
        }
        .inputRepresentative {
          display: inline-block;
          .subText {
            display: inline-block;
            margin-left: 8px;
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
          }
        }
        .selectRepresentative {
          margin-left: 20px;
        }
        .closeBtn {
          position: relative;
          top: 2px;
          margin-left: 5px;
          width: 16px;
          height: 16px;
        }
      }
      .addBtn {
        &.marginTop {
          margin-top: 16px;
        }
        padding: 14px 16px;
        width: 460px;
        border: 1px solid $main400;
        border-radius: 4px;
        text-align: left;
      }
      .stockInfo {
        width: 660px;
        .stockTitle {
          display: flex;
          padding: 10px 8px;
          color: $neutral900;
          font-size: 14px;
          font-weight: 500;
          > span {
            &:nth-child(1) {
              width: 92px;
            }
            &:nth-child(2) {
              width: 99px;
            }
            &:nth-child(3) {
              width: 134px;
            }
            &:nth-child(4) {
              width: 119px;
              text-align: right;
            }
            &:nth-child(5) {
              width: 200px;
              padding-left: 20px;
            }
          }
        }
        .stock {
          display: flex;
          padding: 10px 8px;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.28px;
          border-top: 1px solid $neutral300;
          > span {
            &:nth-child(1) {
              width: 92px;
            }
            &:nth-child(2) {
              width: 99px;
            }
            &:nth-child(3) {
              width: 134px;
            }
            &:nth-child(4) {
              width: 119px;
              text-align: right;
            }
            &:nth-child(5) {
              width: 200px;
              padding-left: 20px;
            }
          }
        }
        .stockSum {
          display: flex;
          align-items: center;
          width: 460px;
          padding: 10px 8px;
          background-color: $neutral200;
          border-top: 1px solid $neutral300;
          border-bottom: 1px solid $neutral300;
          .sumTitle {
            width: 191px;
            color: $neutral800;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            letter-spacing: -0.28px;
          }
          .sum {
            margin-top: 8px;
            &:first-child {
              margin-top: 0;
            }
            color: $neutral800;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            letter-spacing: -0.28px;
            > span {
              display: inline-block;
              &:nth-child(1) {
                width: 134px;
              }
              &:nth-child(2) {
                width: 119px;
                text-align: right;
                .light {
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
      .fileDownload {
        cursor: pointer;
        padding-left: 20px;
        color: $main300;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-decoration: underline;
        background-image: url('/images/downloadIcon_main300.svg');
        background-position: left center;
        background-repeat: no-repeat;
      }
      .radioBox {
        margin-top: 11px;
        display: flex;
        align-items: center;
        width: 143px;
      }
    }
  }
  .btnArea {
    margin-top: 36px;
    padding: 24px 0;
    width: 100%;
    border-top: 1px solid $neutral300;
    text-align: center;
    .submitBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}
