@import '@standby/common-ui/color.scss';

.fileuploadContent {
  margin-top: 20px;
  padding: 36px 50px;
  border: 1px solid $neutral300;
  border-radius: 10px;
  .fileuploadTitle {
    color: $neutral800;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
  }
  ul {
    li {
      display: flex;

      justify-content: space-between;
      padding: 12px 0 14px;
      border-bottom: 1px solid $neutral300;
      color: $neutral800;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      &:first-child {
        padding-top: 0;
      }
      .uploadFileInfo {
        display: flex;
        .uploadFileType {
          width: 266px;
          flex-shrink: 0;
          .required {
            color: $warning200;
          }
        }
        .error {
          color: $warning200;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
        }
        .uploadFileBox {
          cursor: pointer;
          display: inline-flex;
          align-items: top;
          margin-top: 11px;
          &:first-child {
            margin-top: 0;
          }
          .fileDownload {
            max-width: 230px;
            padding-left: 20px;
            color: $main300;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            text-decoration: underline;
            background-image: url('/images/download_icon.svg');
            background-repeat: no-repeat;
            background-position: left top 3px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            word-break: break-all;
          }
          .deleteBtn {
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            line-height: 130%;
            text-decoration-line: underline;
            background-color: transparent;
            margin-left: 8px;
          }
        }
      }
      .fileUpload {
        margin-left: 12px;
        flex-shrink: 0;
        cursor: pointer;
        padding-left: 20px;
        color: $neutral700;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-decoration: underline;
        background-image: url('/images/upload_icon_neutral700.svg');
        background-repeat: no-repeat;
        background-position: left top 3px;
      }
    }
  }
}
