@use '@standby/dike_ui/design-token/color.module.scss' as color;
@use '@standby/dike_ui/design-token/font.module.scss';

.joinMembershipContent {
  width: 100%;
  .logoWrap {
    text-align: center;
    .logo {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .mainTitle {
    color: color.$grayScale800;
    text-align: center;
  }
  .emailInput {
    margin-top: 20px;
  }
  .termsBox {
    padding-top: 20px;
    .termsList {
      margin-top: 8px;
      padding: 16px;
      border-radius: 4px;
      background-color: color.$grayScale100;
      li {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:first-child {
          margin-top: 0;
        }
        .textBtn {
          @extend .Detail-12M;
          color: color.$grayScale500;
        }
        .checkbox {
          color: color.$grayScale700;
          .notRequired {
            color: color.$grayScale400;
          }
        }
      }
    }
  }
  .mainBtn {
    margin-top: 48px;
    width: 100%;
  }
  .likeBtnBox {
    margin-top: 12px;
    text-align: center;
    .likeBtn {
      @extend .Body-14R;
      color: color.$grayScale700;
    }
  }
}
.agreeContent {
  padding: 16px 20px 20px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: color.$grayScale900;
}
