@use '@standby/dike_ui/design-token/color.module.scss' as color;

.readType {
  border-bottom: 1px solid color.$grayScale300;
  padding: 20px 0;

  display: flex;
  flex-direction: column;
  gap: 4px;

  .detail {
    position: relative;
    word-break: break-all;
    color: color.$grayScale900;
    a {
      text-decoration: underline;
      color: color.$grayScale700;
    }
  }

  &.read {
    .detail {
      color: color.$grayScale500;
      a {
        color: inherit;
      }
    }
  }
}
