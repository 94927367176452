@import '@standby/common-ui/color.scss';

.statusBox {
  margin-top: 20px;
  padding: 36px 50px;
  width: 794px;
  height: 263px;
  border: 1px solid $neutral300;
  border-radius: 10px;
  text-align: center;
  .registrationStepBox {
    position: relative;
    .date {
      position: absolute;
      color: $neutral600;
      font-size: 13px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: -0.26px;
      &[data-step='고객_통지_동의'] {
        bottom: 3px;
        left: 10px;
      }
      &[data-step='등기완료'] {
        bottom: 3px;
        left: 491px;
      }
    }
  }
  .changeRegistration {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    > div {
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.28px;
      .explain {
        position: relative;
        padding-left: 10px;
        &:before {
          content: '*';
          display: inline-block;
          position: absolute;
          top: 4px;
          left: 0;
        }
      }
    }
    > button {
      padding-right: 14px;
      color: $main300;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.28px;
      text-decoration: underline;
      background-color: transparent;
      background-image: url('/images/arrow_icon.svg');
      background-repeat: no-repeat;
      background-position: right top 3px;
    }
  }
}

.upDateBox {
  width: 794px;
  height: 272px;
  border: 1px solid $neutral300;
  border-radius: 10px;
  text-align: center;
  .upDateTitle {
    margin-top: 36px;
    color: $neutral800;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.5px;
    .important {
      color: $main500;
    }
  }
  .upDateImgBox {
    position: relative;
    margin-top: 16px;
    .registrationComplete {
      position: absolute;
      bottom: -40px;
      left: 125px;
      color: $neutral800;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.5px;
    }
    .autoUpDate {
      position: absolute;
      bottom: -52px;
      right: 83px;
      color: $neutral800;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.5px;
      .important {
        color: $main500;
      }
    }
  }
}
