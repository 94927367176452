@import '@standby/common-ui/color.scss';

.meetings {
  position: relative;
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 113px;
    bottom: 0;
    border: 1px dashed $neutral300;
  }
  > .meeting {
    background-color: $neutral100;
    display: flex;
    justify-content: space-between;
    &:last-child {
      position: relative;
      z-index: 1;
    }
    &:not(:first-child) {
      margin-top: 42px;
    }
    > .sideTitle {
      width: 226px;
      height: 52px;
      border-radius: 10px;
      border: 1px solid $neutral400;
      background: $neutral200;
      color: $neutral800;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 14px;
      position: relative;
      z-index: 1;
    }
    > .meetingInfo {
      width: 794px;
      .detailTitle {
        height: 52px;
        border-radius: 10px;
        border: 1px solid $neutral400;
        color: $neutral800;
        font-size: 16px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 18px;
      }
      .detailList {
        border-radius: 10px;
        border: 1px solid $neutral300;
        margin-top: 20px;
        padding: 0 18px;
        > li {
          padding: 14px 0;
          display: flex;
          &:not(:last-child) {
            border-bottom: 1px solid $neutral300;
          }
          > .title {
            width: 134px;
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            padding-left: 26px;
            background-repeat: no-repeat;
            background-position: left top;
            &.agenda {
              background-image: url('/images/agenda_title_icon.svg');
            }
            &.place {
              background-image: url('/images/place_title_icon.svg');
            }
            &.date {
              background-image: url('/images/date_title_icon.svg');
            }
            &.notificationMethod {
              background-image: url('/images/notificationMethod_title_icon.svg');
            }
            &.notice {
              background-image: url('/images/notice_title_icon.svg');
            }
            &.receipt {
              background-image: url('/images/receipt_title_icon.svg');
            }
            &.minutes {
              background-image: url('/images/minutes_title_icon.svg');
            }
          }
          > .content {
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            flex: 1;
            .agendaList li:not(:first-child) {
              margin-top: 4px;
            }
            &.warn {
              color: $warning200;
              font-weight: 500;
            }
          }
        }
      }
      .personResultList {
        margin-top: 24px;
        .title {
          color: $neutral800;
          font-size: 16px;
          font-weight: 400;
          padding-left: 32px;
          background-image: url('/images/notificationResult_title_icon.svg');
          background-repeat: no-repeat;
          background-position: top left;
        }
        .qnaWrap {
          margin-top: 14px;
          .tooltipContent {
            padding: 12px 16px;
            padding-bottom: 16px;
            width: 460px;
            .qnaUpperTitle {
              margin-bottom: -2px;
              color: $neutral800;
              font-size: 14px;
              font-weight: 500;
              line-height: 18px;
              &.borderTop {
                border-top: 1px solid $neutral300;
                padding-top: 16px;
                margin-top: 16px;
              }
            }
            .qna {
              margin-top: 12px;
              &:first-child {
                margin-top: 0;
              }
              .qnaTitle {
                display: inline-block;
                padding: 4px 6px;
                color: $main500;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                background-color: $main000;
                border-radius: 2px;
              }
              .qnaContent {
                margin-top: 6px;
                color: $neutral700;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                ul {
                  li {
                    position: relative;
                    padding-left: 9px;
                    margin-top: 4px;
                    &:first-child {
                      margin-top: 0;
                    }
                    &:before {
                      content: '';
                      display: inline-block;
                      position: absolute;
                      top: 8px;
                      left: 0;
                      width: 3px;
                      height: 3px;
                      background-color: $neutral600;
                      border-radius: 50%;
                    }
                  }
                }
              }
            }
          }
        }
        .moreViewBtn {
          margin: 0 auto;
          margin-top: 8px;
          display: block;
          background-color: transparent;
          > span {
            padding-right: 16px;
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            text-decoration: underline;
            background-image: url('/images/moreView_close.svg');
            background-repeat: no-repeat;
            background-position: right center;
            &.open {
              background-image: url('/images/moreView_open.svg');
            }
          }
        }
        .personListTable {
          margin-top: 17px;
          width: 100%;
          thead {
            tr {
              th {
                line-height: 14px;
                letter-spacing: -0.28px;
                &:nth-child(1) {
                  padding-left: 35px;
                }
                &:nth-child(4) {
                  text-align: left;
                }
                &:nth-child(5) {
                  text-align: right;
                }
                &:nth-child(6) {
                  text-align: right;
                }
                &.download {
                  position: relative;
                  cursor: pointer;
                  > span {
                    display: inline-block;
                    padding-right: 16px;
                    background-image: url('/images/table_download_close.svg');
                    background-repeat: no-repeat;
                    background-position: right top;
                  }
                  .allDownloadBtn {
                    position: absolute;
                    bottom: -34px;
                    left: 0;
                    width: 113px;
                    height: 40px;
                    border: 1px solid $neutral400;
                    border-radius: 4px;
                    box-shadow: 1px 1px 3px 1px rgba(102, 102, 102, 0.2);
                    background-color: $neutral100;
                    color: $neutral800;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 14px;
                    opacity: 0;
                    visibility: hidden;
                  }
                  &:hover {
                    > span {
                      background-image: url('/images/table_download_open.svg');
                    }
                    .allDownloadBtn {
                      opacity: 1;
                      visibility: visible;
                    }
                  }
                }
              }
            }
          }
          tbody {
            tr {
              td {
                line-height: 16px;
                letter-spacing: -0.28px;
                &:nth-child(1) {
                  padding: 10px 10px 10px 8px;
                  > span {
                    display: inline-block;
                    margin-right: 4px;
                    width: 23px;
                  }
                }
                &:nth-child(2) {
                  text-align: center;
                }
                &:nth-child(3) {
                  text-align: center;
                }
                &.stockName {
                  padding: 10px 4px 10px 10px;
                }
                &.stockNumber {
                  padding: 10px 6px 10px 4px;
                  text-align: right;
                }
                &.stockRate {
                  padding: 10px 8px 10px 6px;
                  text-align: right;
                }
                .secon200 {
                  color: $secon200;
                  white-space: pre-line;
                }
                .sub300 {
                  color: $sub300;
                  white-space: pre-line;
                }
                .warning200 {
                  position: relative;
                  padding-right: 21px;
                  display: inline-block;
                  color: $warning200;
                  white-space: pre-line;
                  .tooltip {
                    position: absolute;
                    top: 0;
                    right: 0;
                    > span {
                      background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_negative.svg');
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
