@import '@standby/common-ui/color.scss';

.table {
  border: 1px solid $neutral300;
  thead {
    tr {
      th {
        padding: 10px 12px;
        color: $neutral100;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        background-color: $neutral600;
        &:nth-child(1) {
          text-align: left;
        }
        &:nth-child(2) {
          text-align: left;
        }
      }
    }
  }
  tbody {
    tr {
      border: 1px solid $neutral300;
      td {
        padding: 10px 12px;
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
    }
  }
  tfoot {
    tr {
      td {
        padding: 10px 12px;
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.02em;
        background-color: $neutral200;
      }
    }
  }
}
