@import '@standby/common-ui/color.scss';

@keyframes squareMoving {
  from {
    transform: translateY(13px);
  }
  to {
  }
}

.joinBackground {
  background: linear-gradient(
    180deg,
    #07174e 0%,
    #0a3075 12.81%,
    #1b61ad 29.61%,
    #5099dc 46.56%,
    #6ac5f8 62.37%,
    #a0e2ff 74.85%,
    #f0fafe 90.77%,
    #fff 100%
  );
  position: absolute;
  height: 794px;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  min-width: 723px;
  .firecracker {
    position: absolute;
    min-width: 1600px;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    img {
      transition:
        opacity 2s ease-out,
        transform 1s;
      opacity: 0.3;
      position: absolute;
      transform: scale(0);
      &:nth-child(1) {
        top: 130px;
        left: 0;
      }
      &:nth-child(2) {
        top: 220px;
        left: 370px;
      }
      &:nth-child(3) {
        top: 50px;
        left: 450px;
      }
      &:nth-child(4) {
        top: 90px;
        left: 470px;
      }
      &:nth-child(5) {
        top: 206px;
        right: 492px;
      }
      &:nth-child(6) {
        top: 20px;
        right: 430px;
      }
      &:nth-child(7) {
        top: 320px;
        right: 330px;
      }
      &:nth-child(8) {
        top: 80px;
        right: 0px;
      }
    }
  }
  .square {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 330px;
    div {
      position: absolute;
      top: 200px;
      left: 0px;
      opacity: 0;
      transform-origin: center;
      transition:
        top 0.7s ease-in-out,
        left 0.7s ease-in-out,
        opacity 1s;
      &:nth-child(1) {
        width: 9px;
        height: 9px;
        background-color: #fff;
        transform: rotate(100deg);
        animation: squareMoving 0.7s infinite;
        animation-direction: alternate;
      }
      &:nth-child(2) {
        width: 9px;
        height: 9px;
        background-color: #0086b8;
        transform: rotate(150deg);
        animation: squareMoving 1.3s infinite;
        animation-direction: alternate;
      }
      &:nth-child(3) {
        width: 14px;
        height: 14px;
        background-color: #00b4e3;
        transform: rotate(80deg);
        animation: squareMoving 1.2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(4) {
        width: 9px;
        height: 9px;
        background-color: #0086b8;
        transform: rotate(200deg);
        animation: squareMoving 1s infinite;
        animation-direction: alternate;
      }
      &:nth-child(5) {
        width: 6px;
        height: 6px;
        background-color: #fff0a3;
        transform: rotate(150deg);
        animation: squareMoving 1.2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(6) {
        width: 6px;
        height: 6px;
        background-color: #a3f9ff;
        transform: rotate(-120deg);
        animation: squareMoving 1.6s infinite;
        animation-direction: alternate;
      }
      &:nth-child(7) {
        width: 9px;
        height: 9px;
        background-color: #fff;
        transform: rotate(130deg);
        animation: squareMoving 2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(8) {
        width: 9px;
        height: 9px;
        background-color: #0086b8;
        transform: rotate(100deg);
        animation: squareMoving 0.7s infinite;
        animation-direction: alternate;
      }
      &:nth-child(9) {
        width: 9px;
        height: 9px;
        background-color: #00b4e3;
        transform: rotate(120deg);
        animation: squareMoving 0.9s infinite;
        animation-direction: alternate;
      }
      &:nth-child(10) {
        width: 14px;
        height: 14px;
        background-color: #fff0a3;
        transform: rotate(80deg);
        animation: squareMoving 1.2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(11) {
        width: 6px;
        height: 6px;
        background-color: #a3f9ff;
        transform: rotate(180deg);
        animation: squareMoving 0.7s infinite;
        animation-direction: alternate;
      }
    }
  }
  &.action {
    .firecracker {
      img {
        opacity: 1;
        transform: scale(1);
        &:nth-child(2) {
          transition-delay: 0.3s;
        }
        &:nth-child(5) {
          transition-delay: 0.6s;
        }
        &:nth-child(7) {
          transition-delay: 0.9s;
        }
        &:nth-child(3) {
          transition-delay: 1.2s;
        }
        &:nth-child(4) {
          transition-delay: 1.6s;
        }
        &:nth-child(1) {
          transition-delay: 1.5s;
        }
        &:nth-child(8) {
          transition-delay: 1.2s;
        }
      }
    }
    .square {
      div {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        transition-delay: 2.5s;
        &:nth-child(1) {
          top: -170px;
          left: -500px;
        }
        &:nth-child(2) {
          top: -280px;
          left: -450px;
        }
        &:nth-child(3) {
          top: -200px;
          left: -400px;
        }
        &:nth-child(4) {
          top: -130px;
          left: -280px;
        }
        &:nth-child(5) {
          top: -310px;
          left: -170px;
        }
        &:nth-child(6) {
          top: -290px;
          left: 160px;
        }
        &:nth-child(7) {
          top: -240px;
          left: 190px;
        }
        &:nth-child(8) {
          top: -110px;
          left: 210px;
        }
        &:nth-child(9) {
          top: -50px;
          left: 290px;
        }
        &:nth-child(10) {
          top: -130px;
          left: 360px;
        }
        &:nth-child(11) {
          top: -250px;
          left: 400px;
        }
      }
    }
  }
}
.companyRegistraion {
  margin: 0 auto;
  margin-top: 75px;
  padding-bottom: 60px;
  width: 723px;
  text-align: center;
  .form {
    margin: 0 auto;
    margin-top: 48px;
    padding: 40px 40px 60px;
    width: 540px;
    border-radius: 28px;
    background-color: $neutral100;
    box-shadow: 1px 1px 6px 4px rgba(153, 153, 153, 0.2);
    text-align: left;
    .row {
      margin-top: 38px;
      &:first-child {
        margin-top: 0;
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .text {
          font-weight: 500;
          color: $neutral900;
          font-size: 15px;
          line-height: 22px;
          &:after {
            content: '*';
            color: $warning100;
          }
        }
        .tooltipBtn {
          display: block;
          padding: 7px 12px;
          height: 34px;
          border: 1px solid $neutral300;
          border-radius: 18px;
          background-color: $neutral100;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          > span {
            display: inline-block;
            padding-left: 22px;
            background-image: url('/images/companyRegistraion_info_question_icon.svg');
            background-repeat: no-repeat;
          }
        }
      }
      .content {
        margin-top: 8px;
        .companyInfoBox {
          padding: 11px 12px;
          width: 100%;
          border: 1px solid $neutral400;
          border-radius: 4px;
          background-color: $neutral100;
          text-align: left;
          .companyInfo {
            padding-left: 46px;
            position: relative;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: -2px;
              left: 0;
              width: 39px;
              height: 39px;
              background-image: url('/images/company_icon.svg');
              background-repeat: no-repeat;
              background-size: cover;
            }
            .companyTitle {
              color: $neutral800;
              font-size: 14px;
              font-weight: 400;
              line-height: 1;
            }
            .companyAddress {
              margin-top: 6px;
              color: $neutral800;
              font-size: 13px;
              font-weight: 400;
              line-height: 1;
            }
          }
        }
      }
    }
    .btnArea {
      margin-top: 48px;
      .squareBtn {
        width: 100%;
      }
      .btnExplain {
        margin-top: 16px;
        color: $neutral500;
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
      }
    }
  }
}
