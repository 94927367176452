@import '@standby/common-ui/color.scss';
.detailWrap {
  margin-left: 20px;
  .wrap {
    width: 510px;
    border: 1px solid $neutral300;
    border-radius: 12px;
    position: relative;
    padding: 0 20px;
    > .title {
      font-size: 18px;
      font-weight: 500;
      color: $neutral900;
      padding-left: 30px;
      background-position: left top;
      background-repeat: no-repeat;
      position: absolute;
      left: 20px;
      top: 18px;
      height: 24px;
      align-items: center;
      line-height: 24px;
    }
    &:nth-child(1) {
      min-height: 142px;
      > .title {
        background-image: url('/images/stockoptionTitleIcon.svg');
      }
      li > .title {
        width: 96px;
        background-image: url('/images/stockoptionIcon_gray.svg');
        background-position: left 0 top 2px !important;
      }
      li:nth-child(1) {
        > .title {
          background-image: url('/images/stockoptionIcon_blue.svg');
        }
      }
    }
    &:nth-child(2) {
      min-height: 160px;
      margin-top: 20px;
      > .title {
        background-image: url('/images/stockTransferTitleIcon.svg');
      }
      li > .title {
        width: 96px;
        background-image: url('/images/detailPageIcon_gray.svg');
        background-position: left 0 top 2px !important;
      }
      li:nth-child(1) {
        > .title {
          background-image: url('/images/detailPageIcon_blue.svg');
        }
      }
    }
    > .detailList {
      margin-top: 60px;
      li {
        display: flex;
        line-height: 100%;
        border-bottom: 1px solid $neutral300;
        padding-bottom: 16px;
        padding-top: 15px;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: -0.5px;
        color: $neutral800;
        line-height: 1.3;
        &:nth-child(1) {
          padding-top: 0;
        }
        &:nth-last-child(1) {
          border: none;
        }
        > .title {
          padding-left: 22px;
          background-repeat: no-repeat;
          background-position: left top;
          flex-shrink: 0;
        }
        > .content {
          &.empty {
            color: $neutral600;
            font-weight: 400;
          }
        }
      }
    }
    .openModalBtn {
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.28px;
      text-decoration: underline;
      color: $main300;
      background-image: url('/images/arrowRight_main300.svg');
      background-repeat: no-repeat;
      background-position: right top 3px;
      padding-right: 16px;
      position: absolute;
      display: inline-block;
      right: 20px;
      top: 21px;
    }
  }
}
