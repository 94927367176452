@import '@standby/common-ui/color.scss';

.info {
  margin-top: 24px;

  border-radius: 4px;
  border: 1px solid $neutral300;
  background: $neutral200;

  display: inline-flex;
  padding: 11px 16px 13px;
  align-items: center;
  gap: 8px;

  width: 100%;

  .icon {
    width: 18px;
    height: 18px;
    padding: 3.5px;
    border-radius: 50%;
    background-color: $neutral500;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    color: $neutral700;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  .content {
    color: $neutral800;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 16.9px */
    letter-spacing: -0.26px;
  }
}

.contentBox {
  padding: 0;
  margin-top: 24px;
  .select {
    cursor: pointer;
    appearance: none;
    position: relative;
    display: inline-block;
    padding: 0 14px;
    padding-right: 30px;
    width: 300px;
    height: 28px;
    border: 1px solid $neutral400;
    border-radius: 2px;
    color: $neutral800;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.02em;
    background-image: url('~@standby/common-ui/components/asset/Icon/select_arrow.svg');
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: right 10px top 7px;
  }
  .content {
    margin-top: 54px;
    border-top: 1px solid $neutral300;
    padding: 0 50px;
    padding-top: 36px;
    .selectVat {
      display: flex;
      align-items: center;
      .title {
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: -0.02em;
      }
      .selectBox {
        margin-left: 12px;
        .select {
          width: 300px;
        }
      }
    }
    .reportingExemptPeriod {
      margin-top: 12px;
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.02em;
      .reportingExemptExplain {
        position: relative;
        margin-top: 8px;
        padding-left: 10px;
        color: $neutral600;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.02em;
        &:before {
          content: '*';
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 0;
        }
        a {
          cursor: pointer;
          margin-left: 6px;
          color: $main300;
          text-decoration: underline;
        }
      }
    }
    .reportingPeriod {
      position: relative;
      padding-left: 10px;
      margin-top: 12px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.02em;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 2px;
      }
      .date {
        margin-left: 2px;
        color: $neutral800;
      }
    }
    .stepInfoBox {
      position: relative;
      .stepImg {
        display: block;
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 57px;
      }
      .stepInfo {
        color: $neutral600;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.02em;
        .step2 {
          position: absolute;
          left: 235px;
          &.collection {
            top: 141px;
          }
          &.waiting {
            top: 307px;
          }
        }
        .step3 {
          position: absolute;
          top: 224px;
          left: 414px;
          .text {
            margin-left: 1px;
          }
        }
        .step4 {
          position: absolute;
          top: 224px;
          left: 594px;
          .text {
            margin-left: 1px;
          }
        }
      }
    }
    .reportingExemptyStepInfoBox {
      position: relative;
      .stepImg {
        display: block;
        margin: 0 auto;
        margin-top: 24px;
        margin-bottom: 57px;
      }
      .stepInfo {
        position: absolute;
        bottom: -4px;
        left: 418px;
        color: $neutral600;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.02em;
      }
    }
    .infoBox {
      margin-top: 24px;
      padding-bottom: 48px;
      text-align: center;
      .periodInfo {
        margin-top: 12px;
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        .explian {
          display: inline-block;
          position: relative;
          padding-left: 10px;
          margin-top: 6px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.02em;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 0;
          }
        }
      }
      .infoExemptionLink {
        margin-top: 16px;
        color: $main300;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        text-decoration: underline;
      }
    }

    .material {
      margin-top: 36px;
      &:first-child {
        margin-top: 0;
      }
      .title {
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
      }
      .materialLink {
        cursor: pointer;
        display: block;
        margin-top: 16px;
        width: 460px;
        height: 103px;
        border: 1px solid $main400;
        border-radius: 8px;
        background-color: $main000;
        box-shadow: 1px 1px 2px 1px rgba(153, 153, 153, 0.16);
        text-align: center;
        .btnTitle {
          display: inline-block;
          position: relative;
          padding-left: 44px;
          padding-right: 18px;
          margin-top: 28px;
          color: $main500;
          font-size: 20px;
          font-weight: 400;
          list-style: 1.3;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -7px;
            left: 0;
            width: 38px;
            height: 36px;
            background-image: url('/images/corporateTax_material_icon.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
          &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 3px;
            right: 0;
            width: 16px;
            height: 16px;
            background-image: url('/images/corporateTax_material_arrow_icon.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .companyName {
          display: block;
          margin-top: 8px;
          color: $neutral700;
          font-size: 13px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: -0.02em;
        }
      }
      .deadline,
      .reception {
        margin-top: 16px;
        padding-left: 16px;
        color: $neutral800;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        .date,
        .email {
          color: $main500;
        }
      }
      .deadlineExplain {
        position: relative;
        padding-left: 26px;
        margin-top: 6px;
        color: $neutral600;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
        &:before {
          content: '*';
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 16px;
        }
      }
    }
    .vatQuestion {
      position: relative;
      margin-top: 36px;
      margin-bottom: 36px;
      padding-left: 16px;
      color: $neutral700;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.02em;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 1px;
        left: 6px;
        width: 8px;
        height: 12px;
        background-image: url('/images/corporateTax_arrow_icon.svg');
        background-size: cover;
        background-repeat: no-repeat;
      }
      a {
        display: inline-block;
        margin-top: 8px;
        cursor: pointer;
        color: $main300;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        text-decoration: underline;
      }
    }

    .vatTaxesBox {
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px solid $neutral300;
      &:first-child {
        padding-top: 0;
        margin-top: 0;
        border-top: none;
      }
      &:last-child {
        padding-bottom: 36px;
      }
      .vatTaxesTitle {
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: -0.02em;
        .vatTaxesTitleExplain {
          position: relative;
          padding-left: 10px;
          margin-top: 8px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: -0.02em;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 0;
          }
        }
      }
      .estimatedAmountOfNoticeBox {
        position: relative;
        margin-top: 16px;
        padding: 12px;
        width: 220px;
        height: 205px;
        border: 1px solid $neutral400;
        border-radius: 4px;
        .estimatedAmountOfNotice {
          display: flex;
          justify-content: space-between;
          padding: 2px 0;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: -0.02em;
          &.highlight {
            background-color: $secon100;
          }
        }
        .periodExplain,
        .homeTax {
          position: relative;
          padding-left: 10px;
          margin-top: 3px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.02em;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 0;
          }
          &.marginTop {
            margin-top: 70px;
          }
          &.homeTax {
            margin-top: 48px;
            .homeTaxLink {
              position: relative;
              display: inline-block;
              margin-top: 6px;
              color: $main300;
              text-decoration: underline;
              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 1px;
                right: -16px;
                width: 14px;
                height: 14px;
                background-image: url('/images/homeTax_arrow_icon.svg');
                background-size: cover;
                background-repeat: no-repeat;
              }
            }
          }
        }
      }
      .vatTaxes {
        display: flex;
        flex-wrap: wrap;
        margin-top: 16px;
        .vatDataBox {
          position: relative;
          padding: 12px;
          width: 220px;
          height: 205px;
          border: 1px solid $neutral400;
          border-radius: 4px;
          .vatDataInfo {
            height: 84px;
            .vatTax {
              display: flex;
              justify-content: space-between;
              margin-top: 6px;
              &:first-child {
                margin-top: 0;
              }
              color: $neutral800;
              font-size: 14px;
              font-weight: 400;
              line-height: 1;
              letter-spacing: -0.04em;
            }
          }
          .totalVatDataInfo {
            padding-top: 8px;
            border-top: 1px solid $neutral300;
            .totalVatData {
              display: flex;
              justify-content: space-between;
              padding: 2px 0;
              color: $neutral800;
              font-size: 14px;
              font-weight: 400;
              line-height: 1;
              letter-spacing: -0.02em;
              &.highlight {
                background-color: $secon100;
              }
            }
            .dateExplain {
              position: relative;
              padding-left: 10px;
              margin-top: 5px;
              color: $neutral600;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.3;
              letter-spacing: -0.02em;
              &:before {
                content: '*';
                display: inline-block;
                position: absolute;
                top: 3px;
                left: 0;
              }
            }
          }
          .fileBtn {
            position: absolute;
            bottom: 12px;
            left: 12px;
            width: 196px;
          }
        }
        .earlyRefoundExplain {
          position: relative;
          padding-left: 10px;
          margin-top: 8px;
          width: 210px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 0;
          }
        }
        .vatFile {
          position: relative;
          margin-left: 19px;
          width: 220px;
          height: 205px;
          .thumbnailFile {
            position: relative;
            width: 100%;
            height: 100%;
            border: 1px solid $neutral400;
            border-radius: 4px;
            background-size: 220px;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: -1px;
              left: 0;
              width: 218px;
              height: 205px;
              border-radius: 4px;
              background-color: rgba(153, 153, 153, 0.4);
            }
          }
          .fileBtn {
            position: absolute;
            bottom: 12px;
            left: 12px;
            width: 196px;
          }
        }
      }
    }
  }
}
