@import '@standby/common-ui/color.scss';

.accountRegistrationPopup {
  padding: 36px 50px;
  width: 850px;
  .infoBox {
    .info {
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
      position: relative;
      padding-left: 20px;
      color: $neutral800;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.02em;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 16px;
        height: 16px;
        background-image: url('/images/active_check_icon.svg');
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
  .form {
    margin-top: 24px;
    padding: 24px 0;
    border-top: 1px solid $neutral300;
    .bankbookList {
      .title {
        color: $neutral900;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }
      .header {
        margin-top: 16px;
        display: flex;
        padding: 10px 8px;
        width: 100%;
        height: 35px;
        background-color: $neutral200;
        border-top: 1px solid $neutral300;
        border-bottom: 1px solid $neutral300;
        color: $neutral700;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        > div {
          &:nth-child(1) {
            width: 54px;
          }
          &:nth-child(2) {
            width: 122px;
          }
          &:nth-child(3) {
            width: 200px;
          }
        }
      }
      .bankList {
        li {
          display: flex;
          padding: 17px 8px;
          border-bottom: 1px solid $neutral300;
          > span {
            &:nth-child(1) {
              width: 54px;
              flex-shrink: 0;
            }
            &:nth-child(2) {
              width: 122px;
              flex-shrink: 0;
            }
            &:nth-child(3) {
              width: 200px;
              flex-shrink: 0;
            }
          }
          .radio {
            > span {
              margin-left: 0;
              &:before {
                position: relative;
                top: 3px;
                left: 0;
              }
              &:after {
                top: 3px;
                left: 0;
              }
            }
          }
          .bankName {
            position: relative;
            padding-left: 28px;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 1px;
              left: 0;
              width: 22px;
              height: 22px;
              background-repeat: no-repeat;
              background-image: url('/images/bankLogo_medium.svg');
            }
            &.신한은행:before {
              background-position: left 0 top 0;
            }
            &.국민은행:before {
              background-position: left -142px top 0;
            }
            &.기업은행:before {
              background-position: left -284px top 0;
            }
            &.농협은행:before {
              background-position: left -426px top 0;
            }
            &.우리은행:before {
              background-position: left 0 top -44px;
            }
            &.하나은행:before {
              background-position: left -142px top -44px;
            }
            &.산업은행:before {
              background-position: left -284px top -44px;
            }
            &.수협은행:before {
              background-position: left -426px top -44px;
            }
            &.신협은행:before {
              background-position: left 0 top -88px;
            }
            &.부산은행:before {
              background-position: left -142px top -88px;
            }
            &.전북은행:before {
              background-position: left -284px top -88px;
            }
            &.광주은행:before {
              background-position: left -426px top -88px;
            }
            &.대구은행:before {
              background-position: left 0 top -132px;
            }
            &.새마을금고:before {
              background-position: left -142px top -132px;
            }
            &.SC은행:before {
              background-position: left -284px top -132px;
            }
            &.경남은행:before {
              background-position: left -426px top -132px;
            }
            &.한국씨티은행:before {
              background-position: left 0 top -176px;
            }
            &.우체국:before {
              background-position: left -142px top -176px;
            }
            &.카카오뱅크:before {
              background-position: left -284px top -176px;
            }
            &.토스뱅크:before {
              background-position: left -426px top -176px;
            }
            &.케이뱅크:before {
              background-position: left 0 top -220px;
            }
            &.SBI저축은행:before {
              background-position: left -142px top -220px;
            }
            &.산림조합:before {
              background-position: left -284px top -220px;
            }
          }
          &.disabled {
            position: relative;
            padding-bottom: 41px;
            color: $neutral500;
            .cmsExplain {
              position: absolute;
              bottom: 17px;
              left: 64px;
              color: $neutral600;
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              &:before {
                content: '*';
                display: inline-block;
                margin-right: 2px;
                position: relative;
                top: 4px;
              }
            }
          }
        }
      }
      .addAccountBtn {
        margin-top: 24px;
        font-size: 15px;
        font-weight: 400;
        line-height: 15px;
      }
    }
    .row {
      margin-top: 24px;
      &:first-child {
        margin-top: 0;
      }
      .title {
        color: $neutral900;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        .option {
          color: $neutral500;
          font-weight: 400;
        }
      }
      .content {
        margin-top: 16px;
        .bankContent {
          position: relative;
          &.open {
            margin-bottom: 384px;
          }
          .bankBoxOpenBtn {
            width: 220px;
            height: 42px;
            border: 1px solid $neutral400;
            border-radius: 4px;
            background-color: $neutral100;
            color: $main500;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            .text {
              padding-right: 16px;
              background-image: url('/images/bankChooseBtn_icon.svg');
              background-repeat: no-repeat;
              background-position: right center;
              &.open {
                background-image: url('/images/bankChooseBtn_icon_open.svg');
              }
            }
          }
          .chooseBankBtn {
            padding: 10px 14px;
            width: 220px;
            height: 42px;
            border: 1px solid $neutral400;
            border-radius: 4px;
            background-color: $neutral100;
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            background-image: url('/images/bankChooseBtn_icon.svg');
            background-repeat: no-repeat;
            background-position: right 14px center;
            text-align: left;
            .text {
              position: relative;
              padding-left: 28px;
              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                width: 22px;
                height: 22px;
                background-repeat: no-repeat;
                background-image: url('/images/bankLogo_medium.svg');
              }
              &.신한은행:before {
                background-position: left 0 top 0;
              }
              &.국민은행:before {
                background-position: left -142px top 0;
              }
              &.기업은행:before {
                background-position: left -284px top 0;
              }
              &.농협은행:before {
                background-position: left -426px top 0;
              }
              &.우리은행:before {
                background-position: left 0 top -44px;
              }
              &.하나은행:before {
                background-position: left -142px top -44px;
              }
              &.산업은행:before {
                background-position: left -284px top -44px;
              }
              &.수협은행:before {
                background-position: left -426px top -44px;
              }
              &.신협은행:before {
                background-position: left 0 top -88px;
              }
              &.부산은행:before {
                background-position: left -142px top -88px;
              }
              &.전북은행:before {
                background-position: left -284px top -88px;
              }
              &.광주은행:before {
                background-position: left -426px top -88px;
              }
              &.대구은행:before {
                background-position: left 0 top -132px;
              }
              &.새마을금고:before {
                background-position: left -142px top -132px;
              }
              &.SC은행:before {
                background-position: left -284px top -132px;
              }
              &.경남은행:before {
                background-position: left -426px top -132px;
              }
              &.한국씨티은행:before {
                background-position: left 0 top -176px;
              }
              &.우체국:before {
                background-position: left -142px top -176px;
              }
              &.카카오뱅크:before {
                background-position: left -284px top -176px;
              }
              &.토스뱅크:before {
                background-position: left -426px top -176px;
              }
              &.케이뱅크:before {
                background-position: left 0 top -220px;
              }
              &.SBI저축은행:before {
                background-position: left -142px top -220px;
              }
              &.산림조합:before {
                background-position: left -284px top -220px;
              }
            }
          }
          .bankListBox {
            position: absolute;
            margin-top: 12px;
            padding: 18px;
            width: 100%;
            height: 348px;
            border: 1px solid $neutral300;
            visibility: hidden;
            opacity: 0;
            &.open {
              visibility: visible;
              opacity: 1;
            }
            .bankLabel {
              display: inline-block;
              margin-left: 12px;
              &:nth-child(4n + 1) {
                margin-left: 0;
              }
              &:nth-child(n + 5) {
                margin-top: 12px;
              }
              padding: 12px 20px 8px;
              width: 169px;
              height: 42px;
              border: 1px solid $neutral400;
              border-radius: 4px;
              color: $neutral800;
              font-size: 14px;
              font-weight: 400;
              line-height: 14px;
              &.checked {
                border: 1px solid $main400;
                color: $main400;
              }
              .radio {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -10000;
                width: 0;
                height: 0;
              }
              .bankName {
                position: relative;
                padding-left: 28px;
                &:before {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: -3px;
                  left: 0;
                  width: 22px;
                  height: 22px;
                  background-repeat: no-repeat;
                  background-image: url('/images/bankLogo_medium.svg');
                }
                &.신한은행:before {
                  background-position: left 0 top 0;
                }
                &.국민은행:before {
                  background-position: left -142px top 0;
                }
                &.기업은행:before {
                  background-position: left -284px top 0;
                }
                &.농협은행:before {
                  background-position: left -426px top 0;
                }
                &.우리은행:before {
                  background-position: left 0 top -44px;
                }
                &.하나은행:before {
                  background-position: left -142px top -44px;
                }
                &.산업은행:before {
                  background-position: left -284px top -44px;
                }
                &.수협은행:before {
                  background-position: left -426px top -44px;
                }
                &.신협은행:before {
                  background-position: left 0 top -88px;
                }
                &.부산은행:before {
                  background-position: left -142px top -88px;
                }
                &.전북은행:before {
                  background-position: left -284px top -88px;
                }
                &.광주은행:before {
                  background-position: left -426px top -88px;
                }
                &.대구은행:before {
                  background-position: left 0 top -132px;
                }
                &.새마을금고:before {
                  background-position: left -142px top -132px;
                }
                &.SC은행:before {
                  background-position: left -284px top -132px;
                }
                &.경남은행:before {
                  background-position: left -426px top -132px;
                }
                &.한국씨티은행:before {
                  background-position: left 0 top -176px;
                }
                &.우체국:before {
                  background-position: left -142px top -176px;
                }
                &.카카오뱅크:before {
                  background-position: left -284px top -176px;
                }
                &.토스뱅크:before {
                  background-position: left -426px top -176px;
                }
                &.케이뱅크:before {
                  background-position: left 0 top -220px;
                }
                &.SBI저축은행:before {
                  background-position: left -142px top -220px;
                }
                &.산림조합:before {
                  background-position: left -284px top -220px;
                }
              }
            }
          }
        }
        .tooltipContent {
          padding: 10px 20px 16px;
          width: 752px;
          .method1,
          .method2 {
            &.method2 {
              margin-top: 16px;
            }
            .title {
              display: flex;
              align-items: center;
              .number {
                padding: 4px 6px;
                width: 49px;
                height: 26px;
                background-color: $main000;
                border-radius: 2px;
                color: $main500;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
              }
              .text {
                margin-left: 5px;
                color: $neutral700;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
              }
            }
            .method1Example {
              margin-top: 6px;
              display: flex;
              justify-content: space-between;
              .title {
                color: $neutral600;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
              }
              .img {
                margin-top: 6px;
              }
            }
            .bankbookImg {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  .btnArea {
    margin-top: 24px;
    .nextBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}
