@import '@standby/common-ui/color.scss';

.modal {
  width: 460px;
  .title {
    padding-top: 16px;
    padding-left: 20px;
    height: 88px;
    background-image: url('/images/awsExistingCustomerModal_background.svg');
    color: $neutral300;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    .bold {
      font-weight: 700;
      color: $neutral100;
    }
  }
  .content {
    padding: 16px 20px 20px 20px;
    p {
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: -0.7px;
      .bold {
        color: $primary800;
        font-weight: 500;
      }
    }
    p + p {
      margin-top: 12px;
    }
    a {
      display: inline-block;
      margin-top: 35px;
      font-weight: 400;
      letter-spacing: -0.26px;
      font-size: 13px;
      position: relative;
      color: $main300;
      text-decoration: underline;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 1px;
        right: -14px;
        width: 14px;
        height: 14px;
        background-image: url('/images/arrow_icon.svg');
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}
