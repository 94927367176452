@import '@standby/common-ui/color.scss';

.table {
  thead {
    tr {
      th {
        &:nth-child(2) {
          padding: 10px 6px 10px 8px;
        }
        &:nth-child(3) {
          padding: 10px 6px 10px 6px;
        }
        &:nth-child(4) {
          padding: 10px 8px 10px 6px;
        }
        &:nth-child(5) {
          padding: 10px 4px 10px 8px;
        }
        &:nth-child(6) {
          padding: 10px 6px 10px 4px;
          text-align: right;
        }
        &:nth-child(7) {
          padding: 10px 8px 10px 6px;
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        &:nth-child(1) {
          padding: 3px 8px 3px 8px;
        }
        &:nth-child(2) {
          padding: 3px 6px 3px 8px;
        }
        &:nth-child(3) {
          padding: 3px 6px 3px 6px;
        }
        &:nth-child(4) {
          padding: 3px 8px 3px 6px;
        }
        .stockName {
          padding: 3px 4px 3px 8px;
        }
        .stockNumber {
          padding: 3px 6px 3px 4px;
          text-align: right;
        }
        .stockRate {
          padding: 3px 8px 3px 6px;
          text-align: right;
        }
      }
    }
  }
}
