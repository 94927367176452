@use '@standby/dike_ui/design-token/color.module.scss' as color;

.expiredInvitedMembership {
  width: 100%;
  .logoWrap {
    text-align: center;
    .logo {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .title {
    text-align: center;
  }
  .content {
    background-color: color.$grayScale100;
    border-radius: 4px;
    margin-top: 48px;
    padding: 16px;
    color: color.$grayScale800;
    .dateBox {
      border-top: 1px solid color.$grayScale300;
      margin-top: 12px;
      padding-top: 12px;
      .dateInfo {
        display: flex;
        align-items: center;
        gap: 12px;
        &:not(:first-child) {
          margin-top: 4px;
        }
        .dateTitle {
          color: color.$primary500;
        }
      }
    }
  }
}
