@import '@standby/common-ui/color.scss';

.loadingWrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(131, 135, 140, 0.7);
  z-index: 99999;
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .loadingContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      .loadingImg {
        .fillAnimation {
          transition: fill ease 0.1s;
        }
      }
      .loadingText {
        margin-top: 20px;
        color: $neutral100;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.3;
        text-align: center;
      }
    }
  }
}
