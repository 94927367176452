@use '@standby/dike_ui/design-token/color.module.scss' as color;

.wrap {
  background-color: color.$primary50;
  height: 100%;
}
.Auth {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  .AuthBox {
    display: flex;
    width: 1040px;
    height: 680px;
    border-radius: 20px;
    box-shadow: 0px 0px 40px 0px rgba(76, 73, 229, 0.3);
    .authContent {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 48px;
      width: 50%;
      height: 100%;
      background-color: color.$grayScale000;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    .etcContent {
      width: 50%;
      height: 100%;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background: linear-gradient(151deg, #22208c 7.33%, #212165 34.39%, #1f2233 93.23%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
