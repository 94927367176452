@use '@standby/dike_ui/design-token/color.module.scss' as color;
@use '@standby/dike_ui/design-token/font.module.scss';

.formContent {
  width: 100%;
  height: 100%;
  .scrollArea {
    height: 100%;
    .contentView {
      width: 100%;
      padding-right: 45px;
    }
  }
  .logoWrap {
    text-align: center;
    .logo {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .mainTitle {
    text-align: center;
    margin-bottom: 48px;
    color: color.$grayScale800;
  }
  .inputText {
    margin-top: 20px;
  }
  .phoneNumber {
    margin-top: 20px;
    .phoneNumberBox {
      display: flex;
      justify-content: space-between;
      margin-top: 2px;
      width: 100%;
    }
  }
  .mainBtn {
    width: 100%;
    margin-top: 48px;
  }
}
.termsBox {
  padding-top: 20px;
  .termsList {
    margin-top: 8px;
    padding: 16px;
    border-radius: 4px;
    background-color: color.$grayScale100;
    li {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        margin-top: 0;
      }
      .textBtn {
        @extend .Detail-12M;
        color: color.$grayScale500;
      }
      .checkbox {
        color: color.$grayScale700;
        .notRequired {
          color: color.$grayScale400;
        }
      }
    }
  }
}
.agreeContent {
  padding: 16px 20px 20px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: color.$grayScale900;
}
