.popupContent {
  position: relative;
  padding: 36px 34px 40px;
  width: 840px;
  text-align: center;
  background-color: rgba(236, 239, 255, 1);
  .btnArea {
    margin-top: 16px;
    .linkBtn {
      margin: 0 auto;
      width: 480px;
    }
  }
  .closeBtn {
    position: absolute;
    top: 19px;
    right: 20px;
  }
}
