@import '@standby/common-ui/color.scss';

.circleContent {
  color: $main500 !important;
  .itemTitle {
    color: $neutral800;
    margin-right: 3px;
  }
}
.content {
  padding-top: 13px;
  padding-bottom: 13px;
}
