@use '@standby/dike_ui/design-token/color.module.scss' as color;

.container {
  display: flex;
  align-items: center;
  padding: 8px 12px 8px 20px;
  gap: 20px;

  background-color: color.$grayScale100;
  border: 1px solid color.$grayScale300;
  border-radius: 8px;

  .label {
    color: color.$grayScale700;
  }
}
@mixin thumb {
  width: 24px;
  height: 24px;
  border-radius: 8px;
  border: 1px solid color.$primary300;
  background: color.$primary200;
  cursor: pointer;
}

.contrastSlider {
  flex: 1;
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  background: color.$grayScale300;
  box-shadow: 1px 1px 1px 0px rgba(24, 24, 24, 0.3) inset;
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    @include thumb;
  }

  &::-moz-range-thumb {
    @include thumb;
  }

  &::-ms-thumb {
    @include thumb;
  }
}

.rotateButton {
  color: color.$grayScale700 !important;
  display: flex;
  gap: 4px;
}
