@import '@standby/common-ui/color.scss';

.document {
  padding: 36px 50px;
  width: 1040px;
  .title {
    color: $neutral800;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }
  .contentBox {
    display: flex;
    margin-top: 16px;
    .documentBox {
      width: 680px;
      height: 959px;
      border: 1px solid $neutral400;
      border-radius: 4px;
      .content {
        width: 100%;
        height: calc(100% / 0.618);
        transform: scale(0.618);
        transform-origin: top left;
        .iframeDocument {
          width: 1100px;
          height: 100%;
        }
      }
    }
    .documentOption {
      margin-left: 20px;
      width: 240px;
      color: 16px;
      font-weight: 400;
      line-height: 20px;
      .btnArea {
        margin-top: 24px;
        width: 100%;
        .nextBtn {
          margin: 0 auto;
          width: 100%;
        }
      }
    }
  }
}
