@use '@standby/dike_ui/design-token/color.module.scss' as color;

.container {
  display: flex;
  justify-content: space-between;
  padding: 6px 12px;

  .listIcon {
    display: block;
    margin-top: 1px;
    min-width: 20px;
    margin-right: 5px;
  }

  .demo {
    width: 39px;
    background-color: color.$grayScale700;
    color: color.$grayScale000;
    display: inline-block;
    text-align: center;
    border-radius: 2px;
    margin-right: 8px;
    padding: 1px 2px;
    position: relative;
  }

  .companyName {
    &:hover {
      color: color.$primary500;
    }
  }

  .tag {
    min-width: 38px;
    position: relative;
    display: flex;
    padding: 2px 6px 0 6px;
    justify-content: center;
    align-items: center;
    height: 16px;
    color: color.$siYellow800;
    background-color: color.$siYellow100;
    border-radius: 9px;
  }
}
