.table {
  width: 100%;
  border-bottom: none !important;
  thead {
    th {
      &:nth-child(1) {
        padding-left: 36px;
      }
    }
  }
  tbody {
    td {
      &.stockTd {
        position: relative;
        padding-right: 25px;
        .btnArea {
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 10px;
          right: 8px;
          .minusBtn {
            margin-top: 2px;
          }
        }
      }
      .number {
        display: flex;
        span {
          &:nth-child(1) {
            flex-shrink: 0;
            width: 28px;
          }
        }
      }
    }
    tr {
      &:not(.changedShareholderSum) {
        td {
          vertical-align: top;
          &.textTd {
            padding-top: 20px;
          }
          .innerText {
            padding-top: 10px;
          }
        }
      }
    }
  }
  .inputSubText {
    margin-left: 2px;
  }
  .changedShareholderSum {
    td {
      font-weight: 400;
      &:nth-child(2) {
        padding-right: 49px;
        text-align: right;
      }
      .count {
        font-weight: 700;
        margin-right: 2px;
        margin-left: 10px;
      }
    }
  }
  .shareholderSum {
    td {
      font-weight: 400;
      &:nth-child(1) {
        span {
          &:nth-child(1) {
            width: 28px;
            display: inline-block;
          }
        }
        .count {
          margin-left: 0;
        }
      }
      &:nth-child(2) {
        text-align: right;
      }
      .count {
        font-weight: 700;
        margin-right: 2px;
        margin-left: 10px;
      }
    }
  }
}
.radioBox {
  width: 460px;
}
