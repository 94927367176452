@import '@standby/common-ui/color.scss';

.mainTitle {
  margin-top: 68px;
  margin-bottom: 36px;
  color: $neutral900;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.2px;
}

.beforeFinal {
  position: relative;
  margin-top: 42px;
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 113px;
    bottom: 0;
    border: 1px dashed $neutral300;
  }
}

.row {
  display: flex;
  margin-top: 42px;
  background-color: $neutral100;
  &:nth-child(1) {
    margin-top: 0;
  }
  &:last-child {
    position: relative;
    z-index: 1;
  }
  .title {
    padding: 14px 16px;
    padding-left: 48px;
    width: 226px;
    align-self: flex-start;
    border-radius: 10px;
    color: $neutral800;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid $neutral400;
    background-color: $neutral200;
    background-image: url('/images/finalTitle_meetingSchedule_icon.svg');
    background-repeat: no-repeat;
    background-position: left 16px top 13px;
    z-index: 1;
    &.section1,
    &.section2,
    &.section3,
    &.section4,
    &.section5 {
      color: $main500;
      font-weight: 700;
      border: 1px solid $main400;
      background-color: $main000;
      .light {
        font-weight: 400;
      }
      &.section1 {
        background-image: url('/images/finalTitle_section1_icon.svg');
      }
      &.section2 {
        background-image: url('/images/finalTitle_section2_icon.svg');
      }
      &.section3 {
        background-image: url('/images/finalTitle_section3_icon.svg');
      }
      &.section4 {
        background-image: url('/images/finalTitle_section4_icon.svg');
      }
      &.section5 {
        background-image: url('/images/finalTitle_section5_icon.svg');
      }
    }
    &.upDate {
      background-image: url('/images/finalTitle_update_icon.svg');
    }
  }
  .content {
    margin-left: 20px;
    width: 794px;
    .contentTitleBox {
      margin-bottom: 20px;
      padding: 15px 18px;
      width: 100%;
      height: 52px;
      border: 1px solid $neutral500;
      border-radius: 10px;
      color: $neutral800;
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      .light {
        font-weight: 400;
      }
    }
    .tooltipContent {
      padding: 12px 16px;
      padding-bottom: 16px;
      width: 460px;
      .qnaUpperTitle {
        margin-bottom: -2px;
        color: $neutral800;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        &.borderTop {
          border-top: 1px solid $neutral300;
          padding-top: 16px;
          margin-top: 16px;
        }
      }
      .qna {
        margin-top: 12px;
        &:first-child {
          margin-top: 0;
        }
        .qnaTitle {
          display: inline-block;
          padding: 4px 6px;
          color: $main500;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          background-color: $main000;
          border-radius: 2px;
        }
        .qnaContent {
          margin-top: 6px;
          color: $neutral700;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          ul {
            li {
              position: relative;
              padding-left: 9px;
              margin-top: 4px;
              &:first-child {
                margin-top: 0;
              }
              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 8px;
                left: 0;
                width: 3px;
                height: 3px;
                background-color: $neutral600;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
    .moreViewBtn {
      margin: 0 auto;
      margin-top: 16px;
      display: block;
      background-color: transparent;
      > span {
        padding-right: 16px;
        color: $neutral600;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        text-decoration: underline;
        background-image: url('/images/moreView_close.svg');
        background-repeat: no-repeat;
        background-position: right center;
        &.open {
          background-image: url('/images/moreView_open.svg');
        }
      }
    }
    .btnArea {
      margin-top: 30px;
      .btn {
        margin: 0 auto;
        width: 300px;
      }
    }
    .table {
      margin-top: 16px;
      width: 100%;
      thead {
        tr {
          th {
            line-height: 14px;
            letter-spacing: -0.28px;
            &:nth-child(1) {
              padding-left: 35px;
            }
            &:nth-child(4) {
              text-align: left;
            }
            &:nth-child(5) {
              text-align: right;
            }
            &:nth-child(6) {
              text-align: right;
            }
            &.download {
              position: relative;
              > span {
                display: inline-block;
                padding-right: 16px;
                background-repeat: no-repeat;
                background-position: right top;
              }
              &.active {
                cursor: pointer;
                > span {
                  background-image: url('/images/table_download_active.svg');
                }
                .allDownloadBtn {
                  position: absolute;
                  bottom: -34px;
                  left: 0;
                  width: 113px;
                  height: 40px;
                  border: 1px solid $neutral400;
                  border-radius: 4px;
                  box-shadow: 1px 1px 3px 1px rgba(102, 102, 102, 0.2);
                  background-color: $neutral100;
                  color: $neutral800;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 14px;
                  opacity: 0;
                  visibility: hidden;
                }
                &:hover {
                  > span {
                    background-image: url('/images/table_download_open.svg');
                  }
                  .allDownloadBtn {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
              &.disabled {
                cursor: not-allowed;
                > span {
                  background-image: url('/images/table_download_close.svg');
                }
              }
            }
          }
        }
      }
      tbody {
        tr {
          td {
            line-height: 16px;
            letter-spacing: -0.28px;
            &:nth-child(1) {
              padding: 10px 10px 10px 36px;
              position: relative;
              > span {
                position: absolute;
                top: center;
                left: 8px;
                display: inline-block;
                width: 23px;
              }
            }
            &:nth-child(2) {
              text-align: center;
            }
            &:nth-child(3) {
              text-align: center;
            }
            &.stockName {
              padding: 10px 4px 10px 10px;
            }
            &.stockNumber {
              padding: 10px 6px 10px 4px;
              text-align: right;
            }
            &.stockRate {
              padding: 10px 8px 10px 6px;
              text-align: right;
            }
            .secon200 {
              color: $secon200;
              white-space: pre-line;
            }
            .sub300 {
              color: $sub300;
              white-space: pre-line;
            }
            .warning200 {
              position: relative;
              padding-right: 21px;
              display: inline-block;
              color: $warning200;
              white-space: pre-line;
              .tooltip {
                position: absolute;
                top: 0;
                right: 0;
                > span {
                  background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_negative.svg');
                }
              }
            }
          }
        }
      }
    }
    .wrap {
      border: 1px solid $neutral300;
      border-radius: 16px;
      .wrapTitle {
        margin-top: 32px;
        color: $neutral800;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 23px;
        text-align: center;
        .important {
          color: $main300;
        }
      }
      .steps {
        display: flex;
        width: 744px;
        margin: 0 auto;
        margin-top: 16px;
        margin-bottom: 36px;
        .stepWrap {
          flex: 1;
          display: inline-flex;
          justify-content: center;
          &:nth-child(1) {
            .step {
              padding-left: 50px;
              background-image: url('/images/step1_main200.svg');
            }
          }
          &:nth-child(2) {
            .step {
              padding-left: 54px;
              background-image: url('/images/step2_main200.svg');
            }
          }
          &:nth-child(3) {
            .step {
              padding-left: 50px;
              background-image: url('/images/step3_main200.svg');
            }
          }
          .step {
            background-repeat: no-repeat;
            background-position: top left;
            min-height: 66px;
            .stepTitle {
              color: $neutral800;
              font-size: 16px;
              font-weight: 400;
              line-height: 19px;
            }
            .stepContent {
              margin-top: 8px;
              color: $neutral700;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
