@use '@standby/dike_ui/design-token/color.module.scss' as color;

.popupContent {
  position: relative;
  width: 840px;
  text-align: center;
  background-color: color.$primary50;
  position: relative;
  border-radius: 8px;
  .btnArea {
    position: absolute;
    bottom: 32px;
    width: 100%;
    .linkBtn {
      margin: 0 auto;
      width: 550px;
    }
  }
  .closeBtn {
    position: absolute;
    top: 28px;
    right: 28px;
  }
}
