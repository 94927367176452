@import '@standby/common-ui/color.scss';

.row {
  display: flex;
  margin-top: 36px;
  .rowTitle {
    padding-top: 18px;
    width: 210px;
    flex-shrink: 0;
    color: $neutral600;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: -0.02em;
    border-top: 1px solid $neutral600;
    .bold {
      color: $neutral900;
    }
  }
  .content {
    margin-left: 36px;
    padding-top: 20px;
    width: 794px;
    border-top: 1px solid $neutral300;
    &.paddingNone {
      border-top: none;
      padding-top: 0;
    }
    .downloadBtn {
      position: relative;
      top: -2px;
      padding-left: 20px;
      margin-left: 8px;
      color: $main300;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.8;
      text-decoration: underline;
      background-color: transparent;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 0;
        width: 16px;
        height: 16px;
        background-image: url('/images/download_icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .updated {
      color: $neutral500;
      font-size: 14px;
      font-weight: 400;
      margin-left: 8px;
    }
    .contract {
      color: $neutral900;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.02em;
      .contractInfo {
        margin-top: 8px;
        &:first-child {
          margin-top: 0;
        }
        .bold {
          color: $main300;
        }
        .small {
          font-size: 14px;
          font-weight: 400;
        }
        .changeList {
          padding: 0 16px;
          width: 460px;
          background-color: $neutral200;
          margin-top: 8px;
          li {
            color: $neutral700;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding: 8px 0;
            span + span {
              margin-left: 16px;
            }
            &:not(:first-child) {
              border-top: 1px solid $neutral300;
            }
          }
        }
      }
      .contractExplain {
        position: relative;
        padding-left: 10px;
        margin-top: 8px;
        color: $neutral600;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.01em;
        &:before {
          content: '*';
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 0;
        }
      }
      .electronicSignatureBtn {
        position: relative;
        margin-top: 20px;
        width: 460px;
        height: 83px;
        background-color: $main400;
        box-shadow: 1px 1px 2px 1px rgba(153, 153, 153, 0.16);
        border-radius: 8px;
        color: $neutral100;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.3;
        .text {
          position: relative;
          padding-left: 41px;
          padding-right: 18px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 33px;
            height: 33px;
            background-image: url('/images/electronic_signature_icon.svg');
            background-repeat: no-repeat;
            background-size: cover;
          }
          &:after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 7px;
            right: 0;
            width: 16px;
            height: 16px;
            background-image: url('/images/electronic_signature_arrow_icon.svg');
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
      .agreeBox {
        margin-top: 20px;
        padding: 24px 0;
        width: 460px;
        border: 1px solid $neutral300;
        border-radius: 8px;
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        text-align: center;
        .agreeContent {
          display: inline-block;
          text-align: left;
          .checkBox {
            margin-top: 9px;
          }
        }
        .agreeModal {
          color: $main300;
          font-size: 14px;
          font-weight: 400;
          text-decoration: underline;
          background-color: transparent;
          line-height: 100%;
        }
        .registerBtn {
          display: block;
          margin: 0 auto;
          margin-top: 18px;
          width: 300px;
        }
      }
    }
    .payment {
      .paymentInfo {
        position: relative;
        padding-left: 20px;
        margin-top: 8px;
        &:first-child {
          margin-top: 0;
        }
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.02em;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 1px;
          left: 0;
          width: 16px;
          height: 16px;
          background-image: url('/images/check_icon.svg');
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      .accountRegistrationBtn {
        margin-top: 20px;
        width: 300px;
        height: 48px;
        border: 1px solid $main400;
        border-radius: 4px;
        background-color: $neutral100;
        color: $main500;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        text-align: center;
      }
      .cmsDownload {
        margin-top: 8px;
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.02em;
      }
      .changeAccountBtn {
        position: relative;
        display: inline-block;
        margin-top: 5px;
        color: $main300;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        text-decoration: underline;
        background-color: transparent;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 1px;
          right: -14px;
          width: 14px;
          height: 14px;
          background-image: url('/images/arrow_icon.svg');
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
    .advertisement {
      display: flex;
      .img {
        display: block;
        width: 220px;
        height: 144px;
        border: 1px solid $neutral400;
      }
      .jinpyeong {
        margin-left: 20px;
        .jinpyeongTitle {
          color: $neutral700;
          font-size: 16px;
          font-weight: 400;
          .bold {
            color: $neutral900;
            font-weight: 500;
          }
        }
        .jinpyeongInfo {
          position: relative;
          padding-left: 20px;
          margin-top: 8px;
          &:first-child {
            margin-top: 0;
          }
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.02em;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 1px;
            left: 0;
            width: 16px;
            height: 16px;
            background-image: url('/images/check_icon.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
          .boldColor {
            color: $main300;
            font-weight: 500;
          }
          .bold {
            font-weight: 400;
          }
        }
        .jinpyeongLogoImg {
          margin-top: 18px;
        }
        .barunLogoImg {
          margin-top: 15px;
        }
        .showInfoLink {
          position: relative;
          top: -6px;
          display: inline-block;
          margin-left: 6px;
          color: $main300;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          text-decoration: underline;
          background-color: transparent;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 1px;
            right: -14px;
            width: 14px;
            height: 14px;
            background-image: url('/images/arrow_icon.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }
    }
    .companyBenefit {
      .benefit {
        margin-top: 8px;
        &:first-child {
          margin-top: 0;
        }
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.02em;
        .bold {
          color: $main300;
          font-weight: 400;
        }
      }
      .serviceBox {
        margin-top: 20px;
        width: 521px;
        .serviceBenefitBox {
          display: flex;
          justify-content: space-between;
          .benefitBox {
            padding: 16px 0;
            padding-top: 60px;
            width: 165px;
            text-align: center;
            border: 1px solid $neutral300;
            border-radius: 12px;
            color: $neutral800;
            font-size: 15px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: -0.3px;
            background-repeat: no-repeat;
            background-position: center top 16px;
            .bold {
              color: $main500;
            }
            &.helpme {
              background-image: url('/images/businessRegistration_helpme_icon.svg');
            }
            &.emptyCalendar {
              position: relative;
              background-image: url('/images/businessRegistration_empty_calendar_icon.svg');
              .freeMonth {
                position: absolute;
                top: 29px;
                left: 50%;
                transform: translateX(-50%);
                color: $secon100;
                font-size: 18px;
                font-weight: 500;
              }
            }
            &.calendar {
              background-image: url('/images/businessRegistration_calendar_icon.svg');
            }
          }
        }
        .serviceDeadline {
          margin-top: 12px;
          padding: 14px 20px;
          padding-left: 62px;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          border: 1px solid $neutral300;
          border-radius: 12px;
          background-repeat: no-repeat;
          background-position: left 20px top 16px;
          background-image: url('/images/businessRegistration_checkHome_icon.svg');
          .bold {
            color: $main500;
          }
        }
      }
    }
    .service {
      display: flex;
      justify-content: space-between;
      .serviceInfo {
        width: 247px;
        .serviceTitle {
          color: $neutral900;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.02em;
        }
        .serviceList {
          margin-top: 10px;
          li {
            position: relative;
            margin-top: 6px;
            &:first-child {
              margin-top: 0;
            }
            padding-left: 8px;
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 8px;
              left: 0;
              width: 3px;
              height: 3px;
              background-color: $neutral800;
              border-radius: 50%;
            }
          }
        }
      }
    }
    .barunServiceTable {
      width: 100%;
      border-color: $neutral500;
      th {
        text-align: left;
        font-weight: 500;
      }
      .titleLine td {
        border-color: $neutral500;
      }
      .titleTd {
        vertical-align: top;
        font-weight: 500;
      }
    }
  }
  .freeBookkeepingServiceChanged {
    border-radius: 4px;
    padding-left: 38px;
    color: $neutral700;
    font-size: 13px;
    font-weight: 400;
    line-height: 35px;
    background:
      url('/images/exclamationMark_main400.svg') no-repeat,
      url('/images/gift_background.png') no-repeat;
    background-position:
      left 12px center,
      right 16px bottom;
    background-color: $main000;
    height: 36px;
    em {
      font-weight: 700;
    }
  }
}
.moreViewBtn {
  display: inline-block;
  background-color: transparent;
  margin-left: 5px;
  > span {
    padding-right: 16px;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-decoration: underline;
    background-image: url('/images/moreView_close.svg');
    background-repeat: no-repeat;
    background-position: right center;
    &.open {
      background-image: url('/images/moreView_open.svg');
    }
  }
}
.bookkeepingServicePause {
  border-radius: 4px;
  padding-left: 38px;
  color: $neutral800;
  font-size: 13px;
  font-weight: 400;
  line-height: 45px;
  background: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_hover_active.svg') no-repeat;
  background-position: left 12px center;
  background-color: $neutral200;
  border: 1px solid $neutral300;
  height: 48px;
  margin-top: 36px;
  em {
    font-weight: 700;
    margin-right: 8px;
    font-size: 16px;
    color: $neutral700;
  }
  & + .row {
    margin-top: 16px;
  }
}
