@import '@standby/common-ui/color.scss';

.mainCategory {
  > li {
    display: flex;
    gap: 16px;
    &:not(:first-child) {
      margin-top: 12px;
    }
    > .title {
      display: flex;
      align-items: center;
      width: 160px;
      height: 60px;
      background-color: $main500;
      border-radius: 8px;
      color: $neutral100;
      font-size: 18px;
      font-weight: 700;
      padding-left: 20px;
      background-position: right bottom;
      background-repeat: no-repeat;
      &[data-type='통신사'] {
        background-image: url('/images/internetAssociate_통신사Icon.svg');
      }
      &[data-type='상품선택'] {
        background-image: url('/images/internetAssociate_상품선택Icon.svg');
      }
    }
    > .checks {
      flex: 1;
      display: flex;
      justify-content: space-between;
      .check {
        flex-shrink: 0;
        border: 1px solid $neutral400;
        border-radius: 8px;
        &.checked {
          border-color: $main400;
        }
        label {
          display: flex;
          align-items: center;
          width: 280px;
          height: 60px;
          flex-flow: row-reverse;
          justify-content: space-between;
          padding-left: 16px;
          padding-right: 20px;
        }
        span.text {
          margin-left: 0;
          color: $neutral800;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .selectedKt {
        width: 280px;
        height: 60px;
        border-radius: 8px;
        border: 1px solid $neutral300;
        background: $neutral200;
        display: flex;
        align-items: center;
        padding-left: 20px;
      }
    }
  }
}
