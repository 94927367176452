.container {
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 12px;

  .list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.btnArea {
  text-align: center;
  margin-top: 12px;

  .submit {
    width: 300px;
    height: 48px;
  }
}
