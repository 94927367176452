@import '@standby/common-ui/color.scss';

.table {
  width: 620px;
  margin-top: 13px;
  border-bottom: none !important;
  .number {
    display: inline-block;
    width: 28px;
    flex-shrink: 0;
  }
  .name {
    font-weight: 500;
  }
  th {
    text-align: center !important;
    border-left: $neutral300 1px solid;
    &:last-child {
      border-right: $neutral300 1px solid;
    }
  }
  td {
    border-left: $neutral300 1px solid;
    background-color: $neutral100;
    &:not(:first-child) {
      text-align: right;
    }
    &:last-child {
      border-right: $neutral300 1px solid;
    }
  }
  .sum {
    text-align: right;
    td {
      background-color: transparent;
      border-left: none;
      border-right: none;
    }
    .important {
      font-weight: 700;
    }
  }
}
