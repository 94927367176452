@import '@standby/common-ui/color.scss';

.bankbook {
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      color: $neutral900;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
    }
    .addBtn {
      width: 164px;
      height: 31px;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.56px;
    }
  }
  .bankbookInfoBox {
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    .bankbookBox {
      width: 385px;
      height: 197px;
      border: 1px solid $neutral400;
      border-radius: 4px;
      .bank {
        padding: 12px 19px;
        width: 100%;
        height: 50px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        background-color: $neutral200;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .accountUsage {
          color: $neutral500;
          font-size: 15px;
          font-weight: 500;
        }
        .bankName {
          position: relative;
          padding-left: 34px;
          color: $neutral800;
          font-size: 18px;
          font-weight: 400;
          flex-shrink: 0;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 26px;
            height: 26px;
            background-repeat: no-repeat;
            background-image: url('/images/bankLogo_large.svg');
          }
          &.신한은행:before {
            background-position: left 0 top 0;
          }
          &.국민은행:before {
            background-position: left -142px top 0;
          }
          &.기업은행:before {
            background-position: left -284px top 0;
          }
          &.농협은행:before {
            background-position: left -426px top 0;
          }
          &.우리은행:before {
            background-position: left 0 top -44px;
          }
          &.하나은행:before {
            background-position: left -142px top -44px;
          }
          &.산업은행:before {
            background-position: left -284px top -44px;
          }
          &.수협은행:before {
            background-position: left -426px top -44px;
          }
          &.신협은행:before {
            background-position: left 0 top -88px;
          }
          &.부산은행:before {
            background-position: left -142px top -88px;
          }
          &.전북은행:before {
            background-position: left -284px top -88px;
          }
          &.광주은행:before {
            background-position: left -426px top -88px;
          }
          &.대구은행:before {
            background-position: left 0 top -132px;
          }
          &.새마을금고:before {
            background-position: left -142px top -132px;
          }
          &.SC은행:before {
            background-position: left -284px top -132px;
          }
          &.경남은행:before {
            background-position: left -426px top -132px;
          }
          &.한국씨티은행:before {
            background-position: left 0 top -176px;
          }
          &.우체국:before {
            background-position: left -142px top -176px;
          }
          &.카카오뱅크:before {
            background-position: left -284px top -176px;
          }
          &.토스뱅크:before {
            background-position: left -426px top -176px;
          }
          &.케이뱅크:before {
            background-position: left 0 top -220px;
          }
          &.SBI저축은행:before {
            background-position: left -142px top -220px;
          }
          &.산림조합:before {
            background-position: left -284px top -220px;
          }
        }
      }
      .bankInfo {
        padding: 14px 20px 20px;
        .nameBox {
          display: flex;
          color: $neutral900;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          .title {
            width: 52px;
            flex-shrink: 0;
          }
          .name {
            margin-left: 12px;
          }
        }
        .accountBox {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          color: $neutral900;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          .info {
            display: flex;
            .title {
              width: 52px;
              flex-shrink: 0;
            }
            .account {
              margin-left: 12px;
            }
          }
          .toastMessageBtnBox {
            position: relative;
            .toastMessage {
              position: absolute;
              top: -33px;
              left: 50%;
              transform: translateX(-50%);
            }
            > button {
              width: 93px;
              height: 24px;
              border: 1px solid $neutral400;
              border-radius: 2px;
              background-color: $neutral100;
              color: $main300;
              font-size: 12px;
              font-weight: 400;
              line-height: 12px;
              letter-spacing: -0.24px;
            }
          }
        }
        .btnArea {
          display: flex;
          justify-content: space-between;
          margin-top: 28px;
          .toastMessageBtnBox {
            position: relative;
            .toastMessage {
              position: absolute;
              top: -33px;
              left: 50%;
              transform: translateX(-50%);
            }
            .accountInfoCopyBtn {
              width: 168px;
              height: 38px;
              background-color: $neutral100;
              border: 1px solid $main400;
              border-radius: 4px;
              color: $main500;
              font-size: 14px;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: -0.56px;
            }
          }
          .bankbookDownloadBtn {
            width: 168px;
            height: 38px;
            background-color: $main400;
            border-radius: 4px;
            color: $neutral100;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.56px;
            .text {
              padding-left: 17px;
              background-image: url('/images/bankbook_download_icon.svg');
              background-repeat: no-repeat;
              background-position: left 0 center;
            }
          }
        }
      }
    }
  }
  .table {
    width: 100%;
    thead {
      tr {
        th {
          text-align: left;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 10px 8px;
          font-size: 15px;
          .bankName {
            position: relative;
            padding-left: 30px;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 1px;
              left: 0;
              width: 22px;
              height: 22px;
              background-repeat: no-repeat;
              background-image: url('/images/bankLogo_medium.svg');
            }
            &.신한은행:before {
              background-position: left 0 top 0;
            }
            &.국민은행:before {
              background-position: left -142px top 0;
            }
            &.기업은행:before {
              background-position: left -284px top 0;
            }
            &.농협은행:before {
              background-position: left -426px top 0;
            }
            &.우리은행:before {
              background-position: left 0 top -44px;
            }
            &.하나은행:before {
              background-position: left -142px top -44px;
            }
            &.산업은행:before {
              background-position: left -284px top -44px;
            }
            &.수협은행:before {
              background-position: left -426px top -44px;
            }
            &.신협은행:before {
              background-position: left 0 top -88px;
            }
            &.부산은행:before {
              background-position: left -142px top -88px;
            }
            &.전북은행:before {
              background-position: left -284px top -88px;
            }
            &.광주은행:before {
              background-position: left -426px top -88px;
            }
            &.대구은행:before {
              background-position: left 0 top -132px;
            }
            &.새마을금고:before {
              background-position: left -142px top -132px;
            }
            &.SC은행:before {
              background-position: left -284px top -132px;
            }
            &.경남은행:before {
              background-position: left -426px top -132px;
            }
            &.한국씨티은행:before {
              background-position: left 0 top -176px;
            }
            &.우체국:before {
              background-position: left -142px top -176px;
            }
            &.카카오뱅크:before {
              background-position: left -284px top -176px;
            }
            &.토스뱅크:before {
              background-position: left -426px top -176px;
            }
            &.케이뱅크:before {
              background-position: left 0 top -220px;
            }
            &.SBI저축은행:before {
              background-position: left -142px top -220px;
            }
            &.산림조합:before {
              background-position: left -284px top -220px;
            }
          }
          .accountNumber {
            text-decoration: none;
            color: $neutral800;
            font-size: 15px;
          }
          .accountNumberCopyMessage {
            top: -35px;
          }
          &.accountInfo {
            padding-right: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .accountUsage {
              color: $neutral800;
              font-weight: 400;
              line-height: 18px;
            }
            .btnArea {
              display: flex;
              .toastMessageBtnBox {
                position: relative;
                .toastMessage {
                  position: absolute;
                  top: -33px;
                  left: 50%;
                  transform: translateX(-50%);
                }
                .accountInfoCopyBtn {
                  width: 108px;
                  height: 36px;
                  background-color: $neutral100;
                  border: 1px solid $main400;
                  border-radius: 4px;
                  color: $main500;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 14px;
                  letter-spacing: -0.56px;
                }
              }
              .bankbookDownloadBtn {
                margin-left: 4px;
                width: 145px;
                height: 36px;
                background-color: $main400;
                border-radius: 4px;
                color: $neutral100;
                font-size: 13px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: -0.56px;
                .text {
                  padding-left: 17px;
                  background-image: url('/images/bankbook_download_icon.svg');
                  background-repeat: no-repeat;
                  background-position: left 0 center;
                }
              }
            }
          }
        }
      }
    }
  }
}
