@import '@standby/common-ui/color.scss';

.wrap {
  width: 510px;
  height: 214px;
  border: 1px solid $neutral300;
  border-radius: 12px;
  position: relative;
  padding: 0 20px;
  > .title {
    font-size: 18px;
    font-weight: 500;
    color: $neutral900;
    padding-left: 30px;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
    left: 20px;
    top: 18px;
    height: 24px;
    align-items: center;
    line-height: 24px;
    background-image: url('/images/decisionTitleIcon.svg');
  }
  > .decisionList {
    margin-top: 60px;
    li {
      display: flex;
      line-height: 100%;
      border-bottom: 1px solid $neutral300;
      padding-bottom: 16px;
      padding-top: 15px;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: -0.5px;
      color: $neutral800;
      &:nth-child(1) {
        padding-top: 0;
      }
      &:nth-last-child(1) {
        padding-bottom: 0;
        border: none;
      }
      > .title {
        padding-left: 22px;
        background-image: url('/images/decisionPersonIcon_gray.svg');
        background-repeat: no-repeat;
        background-position: left top;
        width: 150px;
      }
      &:nth-child(1) {
        > .title {
          background-image: url('/images/decisionPersonIcon_blue.svg');
        }
      }
      > .content {
        &.empty {
          color: $neutral600;
          font-weight: 400;
        }
        .subText {
          font-size: 14px;
          color: $neutral600;
          font-weight: 400;
          margin-top: 8px;
        }
      }
    }
  }
}
