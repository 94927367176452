@import '@standby/common-ui/color.scss';

.terms {
  padding: 36px;
  .title {
    color: $neutral800;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
  }
  .content {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid $neutral700;
  }
}
