.wrapper {
  inset: 0px !important;
}

.trackX {
  background: transparent !important;
  border-radius: 0 !important;

  // 위아래 4px 마진
  height: calc(100% - 8px) !important;
  top: 4px !important;
}
