@use '@standby/dike_ui/design-token/color.module.scss' as color;

.RequestEmailCheckContent {
  width: 100%;
  .logoWrap {
    text-align: center;
    .logo {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .mainTitle {
    margin-bottom: 48px;
    text-align: center;
    color: color.$grayScale800;
  }
  .infoBox {
    width: 100%;
    margin-top: 4px;
  }
  .retryBtn {
    justify-content: center;
    width: 100%;
    margin-top: 48px;
    align-items: center;
    gap: 2px;
  }
  .textBtn {
    display: block;
    margin: 0 auto;
    margin-top: 12px;
    color: color.$grayScale700;
  }
}
