@use '@standby/dike_ui/design-token/color.module.scss' as color;

.navBox {
  width: 100%;
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }

  .titleWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 4px;
  }

  .linkTitle {
    color: color.$grayScale300;

    &.active {
      color: color.$primary100;
    }
  }

  .sideLinkList {
    width: 184px;
  }
}
