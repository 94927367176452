@use '@standby/dike_ui/design-token/color.module.scss' as color;
@import '@standby/common-ui/color.scss';

.guide {
  background-color: color.$grayScale100;
  padding: 12px 20px 16px 20px;
  border-radius: 8px;
  &.closed {
    padding-bottom: 12px;
    .fullLink {
      display: none;
    }
  }
  .titleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .controlTitle {
      display: flex;
      align-items: center;
      gap: 8px;
      .title {
        color: color.$grayScale900;
      }
    }
    .fullLink {
      color: color.$grayScale700;
    }
  }

  .GuideList {
    margin-top: 15px;
    .inside {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .slide {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        transition: 0.5s;
        a {
          position: relative;
          flex-shrink: 0;
          display: inline-block;
          width: 241px;
          height: 116px;
          background-color: color.$grayScale000;
          margin-left: 12px;
          box-sizing: border-box;
          border: 1px solid color.$grayScale300;
          border-top: 6px solid color.$siYellow500;
          border-radius: 6px;
          text-decoration: none;
          padding-top: 10px;
          padding-left: 16px;
          padding-right: 16px;
          &:nth-child(1) {
            margin-left: 0;
          }
          .title {
            color: color.$grayScale500;
          }
          .content {
            color: color.$grayScale800;
            margin-top: 2px;
          }
          .moreView {
            position: absolute;
            color: color.$grayScale500;
            right: 16px;
            bottom: 12px;
          }
        }
      }
    }
  }
}
