@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/agendaTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.allSaved {
  border: 1px solid $neutral300;
  text-align: center;
  margin-top: 24px;
  padding: 48px 50px 52px;
  .text {
    color: $neutral900;
    font-size: 18px;
    line-height: 23.4px;
  }
  img {
    margin-top: 24px;
  }
  .subInfo {
    color: $neutral700;
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 400;
    margin-top: 36px;
  }
  .nextBtnArea {
    margin-top: 16px;
    .contentSubmitBtn {
      margin: 0 auto;
      width: 298px;
    }
  }
}
.btnArea {
  margin-top: 16px;
  .prevPage {
    background-color: transparent;
    > span {
      padding-left: 16px;
      color: $main300;
      font-weight: 500;
      font-size: 16px;
      background-image: url('/images/prevPage_Icon.svg');
      background-repeat: no-repeat;
      background-position: left top 6px;
    }
  }
}
