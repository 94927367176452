@import '@standby/common-ui/color.scss';

.wrap {
  width: 245px;
  background-color: $neutral200;
  border-radius: 12px;
  position: relative;
  padding: 0 20px;
  padding-bottom: 39px;
  height: 234px;
  margin-left: 20px;
  > .title {
    font-size: 18px;
    font-weight: 500;
    color: $neutral900;
    padding-left: 30px;
    background-position: left top 3px;
    background-repeat: no-repeat;
    position: absolute;
    left: 20px;
    top: 18px;
    height: 24px;
    align-items: center;
    line-height: 24px;
    background-image: url('/images/noticeTitleIcon.svg');
  }
  > .noticeContent {
    margin-top: 58px;
    > .notice {
      display: flex;
      line-height: 130%;
      border-bottom: 1px solid $neutral300;
      padding-top: 15px;
      &:nth-child(1) {
        padding-top: 0;
      }
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      > .title {
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
        color: $neutral800;
        width: 45px;
      }
      > .content {
        .text {
          font-size: 16px;
          color: $neutral800;
          font-weight: 400;
          max-width: 155px;
          display: inline-block;
          word-wrap: break-word;
          word-break: break-all;
        }
      }
    }
  }
  .info {
    color: $neutral600;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 100%;
    font-weight: 400;
    position: absolute;
    left: 20px;
    bottom: 16px;
    a {
      color: $main300;
      text-decoration: underline;
    }
  }
  .empty {
    color: $neutral600;
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.5px;
    margin-top: 86px;
  }
  .openModalBtn {
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.28px;
    text-decoration: underline;
    color: $main300;
    background-image: url('/images/arrowRight_main300.svg');
    background-repeat: no-repeat;
    background-position: right top 3px;
    padding-right: 16px;
    position: absolute;
    display: inline-block;
    right: 20px;
    top: 21px;
  }
}
