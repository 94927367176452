@import '@standby/common-ui/color.scss';

.wrap {
  width: 510px;
  background-color: $neutral200;
  border-radius: 12px;
  position: relative;
  padding: 0 20px;
  padding-bottom: 39px;
  min-height: 234px;
  > .title {
    font-size: 18px;
    font-weight: 500;
    color: $neutral900;
    padding-left: 30px;
    background-position: left top 3px;
    background-repeat: no-repeat;
    position: absolute;
    left: 20px;
    top: 18px;
    height: 24px;
    align-items: center;
    line-height: 24px;
    background-image: url('/images/addressTitleIcon.svg');
  }
  > .addressContent {
    margin-top: 58px;
    > .address {
      display: flex;
      line-height: 100%;
      border-bottom: 1px solid $neutral300;
      &:nth-last-child(1) {
        border-bottom: 0;
      }
      > .title {
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
        color: $neutral800;
        width: 45px;
      }
      > .content {
        .copyBtn {
          text-decoration: none;
        }
        .copyMessage {
          top: -35px;
        }
        .text {
          font-size: 16px;
          color: $neutral800;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
          max-width: 400px;
          display: inline-block;
        }
        ul {
          li {
            margin-bottom: 8px;
            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  .moreView {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 14px;
    margin-top: 8px;
    color: $neutral600;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    background-color: transparent;
    background-image: url('/images/moreView_close.svg');
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 15px;
    &.open {
      background-image: url('/images/moreView_open.svg');
    }
  }
  .empty {
    font-weight: 400;
    color: $neutral600;
  }
}
.openModalBtn {
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.28px;
  text-decoration: underline;
  color: $main300;
  background-image: url('/images/arrowRight_main300.svg');
  background-repeat: no-repeat;
  background-position: right top 3px;
  padding-right: 16px;
}
