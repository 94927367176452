@import '@standby/common-ui/color.scss';

.agenda {
  &:not(:first-child) {
    margin-top: 21px;
  }
  > .title {
    color: $neutral800;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    display: flex;
    line-height: 20px;
    .iconTitle {
      margin-right: 3px;
      padding-left: 28px;
      background-image: url('/images/agenda_title_icon.svg');
      background-repeat: no-repeat;
      background-position: left top;
    }
    .number {
      width: 24px;
    }
    .text {
      .important {
        font-weight: 700;
      }
      margin-right: 8px;
    }
    .badge {
      color: $main300;
      font-size: 13px;
      font-weight: 400;
      line-height: 100%;
      padding: 4px;
      background-color: $main000;
      border-radius: 2px;
      & + .badge {
        margin-left: 7px;
      }
    }
  }
  .info {
    padding-left: 18px;
    margin-top: 11px;
  }
}
.content {
  padding-top: 20px;
  padding-bottom: 20px;
}
.downloadBtn {
  position: relative;
  padding-left: 20px;
  color: $main300;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: underline;
  background-color: transparent;
  background-image: url('/images/download_icon.svg');
  background-repeat: no-repeat;
  background-position: left top -1px;
}
.agendaList {
  color: $neutral800;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  li {
    position: relative;
    padding-left: 6px;
    &:not(:first-child) {
      margin-top: 6px;
    }
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 6px;
      left: 0;
      border-radius: 2px;
      width: 2px;
      height: 2px;
      background-color: $neutral700;
    }
  }
}
