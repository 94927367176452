@import '@standby/common-ui/color.scss';

.electronicSignaturePopup {
  padding: 36px 50px;
  .documentWrap {
    display: flex;
    gap: 20px;
    .documentBox {
      width: 863px;
      height: 770px;
      border: 1px solid $neutral400;
      border-radius: 4px;
      .content {
        width: 100%;
        height: calc(100% / 0.78);
        transform: scale(0.78);
        transform-origin: top left;
        .document {
          width: 1100px;
          height: 100%;
        }
      }
    }
    .optionWrap {
      width: 240px;
    }
  }

  .btnArea {
    margin-top: 21px;
    text-align: center;
    .agreeBtn {
      margin: 0 auto;
      width: 300px;
    }
    .btnExplain {
      position: relative;
      padding-left: 10px;
      display: inline-block;
      margin-top: 8px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.01em;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
  }
}
