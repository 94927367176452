@import '@standby/common-ui/color.scss';

.mainTitle {
  margin-top: 90px;
  color: $neutral900;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
}
.mainContent {
  margin-top: 36px;
  .topInfo {
    display: flex;
    gap: 10px;
    .text {
      color: $primary900;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      height: 48px;
      padding: 0 20px;
      line-height: 48px;
      background-color: $neutral200;
      border-radius: 8px;
      display: inline-block;
      .bold {
        font-weight: 600;
        .color {
          color: $main400;
        }
      }
    }
  }
  .mainInfo {
    background-color: transparent;
    margin-top: 20px;
  }
  .allianceList {
    li {
      padding: 24px 40px;
      &:not(:last-child) {
        border-bottom: 1px solid $neutral300;
      }
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .content {
        .badge {
          display: inline-block;
          color: $secon200;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          padding: 2px 8px;
          border-radius: 4px;
          background: $secon50;
        }
        .title {
          color: $neutral800;
          font-size: 18px;
          font-weight: 500;
          line-height: 26px;
          margin-top: 12px;
        }
        .sub {
          color: $neutral500;
          font-size: 15px;
          font-weight: 400;
          line-height: 22px;
          margin-top: 4px;
        }
        .link {
          color: $neutral100;
          font-size: 13px;
          font-weight: 400;
          line-height: 36px;
          letter-spacing: -0.52px;
          width: 106px;
          height: 36px;
          border-radius: 4px;
          background: $main400;
          display: inline-block;
          text-align: center;
          margin-top: 32px;
        }
      }
    }
  }
}
