@import '@standby/common-ui/color.scss';

.capital {
  width: 510px;
  height: 274px;
  background-image: url('/images/capital_dashboard_background.svg');
  padding: 20px;
  position: relative;
  .title {
    color: $neutral100;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
  }
  .titleLabel {
    text-align: center;
    margin-top: 38px;
    span {
      display: inline-block;
      width: 95px;
      height: 24px;
      line-height: 24px;
      background-color: $neutral100;
      border-radius: 4px;
      color: $main500;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: -0.52px;
    }
  }
  .price_important {
    text-align: center;
    color: $neutral100;
    font-weight: 400;
    margin-top: 13px;
    .price {
      font-family: 'Roboto';
      font-size: 36px;
      letter-spacing: -0.684px;
      line-height: 100%;
    }
    .won {
      font-size: 28px;
      margin-left: 2px;
      letter-spacing: -0.532px;
      line-height: 100%;
    }
  }
  .price_sub {
    font-weight: 400;
    color: $neutral100;
    font-size: 14px;
    text-align: center;
    line-height: 100%;
    letter-spacing: -0.285px;
    margin-top: 8px;
  }
  .info {
    position: absolute;
    height: 30px;
    width: calc(100% - 40px);
    background-color: $neutral100;
    left: 20px;
    bottom: 20px;
    border-radius: 4px;
    padding-left: 34px;
    background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_positive.svg');
    background-position: top 6px left 12px;
    background-repeat: no-repeat;
    box-sizing: border-box;
    line-height: 30px;
    span,
    a {
      font-size: 14px;
      font-weight: 400;
      color: $neutral800;
      position: relative;
      top: -1px;
    }
    a {
      color: $main300;
      text-decoration: underline;
    }
  }
}
