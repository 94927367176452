@import '@standby/common-ui/color.scss';
.wrap {
  width: 798px;
  padding-bottom: 48px;
  > .title {
    color: $neutral800;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-align: center;
    margin-top: 48px;
    .important {
      color: $main300;
    }
  }
  > .steps {
    display: flex;
    width: 648px;
    margin: 0 auto;
    margin-top: 24px;
    > .stepWrap {
      flex: 1;
      display: inline-flex;
      justify-content: center;
      &:nth-child(1) {
        .step {
          padding-left: 50px;
          background-image: url('/images/step1_main200.svg');
        }
      }
      &:nth-child(2) {
        .step {
          padding-left: 54px;
          background-image: url('/images/step2_main200.svg');
        }
      }
      &:nth-child(3) {
        .step {
          padding-left: 54px;
          background-image: url('/images/step3_main200.svg');
        }
      }
      .step {
        background-repeat: no-repeat;
        background-position: top left;
        min-height: 66px;
        > .title {
          color: $neutral800;
          font-size: 16px;
          font-weight: 500;
          line-height: 120%;
          margin-top: 2px;
          letter-spacing: -0.1px;
        }
        > .content {
          color: $neutral700;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin-top: 8px;
          .important {
            color: $secon200;
          }
        }
      }
    }
  }
  .link {
    text-align: center;
    margin-top: 16px;
    a {
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.28px;
      text-decoration: underline;
      color: $main300;
      background-image: url('/images/arrowRight_main300.svg');
      background-repeat: no-repeat;
      background-position: right top 3px;
      padding-right: 16px;
      display: inline-block;
    }
  }
  .buttonArea {
    text-align: center;
    margin-top: 34px;
    a {
      display: inline-block;
      width: 372px;
      height: 48px;
      background-color: $main400;
      border-radius: 30px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 48px;
      color: $neutral100;
    }
  }
  .additionalEx {
    text-align: center;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    margin-top: 8px;
    &::before {
      content: '* ';
      position: relative;
      top: 3px;
    }
  }
}
