@import '@standby/common-ui/color.scss';

.sealLabel {
  border-radius: 4px;
  border: 1px solid $neutral400;
  width: 100%;
  display: inline-block;
  display: flex;
  align-items: center;
  padding: 8px 16px 12px 12px;
  gap: 12px;
  .imgWrap {
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    .sealImg {
      max-width: 33px;
      max-height: 33px;
      width: auto;
      height: auto;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    .info {
      max-width: 150px;
      .badge {
        color: $neutral700;
        font-size: 12px;
        font-weight: 400;
        line-height: 100%;
        padding: 4px;
        border-radius: 2px;
        background-color: $neutral200;
        white-space: nowrap;
        max-width: 100%;
        width: fit-content;
        overflow: hidden;
        text-overflow: ellipsis;
        &.corp {
          color: $main400;
          background-color: $main000;
        }
      }
      .sealTitle {
        overflow: hidden;
        color: $neutral800;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        margin-top: 2px;
        white-space: nowrap;
        width: 100%;
      }
    }
    .radioWrap {
      span {
        display: inline;
        top: -1px;
        left: 5px;
      }
    }
  }
}
