@use '@standby/dike_ui/design-token/color.module.scss' as color;

.box {
  height: 496px;

  border-radius: 8px;
  border: 1px solid color.$grayScale300;
  background: color.$grayScale100;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .explain {
    margin-top: 2px;
  }

  .upload {
    width: 424px;
    margin-top: 24px;
  }
}
