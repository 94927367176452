@use '@standby/dike_ui/design-token/color.module.scss' as color;
@use '@src/common/standby-color.scss' as standbyColor;

.modalContent {
  min-height: 385px;
  padding: 0 !important;
  display: flex;
  .side {
    width: 216px;
    background-color: standbyColor.$shCg700;
    padding: 16px 12px;
    position: relative;
    .logout {
      border-top: 1px solid color.$grayScale900;
      color: color.$grayScale000;
      width: 100%;
      height: 44px;
      align-items: center;
      position: absolute;
      left: 0;
      bottom: 0;
      padding-left: 12px;
      background-color: transparent;
      .inner {
        height: 100%;
        align-items: center;
        display: flex;
      }
    }
  }

  .tabContentWrapper {
    width: 472px;
    background-color: color.$grayScale000;
    display: flex;
    flex-direction: column;
    .tabContent {
      flex: 1;
    }
    .explainList {
      margin-top: 8px;
      margin-left: 8px !important;
      li {
        &:not(:first-child) {
          margin-top: 4px;
        }
      }
      &.right {
        display: flex;
        justify-content: flex-end;
      }
    }
    .inputWithBtn {
      display: flex;
      align-items: end;
      justify-content: space-between;
      gap: 8px;
      .input {
        flex: 1;
      }
    }
    .inputRow {
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    .form {
      height: 100%;
      position: relative;
      &.btnForm {
        padding-bottom: 60px;
      }
      .formBtn {
        position: absolute;
        bottom: 20px;
        right: 0;
      }
    }
    .termsAllCheckBox {
      display: flex;
      align-items: center;
      gap: 8px;
      .option {
        color: color.$grayScale400;
      }
      button {
        color: color.$grayScale500;
      }
    }
    .termsCheckBox {
      display: flex;
      flex-flow: column;
      gap: 8px;
      background-color: color.$grayScale100;
      padding: 12px 16px;
      margin-top: 8px;
    }
  }
}
