@import '@standby/common-ui/color.scss';

.loginContent {
  width: 100%;
  padding: 0 25px;
  padding-top: 84px;
  .logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .mainTitle {
    margin-bottom: 24px;
    color: $neutral900;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    .bold {
      font-weight: 700;
    }
  }
  .emailBox,
  .passwordBox {
    &.passwordBox {
      margin-top: 16px;
    }
    .title {
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
    }
    .inputText {
      margin-top: 12px;
      input {
        height: 51px;
      }
    }
  }
  .checkBox {
    display: block;
    margin-top: 16px;
  }
  .squareBtn {
    margin-top: 39px;
    width: 100%;
    height: 52px;
  }
  .likeBtnBox {
    margin-top: 16px;
    text-align: center;
    .likeBtn {
      color: $neutral600;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
      &:before {
        content: '';
        display: inline-block;
        margin: 0 12px;
        width: 1px;
        height: 12px;
        position: relative;
        top: 1px;
        background-color: $neutral300;
      }
      &:first-child {
        &:before {
          content: none;
        }
      }
    }
  }
}
