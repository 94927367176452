@import '@standby/common-ui/color.scss';

.DocumentPopup {
  padding: 20px 50px;
  width: 100%;
  height: calc(100vh - 135px);
  display: flex;
  flex-flow: column;
  @media screen and (max-height: 551px) {
    height: calc(100vh - 110px);
  }
  .documentWrap {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
    .options {
      width: 240px;
    }
    .documentBox {
      width: calc(1100px * 0.6);
      height: calc(100% - 68px);
      border: 1px solid $neutral400;
      border-radius: 4px;
      .content {
        width: 100%;
        height: calc(100% / 0.6);
        transform: scale(0.6);
        transform-origin: top left;
        .document {
          width: 1100px;
          height: 100%;
        }
      }
    }
  }
}
