@import '@standby/common-ui/color.scss';

.radio {
  position: relative;
  .bold {
    font-weight: 700;
  }
  .tag {
    position: absolute;
    top: -16px;
    right: -148px;
    padding: 6px 9px;
    width: 42px;
    height: 25px;
    background-color: $main400;
    border-radius: 0 6px;
    color: $neutral100;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
  }
  .radioExplain {
    position: relative;
    padding-left: 11px;
    margin-top: 15px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 0;
      width: 8px;
      height: 12px;
      background-image: url('/images/icon_arrow_triangle.svg');
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.disabled {
      &:before {
        background-image: url('/images/icon_arror_triangle_disabled.svg');
      }
    }
  }
}

.writtenResolutionMethod {
  text-align: center;
  .title {
    padding-top: 60px;
    color: $neutral800;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.18px;
    background-repeat: no-repeat;
    background-image: url('/images/loud_circle_icon.svg');
    background-position: center top;
    .bold {
      color: $main500;
    }
  }
  .explain {
    display: inline-block;
    position: relative;
    margin-top: 6px;
    padding-left: 10px;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    &:before {
      content: '*';
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
}
