@import '@standby/common-ui/color.scss';
.pageTitle {
  background-image: url('/images/agendaTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}
.selectAgenda {
  .content {
    display: flex;
    .vertical {
      &:not(:first-child) {
        margin-left: 20px;
      }
      .checkListItem {
        position: relative;
        border: 1px solid $neutral300;
        border-radius: 12px;
        &.highlight {
          border-color: $main200;
          .title {
            background-color: $main000;
            border-top-right-radius: 12px;
            border-top-left-radius: 12px;
          }
        }
        .highlightText {
          border: 1px solid $main200;
          border-radius: 2px;
          position: absolute;
          top: -21px;
          left: 48px;
          color: $neutral800;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: -0.22px;
          background-color: $neutral100;
          padding: 6px 8px;
          b {
            font-weight: 500;
          }
          &::before {
            position: absolute;
            content: '';
            display: inline-block;
            border-bottom: 4px solid transparent;
            border-top: 4px solid $main200;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            bottom: -8px;
            left: 4;
          }
          &::after {
            position: absolute;
            content: '';
            display: inline-block;
            border-bottom: 3px solid transparent;
            border-top: 3px solid $neutral100;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            z-index: 10;
            left: 9px;
            bottom: -6px;
          }
        }
        .title {
          background-repeat: no-repeat;
          background-position: left 14px top 10px;
          line-height: 24px;
          font-size: 16px;
          font-weight: 500;
          color: $neutral900;
          padding-left: 44px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        .tip {
          padding: 4px 12px;
          padding-left: 40px;
          background-color: $neutral200;
          color: $neutral600;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          letter-spacing: -0.77px;
          background-image: url('/images/tip_Icon.svg');
          background-repeat: no-repeat;
          background-position: left 12px top 10px;
        }
        .checkList {
          margin-top: 6px;
          padding-left: 16px;
          padding-right: 16px;
          &.multiRow {
            display: inline-flex;
            flex-wrap: wrap;
            .checkItem {
              flex: 1 1 40%;
              &:nth-child(-n + 2) {
                margin-top: 0;
              }
              &:nth-child(2n) {
                padding-left: 9px;
              }
            }
          }
          .checkItem {
            margin-top: 16px;
            &:nth-child(1) {
              margin-top: 0;
            }
            .disabeldAgenda {
              cursor: url('/images/disabeldAgenda.svg'), auto;
            }
            &:hover {
              label:not(.disabeldAgenda) {
                .checkTitle {
                  color: $main500;
                }
              }
            }
            .checkTitle {
              font-weight: 500;
              position: relative;
              white-space: pre-wrap;
              .badge {
                position: absolute;
                width: 48px;
                height: 18px;
                background-color: $neutral200;
                border-radius: 2px;
                color: $neutral600;
                font-size: 11px;
                font-weight: 400;
                letter-spacing: -0.22px;
                text-align: center;
                line-height: 18px;
                left: 135px;
                top: 0;
              }
              .toolTip {
                position: absolute;
                top: 0;
                left: 150px;
                white-space: normal;
              }
            }
            .checkboxSubInfo {
              font-size: 12px;
              color: $neutral600;
              font-weight: 300;
              line-height: 12px;
              margin-top: 2px;
              span + span {
                margin-left: 8px;
                position: relative;
                &:before {
                  position: absolute;
                  content: '';
                  background-color: $neutral600;
                  width: 2px;
                  height: 2px;
                  border-radius: 2px;
                  display: inline-block;
                  top: 8px;
                  left: -5px;
                }
              }
            }
            .freeAgendaCountBox {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-top: 4px;
              padding: 6px 8px;
              width: 192px;
              height: 28px;
              background-color: $neutral200;
              border-radius: 2px;
              color: $neutral700;
              font-size: 12px;
              font-weight: 500;
              line-height: 12px;
              .btnArea {
                display: inline-flex;
                align-items: center;
                justify-content: space-between;
                width: 55px;
                color: $neutral900;
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                .minusBtn {
                  width: 16px;
                  height: 16px;
                  background-image: url('/images/minusBtn_Icon.svg');
                  background-size: cover;
                  background-repeat: no-repeat;
                  &.disabled {
                    cursor: not-allowed;
                    background-image: url('/images/minusBtn_Icon_Disabled.svg');
                  }
                }
                .plusBtn {
                  width: 16px;
                  height: 16px;
                  background-image: url('/images/plusBtn_Icon.svg');
                  background-size: cover;
                  background-repeat: no-repeat;
                  &.disabled {
                    cursor: not-allowed;
                    background-image: url('/images/plusBtn_Icon_Disabled.svg');
                  }
                }
              }
            }
          }
        }
        .subLink {
          position: absolute;
          bottom: 16px;
          left: 16px;
        }
        .titleToolTip {
          position: absolute;
          right: 20px;
          top: 20px;
        }
        &:not(:first-child) {
          margin-top: 16px;
        }
      }
      .row {
        display: flex;
        .infoItem {
          &:not(:first-child) {
            margin-left: 16px;
          }
          width: 248px;
          .info {
            height: 98px;
            padding: 10px;
            background-repeat: no-repeat;
            background-position: top 10px left 10px;
            border-radius: 12px;
            &.capital {
              background-color: $main200;
              background-image: url('/images/capital_won_Icon_0402.svg');
              color: $main300;
              > .modifiableInfoSummary {
                background-image: url('/images/InfoSummary_main200.svg');
                > .text.iconTrue {
                  background-image: url('/images/main200_trueIcon.svg');
                }
              }
            }
            &.executives {
              background-color: $sub100;
              background-image: url('/images/executives_item_icon_0402.svg');
              color: $sub300;
              > .modifiableInfoSummary {
                background-image: url('/images/executivesSummary_sub300_0402.svg');
                > .text.iconTrue {
                  background-image: url('/images/sub300_trueIcon_0402.svg');
                }
                > .text.iconFalse {
                  background-image: url('/images/sub300_falseIcon_0402.svg');
                }
              }
            }
            > .title {
              color: $neutral100;
              font-size: 15px;
              font-weight: 700;
              padding-left: 26px;
              line-height: 15px;
              margin-top: 2px;
              > .basicInfo {
                margin-left: 4px;
                font-size: 13px;
                font-weight: 400;
                color: #fff;
                letter-spacing: -0.5px;
                line-height: 100%;
                margin-top: 9px;
              }
            }
            > .modifiableInfo {
              font-size: 12px;
              letter-spacing: -0.5px;
              font-weight: 500;
              color: $neutral100;
              line-height: 12px;
              margin-top: 13px;
            }
            > .modifiableInfoSummary {
              height: 30px;
              background-color: $neutral100;
              border-radius: 4px;
              margin-top: 6px;
              background-repeat: no-repeat;
              background-position: top 8px left 8px;
              > .text {
                font-size: 16px;
                font-weight: 500;
                line-height: 32px;
                padding-left: 28px;
                background-repeat: no-repeat;
                background-position: top 5px right;
                padding-right: 18px;
              }
            }
          }
          .subLink {
            line-height: 12px;
            margin-top: 4px;
          }
        }
      }
    }
  }
  .btnArea {
    padding: 24px 0;
    text-align: center;
    .contentSubmitBtn {
      margin: 0 auto;
      width: 298px;
    }
  }
}
.agendaInfoInputs {
  margin-bottom: 400px;
  > .agendaInputsTitle {
    height: 58px;
    background-color: $main000;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    color: $neutral800;
    padding-left: 52px;
    background-image: url('/images/agendaInputsTitleIcon.svg');
    background-repeat: no-repeat;
    background-position: top 16px left 20px;
    padding-top: 15px;
    position: relative;
    .toolTip {
      position: relative;
      top: 2px;
    }
  }
}
.allSaved {
  text-align: center;
  margin-top: 24px;
  border: 1px solid $neutral300;
  padding-top: 48px;
  padding-bottom: 52px;
  .text {
    color: $neutral900;
    .big {
      font-size: 18px;
      line-height: 23.4px;
      .important {
        color: $main500;
      }
    }
    .small {
      color: $neutral800;
      font-size: 15px;
      line-height: 19.5px;
      margin-top: 8px;
    }
  }
  img {
    margin-top: 24px;
  }
  .btnArea {
    margin-top: 36px;
    .contentSubmitBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}
.aLink {
  font-weight: 400;
  letter-spacing: -0.26px;
  font-size: 13px;
  position: relative;
  display: inline-block;
  color: $neutral600;
  text-decoration: underline;
  line-height: 12px;
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 1px;
    right: -12px;
    width: 12px;
    height: 12px;
    background-image: url('/images/arrow_icon_neutral600.svg');
    background-size: cover;
    background-repeat: no-repeat;
  }
  &:hover {
    text-decoration: underline;
  }
}
