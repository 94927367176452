@use '@standby/dike_ui/design-token/color.module.scss' as color;

.wrap {
  display: flex;
  gap: 8px;
  padding-top: 20px;
  .icon {
    width: 32px;
    height: 32px;
    background-color: color.$primary50;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .title {
    color: color.$grayScale900;
  }
}
