@import '@standby/common-ui/color.scss';

.questionForm {
  .qusetion {
    .questionTitle {
      font-size: 16px;
      line-height: 16px;
      color: $neutral900;
      font-weight: 700;
      margin-bottom: 16px;
      .explain {
        color: $neutral600;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        margin-top: 6px;
        &::before {
          content: '* ';
        }
      }
    }
    .titleSubInfo {
      font-size: 14px;
      line-height: 18.2px;
      color: $neutral600;
      font-weight: 400;
      margin-top: 6px;
      margin-bottom: 10px;
    }
    margin-bottom: 36px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    .subTextInput {
      position: relative;
      display: inline-block;
      padding-right: 20px;
      .inputSubText {
        right: 0;
        top: 10px;
        position: absolute;
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        &.hasEx {
          top: 35px;
        }
        &.hasExSmall {
          top: 30px;
        }
      }
    }
    .radio {
      .radioExplain {
        margin-top: 15px;
        color: $neutral700;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: -0.24px;
      }
    }
  }
}
