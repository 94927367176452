@use '@standby/dike_ui/design-token/color.module.scss' as color;

.sectionTitle {
  color: color.$grayScale900;
  display: flex;
  align-items: center;
  gap: 8px;
  &:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 18px;
    border-radius: 1px;
    background-color: color.$primary900;
  }
}
