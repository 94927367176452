@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/stockHomeTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}
.imgBox {
  position: relative;
  .explain {
    position: absolute;
    top: 136px;
    left: 50%;
    transform: translateX(-50%);
    color: $neutral100;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-shadow: 2px 2px 6px rgba(68, 68, 68, 0.8);
    .subExplain {
      margin-top: 6px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.32px;
    }
  }
}
.row {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  .capital {
    width: 400px;
    height: 279px;
    background-image: url('/images/capital_background.svg');
    padding: 20px 16px;
    position: relative;
    .title {
      color: $neutral100;
      font-weight: 400;
      font-size: 16px;
      margin-top: 5px;
    }
    .titleLabel {
      text-align: center;
      margin-top: 30px;
      span {
        display: inline-block;
        height: 25px;
        padding: 0 6px;
        line-height: 25px;
        background-color: $neutral100;
        border-radius: 4px;
        color: $main500;
        font-size: 13px;
        font-weight: 400;
      }
    }
    .price_important {
      text-align: center;
      color: $neutral100;
      font-weight: 400;
      margin-top: 10px;
      .price {
        font-family: 'Roboto';
        font-size: 36px;
      }
      .won {
        font-size: 28px;
        margin-left: 3px;
      }
    }
    .price_sub {
      font-weight: 400;
      color: $neutral100;
      font-size: 14px;
      letter-spacing: -0.019em;
      text-align: center;
    }
    .info {
      position: absolute;
      height: 30px;
      width: calc(100% - 32px);
      background-color: $neutral100;
      left: 16px;
      line-height: 18.2px;
      bottom: 16px;
      border-radius: 6px;
      padding-left: 30px;
      background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_positive.svg');
      background-position: top 7px left 8px;
      background-repeat: no-repeat;
      box-sizing: border-box;
      padding-top: 5px;
      span,
      a {
        font-size: 14px;
        font-weight: 400;
        color: $neutral900;
      }
      a {
        color: $main300;
        text-decoration: underline;
      }
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .info {
      font-size: 15px;
      color: $neutral900;
      padding-left: 40px;
      font-weight: 400;
    }
    .changeDate {
      margin-left: 77px;
      .datepicker {
        input {
          width: 107px;
          height: 26px;
          font-size: 13px;
        }
      }
      span {
        margin-left: 6px;
        color: $neutral600;
        font-size: 13px;
        display: inline-block;
        width: 30px;
        font-weight: 400;
      }
    }
    .btnarea {
      a,
      button {
        margin-left: 10px;
        &:nth-child(1) {
          margin-left: 0;
        }
      }
    }
  }
  .stockList {
    margin-top: 16px;
    .listCard {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
    &.overflow {
      height: 172px;
      overflow-y: hidden;
    }
    &.many {
      margin-top: 13px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 20px;
      .listCard {
        width: 284px;
        &:nth-child(2) {
          margin-top: 0;
        }
      }
      &.open {
        overflow-y: visible;
        height: auto;
      }
    }
  }
  .moreView {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
    color: $neutral600;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    background-color: transparent;
    background-image: url('/images/moreView_close.svg');
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 15px;
    &.open {
      background-image: url('/images/moreView_open.svg');
    }
  }
  .shareholderList {
    .title {
      border-bottom: 1px solid $neutral400;
      font-size: 15px;
      color: $neutral900;
      font-weight: 400;
      display: flex;
      align-items: flex-end;
      letter-spacing: -0.019em;
      padding-bottom: 6px;
      margin-top: 9px;
      > span {
        display: inline-block;
        box-sizing: border-box;
        &:nth-child(1) {
          width: 349px;
          padding-left: 20px;
        }
        &:nth-child(2) {
          width: 120px;
        }
        &:nth-child(3) {
          width: 180px;
        }
        &:nth-child(4) {
          width: 121px;
          text-align: right;
        }
        &:nth-child(5) {
          width: 82px;
          text-align: right;
        }
        &:nth-child(6) {
          padding-left: 30px;
          text-align: center;
          flex-grow: 1;
        }
      }
    }
    .list {
      margin-top: 10px;
      > a {
        display: inline-block;
        margin-top: 8px;
        width: 100%;
        text-decoration: none;
        &:nth-child(1) {
          margin-top: 0;
        }
      }
      .item {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        white-space: pre-wrap;
        [data-status='발송전'],
        [data-status='발송중'] {
          color: $secon200;
        }
        [data-status='발송완료'] {
          color: $secon200;
          line-height: 16px;
        }
        [data-status='동의완료'] {
          color: $sub300;
        }
        [data-status='발송실패'] {
          color: $warning200;
          padding-right: 21px;
          background-repeat: no-repeat;
          background-position: right 1px center;
          background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_negative.svg');
        }
        > span {
          &:nth-child(1) {
            position: relative;
            width: 314px;
            padding-left: 27px;
            margin-left: 8px;
            margin-right: 16px;
            > span {
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          &:nth-child(2) {
            width: 120px;
          }
          &:nth-child(4) {
            padding-left: 30px;
            text-align: center;
            flex-grow: 1;
          }
        }
        .stocksList {
          width: 383px;
          .stockItem {
            display: flex;
            align-items: center;
            border-top: 1px solid $neutral300;
            padding: 7px 0;
            &:nth-child(1) {
              border: none;
              padding-top: 0px;
            }
            &:nth-last-child(1) {
              padding-bottom: 0;
            }
            > span {
              display: inline-block;
              &:nth-child(1) {
                width: 164px;
                margin-right: 16px;
              }
              &:nth-child(2) {
                width: 121px;
                text-align: right;
              }
              &:nth-child(3) {
                width: 82px;
                text-align: right;
              }
            }
          }
        }
      }
    }
    .sum {
      font-size: 15px;
      font-weight: 400;
      margin-top: 12px;
      > span {
        display: inline-block;
        &:nth-child(1) {
          font-weight: 400;
          width: 340px;
          padding-left: 20px;
        }
        &:nth-child(2) {
          width: 430px;
          text-align: right;
        }
      }
    }
    .emptyShareholders {
      padding: 46px 0 37px;
      text-align: center;
      .explain {
        margin-top: 12px;
        color: $neutral500;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.3;
      }
    }
  }
}
.alertContent {
  margin-top: 12px;
  li {
    margin-top: 2px;
    position: relative;
    padding-left: 9px;
    color: $neutral700;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 0;
      width: 3px;
      height: 3px;
      background-color: $neutral700;
      border-radius: 50%;
    }
  }
}
