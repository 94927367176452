@import '@standby/common-ui/color.scss';

.top {
  margin-top: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    color: $primary800;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
  }
  .moveStep2 {
    padding: 8px 30px 9px 16px;
    height: 31px;
    background-color: $main400;
    border-radius: 4px;
    color: $neutral100;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.5px;
    background-image: url('/images/booking_small_arrow_icon.svg');
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: right 16px top 9px;
  }
}
.promotion {
  margin-top: 36px;
  display: flex;
  flex-flow: column;
  padding-bottom: 110px;
  &.preview {
    margin-top: 0;
    position: initial;
    padding-bottom: 60px;
    .faq {
      padding: 0 40px;
    }
    .notice {
      margin-left: 40px;
      margin-right: 40px;
    }
  }
  .faq {
    margin-top: 80px;
    > .title {
      color: $neutral900;
      font-size: 32px;
      font-weight: 700;
      line-height: 140%;
      letter-spacing: -1.28px;
      text-align: center;
    }
    > ul {
      margin-top: 32px;
      display: flex;
      flex-flow: column;
      gap: 12px;
      > li {
        border-radius: 12px;
        background: $neutral200;
        padding: 20px 24px 20px 48px;
        position: relative;
        cursor: pointer;
        &:before {
          content: 'Q.';
          display: inline-block;
          position: absolute;
          left: 24px;
          top: 24px;
          color: $main200;
          font-size: 16px;
          font-weight: 700;
          line-height: 140%;
          letter-spacing: -0.64px;
        }
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          right: 24px;
          top: 27px;
          width: 16px;
          height: 16px;
          background-image: url('/images/awsCreditFaq_opened.svg');
        }
        &.closed::after {
          background-image: url('/images/awsCreditFaq_closed.svg');
        }
        .title {
          color: $neutral800;
          font-size: 18px;
          font-weight: 500;
          line-height: 30px;
          letter-spacing: -0.72px;
        }
        .content {
          color: $neutral700;
          font-size: 16px;
          font-weight: 400;
          line-height: 160%;
          letter-spacing: -0.64px;
          margin-top: 8px;
          margin-left: 1px;
        }
      }
    }
  }
  .notice {
    border-top: 2px solid $neutral200;
    margin-top: 40px;
    padding-top: 24px;
    .title {
      color: $neutral800;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
    }
    ul {
      li {
        color: $neutral600;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        margin-top: 8px;
        &:before {
          content: '* ';
          position: relative;
          top: 3px;
        }
      }
    }
  }
  a.moveStep2 {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .moveStep2 {
    position: fixed;
    left: calc(50% + 108px - 288px);
    bottom: 24px;
    width: 576px;
    height: 76px;
    background-color: $main500;
    box-shadow: 1px 1px 4px 2px rgba(51, 51, 51, 0.24);
    border-radius: 4px;
    z-index: 50;
    .btnContent {
      display: inline-block;
      position: relative;
      padding-right: 22px;
      color: $neutral100;
      font-size: 22px;
      font-weight: 400;
      line-height: 1;
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 3px;
        right: 0;
        width: 20px;
        height: 20px;
        background-image: url('/images/booking_arrow_icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }
}
