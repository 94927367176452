@import '@standby/common-ui/color.scss';

.expired {
  width: 100%;
  height: 100vh;
  background-color: $primary900;
  padding-top: 141px;
  .expiredBox {
    margin: 0 auto;
    padding: 60px 50px 56px;
    width: 560px;
    min-height: 492px;
    background-color: $neutral100;
    border-radius: 20px;
    .logo {
      text-align: center;
      padding-bottom: 30px;
      border-bottom: 1px solid $neutral300;
    }
  }
  .content {
    margin-top: 80px;
    text-align: center;
    .title {
      color: $neutral800;
      font-size: 24px;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: -0.5px;
    }
    .explain {
      margin-top: 20px;
      color: $neutral700;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: -0.3px;
    }
  }
}
