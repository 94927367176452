@use '@standby/dike_ui/design-token/color.module.scss' as color;
@use '@standby/dike_ui/design-token/font.module.scss';

.result {
  width: 100%;
  .logoWrap {
    text-align: center;
    .logo {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .mainTitle {
    text-align: center;
    margin-bottom: 48px;
    color: color.$grayScale800;
  }
  .likeBtnBox {
    margin-top: 12px;
    text-align: center;
    .likeBtn {
      @extend .Body-14R;
      color: color.$grayScale700;
    }
  }
  .idList {
    border: 1px solid color.$grayScale300;
    border-radius: 4px;
    padding: 0 16px;
    background-color: color.$grayScale100;
    margin-bottom: 48px;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
      &:not(:first-child) {
        border-top: 1px solid color.$grayScale200;
      }
      .date {
        color: color.$grayScale500;
      }
      .userId {
        color: color.$grayScale700;
      }
    }
  }
  .mainBtn {
    width: 100%;
  }
}
