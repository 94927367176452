@use '@standby/dike_ui/design-token/color.module.scss' as color;

.modalContent {
  display: flex;
  justify-content: space-between;
  width: 760px;
  .preview {
    border-radius: 4px;
    border: 1px solid color.$grayScale300;
    background-color: color.$grayScale000;
    width: 310px;
    .imgWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 300px;
      img {
        max-width: 160px;
        max-height: 160px;
        width: auto;
        height: auto;
      }
    }
    .info {
      background-color: color.$grayScale500;
      color: color.$grayScale000;
      text-align: center;
      padding: 8px 0;
      width: 100%;
      display: inline-block;
    }
  }
  .form {
    width: 424px;
    .titleWrap {
      display: flex;
      align-items: center;
      gap: 8px;
      color: color.$grayScale900;
      .icon {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        background-color: color.$primary500;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .updatedAt {
      color: color.$grayScale500;
      margin-top: 4px;
    }
    .radioLabel {
      margin-top: 24px;
      margin-bottom: 2px;
    }
    .radioGroup {
      display: flex;
      gap: 16px;
    }
    .executiveId {
      margin-top: 8px;
    }
    .infoBox {
      width: 100%;
      margin-top: 4px;
    }
    .alias {
      margin-top: 16px;
    }
  }
}
.buttonContainer {
  display: flex;
  gap: 12px;
  button {
    width: 336px;
  }
}
