@use '@standby/dike_ui/design-token/color.module.scss' as color;

.setupNewPasswordContent {
  width: 100%;
  .logoWrap {
    text-align: center;
    .logo {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .mainTitle {
    text-align: center;
    margin-bottom: 48px;
    color: color.$grayScale800;
  }
  .newPassword {
    margin-top: 20px;
  }
  .mainBtn {
    margin-top: 48px;
    width: 100%;
  }
}
