@import '@standby/common-ui/color.scss';

.tooltipContent {
  padding: 13px 16px 16px 16px;
  width: 460px;
  .sampleList {
    display: flex;
    justify-content: space-between;
  }
  ul.exList {
    font-size: 14px;
    font-weight: 400;
    color: $neutral700;
    margin-bottom: 12px;
    li {
      padding-left: 8px;
      position: relative;
      line-height: 1.2;
      margin-top: 4px;
      &:nth-child(1) {
        margin-top: 0;
      }
      &::before {
        content: '';
        display: inline-block;
        background-color: $neutral600;
        border-radius: 3px;
        width: 3px;
        height: 3px;
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
  }
  .exTitle {
    color: $main500;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    background-color: $main000;
    padding: 4px 6px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 6px;
  }
}
.ex {
  color: $neutral600;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  margin-top: 6px;
  &:before {
    content: '* ';
    position: relative;
    top: 4px;
  }
}
