@import '@standby/common-ui/color.scss';

.stockInfo {
  padding: 24px 36px;
  width: 1040px;
  .stock {
    color: $neutral800;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    .explain {
      margin-top: 6px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
    }
  }
  .content {
    margin-top: 16px;
    padding: 14px;
    color: $neutral900;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    border: 1px solid $neutral400;
    border-radius: 4px;
  }
}
