@import '@standby/common-ui/color.scss';

.delegationPopup {
  padding: 36px 50px;
  width: 778px;
  height: 461px;
  .advertisement {
    .title {
      color: $neutral900;
      font-size: 18px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.02em;
    }
    .jinpyeongBox {
      margin-top: 12px;
      display: flex;
      .img {
        display: block;
        width: 220px;
        height: 144px;
        border: 1px solid $neutral400;
      }
      .jinpyeong {
        margin-left: 20px;
        .jinpyeongTitle {
          color: $neutral700;
          font-size: 16px;
          font-weight: 400;
          .bold {
            color: $neutral900;
            font-weight: 400;
          }
        }
        .jinpyeongInfo {
          position: relative;
          padding-left: 20px;
          margin-top: 8px;
          &:first-child {
            margin-top: 0;
          }
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.02em;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 1px;
            left: 0;
            width: 16px;
            height: 16px;
            background-image: url('/images/check_icon.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
          .boldColor {
            color: $main300;
            font-weight: 400;
          }
          .bold {
            font-weight: 400;
          }
        }
        .jinpyeongLogoImg {
          margin-top: 18px;
        }
        .barunLogoImg {
          margin-top: 15px;
        }
      }
    }
  }
  .agreeInfo {
    padding: 24px 120px 0;
    margin-top: 24px;
    border-top: 1px solid $neutral300;
    .question {
      color: $neutral900;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.02em;
    }
    .checkBox {
      display: inline-block;
      margin-top: 13px;
      font-weight: 400;
      .agreeModal {
        margin-left: 2px;
        color: $main300;
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        background-color: transparent;
        line-height: 100%;
      }
    }
  }
  .btnArea {
    margin-top: 23px;
    .registerBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}
