@use '@standby/dike_ui/design-token/color.module.scss' as color;

.container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inviteLink {
      display: flex;
      align-items: center;
      color: color.$primary400;
      gap: 4px;
    }
  }

  table {
    thead {
      tr {
        color: color.$grayScale700;
      }
    }

    tbody {
      tr {
        color: color.$grayScale800;

        td {
          padding: 12px 16px;
        }
      }
    }
  }
}
