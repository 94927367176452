@import '@standby/common-ui/color.scss';
.header {
  height: 60px;
  background-color: $primary900;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    align-items: center;
    .copyBtn {
      background-image: url('/images/copySmall_white.svg');
      color: $neutral100;
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
      text-decoration: none;
      background-position: top 2px right;
      &.cssn {
        font-weight: 400;
        margin-left: 20px;
      }
    }
    .downloadBtn {
      padding-left: 20px;
      background-image: url('/images/download_white_icon.svg');
      background-color: transparent;
      background-position: top -1px left;
      background-repeat: no-repeat;
      color: $neutral100;
      font-size: 14px;
      font-weight: 400;
      text-decoration-line: underline;
      margin-left: 60px;
    }
    .pastNames {
      color: $neutral100;
      font-size: 14px;
      font-weight: 400;
      margin-left: 60px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .registrationAgency {
      color: $neutral100;
      font-size: 13px;
      font-weight: 700;
      line-height: 34px;
      padding: 0 14px;
      height: 34px;
      background-color: $sub200;
      border-radius: 4px;
      margin-right: 20px;
      &.new {
        background-image: url('/images/changeIcon.svg');
        background-position: right 12px top 10px;
        background-repeat: no-repeat;
        padding-right: 32px;
      }
    }
  }
  .cancelRegBtn {
    color: $neutral100;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: -0.28px;
    text-decoration-line: underline;
    padding-right: 14px;
    background-image: url('/images/arrow_neutral100.svg');
    background-position: top 1px right;
    background-repeat: no-repeat;
    background-color: transparent;
    margin-right: 20px;
  }
}
