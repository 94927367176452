@import '@standby/common-ui/color.scss';

.sendPreview {
  padding: 50px;
  padding-top: 36px;
  width: 1040px;
  .popUptitle {
    padding-left: 24px;
    color: $neutral800;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_hover_active.svg');
    background-repeat: no-repeat;
    background-position: left center;
  }
  .informationBaord {
    margin-top: 28px;
    .title {
      position: relative;
      padding-left: 8px;
      color: $neutral700;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.56px;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 8px;
        left: 0;
        width: 3px;
        height: 3px;
        background-color: $neutral700;
        border-radius: 50%;
      }
    }
    .informationBoardImg {
      margin-top: 8px;
    }
  }
  .emailAndAlimTalk {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    .email,
    .alimTalk {
      .title {
        position: relative;
        padding-left: 8px;
        color: $neutral700;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.56px;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 8px;
          left: 0;
          width: 3px;
          height: 3px;
          background-color: $neutral700;
          border-radius: 50%;
        }
      }
      .img {
        margin-top: 12px;
      }
    }
  }
}
