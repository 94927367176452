@import '@standby/common-ui/color.scss';

.modalContent {
  width: 889px;
  padding: 32px 50px 36px 50px;
  display: flex;
  justify-content: space-between;
  .content {
    > .title {
      background-repeat: no-repeat;
      background-position: left center;
      color: $neutral800;
      font-size: 18px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.72px;
      padding-left: 32px;
      display: flex;
      align-items: center;
      background-image: url('/images/main500_icon_24/icon_pencil.svg');
    }
    > .subTitle {
      color: $neutral500;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      margin-top: 5px;
    }
    form {
      margin-top: 21px;
      .inputRows {
        &:not(:first-child) {
          margin-top: 13px;
        }
        .inputTitle {
          margin-bottom: 4px;
          color: $neutral800;
          font-size: 15px;
          font-weight: 500;
          line-height: 22px;
          display: inline-block;
          &.required::after {
            color: $warning200;
            content: '*';
          }
        }
        .textarea {
          min-height: 91px;
          resize: none;
        }
        .radios {
          display: inline-flex;
          gap: 17px;
        }
      }
      .submit {
        width: 100%;
        margin-top: 33px;
      }
    }
  }
}
