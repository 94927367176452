@import '@standby/common-ui/color.scss';

.fileWrap {
  width: 100%;
}

.downloadBtn {
  background-color: transparent;
  background-image: url('/images/downloadIcon_main300.svg');
  padding-left: 20px;
  background-position: left top 2px;
  background-repeat: no-repeat;
  span {
    color: $main300;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    text-decoration: underline;
  }
}
.deleteFile {
  color: $neutral600;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  text-decoration-line: underline;
  background-color: transparent;
  margin-left: 8px;
}
.uploadBtn {
  background-color: transparent;
  background-image: url('/images/uploadIcon_neutral700.svg');
  padding-left: 20px;
  background-position: left top 2px;
  background-repeat: no-repeat;
  span {
    color: $neutral700;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;
    text-decoration: underline;
  }
}
.emptyFile {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.empty {
  color: $neutral500;
  font-size: 14px;
  font-weight: 400;
}
