@import '@standby/common-ui/color.scss';

.table {
  width: 440px;
  margin-top: 15px;
  tr.samePerson {
    td {
      padding-bottom: 4px;
    }
  }
  tr.samePerson + tr.samePerson {
    td {
      border-top: none;
      padding-top: 4px;
      padding-bottom: 10px;
    }
  }
  th {
    font-size: 16px !important;
    text-align: left;
    font-weight: 500;
    .important {
      color: $warning200;
    }
  }
  .new {
    position: relative;
    background-image: url('/images/triangle_neutral600.svg');
    background-repeat: no-repeat;
    background-position: right center;
    .name {
      display: flex;
      color: $neutral500;
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      letter-spacing: -0.28px;
      position: relative;
      top: -1px;
      .number {
        width: 18px;
        display: inline-block;
      }
    }
  }
  .origin {
    .info {
      display: flex;
      align-items: center;
      .tags {
        display: inline-flex;
        flex-flow: column;
        .tag {
          &:not(:first-child) {
            margin-top: 2px;
          }
          width: 63px;
          height: 18px;
          border-radius: 2px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: $neutral100;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.24px;
          &[data-changetype='임기연장'],
          &[data-changetype='직급변경'],
          &[data-changetype='집주소변경'] {
            background-color: $sub100;
          }
          &[data-changetype='사임_퇴임'] {
            background-color: $warning100;
          }
          &[data-changetype='변경없음'] {
            background-color: $neutral500;
          }
          &[data-changetype='신규'] {
            background-color: $main200;
          }
        }
      }
      .name {
        margin-left: 6px;
        font-size: 14px;
        font-weight: 400;
        color: $neutral800;
        letter-spacing: -0.28px;
        position: relative;
        top: -1px;
        &.erase {
          text-decoration: line-through;
          color: $warning200;
        }
      }
    }
    .position {
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      letter-spacing: -0.24px;
      color: $neutral500;
      margin-top: 2px;
      .before {
        padding-right: 14px;
        background-image: url('/images/before_neutral600.svg');
        background-repeat: no-repeat;
        background-position: right 1px top 3px;
      }
      .after {
        color: $sub300;
      }
    }
  }
}
