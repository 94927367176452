@use '@standby/dike_ui/design-token/color.module.scss' as color;

.cell {
  display: flex;
  align-items: center;
  gap: 8px;

  .authorityType {
    width: 226px;
  }

  .deleteButton {
    width: 72px;
    color: color.$grayScale700;
  }
}
