@import '@standby/common-ui/color.scss';

.table {
  width: 940px;
  th {
    text-align: left;
    &:nth-child(1) {
      padding-left: 26px;
    }
  }
  td {
    vertical-align: top;
  }
  .origin {
    color: $neutral700;
    line-height: 1.3;
    letter-spacing: -0.02em;
    position: relative;
    padding-right: 16px;
    color: $neutral500;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 13px;
      right: 8px;
      width: 8px;
      height: 12px;
      background-image: url('/images/icon_arrow_triangle.svg');
    }
  }
  .latest {
    color: $main300;
    .subTextInput {
      margin-top: 10px;
      color: $neutral800;
      .inputTitle {
        margin-right: 12px;
        margin-top: 10px;
      }
      .won {
        margin-left: 6px;
        margin-top: 10px;
      }
    }
    .inputRow {
      display: flex;
      align-items: top;
      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }
}
