@import '@standby/common-ui/color.scss';

.advertisement {
  display: flex;
  &:nth-child(2) {
    margin-top: 16px;
  }
  .img {
    width: 220px;
    height: 144px;
    border: 1px solid $neutral400;
  }
  .adBox {
    margin-left: 20px;
    .adTitle {
      margin-bottom: 10px;
      color: $neutral900;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.02em;
      .bold {
        color: $main500;
        font-weight: 400;
      }
      .light {
        color: $neutral700;
      }
      .lightBold {
        font-weight: 500;
      }
    }
    .adSub {
      position: relative;
      margin-top: 8px;
      padding-left: 20px;
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.02em;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 16px;
        height: 16px;
        background-image: url('/images/check_icon.svg');
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .jinpyeongLogoImg {
      margin-top: 23px;
    }
    .barunLogoImg {
      margin-top: 19px;
    }
  }
}
.form {
  position: relative;
  padding-bottom: 30px;
  .contentTitle {
    color: $neutral600;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    .bold {
      color: $neutral900;
    }
  }
  .contentBox {
    margin-top: 60px;
    &:nth-child(2) {
      margin-top: 36px;
    }
    width: 460px;
    .title {
      position: relative;
      color: $neutral900;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: -0.5px;
      .num {
        position: absolute;
        top: 0;
        left: 0;
      }
      .ex {
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
      }
      .openTooltipBox {
        margin-top: 16px;
        padding: 11px 14px 16px;
        border: 1px solid $neutral300;
        border-radius: 12px;
        .tooltipBoxTitle {
          position: relative;
          padding-left: 24px;
          color: $neutral900;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 18px;
            height: 18px;
            background-image: url('~@standby/common-ui/components/asset/Icon/icon_question_hover_active.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        .title {
          margin-top: 10px;
          color: $neutral700;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
        }
        .explain {
          margin-top: 4px;
          padding-left: 15px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.02em;
        }
      }
    }
    .inputRow {
      margin-top: 16px;
      .datepicker {
        input {
          width: 220px;
        }
      }
      .fileUploadBox {
        margin-top: 16px;
        .fileExplain {
          margin-bottom: 6px;
          color: $neutral700;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.3;
        }
      }
      .table {
        width: 100%;
        thead {
          tr {
            th {
              padding: 9px 8px;
              border-top: 1px solid $neutral600;
              border-bottom: 1px solid $neutral600;
              &:nth-child(1) {
                color: $neutral100;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.3;
                text-align: left;
                background-color: $neutral600;
              }
              &:nth-child(2) {
                color: $neutral800;
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
              }
            }
          }
        }
        tbody {
          tr {
            border-top: 1px solid $neutral300;
            &:first-child {
              border-top: none;
            }
            &:last-child {
              border-bottom: 1px solid $neutral300;
            }
            td {
              padding: 8px;
              &:nth-child(1) {
                color: $neutral900;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.3;
                letter-spacing: -0.02em;
                background-color: $neutral200;
              }
            }
          }
        }
      }
      .businessBox {
        position: relative;
        margin-top: 16px;
        .businessTitle {
          padding: 11px 12px;
          font-size: 16px;
          font-weight: 400;
          background-color: $neutral200;
          border-top: 1px solid $neutral500;
          border-bottom: 1px solid $neutral300;
        }
        .select {
          margin-top: 16px;
          margin-bottom: 16px;
        }
        .closeBtn {
          position: absolute;
          top: 17px;
          right: 10px;
        }
      }
      .btnArea {
        margin-top: 16px;
        border-top: 1px solid $neutral300;
        .addBtn {
          margin-top: 16px;
          padding: 14px 16px;
          width: 100%;
          height: 48px;
          text-align: left;
          border: 1px solid $main400;
          border-radius: 6px;
          transition: 0.3s;
          &:hover {
            background-color: $main000;
          }
        }
      }
    }
    .tooltip {
      margin-top: 10px;
      .tooltipContent {
        padding: 0 14px 16px;
        width: 460px;
        .tooltipBox {
          margin-top: 10px;
          .tooltipTitle {
            color: $neutral700;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3;
          }
          .tooltipExplain {
            margin-top: 4px;
            padding-left: 15px;
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: -0.02em;
            .fileExplain {
              margin-top: 7px;
              &:first-child {
                margin-top: 0;
              }
            }
            .fileBtn {
              margin-top: 6px;
            }
            &.hyphen {
              position: relative;
              padding-left: 23px;
              &:before {
                content: '-';
                display: inline-block;
                position: absolute;
                top: 0;
                left: 15px;
              }
            }
          }
        }
        ul {
          li {
            position: relative;
            margin-top: 10px;
            padding-left: 8px;
            color: $neutral700;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 8px;
              left: 0;
              width: 3px;
              height: 3px;
              background-color: $neutral700;
              border-radius: 50%;
            }
          }
        }
        .cssn {
          margin-top: 10px;
          margin-bottom: -4px;
          display: inline-block;
          padding: 4px 6px;
          color: $main500;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.3;
          background-color: $main000;
          border-radius: 2px;
        }
      }
    }
    .informationRow {
      .explainImg {
        margin-top: 16px;
        padding-bottom: 20px;
        display: inline-block;
        border-bottom: 1px solid $neutral300;
      }
      .explainBox {
        margin-top: 16px;
        margin-bottom: 24px;
        padding-bottom: 20px;
        border-bottom: 1px solid $neutral300;
        .serviceExplain {
          margin-top: 16px;
          &:first-child {
            margin-top: 0;
          }
          .title {
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.28px;
          }
          .serviceExplainBox {
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            .serviceBox {
              padding: 16px 10px;
              width: 148px;
              text-align: center;
              border: 1px solid $neutral300;
              border-radius: 12px;
              .serviceBoxTitle {
                padding-top: 44px;
                color: $neutral800;
                font-size: 15px;
                font-weight: 500;
                line-height: 19px;
                letter-spacing: -0.6px;
                background-repeat: no-repeat;
                background-position: center top;
                &.calculator {
                  background-image: url('/images/businessRegistration_calculator_icon.svg');
                }
                &.document {
                  background-image: url('/images/businessRegistration_document_icon.svg');
                }
                &.handshake {
                  background-image: url('/images/businessRegistration_handshake_icon.svg');
                }
                .bold {
                  color: $main500;
                }
              }
              .serviceBoxContent {
                margin-top: 4px;
                color: $neutral800;
                font-size: 12px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: -0.85px;
                .bold {
                  font-weight: 500;
                }
              }
            }
          }
          .serviceBenefitBox {
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            .benefitBox {
              padding: 16px 0;
              padding-top: 60px;
              width: 148px;
              text-align: center;
              border: 1px solid $neutral300;
              border-radius: 12px;
              color: $neutral800;
              font-size: 15px;
              font-weight: 500;
              line-height: 19px;
              letter-spacing: -0.3px;
              background-repeat: no-repeat;
              background-position: center top 16px;
              .bold {
                color: $main500;
              }
              &.helpme {
                background-image: url('/images/businessRegistration_helpme_icon.svg');
              }
              &.emptyCalendar {
                position: relative;
                background-image: url('/images/businessRegistration_empty_calendar_icon.svg');
                .freeMonth {
                  position: absolute;
                  top: 29px;
                  left: 50%;
                  transform: translateX(-50%);
                  color: $secon100;
                  font-size: 18px;
                  font-weight: 500;
                }
              }
              &.calendar {
                background-image: url('/images/businessRegistration_calendar_icon.svg');
              }
            }
          }
          .serviceDeadline {
            margin-top: 12px;
            padding: 14px 20px;
            padding-left: 62px;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            border: 1px solid $neutral300;
            border-radius: 12px;
            background-repeat: no-repeat;
            background-position: left 20px top 16px;
            background-image: url('/images/businessRegistration_checkHome_icon.svg');
            .bold {
              color: $main500;
            }
          }
        }
        .jinpyeongExplain {
          margin-top: 18px;
          color: $neutral700;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.28px;
          display: flex;
          align-items: center;
          justify-content: center;
          .logo {
            margin-left: 8px;
          }
        }
      }

      .radioLabel {
        margin-top: 16px;
        .radioExplain {
          position: relative;
          padding-left: 10px;
          margin-top: 4px;
          color: $neutral700;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: -0.02em;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 0;
            width: 8px;
            height: 10px;
            background-image: url('/images/icon_arrow_triangle.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  .btnArea {
    margin-top: 36px;
    width: 460px;
    .submitBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
  .stickyArea {
    position: absolute;
    top: 0;
    right: 198px;
    .tempBtn {
      position: sticky;
      top: 105px;
      width: 88px;
      height: 88px;
      background-color: $main000;
      border: 1px solid $main400;
      border-radius: 50%;
      box-shadow: 0px 1px 4px 1px rgba(153, 153, 153, 0.25);
      color: $main500;
      font-size: 13px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: -0.04em;
    }
  }
  .aLink {
    font-weight: 400;
    letter-spacing: -0.02em;
    font-size: 14px;
    position: relative;
    display: inline-block;
    margin-bottom: 4px;
    color: $main300;
    text-decoration: underline;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1px;
      right: -14px;
      width: 14px;
      height: 14px;
      background-image: url('/images/arrow_icon.svg');
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
