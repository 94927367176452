@import '@standby/common-ui/color.scss';

.modalContent {
  padding: 36px 50px;
  padding-top: 20px;
  width: 1040px;
  .btnArea {
    margin-top: 36px;
    .submitBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}
