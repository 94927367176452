@import '@standby/common-ui/color.scss';

.document {
  padding: 36px 50px;
  width: 1040px;
  .title {
    color: $neutral800;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }
  .contentBox {
    display: flex;
    margin-top: 16px;
    .documentBox {
      position: relative;
      .moreDocument {
        position: absolute;
        right: 27px;
        // padding-left: 38px;
        width: 160px;
        height: 42px;
        background-image: url('/images/moreDocument.svg');
        // background-position: left top;
        background-repeat: no-repeat;
        color: $main500;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.8px;

        &.scroll {
          animation: fadeout 0.9s ease-out;
          @keyframes fadeout {
            100% {
              opacity: 0;
            }
          }
        }
      }
      width: 680px;
      height: 959px;
      border: 1px solid $neutral400;
      border-radius: 4px;
      .content {
        width: 677px;
        height: 959px;
        overflow-y: scroll;
        .iframeDiv {
          width: 100%;
          height: calc(100% / 0.6);
          transform: scale(0.6);
          transform-origin: top left;
          .iframeDocument {
            width: 1100px;
          }
        }
      }
    }
    .documentOption {
      margin-left: 20px;
      width: 240px;
      .optionSeparate {
        margin-top: 18px;
        padding-top: 18px;
        border-top: 1px solid $neutral300;
        &:first-child {
          margin-top: 0;
          padding-top: 0;
          border-top: none;
        }
        .optionBox {
          margin-top: 24px;
          &:first-child {
            margin-top: 0;
          }
          .optionTitle {
            color: $neutral900;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.3;
          }
          .option {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            align-items: start;
            .radio {
              width: fit-content;
              margin-left: 16px;
              &:first-child {
                margin-left: 0;
              }
              &.marginTop {
                margin-top: 4px;
              }
              &.wrap {
                &:nth-child(2n + 1) {
                  margin-left: 0;
                }
              }
            }
          }
          .explain {
            position: relative;
            padding-left: 10px;
            margin-top: 12px;
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            line-height: 18.2px;
            letter-spacing: -0.28px;
            &:before {
              content: '*';
              display: inline-block;
              position: absolute;
              left: 0;
              top: 2px;
            }
          }
          .btnArea {
            margin-top: 24px;
            width: 100%;
            .nextBtn {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
