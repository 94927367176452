@use '@standby/dike_ui/design-token/color.module.scss' as color;

.ChangeEmailContent {
  width: 100%;
  .logoWrap {
    text-align: center;
    .logo {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .mainTitle {
    text-align: center;
    color: color.$grayScale800;
  }
  .inputText {
    margin-top: 48px;
  }
  .mainBtn {
    margin-top: 48px;
    width: 100%;
  }
}
