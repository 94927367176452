@import '@standby/common-ui/color.scss';

.firstPage {
  position: relative;
  padding-bottom: 120px;
  .exampleImgBox {
    position: relative;
    margin-top: 36px;
    &.jinpyeongHelpme {
      height: 3383px;
    }
    .serviceInfoBox {
      position: absolute;
      left: 150px;
      top: 234px;
      padding: 22px 23px;
      width: 740px;
      height: 194px;
      background-color: $neutral100;
      border-radius: 8px;
      .info {
        display: flex;
        justify-content: space-between;
        .serviceBox {
          width: 246px;
          text-align: center;
          .title {
            padding-top: 56px;
            color: $neutral800;
            font-size: 18px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: -0.72px;
            background-repeat: no-repeat;
            background-position: center top 5px;
            &.calculator {
              background-image: url('/images/bookKeeping_calculator_icon.svg');
            }
            &.document {
              background-image: url('/images/bookKeeping_document_icon.svg');
            }
            &.handshake {
              background-image: url('/images/bookKeeping_handshake_icon.svg');
            }
          }
          .content {
            margin-top: 4px;
            color: $neutral800;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.8px;
            .bold {
              font-weight: 500;
            }
          }
          &:nth-child(2) {
            border-left: 1px solid $neutral300;
            border-right: 1px solid $neutral300;
          }
        }
      }
    }
    .helpmeInfoBox {
      position: absolute;
      left: 150px;
      top: 504px;
      padding: 22px 23px;
      width: 740px;
      height: 204px;
      background-color: $neutral100;
      border-radius: 8px;
      .info {
        display: flex;
        justify-content: space-between;
        .helpmeBox {
          width: 246px;
          text-align: center;
          .title {
            padding-top: 52px;
            color: $neutral800;
            font-size: 18px;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: -0.72px;
            background-repeat: no-repeat;
            background-position: center top;
            &.helpme {
              background-image: url('/images/bookKeeping_helpme_icon.svg');
            }
            &.emptyCalendar {
              background-image: url('/images/bookKeeping_empty_calendar_icon.svg');
              position: relative;
              .freeMonth {
                position: absolute;
                top: 16px;
                left: 50%;
                transform: translateX(-50%);
                color: $secon100;
                font-size: 18px;
                font-weight: 500;
              }
            }
            &.calendar {
              background-image: url('/images/bookKeeping_calender_icon.svg');
            }
          }
          .content {
            margin-top: 4px;
            color: $neutral800;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.8px;
          }
          &:nth-child(2) {
            border-left: 1px solid $neutral300;
            border-right: 1px solid $neutral300;
          }
        }
      }
      .explain {
        margin-top: 22px;
        color: $neutral600;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.7px;
        .bold {
          font-weight: 500;
        }
      }
    }
    .jinpyeongInfo {
      position: absolute;
      left: 0;
      bottom: 0;
    }
    .jinpyeongVideo {
      position: absolute;
      left: 150px;
      top: 2311px;
    }
  }
  .subApplyBtn {
    position: absolute;
    top: -62px;
    right: 0;
    padding: 8px 30px 9px 16px;
    width: 140px;
    height: 31px;
    background-color: $main400;
    border-radius: 4px;
    color: $neutral100;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.5px;
    background-image: url('/images/booking_small_arrow_icon.svg');
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: right 16px top 9px;
  }
  .mainApplyBtn {
    position: fixed;
    left: calc(50% + 108px - 288px);
    bottom: 24px;
    width: 576px;
    height: 84px;
    background-color: $main500;
    box-shadow: 1px 1px 4px 2px rgba(51, 51, 51, 0.24);
    border-radius: 4px;
    z-index: 50;
    .btnContent {
      display: inline-block;
      position: relative;
      padding-left: 24px;
      padding-right: 22px;
      color: $neutral100;
      font-size: 22px;
      font-weight: 400;
      line-height: 1;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 1px;
        left: 0;
        width: 18px;
        height: 24px;
        background-image: url('/images/calculator_icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 3px;
        right: 0;
        width: 20px;
        height: 20px;
        background-image: url('/images/booking_arrow_icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .explain {
      margin-top: 10px;
      color: $neutral100;
      font-size: 13px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: -0.04em;
    }
  }
}
