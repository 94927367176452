@import '@standby/common-ui/color.scss';

.accountEstablishWay {
  .accountStepBox {
    position: relative;
    margin-top: 22px;
    padding: 14px 16px 16px;
    border: 1px solid $neutral300;
    border-radius: 16px;
    &:first-child {
      margin-top: 0;
    }
    &:nth-child(n + 2) {
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: -20px;
        left: 367px;
        width: 60px;
        height: 18px;
        background-image: url('/images/date_arrow_icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .titleBox {
      display: flex;
      align-items: center;
      .title {
        padding-left: 12px;
        margin-left: 12px;
        border-left: 1px solid $neutral400;
        color: $neutral700;
        font-size: 18px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: -0.05em;
        .bold {
          margin-left: 4px;
          color: $main500;
          font-weight: 400;
          letter-spacing: 0;
        }
      }
    }
    .explainBox {
      margin-top: 12px;
      padding-left: 48px;
      .tip {
        position: relative;
        padding-left: 42px;
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        &.nonTipIcon {
          padding-left: 0;
        }
        .tipIcon {
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          padding: 2px 0;
          margin-right: 6px;
          width: 36px;
          background-color: $sub200;
          border-radius: 2px;
          color: $neutral100;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: -0.02em;
          text-align: center;
        }
      }
      .tipBox {
        margin-top: 7px;
        margin-left: 42px;
        padding: 10px 12px;
        width: 209px;
        background-color: #e4f9f6;
        border-radius: 2px;
        color: $neutral900;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        div {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
      .tipExplain {
        margin-top: 6px;
        color: $neutral700;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.3;
      }
      .documentInfo {
        display: flex;
        margin-top: 8px;
        .download,
        .supply {
          display: flex;
          &.download {
            width: 380px;
            flex-shrink: 0;
            border-right: 1px solid $neutral300;
          }
          &.supply {
            padding-left: 31px;
          }
          .title {
            margin-top: 4px;
            color: $neutral800;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
          }
          .infoList {
            margin-left: 17px;
            .downloadDocument,
            .supplyItem {
              position: relative;
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 8px;
              &:first-child {
                margin-top: 0;
              }
              color: $neutral800;
              font-size: 15px;
              font-weight: 400;
              line-height: 1.2;
              &.helpme {
                &:before {
                  content: '헬프미 \a 전달예정';
                  display: inline-block;
                  position: absolute;
                  top: 14px;
                  right: -87px;
                  white-space: pre-wrap;
                  color: $neutral600;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 1.2;
                }
                &:after {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 22px;
                  right: -32px;
                  width: 14px;
                  height: 1px;
                  background-color: $neutral400;
                }
              }
              .documentTitle,
              .supplyTitle {
                position: relative;
                padding-left: 7px;
                &:before {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 8px;
                  left: 0;
                  width: 3px;
                  height: 3px;
                  background-color: $neutral700;
                  border-radius: 50%;
                }
                &.companyStamp {
                  position: relative;
                  &:after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    right: -63px;
                    top: 8px;
                    width: 59px;
                    height: 15px;
                    border: 1px solid $neutral400;
                    border-left: none;
                    border-bottom: none;
                  }
                }
                &.companyCertifi {
                  position: relative;
                  &:after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    right: -18px;
                    top: -5px;
                    width: 15px;
                    height: 15px;
                    border: 1px solid $neutral400;
                    border-top: none;
                    border-left: none;
                  }
                }
              }
              .downloadBtn {
                position: relative;
                padding-left: 20px;
                margin-left: 20px;
                color: $main300;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.8;
                text-decoration: underline;
                background-color: transparent;
                &.disabled {
                  cursor: not-allowed;
                  color: $neutral400;
                  &:before {
                    background-image: url('/images/download_negative_icon.svg');
                  }
                }
                &:before {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 5px;
                  left: 0;
                  width: 16px;
                  height: 16px;
                  background-image: url('/images/download_icon.svg');
                  background-repeat: no-repeat;
                  background-size: cover;
                }
              }
              .registraionAlert {
                position: absolute;
                bottom: -18px;
                left: 7px;
                width: 218px;
                color: $neutral600;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.3;
                letter-spacing: -0.03em;
              }
            }
          }
        }
      }
    }
  }
}

.faq {
  padding-bottom: 28px;
  border-bottom: 1px solid $neutral400;
  .qnaBox {
    margin-top: 24px;
    &:first-child {
      margin-top: 0;
    }
    .question {
      position: relative;
      padding-left: 26px;
      color: $primary800;
      font-size: 18px;
      font-weight: 400;
      line-height: 1;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        background-image: url('/images/faq_icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
    .answer {
      padding-left: 26px;
      margin-top: 10px;
      color: $neutral800;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.6;
      letter-spacing: -0.03em;
      .bold {
        font-weight: 400;
      }
      .answerExplain {
        margin-top: 4px;
        line-height: 1.4;
        .aLink {
          color: $main300;
          font-weight: 400;
          text-decoration: underline;
        }
      }
    }
  }
}
