@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/companyInfoTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.contentBox {
  margin-top: 24px;
  padding: 0;
  .content {
    margin-top: 54px;
    border-top: 1px solid $neutral300;
    .businessBox {
      padding: 48px;
      background-color: $neutral200;
      text-align: center;
      .subTitle {
        color: $neutral700;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.32px;
      }
      .title {
        margin-top: 8px;
        color: $neutral900;
        font-size: 22px;
        font-weight: 500;
        line-height: 1;
        .bold {
          color: $main500;
        }
      }
      .ad {
        margin-top: 20px;
        color: $neutral800;
        font-size: 18px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: -0.5px;
      }
      .file {
        // margin: 0 auto;
        margin-top: 24px;
        // width: 460px;
        // text-align: left;
        .fileGuide {
          cursor: pointer;
          display: inline-block;
          position: relative;
          padding: 12px 16px;
          width: 460px;
          height: 59px;
          border: 1px solid $main400;
          border-radius: 6px;
          background-color: $neutral100;
          text-align: left;
          &:hover {
            background-color: $main000;
          }
          .fileTitle {
            position: relative;
            padding-left: 24px;
            color: $main500;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
              width: 18px;
              height: 20px;
              background-image: url('~@standby/common-ui/components/asset/Icon/file_upload_positive.svg');
              background-size: cover;
              background-repeat: no-repeat;
            }
          }
          .fileExplain {
            margin-top: 3px;
            padding-left: 24px;
            color: $neutral600;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.3;
          }
        }
      }
    }
    .businessFAQBox {
      padding: 36px 50px;
      .faq {
        margin-top: 24px;
        &:first-child {
          margin-top: 0;
        }
        .question {
          position: relative;
          padding-left: 26px;
          color: $primary800;
          font-size: 18px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: -0.3px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url('/images/faq_icon.svg');
            background-repeat: no-repeat;
          }
        }
        .answer {
          margin-top: 10px;
          padding-left: 26px;
          color: $neutral800;
          font-size: 16px;
          font-weight: 400;
          list-style: 1.4;
          letter-spacing: -0.32px;
          .arrowIcon {
            display: inline-block;
            width: 12px;
            height: 11px;
            background-image: url('/images/faq_arrow_icon.svg');
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
}
