@import '@standby/common-ui/color.scss';

.content {
  padding-top: 16px;
  padding-bottom: 16px;
  .meetingsWrap {
    min-width: 1040px;
    .notice {
      border-radius: 10px;
      border: 1px solid $neutral400;
      background: $main000;
      margin-bottom: 12px;
      display: flex;
      padding: 17px 14px;
      .item {
        flex: 1;
        color: $neutral800;
        font-size: 16px;
        font-weight: 700;
        line-height: 100%;
        .title {
          margin-right: 12px;
        }
        .count {
          font-weight: 400;
          .complete {
            color: $main500;
            font-weight: 700;
          }
          .progress {
            color: $warning200;
            font-weight: 700;
          }
        }
      }
    }
  }
}
