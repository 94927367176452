@import '@standby/common-ui/color.scss';

.agendaView {
  padding: 0 50px;
  width: 1040px;
  padding-bottom: 36px;
  .item {
    margin-top: 36px;
    > .title {
      color: $neutral900;
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
    }
    > .content {
      margin-top: 16px;
      &.agendas {
        border-top: $neutral300 1px solid;
        border-bottom: $neutral300 1px solid;
        padding: 15px 0;
      }
      .methodList {
        border-radius: 10px;
        border: 1px solid $neutral300;
        margin-top: 20px;
        padding: 0 18px;
        > li {
          padding: 14px 0;
          display: flex;
          &:not(:last-child) {
            border-bottom: 1px solid $neutral300;
          }
          > .title {
            width: 134px;
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            padding-left: 26px;
            background-repeat: no-repeat;
            background-position: left top;
            &.agenda {
              background-image: url('/images/agenda_title_icon.svg');
            }
            &.place {
              background-image: url('/images/place_title_icon.svg');
            }
            &.date {
              background-image: url('/images/date_title_icon.svg');
            }
            &.notificationMethod {
              background-image: url('/images/notificationMethod_title_icon.svg');
            }
            &.notice {
              background-image: url('/images/notice_title_icon.svg');
            }
            &.receipt {
              background-image: url('/images/receipt_title_icon.svg');
            }
            &.minutes {
              background-image: url('/images/minutes_title_icon.svg');
            }
            &.notice_newStock {
              background-image: url('/images/notice_newStock_title_icon.svg');
            }
          }
          > .content {
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            flex: 1;
            .agendaList li:not(:first-child) {
              margin-top: 4px;
            }
          }
        }
      }
      .stockholderTable {
        width: 100%;
        border-bottom: none !important;
        thead {
          th {
            &:nth-child(1) {
              padding-left: 36px;
            }
          }
        }
        tbody {
          td {
            .number {
              display: flex;
              span {
                &:nth-child(1) {
                  flex-shrink: 0;
                  width: 28px;
                }
              }
            }
            vertical-align: top;
            &.textTd {
              padding-top: 20px;
            }
            .innerText {
              padding-top: 10px;
            }
          }
        }
        .shareholderSum {
          td {
            font-weight: 400;
            &:nth-child(1) {
              span {
                &:nth-child(1) {
                  width: 28px;
                  display: inline-block;
                }
              }
              .count {
                margin-left: 0;
              }
            }
            &:nth-child(2) {
              text-align: right;
            }
            .count {
              font-weight: 700;
              margin-right: 2px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
