@import '@standby/common-ui/color.scss';

.tooltipContent {
  padding: 13px 16px 16px 16px;
  width: 460px;
  .exTitle {
    color: $main500;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    background-color: $main000;
    padding: 4px 6px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 6px;
  }
  ul.exList {
    font-size: 14px;
    font-weight: 400;
    color: $neutral700;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    > li {
      padding-left: 8px;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        background-color: $neutral600;
        border-radius: 3px;
        width: 3px;
        height: 3px;
        position: absolute;
        top: 7px;
        left: 0;
      }
    }
    li {
      line-height: 1.2;
      margin-top: 4px;
      &:nth-child(1) {
        margin-top: 0;
      }
      .important {
        font-weight: 500;
      }
      .stepList {
        margin-left: -8px;
        li {
          margin-top: 8px;
          display: flex;
          .step {
            color: $main400;
            margin-right: 5px;
          }
          .content {
            .fileBtn {
              margin-bottom: 6px;
              margin-top: 8px;
              button {
                width: 284px;
              }
            }
          }
        }
      }
    }
  }
}

.radio {
  position: relative;
  &:first-child {
    &:before {
      content: '추천';
      display: inline-block;
      position: absolute;
      top: -1px;
      right: -1px;
      padding: 6px 8px;
      width: 42px;
      height: 25px;
      background-color: $main400;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 4px;
      color: $neutral100;
      font-size: 13px;
      font-weight: 400;
      text-align: center;
    }
  }
}

.datePickerBox {
  display: inline-flex;
  input {
    width: 220px;
  }
}

.newTag {
  display: inline-block;
  margin-left: 6px;
  padding: 3px 6px 3px;
  color: $neutral100;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.02em;
  background-color: $main200;
  border-radius: 2px;
}
