@import '@standby/common-ui/color.scss';

.companyNameSearch {
  padding: 36px 50px;
  width: 673px;
  min-height: 488px;
  .form {
    .row {
      display: flex;
      margin-top: 12px;
      &:first-child {
        margin-top: 0;
      }
      .title {
        margin-top: 12px;
        width: 127px;
        flex-shrink: 0;
        color: $neutral900;
        font-size: 16pe;
        font-weight: 400;
        line-height: 1;
      }
      .content {
        width: 100%;
        .contentRow {
          display: flex;
          justify-content: space-between;
          .select {
            width: 220px;
          }
          .submitBtn {
            margin-left: 8px;
            width: 84px;
          }
        }
        .explain {
          position: relative;
          padding-left: 8px;
          margin-top: 6px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 0;
          }
        }
      }
    }
  }
  .companyListBox {
    margin-top: 24px;
    width: 575px;
    border: 1px solid $neutral300;
    border-radius: 4px;
    .companyListEmpty {
      display: flex;
      width: 100%;
      height: 200px;
      justify-content: center;
      align-items: center;
      text-align: center;
      .emptyInfo {
        .empty {
          padding-top: 24px;
          color: $neutral800;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.176px;
          background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_negative.svg');
          background-repeat: no-repeat;
          background-position: left 50% top 0;
        }
        .emptyExplainBox {
          display: flex;
          justify-content: space-between;
          padding: 16px 46px;
          margin-top: 20px;
          width: 527px;
          background-color: $neutral200;
          border-radius: 4px;
          .emptyExplain {
            position: relative;
            padding-left: 7px;
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            letter-spacing: -0.154px;
            text-align: left;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 6px;
              left: 0;
              width: 3px;
              height: 3px;
              background-color: $neutral800;
              border-radius: 50%;
            }
            .bold {
              font-weight: 500;
            }
            .sub {
              margin-top: 7px;
              color: $neutral700;
              font-size: 13px;
              font-weight: 400;
              line-height: 1.5;
              letter-spacing: -0.143px;
            }
          }
        }
      }
    }
    .companyList {
      padding: 16px;
      padding-bottom: 0;
      min-height: 401px;

      .scrollArea {
        height: 385px !important;
        position: 'none';
      }

      .companyInfoList {
        .companyInfo {
          padding: 16px 0;
          border-top: 1px solid $neutral300;
          &:first-child {
            padding-top: 0;
            border-top: none;
          }
          .companyAddress {
            display: block;
            margin-top: 6px;
            color: $neutral600;
            font-size: 13px;
            font-weight: 400;
            line-height: 1;
          }
        }
      }
    }
    .companyError {
      padding: 16px;
      height: 200px;
      .companyInfo {
        .companyAddress {
          display: block;
          margin-top: 6px;
          color: $neutral600;
          font-size: 13px;
          font-weight: 400;
          line-height: 1;
        }
      }
    }
  }
  .nextBtnArea {
    margin-top: 24px;
    width: 100%;
    .nextBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}
.alertContent {
  margin-top: 12px;
  li {
    margin-top: 2px;
    position: relative;
    padding-left: 9px;
    color: $neutral700;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 0;
      width: 3px;
      height: 3px;
      background-color: $neutral700;
      border-radius: 50%;
    }
  }
}

@media screen and (max-height: 600px) {
  .companyNameSearch {
    min-height: calc(100vh * 0.75);
    max-height: calc(100vh * 0.75);
    .companyListBox {
      .companyList {
        min-height: 122px;
        max-height: 122px;
      }
      .companyError {
        height: 122px;
      }
    }
  }
}
