@import '@standby/common-ui/color.scss';
.wrap {
  width: 616px;
  border: 1px solid $neutral300;
  border-radius: 12px;
  position: relative;
  padding: 0 20px;
  padding-bottom: 38px;
  min-height: 300px;
  > .title {
    font-size: 18px;
    font-weight: 500;
    color: $neutral900;
    padding-left: 30px;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
    left: 20px;
    top: 18px;
    display: flex;
    height: 24px;
    align-items: center;
    line-height: 24px;
    background-image: url('/images/executivesTitleIcon.svg');
    > .content {
      font-size: 16px;
      color: $neutral700;
      font-weight: 400;
      margin-left: 12px;
    }
  }
  > .legend {
    display: flex;
    position: absolute;
    right: 148px;
    top: 23px;
    > span {
      position: relative;
      padding-left: 10px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: -0.5px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
  }
  > .openModalBtn {
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.28px;
    text-decoration: underline;
    color: $main300;
    background-image: url('/images/arrowRight_main300.svg');
    background-repeat: no-repeat;
    background-position: right top 3px;
    padding-right: 16px;
    position: absolute;
    display: inline-block;
    right: 20px;
    top: 21px;
  }
  > .person {
    margin-top: 60px;
    > li {
      border-bottom: 1px solid $neutral300;
      line-height: 100%;
      display: flex;
      padding-top: 14px;
      padding-bottom: 14px;
      &:nth-child(1) {
        padding-top: 0;
      }
      > .name {
        color: $neutral800;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: -0.5px;
        padding-left: 24px;
        background-position: left top 1px;
        background-repeat: no-repeat;
        line-height: 19px;
        width: 120px;
        padding-right: 6px;
        flex-shrink: 0;
        &[data-position='대표이사'] {
          background-image: url('/images/executive/executive_대표이사_icon.svg');
        }
        &[data-position='사내이사'] {
          background-image: url('/images/executive/executive_사내이사_icon.svg');
        }
        &[data-position='사외이사'] {
          background-image: url('/images/executive/executive_사외이사_icon.svg');
        }
        &[data-position='기타'] {
          background-image: url('/images/executive/executive_기타_icon.svg');
        }
        &[data-position='감사'] {
          background-image: url('/images/executive/executive_감사_icon.svg');
        }
      }
      > .positionList {
        flex-grow: 1;
        margin-top: 1px;
        li {
          display: flex;
          font-size: 14px;
          letter-spacing: -0.5px;
          font-weight: 400;
          margin-top: 8px;
          &:nth-child(1) {
            margin-top: 0;
          }
          > .position {
            width: 100px;
            padding-right: 12px;
            flex-shrink: 0;
          }
          > .date {
            font-size: 14px;
          }
          > .gaugeWrap {
            flex-grow: 1;
            align-self: center;
            > .gauge {
              margin-left: auto;
              width: 194px;
              height: 6px;
              border-radius: 1px;
              background-color: $neutral200;
              position: relative;
              .fill {
                position: absolute;
                height: 2px;
                left: 2px;
                top: 2px;
                .text {
                  width: 67px;
                  height: 16px;
                  border-radius: 8px;
                  position: absolute;
                  right: -67px;
                  top: -7px;
                  color: $neutral100;
                  font-size: 11px;
                  font-weight: 400;
                  letter-spacing: -0.5px;
                  text-align: center;
                  line-height: 15px;
                }
              }
              &[data-tag='임기만료'] {
                .fill,
                .text {
                  background-color: $warning100;
                }
              }
              &[data-tag='임기여유'] {
                .fill,
                .text {
                  background-color: $sub100;
                }
              }
              &[data-tag='종료임박'] {
                .fill,
                .text {
                  background-color: $secon200;
                }
              }
            }
          }
        }
      }
    }
  }
  .moreView {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 14px;
    margin-top: 8px;
    color: $neutral600;
    font-weight: 400;
    font-size: 14px;
    text-decoration: underline;
    background-color: transparent;
    background-image: url('/images/moreView_close.svg');
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 15px;
    &.open {
      background-image: url('/images/moreView_open.svg');
    }
  }
}
