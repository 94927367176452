@import '@standby/common-ui/color.scss';

.contentBox {
  box-sizing: border-box;
  border: 1px solid $neutral300;
  position: relative;
  padding: 20px;
  .title {
    position: absolute;
    top: 23px;
    left: 0;
    height: 24px;
    padding-left: 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $neutral900;
    .subTitle,
    .leftSubTitle {
      font-size: 17px;
      color: $neutral600;
      font-weight: 400;
      margin-left: 6px;
      &.leftSubTitle {
        position: relative;
        padding-right: 18px;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 5px;
          right: 0;
          width: 16px;
          height: 16px;
          background-image: url('/images/contentTitle_arrow_icon.svg');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
    .subTitleBadge {
      font-size: 13px;
      color: $main300;
      font-weight: 400;
      line-height: 22px;
      padding: 0 4px;
      height: 22px;
      background-color: $main000;
      border-radius: 2px;
      text-align: center;
      display: inline-block;
      margin-left: 12px;
      position: relative;
      top: -2px;
    }
  }
  .titleComponent {
    display: inline-block;
    margin-left: 16px;
  }
}
