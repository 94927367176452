@import '@standby/common-ui/color.scss';

.agendaQuestion {
  margin-top: 24px;
  padding: 0;
  .title {
    height: 54px;
    position: relative;
    .editBtn {
      position: absolute;
      right: 20px;
      width: 86px;
      height: 31px;
      top: 12px;
    }
  }
  .content {
    border-top: 1px solid $neutral300;
    padding: 0 50px;
    .questions {
      padding: 36px 0;
    }
    .btnArea {
      padding: 24px 0;
      text-align: center;
      border-top: 1px solid $neutral300;
      .contentSubmitBtn {
        margin: 0 auto;
        width: 300px;
      }
    }
  }
}
.tooltip {
  position: relative;
  z-index: 1;
}
.questionForm {
  position: relative;
  .focusArea {
    position: absolute;
    left: 0;
    top: -70px;
  }
}
