@import '@standby/common-ui/color.scss';

.question {
  > .title {
    position: relative;
    color: $neutral900;
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    > .bold {
      color: $primary800;
    }
  }
  > .info {
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    margin-top: 8px;
    margin-bottom: 16px;
    &::before {
      content: '* ';
      position: relative;
      top: 3px;
    }
  }
  &:not(:first-child) {
    margin-top: 60px;
  }
  > .answer {
    margin-top: 12px;
    .inputRow:not(:first-child) {
      margin-top: 12px;
    }
  }
  .termsList {
    margin-top: 8px;
    padding: 18px 20px;
    border-radius: 4px;
    background-color: $neutral200;
    li {
      margin-top: 11px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        margin-top: 0;
      }
      .textBtn {
        color: $neutral400;
      }
      .radioText {
        color: $neutral600;
        font-size: 14px;
        .notRequired {
          color: $neutral400;
        }
      }
    }
  }
}
