@import '@standby/common-ui/color.scss';

.table {
  width: 620px;
  tbody {
    tr {
      td {
        color: $neutral800;
        &:nth-child(1) {
          .index {
            display: inline-block;
            margin-right: 3px;
            width: 15px;
          }
          .bold {
            font-weight: 700;
          }
          .normal {
            font-weight: 400;
          }
        }

        .stock {
          margin-top: 6px;
          color: $neutral500;
          &:first-child {
            margin-top: 0;
          }
        }
        .newStock {
          margin-top: 6px;
          padding-left: 24px;
          color: $main300;
          background-repeat: no-repeat;
          background-image: url('/images/arrow_icon_triangle_neutral600.svg');
          background-position: left 0 top 0;
          &:first-child {
            margin-top: 0;
          }
        }
        .nameArea {
          display: flex;
        }
      }
    }
  }
}

.radio {
  .bold {
    font-weight: 700;
  }
  .tag {
    position: absolute;
    top: -16px;
    right: -148px;
    padding: 6px 9px;
    width: 42px;
    height: 25px;
    background-color: $main400;
    border-radius: 0 6px;
    color: $neutral100;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
  }
  .radioTag {
    color: $neutral700;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
  }
  .radioExplain {
    position: relative;
    padding-left: 11px;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 2px;
      left: 0;
      width: 8px;
      height: 12px;
      background-image: url('/images/icon_arrow_triangle.svg');
      background-size: cover;
      background-repeat: no-repeat;
    }
    &.disabled {
      &:before {
        background-image: url('/images/icon_arror_triangle_disabled.svg');
      }
    }
  }
}

.tooltipContent {
  padding: 12px;
  padding-bottom: 14px;
  width: 460px;
  .qna {
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
    .title {
      display: inline-block;
      padding: 4px 6px;
      color: $main500;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      background-color: $main000;
      border-radius: 2px;
    }
    .content {
      margin-top: 6px;
      color: $neutral700;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      ul {
        li {
          position: relative;
          padding-left: 9px;
          margin-top: 4px;
          &:first-child {
            margin-top: 0;
          }
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 0;
            width: 3px;
            height: 3px;
            background-color: $neutral600;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .sample {
    width: 580px;
    img {
      margin-top: 6px;
      &:first-child {
        margin-top: 8px;
      }
    }
  }
}
