@import '@standby/common-ui/color.scss';

.wrap {
  width: 510px;
  height: 274px;
  border: 1px solid $neutral300;
  border-radius: 12px;
  margin-left: 20px;
  padding: 0 20px;
  .title {
    font-size: 18px;
    font-weight: 500;
    color: $neutral900;
    padding-left: 30px;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    height: 24px;
    align-items: center;
    line-height: 100%;
    > .content {
      font-size: 16px;
      color: $neutral700;
      font-weight: 400;
      margin-left: 12px;
    }
  }
  .shareholder {
    position: relative;
    height: 173px;
    border-bottom: 1px solid $neutral300;
    > .title {
      top: 18px;
      background-image: url('/images/shareholderTitleIcon.svg');
    }
    > .content {
      padding-top: 57px;
      display: flex;
      align-items: center;
      justify-content: center;
      > .empty {
        text-align: center;
        font-size: 16px;
        color: $neutral600;
        line-height: 130%;
        height: 100%;
        font-weight: 400;
        margin-top: 25px;
      }
      > .chart {
        width: 96px;
        height: 96px;
        background-image: url('/images/stockChartIcon.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
      > .list {
        margin-left: 12px;
        height: 80px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        > li {
          margin-top: 12px;
          font-size: 18px;
          color: $neutral800;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -0.5px;
          padding-left: 12px;
          position: relative;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $sub200;
            left: 0;
            top: 7px;
          }
          &:nth-child(1) {
            margin-top: 0;
            font-weight: 400;
          }
          &:nth-child(2) {
            &::before {
              background-color: $secon100;
            }
          }
          &:nth-child(3) {
            &::before {
              background-color: $main200;
            }
          }
          > span {
            &:nth-child(1) {
              max-width: 214px;
              display: inline-block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &:nth-child(2) {
              margin-left: 6px;
              font-size: 16px;
              vertical-align: top;
            }
          }
        }
      }
    }
    .moreView {
      position: absolute;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      background-image: url('/images/moreView_Icon_600.svg');
      background-repeat: no-repeat;
      background-position: right top 2px;
      line-height: 100%;
      padding-right: 14px;
      right: 0px;
      top: 23px;
    }
  }
  .stock {
    position: relative;
    margin-top: 18px;
    > .title {
      background-image: url('/images/stockTitleIcon.svg');
      width: 100%;
    }
    > .content {
      padding-top: 40px;
    }
    .openModalBtn {
      background-color: transparent;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.28px;
      text-decoration: underline;
      color: $main300;
      background-image: url('/images/arrowRight_main300.svg');
      background-repeat: no-repeat;
      background-position: right top 3px;
      padding-right: 16px;
      position: absolute;
      display: inline-block;
      right: 0;
      top: 3px;
    }
  }
}
