@import '@standby/common-ui/color.scss';

.registerTable {
  width: 700px;
  border-bottom: none !important;
  tbody {
    tr {
      td {
        .showStockBtn {
          margin-left: 6px;
          padding: 0 6px;
          height: 20px;
          color: $main300;
          font-size: 12px;
          font-weight: 400;
          line-height: 1;
          border: 1px solid $neutral400;
          border-radius: 4px;
          background-color: $neutral100;
        }
      }
    }
  }
  .totalTr {
    td {
      .bold {
        font-weight: 700;
      }
    }
  }
}

.table {
  width: 100%;
  margin-top: 17px;
  thead {
    tr {
      th {
        &:nth-child(1) {
          text-align: left;
          padding-left: 28px;
        }
        &:nth-child(2) {
          text-align: center;
        }
        &:nth-child(3) {
          text-align: center;
        }
        &:nth-child(4) {
          text-align: center;
        }
        &:nth-child(5) {
          text-align: center;
        }
        &:nth-child(6) {
          text-align: right;
        }
      }
    }
  }
  tbody {
    position: relative;
    border-top: 1px solid $neutral300;
    &:first-child {
      border-top: none;
    }
    tr {
      td {
        padding: 12px 8px;
        border: none;
        vertical-align: top;
        &.stockTd {
          position: relative;
          padding-right: 29px;
          .btnArea {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 12px;
            right: 8px;
            .minusBtn {
              margin-top: 2px;
            }
          }
        }
        &.calcTd {
          text-align: right;
          position: relative;
          .absoluteText {
            position: absolute;
            // bottom: 6px;
            top: 40px;
            right: 8px;
            color: $main500;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.28px;
          }
          .text {
            margin-top: 11px;
            position: relative;
            padding-right: 16px;
            .before {
              color: $neutral500;
            }
            .subText {
              position: absolute;
              right: 0;
            }
            .calculation {
              width: 85px;
              overflow: hidden;
              display: inline-block;
              white-space: nowrap;
              text-overflow: ellipsis;
              word-break: break-all;
              &.warn {
                color: $warning200;
              }
            }
          }
          .calculationWarn {
            .tooltip {
              text-align: left;
              :global .icon {
                background-image: url('/images/tooltip_warn.svg');
              }
              position: relative;
              top: 3px;
              margin-right: 4px;
            }
            color: $warning200;
          }
        }
        .rowDeleteBtn {
          position: absolute;
          right: -25px;
          top: 50%;
          transform: translateY(-50%);
        }
        .subTextInput {
          position: relative;
          display: inline-block;
          padding-right: 16px;
          .inputSubText {
            right: 0;
            top: 11px;
            position: absolute;
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }
  .count {
    width: 20px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: $neutral800;
    margin-left: 8px;
  }
}
.sum {
  margin-top: 10px;
  position: relative;
  .sumText {
    top: 2px;
    right: 0;
    position: absolute;
    color: $main500;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.28px;
    .sumvalue {
      font-weight: 700;
    }
  }
}
.stockLink {
  height: 38px;
  border: 1px solid $main400;
  color: $main400;
  border-radius: 4px;
  letter-spacing: -0.56px;
  font-size: 14px;
  font-weight: 500;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  span {
    padding-right: 15px;
    background-image: url('/images/main500_arrow.svg');
    background-repeat: no-repeat;
    background-position: center right;
  }
}
.tooltipContent {
  padding: 13px 16px 16px 16px;
  width: 700px;
  .circleList li {
    padding-left: 23px;
    position: relative;
    &::before {
      content: '';
      display: inline-block;
      background-color: $neutral600;
      border-radius: 3px;
      width: 3px;
      height: 3px;
      position: absolute;
      top: 7px;
      left: 15px;
    }
  }
  .exTitle {
    color: $main500;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    background-color: $main000;
    padding: 4px 6px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 6px;
    letter-spacing: -0.2px;
    &:not(:first-child) {
      margin-top: 12px;
    }
  }
  .list {
    padding-left: 0;
    list-style: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    color: $neutral800;
    margin: 0;
    li:not(:first-child) {
      margin-top: 8px;
    }
    &.bold {
      font-weight: 500;
    }
    ul {
      font-weight: 400;
      color: $neutral700;
      margin-top: 4px;
      li:not(:first-child) {
        margin-top: 6px;
      }
    }
    .caution {
      color: $neutral600;
      font-weight: 400;
      margin-top: 6px;
      padding-left: 10px;
      position: relative;
      &::before {
        position: absolute;
        content: '*';
        left: 0;
        top: 3px;
      }
    }
    .number {
      padding-left: 15px;
      position: relative;
      display: inline-block;
      &::before {
        content: attr(data-number);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .aLink {
      margin-top: 6px;
      margin-left: 15px;
      font-weight: 400;
      letter-spacing: -0.26px;
      font-size: 13px;
      position: relative;
      display: inline-block;
      color: $main300;
      text-decoration: underline;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 1px;
        right: -14px;
        width: 14px;
        height: 14px;
        background-image: url('/images/arrow_icon_main300.svg');
        background-size: cover;
        background-repeat: no-repeat;
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
