@import '@standby/common-ui/color.scss';

.h1 {
  display: flex;
  align-items: center;
  font-size: 22px;
  color: $neutral900;
  font-weight: 500;
  padding-top: 24px;
  margin-bottom: 24px;
  &:after {
    content: '';
    display: inline-block;
    height: 1px;
    background-color: $neutral900;
    flex: 1;
    margin-left: 12px;
  }
}
