@import '@standby/common-ui/color.scss';

.agreeStatePopup {
  padding: 36px 50px;
  width: 1040px;
  .explain {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    &:before {
      content: '*';
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
  .table {
    margin-top: 8px;
    width: 100%;
    border: 1px solid $neutral300;
    thead {
      tr {
        th {
          color: $neutral100;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          background-color: $neutral500;
          &:nth-child(1) {
            padding: 9px 14px 9px 15px;
            text-align: center;
            border-right: 1px solid $neutral300;
            .checkAll {
              input + span {
                border-color: $neutral300;
                background-color: $neutral100;
              }
              input + span + span {
                margin-left: 4px;
                color: $neutral100;
              }
            }
          }
          &:nth-child(2) {
            padding: 9px 7px 9px 8px;
            text-align: center;
            border-right: 1px solid $neutral300;
            .checkAll {
              input + span {
                border-color: $neutral300;
                background-color: $neutral100;
              }
              input + span + span {
                margin-left: 4px;
                color: $neutral100;
              }
            }
          }
          &:nth-child(3) {
            padding: 10px 10px 12px 36px;
            text-align: left;
          }
          &:nth-child(4) {
            padding: 10px 15px 12px 10px;
            text-align: center;
          }
          &:nth-child(5) {
            padding: 10px 10px 12px 15px;
            text-align: center;
          }
          &:nth-child(6) {
            padding: 10px 4px 12px 10px;
            text-align: left;
          }
          &:nth-child(7) {
            padding: 10px 6px 12px 4px;
            text-align: right;
          }
          &:nth-child(8) {
            padding: 10px 9px 12px 6px;
            text-align: right;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid $neutral300;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.28px;
          white-space: pre-wrap;
          &.checkbox {
            padding: 9px 20px;
            border-right: 1px solid $neutral300;
            text-align: center;
            span {
              margin-left: 0;
            }
          }
          &:nth-child(3) {
            padding: 10px 10px 12px 8px;
            font-weight: 500;
            text-align: left;
            .index {
              margin-right: 4px;
              display: inline-block;
              width: 28px;
            }
          }
          &:nth-child(4) {
            padding: 10px 15px 12px 10px;
            text-align: center;
          }
          &:nth-child(5) {
            padding: 10px 10px 12px 15px;
            text-align: center;
          }
          &.stockName {
            padding: 10px 4px 12px 10px;
            text-align: left;
          }
          &.stockNumber {
            padding: 10px 6px 12px 4px;
            text-align: right;
          }
          &.stockRate {
            padding: 10px 9px 12px 6px;
            text-align: right;
            .rate {
              margin-left: 3px;
            }
          }
          [data-status='발송전'],
          [data-status='발송중'] {
            color: $secon200;
          }
          [data-status='발송완료'] {
            color: $secon200;
            line-height: 16px;
          }
          [data-status='동의완료'] {
            color: $sub300;
          }
          [data-status='발송실패'] {
            color: $warning200;
            padding-right: 21px;
            background-repeat: no-repeat;
            background-position: right 1px center;
            background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_negative.svg');
          }
          [data-status='발송실패_알림톡'],
          [data-status='발송실패_메일'] {
            position: relative;
            display: inline-block;
            color: $warning200;
            padding-right: 21px;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 0;
              right: 0;
              width: 18px;
              height: 18px;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_negative.svg');
            }
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          padding: 10px 0;
          padding-right: 71px;
          background-color: $neutral200;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.28;
          text-align: right;
          .stockTotal {
            display: inline-block;
            margin: 0 3px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .questionTooltip {
    margin-top: 10px;
    .content {
      padding: 12px 16px;
    }
  }
  .shareholderEmailInfo,
  .shareholderPhoneNumberInfo {
    margin-top: 36px;
    .title {
      color: $neutral900;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;
    }
    .explain {
      position: relative;
      padding-left: 10px;
      margin-top: 6px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 0;
      }
    }
    .shareholderEmail,
    .shareholderPhoneNumber {
      margin-top: 8px;
      &:first-child {
        margin-top: 16px;
      }
      display: flex;
      align-items: center;
      .name {
        flex-shrink: 1;
        width: 160px;
        color: $neutral800;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.32px;
      }
    }
  }
  .btnArea {
    margin-top: 36px;
    width: 100%;
    .sendBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}
