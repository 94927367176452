@import '@standby/common-ui/color.scss';

.tooltipContent {
  padding: 10px 16px 15px;
  width: 460px;
  .strength,
  .weakness {
    display: inline-block;
    padding: 4px 6px;
    background-color: $main000;
    border-radius: 2px;
    color: $main500;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    &.weakness {
      margin-top: 16px;
    }
  }
  .strengthList,
  .weaknessList {
    li {
      margin-top: 6px;
      position: relative;
      padding-left: 9px;
      color: $neutral800;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 8px;
        left: 2px;
        width: 3px;
        height: 3px;
        background-color: $neutral800;
        border-radius: 50%;
      }
      .strengthContentList,
      .weaknessContentList {
        li {
          margin-top: 4px;
          padding: 0;
          color: $neutral700;
          font-size: 14px;
          font-weight: 400;
          &:before {
            content: none;
          }
        }
      }
    }
  }
}

.radioExplain {
  color: $neutral600;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
}

.writtenResolutionMethod {
  text-align: center;
  .title {
    padding-top: 60px;
    color: $neutral800;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: -0.18px;
    background-repeat: no-repeat;
    background-image: url('/images/loud_circle_icon.svg');
    background-position: center top;
    .bold {
      color: $main500;
    }
  }
  .explain {
    display: inline-block;
    position: relative;
    margin-top: 6px;
    padding-left: 10px;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    &:before {
      content: '*';
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
}

.hasCapitalOverAlert {
  position: relative;
  margin-top: 8px;
  padding-left: 22px;
  color: $neutral800;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.3;
  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.radioImportant {
  font-weight: 500;
}

.radio {
  height: 106px;
  position: relative;
  .radioExplainBox {
    position: absolute;
    top: 27px;
    left: -24px;
    padding: 6px 12px;
    width: 432px;
    height: 48px;
    background-color: $main000;
    border-radius: 2px;
    color: $neutral700;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    .important {
      color: $main400;
      font-weight: 700;
    }
  }
}
