@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/companyInfoTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.contentBox {
  margin-top: 24px;
  padding: 0;
  .content {
    margin-top: 54px;
    border-top: 1px solid $neutral300;
    padding: 36px 50px;
    .businessBox {
      position: relative;
      width: 460px;
      height: 544px;
      border: 1px solid $neutral400;
      border-radius: 4px;
      overflow: hidden;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0px;
        left: 0;
        width: 458px;
        height: 543px;
        border-radius: 4px;
        background-color: rgba(153, 153, 153, 0.4);
      }
      .businessImg {
        width: 100%;
      }
      .businessDownloadBtn {
        position: absolute;
        bottom: 20px;
        left: 80px;
        width: 300px;
        height: 38px;
        background-color: $main400;
        border-radius: 4px;
        color: $neutral100;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        text-align: left;
        text-align: center;
        span {
          padding-left: 17px;
          background-image: url('/images/companyCopy_download_icon.svg');
          background-repeat: no-repeat;
          background-position: top 3px left 0;
        }
      }
    }
    .companyCopyDate {
      margin-top: 10px;
      color: $neutral900;
      font-size: 16px;
      font-weight: 400;
    }
    .companyCopyList {
      width: 460px;
      border-top: 1px solid $neutral300;
      border-bottom: 1px solid $neutral300;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        border-top: 1px solid $neutral300;
        &:first-child {
          border-top: none;
        }
        .name {
          color: $neutral800;
          font-size: 16px;
          font-weight: 400;
        }
        .downloadBtn {
          width: 220px;
        }
      }
    }
  }
}
