
.guide {
  ul {
    margin-left: 4px !important;
  }

  img {
    margin: 4px 0px 12px 14px;
    width: 644px;
    height: 120px;
    flex-shrink: 0;
  }
}
