@import '@standby/common-ui/color.scss';
.content {
  line-height: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  .btnArea {
    margin-top: 3px;
  }
  .memo {
    color: $neutral800;
    font-size: 13px;
    font-weight: 400;
    line-height: 155%;
    b {
      font-weight: 700;
    }
  }
}
