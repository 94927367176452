@import '@standby/common-ui/color.scss';

.shareholderList {
  .title {
    color: $neutral900;
    font-size: 16px;
    font-weight: 400;
  }
  .table {
    margin-top: 10px;
    width: 940px;
    thead {
      tr {
        th {
          &:nth-child(3) {
            padding-left: 0;
            text-align: left;
          }
          &:nth-child(4) {
            padding: 0 20px;
            text-align: right;
          }
          &:nth-child(5) {
            padding: 0 20px;
            text-align: right;
          }
          &:nth-child(6) {
            border: 1px solid $neutral300;
          }
          .checkBox {
            display: inline-block;
            .text {
              color: $neutral100;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          .checkBox {
            position: relative;
            left: 3px;
          }
          .positionTag {
            display: inline-block;
            margin-left: 6px;
            padding: 3px 6px;
            height: 18px;
            color: $neutral100;
            font-size: 12px;
            font-weight: 400;
            background-color: $main200;
            border-radius: 4px;
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          &:nth-child(4) {
            text-align: right;
            .bold {
              font-weight: 400;
            }
          }
          &.enough {
            color: $main500;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
          }
          &.lack {
            color: $warning200;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
          }
          .icon {
            position: relative;
            top: 3px;
            margin-right: 2px;
          }
        }
      }
    }
  }
  .informationBox {
    margin-top: 36px;
    padding-bottom: 36px;
    .emailBox {
      .emailTitle {
        color: $neutral900;
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        .explain {
          position: relative;
          padding-left: 10px;
          margin-top: 6px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 0;
          }
        }
      }
      .emailInfoBox {
        margin-top: 16px;
        .emailInfo {
          display: flex;
          margin-top: 8px;
          .name {
            margin-top: 11px;
            width: 152px;
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            letter-spacing: -0.02em;
          }
          .email {
            .inputText {
              margin-left: 8px;
            }
          }
        }
      }
    }
    .addEmailBox {
      margin-top: 36px;
      .addEmailTitle {
        color: $neutral900;
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
      }
      .addEmailInfoBox {
        margin-top: 16px;
        .addEmailInfo {
          margin-top: 8px;
          display: flex;
          .addEmialInputText {
            margin-left: 8px;
          }
          .minusBtn {
            margin-top: 29px;
            margin-left: 4px;
          }
        }
      }
      .addBtnArea {
        margin-top: 10px;
      }
    }
  }
  .btnArea {
    margin-top: 36px;
    padding-bottom: 24px;
    border-top: 1px solid $neutral300;
    .sendBtn {
      margin: 0 auto;
      margin-top: 24px;
      width: 300px;
    }
  }
}
