@use '@standby/dike_ui/design-token/color.module.scss' as color;

.container {
  .gap24 {
    margin-top: 24px;
  }

  .gap7 {
    margin-top: 7px;
  }

  .box {
    border-radius: 8px;
    border: 1px solid color.$grayScale200;
    background: color.$grayScale000;
    box-shadow: 2px 2px 4px 0px rgba(7, 23, 78, 0.3);

    display: inline-flex;
    padding: 32px 40px;
    flex-direction: column;
    gap: 24px;

    width: 100%;

    .input {
      max-width: 424px;
    }

    .authority {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .radioGroup {
        display: flex;
        gap: 12px;
      }

      .table {
        width: 100%;

        .checkboxContainer {
          display: flex;
          align-items: center;
          gap: 12px;
        }
      }
    }
  }

  .btnArea {
    text-align: center;
    margin-top: 12px;

    .submit {
      width: 336px;
    }
  }
}
