@import '@standby/common-ui/color.scss';

.productDetail {
  padding: 42px 50px 36px 50px;
  .badge {
    color: $main500;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    padding: 4px 6px;
    border-radius: 2px;
    background: $main000;
    display: inline-block;
  }
  .name {
    color: $neutral800;
    font-size: 24px;
    font-weight: 500;
    line-height: 150%;
    margin-top: 8px;
  }
  .detailList {
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid $neutral300;
    background: $neutral200;
    margin-top: 12px;
    li {
      display: flex;
      padding: 8px 0;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $neutral300;
      }
      .title {
        color: $neutral800;
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
        width: 64px;
      }
      .content {
        color: $neutral600;
        font-size: 12px;
        font-weight: 400;
        line-height: 150%;
        .copyBtn {
          text-decoration: none;
        }
        .copyMessage {
          top: -35px;
        }
      }
    }
  }
  .memoTitle {
    color: $neutral800;
    font-size: 15px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 24px;
  }
  .memoTextArea {
    min-height: 131px;
    resize: none;
    margin-top: 4px;
    line-height: 150%;
  }
  .memoSubmit {
    width: 300px;
    margin: 0 auto;
    margin-top: 36px;
  }
}
