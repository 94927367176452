@use '@standby/dike_ui/design-token/color.module.scss' as color;

.sealInfo {
  height: 70px;
  width: 100%;
  background-color: color.$grayScale100;
  text-align: left;
  padding-left: 16px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: start;
  gap: 4px;
  position: relative;
  cursor: pointer;
  .title {
    color: color.$grayScale900;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    white-space: nowrap;
  }
  .updateAt {
    color: color.$grayScale500;
    position: relative;
  }
  .moreIcon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .sealControlList {
    position: absolute;
    box-shadow: 4px 4px 10px 0px rgba(24, 24, 24, 0.3);
    width: 160px;
    background-color: color.$grayScale000;
    border: 1px solid color.$grayScale300;
    right: 16px;
    top: 53px;
    padding-bottom: 12px;
    z-index: 1;
    border-radius: 4px;
    li {
      margin-top: 12px;
      .btn {
        width: 100%;
        padding-left: 16px;
        color: color.$grayScale700;
      }
    }
  }
}
