@import '@standby/common-ui/color.scss';

.modalContent {
  padding: 36px 50px;
  width: 1040px;
  .questionBox {
    margin-top: 48px;
    &:first-child {
      margin-top: 0;
    }
    display: flex;
    align-items: flex-start;
    .questionBoxTitle {
      width: 160px;
      flex-shrink: 0;
      color: $primary800;
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
    }
    .qusetion {
      .questionTitle {
        font-size: 16px;
        line-height: 21px;
        color: $neutral900;
        font-weight: 500;
        margin-bottom: 16px;
        .bold {
          color: $main500;
        }
      }
      .titleSubInfo {
        font-size: 14px;
        line-height: 18.2px;
        color: $neutral600;
        font-weight: 400;
        margin-top: 6px;
        margin-bottom: 10px;
      }
      margin-bottom: 36px;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
      .subTextInput {
        position: relative;
        display: inline-block;
        padding-right: 20px;
        .inputSubText {
          right: 0;
          top: 10px;
          position: absolute;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          &.hasEx {
            top: 35px;
          }
          &.hasExSmall {
            top: 30px;
          }
        }
      }
      .procedureImg {
        margin-bottom: -5px;
      }
      .progressTypeExplain {
        .explain {
          position: relative;
          padding-left: 10px;
          margin-top: 6px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          &:first-child {
            margin-top: 8px;
          }
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 3px;
            left: 0;
          }
        }
        .agencyInfo {
          margin-top: 6px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          .info {
            margin-top: 4px;
            a {
              cursor: pointer;
              margin-right: 6px;
              color: $main300;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.3;
              .icon {
                position: relative;
                top: 3px;
                margin-left: 4px;
              }
            }
          }
        }
      }
      .personInChargeBox {
        width: 460px;
        .personInCharge {
          margin-top: 8px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .title {
            padding-top: 11px;
            color: $neutral700;
            font-size: 15px;
            font-weight: 400;
            line-height: 1.3;
          }
          .inputText {
            width: 380px;
          }
        }
      }
      .termsBox {
        margin-top: 12px;
        padding-top: 12px;
        width: 460px;
        border-top: 1px solid $neutral300;
        .checkbox {
          margin-top: 10px;
          &:first-child {
            margin-top: 0;
          }
          .termsBtn {
            color: $main300;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: -0.28px;
            text-decoration: underline;
            background-color: transparent;
          }
          .required {
            color: $secon200;
          }
        }
      }
      .tooltipContent {
        padding: 12px 16px 16px;
        &.certificate {
          width: 460px;
        }
        .tooltip {
          margin-top: 12px;
          &:first-child {
            margin-top: 0;
          }
          .title {
            display: inline-block;
            padding: 4px 6px;
            color: $main500;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            background-color: $main000;
            border-radius: 2px;
          }
          .content {
            margin-top: 6px;
            color: $neutral700;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.2px;
          }
        }
      }
      .radioBox {
        margin-top: 16px;
        width: 460px;
        .radioLabel {
          .radioTag {
            color: $neutral700;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
          }
          .radioExplain {
            margin-top: 13px;
            position: relative;
            padding-left: 8px;
            color: $neutral700;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            &:before {
              content: '';
              display: inline-block;
              position: absolute;
              top: 4px;
              left: 0;
              width: 0;
              height: 0;
              border-left: 4px solid $neutral600;
              border-right: 4px solid transparent;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
            }
          }
          .shareholderList {
            margin-top: 15px;
            li {
              position: relative;
              margin-top: 8px;
              padding-left: 10px;
              color: $neutral700;
              font-size: 12px;
              font-weight: 400;
              line-height: 15px;
              letter-spacing: -0.24px;
              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 3px;
                left: 0;
                border-left: 5px solid $neutral600;
                border-right: 5px solid transparent;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
              }
            }
          }
        }
      }
      .certificateBox {
        padding: 12px 16px;
        border: 1px solid $neutral300;
        border-radius: 18px;
        .title {
          padding-left: 22px;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          background-image: url('~@standby/common-ui/components/asset/Icon/QuestionTooltip_active_icon.svg');
          background-repeat: no-repeat;
          background-position: left top;
          margin-bottom: 12px;
        }
        .finalIssue {
          margin-top: 12px;
          display: inline-block;
          padding: 4px 6px;
          color: $main500;
          font-size: 14px;
          font-weight: 400;
          background-color: $main000;
          border-radius: 2px;
        }
        .finalIssueState {
          position: relative;
          padding-left: 9px;
          margin-top: 8px;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 0;
            background-color: $neutral700;
            border-radius: 50%;
            width: 3px;
            height: 3px;
          }
        }
      }
      .requestRequirementBtn {
        display: block;
        width: 220px;
        height: 42px;
        background-color: transparent;
        border: 1px solid $neutral400;
        border-radius: 4px;
        > span {
          padding-right: 19px;
          color: $main500;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          background-repeat: no-repeat;
          background-image: url('/images/arrow_icon_main500.svg');
          background-position: right top 4px;
        }
        &.open {
          margin-bottom: 10px;
          > span {
            background-image: url('/images/arrow_up_icon_main500.svg');
          }
        }
      }
      .companyShareholder {
        margin-top: 36px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  .btnArea {
    margin-top: 36px;
    .submitBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}
