@import '@standby/common-ui/color.scss';

.progressContent {
  position: relative;
  .progress {
    display: flex;
    .accountImg {
      padding-left: 48px;
      margin-left: 48px;
      border-left: 1px solid $neutral300;
    }
    .statusInfo {
      position: absolute;
      color: $neutral600;
      font-size: 13px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.02em;
      text-align: center;
    }
  }
}
.informationInputComplete {
  position: relative;
  margin-top: 8px;
  padding-left: 22px;
  color: $neutral800;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3;
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 1px;
    left: 0;
    width: 18px;
    height: 18px;
    background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_hover.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}
.taxOfficeReceive {
  position: relative;
  margin-top: 24px;
  .title {
    color: $main500;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
  }
  .thumbnailFile {
    position: relative;
    margin-top: 12px;
    width: 360px;
    height: 200px;
    border: 1px solid $neutral500;
    border-radius: 4px;
    overflow: hidden;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      width: 359px;
      height: 200px;
      border-radius: 4px;
      background-color: rgba(153, 153, 153, 0.4);
    }
    .img {
      width: 100%;
    }
  }
  .downloadBtn {
    position: absolute;
    bottom: 46px;
    left: 112px;
    width: 135px;
  }
  .explain {
    margin-top: 12px;
    position: relative;
    padding-left: 22px;
    color: $neutral800;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.3;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 1px;
      left: 0;
      width: 18px;
      height: 18px;
      background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_hover.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}
.registerComplete {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  .document {
    position: relative;
    .title {
      color: $main500;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
    }
    .thumbnailFile {
      position: relative;
      margin-top: 12px;
      width: 385px;
      height: 180px;
      border: 1px solid $neutral500;
      border-radius: 4px;
      overflow: hidden;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 384px;
        height: 180px;
        border-radius: 4px;
        background-color: rgba(153, 153, 153, 0.4);
      }
      .img {
        width: 100%;
      }
    }
    .downloadBtn {
      position: absolute;
      bottom: 46px;
      left: 125px;
      width: 135px;
    }
    .registerNumber {
      margin-top: 12px;
      color: $neutral800;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.3;
      .copyBtn {
        margin-left: 11px;
      }
    }
  }
  .sendDocument {
    .title {
      color: $neutral800;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
    }
    .documentSendBtn {
      margin-top: 12px;
      width: 385px;
      height: 180px;
      background-color: $neutral100;
      border: 1px solid $main400;
      border-radius: 4px;
      box-shadow: 1px 1px 2px 1px rgba(153, 153, 153, 0.16);
      .btnText {
        display: inline-block;
        padding-left: 34px;
        color: $main500;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        background-repeat: no-repeat;
        background-image: url('/images/bankbook_icon.svg');
        background-position: left top;
      }
      .explain {
        margin-top: 9px;
        color: $neutral700;
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
      }
      &:active {
        position: relative;
        top: 1px;
        left: 1px;
      }
    }
  }
}
