@import '@standby/common-ui/color.scss';

.modalContent {
  width: 1040px;
  padding: 36px 50px;
  .question {
    &:not(:first-child) {
      margin-top: 36px;
    }
    > .title {
      color: $neutral900;
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
      margin-bottom: 16px;
    }
    > .content {
      .radio {
        width: 460px;
      }
    }
  }
  .btnArea {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    position: relative;
    .contentSubmitBtn {
      width: 300px;
    }
  }
}
