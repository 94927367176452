@use '@standby/dike_ui/design-token/color.module.scss' as color;
@import '@standby/common-ui/color.scss';

.wrap {
  .registerTop {
    height: 56px;
    background-color: $secon100;
    margin-bottom: 17px;
    .content {
      height: 100%;
      a {
        display: inline-flex;
        justify-content: start;
        align-items: center;
        height: 100%;
        position: relative;
        gap: 12px;
        img {
          position: relative;
          z-index: 1;
        }
        &::after {
          position: absolute;
          content: '';
          display: inline-block;
          background-image: url('/images/registerTopBackground.svg');
          background-repeat: no-repeat;
          width: 62px;
          height: 31px;
          bottom: 0px;
          right: -31px;
        }
      }
    }
  }
  .liquidationTop {
    height: 48px;
    border-bottom: 1px solid $neutral300;
    background: $neutral200;
    .content {
      height: 100%;
      display: flex;
      align-items: end;
    }
  }
  .notificationTop {
    height: 48px;
    border-bottom: 1px solid $neutral300;
    background: $neutral200;
    position: relative;
    .content {
      height: 100%;
      .notificationInfo {
        font-family: 'Pretendard';
        height: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        color: $neutral800;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.15px;
        .title {
          font-weight: 700;
        }
      }
      .infoLink {
        font-family: 'Pretendard';
        background-color: color.$grayScale500;
        color: $neutral100;
        width: 78px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 40px;
        gap: 2px;
      }
    }
  }
  .allianceTop {
    border-bottom: 1px solid $main100;
    background: $main000;
    height: 47px;
    position: relative;
    .content {
      height: 100%;
      .relative {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          display: inline-flex;
        }
        .leftImg {
          position: absolute;
          top: 0;
          left: 180px;
        }
      }
    }
  }
  .topBannerClose {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding-left: 20px;
    padding-right: 44px;
    color: $neutral500;
    font-size: 12px;
    font-weight: 500;
    background-image: url('/images/allianceTopCloseIcon.svg');
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-color: transparent;
    line-height: 14px;
  }
  .vocLinkWrap {
    position: fixed;
    bottom: 96px;
    right: 24px;
    border-radius: 24px;
    transition: 0.4s;
    width: 56px;
    height: 56px;
    background-color: $neutral100;
    z-index: 1000;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 30px 0px;
    }
    .vocLink {
      border-radius: 24px;
      display: inline-block;
      box-shadow:
        rgba(255, 255, 255, 0.2) 0px 0px 0px 1px inset,
        rgba(0, 0, 0, 0.1) 0px 4px 6px,
        rgba(0, 0, 0, 0.15) 0px 8px 30px;
      width: 100%;
      height: 100%;
      background-image: url('/images/vocIcon.svg');
      background-repeat: no-repeat;
      background-position: top 14px center;
      position: relative;
      &:hover {
        .tooltip {
          opacity: 1;
          visibility: visible;
        }
      }
      .tooltip {
        position: absolute;
        transition: 0.2s;
        visibility: hidden;
        opacity: 0;
        color: $neutral800;
        font-size: 11px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.22px;
        right: 68px;
        top: 50%;
        transform: translateY(-50%);
        white-space: nowrap;
        border: 1px solid $neutral400;
        border-radius: 2px;
        padding: 6px 8px 7px 8px;
        background-color: $neutral100;
        &:before {
          position: absolute;
          content: '';
          display: inline-block;
          border-width: 5px;
          border-style: solid;
          border-color: transparent transparent transparent $neutral400;
          bottom: 50%;
          right: -10px;
          transform: translateY(50%);
        }
        &:after {
          position: absolute;
          content: '';
          display: inline-block;
          border-width: 4px;
          border-style: solid;
          border-color: transparent transparent transparent $neutral100;
          z-index: 10;
          right: -8px;
          bottom: 50%;
          transform: translateY(50%);
        }
      }
    }
  }
}
