@use '@standby/dike_ui/design-token/color.module.scss' as color;

.confirm {
  border-bottom: 1px solid color.$grayScale300;
  padding: 20px 0;

  .content {
    display: flex;
    gap: 8px;

    .message {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  .btnArea {
    margin-top: 8px;
    display: flex;
    justify-content: end;
    gap: 8px;

    button {
      width: 72px;
    }
  }
}
