@import '@standby/common-ui/color.scss';
.content {
  width: 100%;
  .topWarning {
    width: 100%;
    background-color: #ff238d;
    color: $neutral100;
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    padding-left: 39px;
    padding-top: 9px;
    padding-bottom: 11px;
    border-radius: 4px;
    & + .topWarning {
      margin-top: 4px;
    }
  }
}
