@use '@standby/dike_ui/design-token/color.module.scss' as color;
@use '@standby/dike_ui/design-token/font.module.scss';

.form {
  width: 100%;
  .logoWrap {
    text-align: center;
    .logo {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .mainTitle {
    text-align: center;
    margin-bottom: 8px;
    .color {
      color: color.$primary400;
    }
  }
  .info {
    margin-bottom: 48px;
    color: color.$grayScale700;
    line-height: 20px;
    text-align: center;
  }
  .inputText {
    margin-top: 20px;
  }
  .timer {
    @extend .Body-14M;
    color: color.$grayScale700;
    min-width: 80px;
    text-align: right;
  }
  .mainBtn {
    margin-top: 48px;
    width: 100%;
  }
  .likeBtnBox {
    margin-top: 12px;
    text-align: center;
    .likeBtn {
      @extend .Body-14R;
      color: color.$grayScale700;
    }
  }
}
