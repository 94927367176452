@use '@standby/dike_ui/design-token/color.module.scss' as color;

.table {
  width: 100%;

  thead {
    color: color.$grayScale700;
  }

  tbody {
    color: color.$grayScale800;

    .checkboxContainer {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  }
}
