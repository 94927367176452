@import '@standby/common-ui/color.scss';

.tooltipContent {
  padding: 12px;
  padding-bottom: 14px;
  width: 620px;
  .qna {
    margin-top: 12px;
    &:first-child {
      margin-top: 0;
    }
    .title {
      display: inline-block;
      padding: 4px 6px;
      color: $main500;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      background-color: $main000;
      border-radius: 2px;
    }
    .content {
      margin-top: 6px;
      color: $neutral700;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      ul {
        li {
          position: relative;
          padding-left: 9px;
          margin-top: 4px;
          &:first-child {
            margin-top: 0;
          }
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 0;
            width: 3px;
            height: 3px;
            background-color: $neutral600;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .sample {
    width: 580px;
    img {
      margin-top: 6px;
      &:first-child {
        margin-top: 8px;
      }
    }
  }
}
