@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/homeTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}
.contentBox {
  margin-top: 28px;
  > .row {
    display: flex;
    margin-top: 20px;
    align-items: flex-start;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}
