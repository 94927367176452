@import '@standby/common-ui/color.scss';

.receiptPopup {
  padding: 28px 52px;
  width: 489px;
  .title {
    position: relative;
    padding-top: 40px;
    color: $neutral900;
    font-size: 17px;
    font-weight: 400;
    line-height: 1;
    text-align: center;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      left: 178px;
      width: 28px;
      height: 28px;
      background-image: url('/images/alert_icon.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .content {
    margin-top: 11px;
    color: $neutral800;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: -0.5px;
  }
  .checkBtn {
    display: block;
    margin: 0 auto;
    margin-top: 20px;
    width: 66px;
    height: 34px;
    border: 1px solid $main400;
    border-radius: 4px;
    background-color: $neutral100;
    color: $main500;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.04em;
  }
}
