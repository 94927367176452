@import '@standby/common-ui/color.scss';

.tooltipContent {
  padding: 12px;
  padding-bottom: 16px;
  width: 460px;
  .qna {
    padding-left: 24px;
    margin-top: 14px;
    &:first-child {
      margin-top: 0;
    }
    .question,
    .answer {
      position: relative;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.4;
      &.question {
        color: $neutral700;
      }
      &.answer {
        margin-top: 6px;
        color: $neutral800;
        .bold {
          font-weight: 600;
        }
      }
      .icon {
        position: absolute;
        top: 1px;
        left: -24px;
      }
    }
  }
  .exTitle {
    color: $main500;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    background-color: $main000;
    padding: 4px 6px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 6px;
  }
  .exContent {
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 400;
    color: $neutral700;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
.inputTitle {
  margin-right: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.32px;
  color: $neutral800;
}
.solidBtn {
  width: 140px;
  height: 42px;
  background-color: $neutral100;
  border: 1px solid $main400;
  color: $main500;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.56px;
  border-radius: 4px;
  margin-left: 22px;
}
.inputSubText {
  font-size: 14px;
  font-weight: 400;
  color: $neutral800;
  margin-left: 6px;
}
.shareholdersTable {
  width: 620px;
  th {
    font-size: 14px;
    font-weight: 400;
    color: $neutral700;
    background-color: $neutral200;
    height: 36px;
    border-bottom: 1px solid $neutral300;
    border-top: 1px solid $neutral300;
    border-left: 1px solid $neutral300;
    &:nth-last-child(1) {
      border-right: 1px solid $neutral300;
    }
  }
  td {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    color: $neutral800;
    padding: 10px 8px;
    border-bottom: 1px solid $neutral300;
    border-top: 1px solid $neutral300;
    border-left: 1px solid $neutral300;
    &:nth-last-child(1) {
      border-right: 1px solid $neutral300;
      text-align: right;
    }
    &:nth-child(1) {
      font-weight: 500;
    }
    &:nth-child(2),
    &:nth-child(3) {
      text-align: right;
    }
  }
}
.indexWrap {
  display: flex;
  .index {
    width: 28px;
    flex-shrink: 0;
  }
}
.shareholdersSum {
  color: $neutral800;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-top: 10px;
  .rateSum {
    display: inline-block;
    width: 309px;
    text-align: right;
    padding-right: 8px;
  }
  .numberOfStocksSum {
    display: inline-block;
    width: calc(620px - 309px);
    padding-right: 8px;
    text-align: right;
  }
}

.sumList {
  width: 310px;
  li {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $neutral300;
    font-size: 14px;
    letter-spacing: -0.28px;
    font-weight: 400;
    color: $neutral800;
    padding: 10px 8px;
    &:last-child {
      border-bottom: 1px solid $neutral300;
      background-color: $neutral200;
      font-weight: 700;
    }
  }
}

.inputs {
  input {
    padding-right: 30px !important;
  }
  select {
    text-align: right;
  }
}
