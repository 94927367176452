@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/homeTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.companyInfoBox {
  padding: 18px 32px;
  width: 100%;
  border: 1px solid $neutral300;
  border-radius: 4px;
  background-color: $neutral100;
  text-align: left;
  .companyInfo {
    padding-left: 60px;
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: -2px;
      left: 0;
      width: 48px;
      height: 48px;
      background-image: url('/images/company_icon_big.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
    .companyTitle {
      color: $neutral800;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
    }
    .companyAddress {
      margin-top: 8px;
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
  }
}
.both,
.either {
  &.both {
    display: flex;
    justify-content: space-between;
    .contentBox {
      width: 508px;
      .content {
        .businessBox,
        .bankBookBox {
          .downloadBox {
            .imgDownload {
              .downloadBtn {
                width: 336px;
              }
            }
          }
        }
        .informationBox {
          margin-top: 24px;
        }
      }
    }
  }
  &.either {
    .contentBox {
      .content {
        .businessBox,
        .bankBookBox {
          display: flex;
          .downloadBox {
            .imgDownload {
              .downloadBtn {
                width: 380px;
              }
            }
          }
        }
        .informationBox {
          margin-left: 36px;
          .infoBox {
            width: 452px;
          }
        }
      }
    }
  }
  .contentBox {
    padding: 0;
    margin-top: 24px;
    .content {
      margin-top: 54px;
      border-top: 1px solid $neutral300;
      padding: 36px 50px;
      .businessBox,
      .bankBookBox {
        .downloadBox {
          .imgDownload {
            position: relative;
            .downloadBtn {
              position: absolute;
              bottom: 28px;
              left: 36px;
              height: 44px;
              background-color: $main400;
              border-radius: 4px;
              .text {
                padding-left: 27px;
                color: $neutral100;
                font-size: 20px;
                font-weight: 400;
                line-height: 20px;
                background-repeat: no-repeat;
                background-position: left top 4px;
                background-image: url('/images/download_positive_icon.svg');
              }
            }
          }
          .updatedAt {
            margin-top: 5px;
            position: relative;
            padding-left: 10px;
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3;
            &:before {
              content: '*';
              display: inline-block;
              position: absolute;
              top: 4px;
              left: 0;
            }
          }
        }
        .informationBox {
          .title,
          .bankBookTitle {
            color: $neutral900;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            &.bankBookTitle {
              display: flex;
              justify-content: space-between;
            }
          }
          .infoBox {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            border-top: 1px solid $neutral300;
            &:nth-child(2) {
              margin-top: 16px;
            }
            &:last-child {
              border-bottom: 1px solid $neutral300;
            }
            .infoTitle,
            .info {
              color: $neutral700;
              font-size: 14px;
              font-weight: 400;
              line-height: 14px;
              letter-spacing: -0.28px;
              &.infoTitle {
                position: relative;
                padding-left: 30px;
                &:before {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  width: 16px;
                  height: 16px;
                  top: -1px;
                  left: 8px;
                }
                &.businessRegistrationNumber {
                  &:before {
                    background-image: url('/images/사업자등록번호_icon.svg');
                  }
                }
                &.companyName,
                &.bank {
                  &:before {
                    background-image: url('/images/법인설립일_icon.svg');
                  }
                }
                &.representativeName {
                  &:before {
                    background-image: url('/images/개업연월일_icon.svg');
                  }
                }
                &.email {
                  &:before {
                    background-image: url('/images/사업연도_icon.svg');
                  }
                }
                &.account {
                  &:before {
                    background-image: url('/images/계좌번호_icon.svg');
                  }
                }
                &.depositor {
                  &:before {
                    background-image: url('/images/사람_icon.svg');
                  }
                }
              }
              &.info {
                .copyBtn {
                  margin-left: 8px;
                }
                .copyBox {
                  margin-top: 8px;
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
}

.oneStop {
  position: relative;
  margin-top: 36px;
  .joinBtn {
    position: absolute;
    bottom: 54px;
    left: 232px;
    width: 576px;
    box-shadow: 1px 1px 4px 2px rgba(51, 51, 51, 0.24);
  }
}
