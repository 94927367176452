@import '@standby/common-ui/color.scss';

.aoaDetail {
  width: 940px;
  padding: 36px 50px;
  .aoaDetailBoxTitle {
    color: $neutral900;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
  }
  .table {
    margin-top: 16px;
    width: 100%;
    tbody {
      tr {
        td {
          &:nth-child(1) {
            width: 240px;
          }
          > strong {
            color: $primary800;
            font-weight: 500;
          }
        }
      }
    }
  }
}
