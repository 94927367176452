@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/companyInfoTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}
.imgBox {
  position: relative;
  .explain {
    position: absolute;
    top: 136px;
    left: 50%;
    transform: translateX(-50%);
    color: $neutral100;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-shadow: 2px 2px 6px rgba(68, 68, 68, 0.8);
    .subExplain {
      margin-top: 6px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.32px;
    }
  }
}
.contentBox {
  margin-top: 24px;
  padding: 0;
  .content {
    margin-top: 54px;
    border-top: 1px solid $neutral300;
    padding: 36px 50px;
    .aoa {
      &.limit {
        .aoaDownload {
          margin: 0 auto;
          width: 460px;
          .imgDownload {
            position: relative;
            .downloadBtn {
              position: absolute;
              bottom: 28px;
              left: 36px;
              width: 388px;
            }
          }
          .updatedAt {
            margin-top: 3px;
            position: relative;
            padding-left: 10px;
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3;
            &::before {
              content: '*';
              display: inline-block;
              position: absolute;
              top: 4px;
              left: 0;
            }
          }
        }
      }
      .aoaDownloadAndChange {
        display: flex;
        .aoaDownload {
          width: 460px;
          .imgDownload {
            position: relative;
            .downloadBtn {
              position: absolute;
              bottom: 28px;
              left: 36px;
              width: 388px;
            }
          }
          .updatedAt {
            margin-top: 3px;
            position: relative;
            padding-left: 10px;
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.3;
            &::before {
              content: '*';
              display: inline-block;
              position: absolute;
              top: 4px;
              left: 0;
            }
          }
        }
        .aoaInfoBox {
          margin-left: 43px;
          width: 380px;
          .title {
            color: $neutral900;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
          }
          .aoaContent {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:nth-child(2) {
              margin-top: 16px;
            }
            &:nth-child(6) {
              border-bottom: 1px solid $neutral300;
            }
            padding: 9px 0;
            padding-left: 22px;
            border-top: 1px solid $neutral300;
            color: $neutral700;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            letter-spacing: -0.02em;
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: top 10px left 0;
            .bold {
              color: $neutral700;
              font-weight: 400;
            }
            .stateBold {
              color: $neutral800;
              font-weight: 400;
              &.yes {
                position: relative;
                padding-right: 18px;
                &:before {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 3px;
                  right: 0;
                  width: 16px;
                  height: 16px;
                  background-image: url('/images/aoa_true_icon.svg');
                  background-size: cover;
                  background-repeat: no-repeat;
                }
              }
              &.no {
                position: relative;
                padding-right: 18px;
                &:before {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 3px;
                  right: 0;
                  width: 16px;
                  height: 16px;
                  background-image: url('/images/aoa_false_icon.svg');
                  background-size: cover;
                  background-repeat: no-repeat;
                }
              }
            }
            .stockOptionTag {
              margin-left: 1px;
              color: $neutral800;
            }
          }
        }
      }
      .aoaDetailBox {
        margin-top: 16px;
        margin-bottom: 36px;
        &.open {
          margin-bottom: 80px;
          .aoaShowDetail {
            background-image: url('/images/aoaShowDetail_open.svg');
          }
        }
        .aoaShowDetail {
          padding-right: 16px;
          color: $main300;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
          text-decoration: underline;
          background-color: transparent;
          background-image: url('/images/aoaShowDetail.svg');
          background-repeat: no-repeat;
          background-position: right 0 top 1px;
        }
        .aoaDetailBoxTitle {
          margin-top: 12px;
          color: $neutral900;
          font-size: 16px;
          font-weight: 500;
          line-height: 1;
        }
        .table {
          margin-top: 16px;
          width: 100%;
          tbody {
            tr {
              td {
                &:nth-child(1) {
                  width: 240px;
                }
                > strong {
                  color: $primary800;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
    .aoaChange {
      padding: 36px 0;
      border-top: 1px solid $neutral300;
      &.limit {
        padding-bottom: 0;
        border-top: none;
        .aoaChangeInfoImg {
          margin-top: 16px;
        }
      }
      .title {
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
      }
      .changeBtn {
        margin-top: 16px;
        width: 460px;
        height: 80px;
        background-color: $neutral100;
        border: 1px solid $main400;
        border-radius: 4px;
        color: $main500;
        font-size: 20px;
        font-weight: 400;
        line-height: 1.3;
        text-align: center;
        .text {
          position: relative;
          padding-left: 32px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 22px;
            height: 26px;
            background-image: url('/images/aoaChange_icon.svg');
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
      .aoaChangeInfoImg {
        margin-top: 24px;
      }
    }
    .aoaHistory {
      padding: 36px 0;
      border-top: 1px solid $neutral300;
      .title {
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
      }
      .table {
        margin-top: 17px;
        width: 460px;
        thead {
          tr {
            th {
              width: 50%;
              &:nth-child(2) {
                text-align: left;
              }
            }
          }
        }
        tbody {
          tr {
            td {
              .showAoA {
                color: $main300;
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
                letter-spacing: -0.02em;
                text-decoration: underline;
                background-color: transparent;
              }
            }
          }
        }
      }
      .moreView {
        margin-top: 8px;
        color: $neutral600;
        font-weight: 400;
        font-size: 14px;
        text-decoration: underline;
        background-color: transparent;
        background-image: url('/images/moreView_close.svg');
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 15px;
        &.open {
          background-image: url('/images/moreView_open.svg');
        }
      }
    }
  }
}
