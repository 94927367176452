@import '@standby/common-ui/color.scss';
.personInCharge {
  div {
    &:not(:first-child) {
      margin-left: 12px;
    }
    &:nth-child(4) {
      margin-left: 40px;
    }
  }
  .copyBtn {
    color: $neutral800;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
    text-decoration: none;
    background-image: url('/images/copySmall_netural600.svg');
  }
}
