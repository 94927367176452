@import '@standby/common-ui/color.scss';

.table {
  tbody {
    tr {
      td {
        &:nth-child(2) {
          padding: 10px;
        }
        &:nth-child(3) {
          padding: 10px;
        }
      }
    }
  }
}
