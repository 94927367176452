@import '@standby/common-ui/color.scss';

.wrap {
  width: 404px;
  height: 300px;
  border: 1px solid $neutral300;
  border-radius: 12px;
  margin-left: 20px;
  position: relative;
  padding: 0 20px;
  > .title {
    font-size: 18px;
    font-weight: 500;
    color: $neutral900;
    padding-left: 30px;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
    left: 20px;
    top: 18px;
    height: 24px;
    align-items: center;
    line-height: 23px;
    background-image: url('/images/aoaTitleIcon.svg');
  }
  .moreView {
    position: absolute;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    background-image: url('/images/moreView_Icon_600.svg');
    background-repeat: no-repeat;
    background-position: right top 2px;
    line-height: 100%;
    padding-right: 14px;
    left: 100px;
    top: 23px;
  }
  .openModalBtn {
    background-color: transparent;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.28px;
    text-decoration: underline;
    color: $main300;
    background-image: url('/images/arrowRight_main300.svg');
    background-repeat: no-repeat;
    background-position: right top 3px;
    padding-right: 16px;
    position: absolute;
    display: inline-block;
    right: 20px;
    top: 21px;
  }
  .empty {
    text-align: center;
    color: $neutral600;
    font-size: 16px;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: -0.5px;
    margin-top: 130px;
  }
  .aoaInfoBox {
    margin-top: 44px;
    .title {
      color: $neutral900;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
    }
    .aoaContent {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:nth-child(1) {
        border-top: none;
      }
      padding: 15px 0;
      padding-left: 22px;
      border-top: 1px solid $neutral300;
      color: $neutral700;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: -0.02em;
      background-repeat: no-repeat;
      background-size: 16px;
      background-position: top 16px left 0;
      .bold {
        color: $neutral700;
        font-weight: 400;
      }
      .stateBold {
        color: $neutral800;
        font-weight: 400;
        &.yes {
          position: relative;
          padding-right: 18px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 3px;
            right: 0;
            width: 16px;
            height: 16px;
            background-image: url('/images/aoa_true_icon.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
        &.no {
          position: relative;
          padding-right: 18px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 3px;
            right: 0;
            width: 16px;
            height: 16px;
            background-image: url('/images/aoa_false_icon.svg');
            background-size: cover;
            background-repeat: no-repeat;
          }
        }
      }
      .stockOptionTag {
        margin-left: 1px;
        color: $neutral800;
      }
    }
  }
}
