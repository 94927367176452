@import '@standby/common-ui/color.scss';

.modalContent {
  width: 985px;
  padding: 36px 50px;
  .downloadList {
    display: flex;
    justify-content: space-between;
    li {
      border-radius: 8px;
      border: 1px solid $neutral300;
      text-align: center;
      overflow: hidden;
      .content {
        padding: 16px 0;
        .title {
          color: $neutral700;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        .btn {
          padding: 0 24px;
          margin-top: 8px;
          &.arrowBtn {
            background-image: url('/images/main500_arrow.svg');
            padding-right: 42px;
            background-repeat: no-repeat;
            background-position: right 24px top 11px;
          }
        }
      }
    }
  }
}
