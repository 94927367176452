@use '@standby/dike_ui/design-token/color.module.scss' as color;
@use '@src/common/standby-color.scss' as standbyColor;

.side {
  width: 216px;
  height: calc(100vh - 48px);
  position: fixed;
  top: 48px;
  left: 0;
  background-color: standbyColor.$shCg700;
  z-index: 1000;

  .companySelectOpen {
    height: 48px;
    border-bottom: 1px solid color.$grayScale900;
    position: relative;

    > button {
      width: 100%;
      height: 100%;
      background-color: standbyColor.$shCg700;
      text-align: left;
      padding-left: 12px;
      padding-right: 36px;
      background-image: url('/images/imgSelectClose.svg');
      background-repeat: no-repeat;
      background-position: right 12px center;
      color: color.$grayScale000;
    }

    &:hover {
      > button {
        background-image: url('/images/imgSelectOpen.svg');
      }

      .selectCompany {
        visibility: visible;
        opacity: 1;
        transition: 0.3s;
        top: 41px;
        background-color: color.$grayScale000;
        border-radius: 8px;
      }
    }

    .selectCompany {
      width: 264px;
      visibility: hidden;
      position: absolute;
      left: 12px;
      top: 41px;
      border-radius: 8px;
      box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.16);
      opacity: 0;
      background-color: color.$grayScale000;
      z-index: 50;

      .title {
        position: relative;
        display: flex;
        gap: 8px;
        align-items: center;
        padding: 10px 12px;
      }

      .list {
        position: relative;
        width: 264px;

        .companyListScrollArea {
          max-height: 341px;

          li {
            display: flex;
            justify-content: space-between;
            padding: 6px 12px;
            line-height: 1;

            a {
              position: relative;
              width: 100%;
              display: inline-block;
              padding-left: 8px;
              text-decoration: none;

              &:hover {
                color: color.$primary500;
              }
            }
          }
        }
      }

      .bottomLink {
        display: inline-block;
        border-top: 1px solid color.$grayScale400;
        height: 48px;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;

        .textButton {
          color: color.$grayScale700;

          &:hover {
            color: color.$primary500;

            .plusIcon {
              fill: color.$primary500;
            }
          }
        }
      }
    }
  }

  .scrollArea {
    height: calc(100vh - 280px) !important;

    nav {
      padding: 12px;
    }
  }
}

.adminSetting {
  border-top: 1px solid color.$grayScale900;
  color: color.$grayScale000;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  padding-left: 12px;
}

.blogWrap {
  position: absolute;
  left: 12px;
  bottom: 56px;
  padding-top: 13px;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    top: 0;
    left: 16px;
    background-color: color.$grayScale000;
    background-image: url('/images/blogLightIcon.svg');
    background-position: center;
    background-repeat: no-repeat;
  }

  .blogContent {
    width: 192px;
    height: 106px;
    background-color: #1d2231;
    border-radius: 8px;
    padding: 12px 16px;

    .guideTitle {
      display: inline-block;
      margin-top: 6px;
      color: color.$grayScale400;
    }

    .guideExplain {
      color: color.$grayScale300;
      display: inline-flex;
      align-items: center;
      position: relative;

      &::after {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        background-image: url('/images/blogOutLink.svg');
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: 4px;
      }

      &.topText {
        padding-bottom: 8px;
        border-bottom: 1px dashed color.$grayScale500;
      }
      &.bottomText {
        padding-top: 8px;
      }
    }
  }
}

@media (max-height: 768px) {
  #side {
    .companySelectOpen {
      height: 36px;
      &:hover {
        .selectCompany {
          top: 31px;
        }
      }
    }
    .scrollArea {
      height: calc(100vh - 250px) !important;
    }
    .blogWrap {
      &::before {
        content: none;
      }
      .blogContent {
        height: 92px;
        padding: 1px 16px 0 16px;
      }
    }
    .adminSetting {
      padding-top: 13px;
      padding-bottom: 13px;
      background-position: left 24px top 13px;
    }
  }
}
