@import '@standby/common-ui/color.scss';
.shareholderList {
  .sum {
    margin-left: 12px;
    color: $neutral800;
    .count {
      font-weight: 700;
      text-decoration: none;
    }
  }
  .person {
    margin-left: 6px;
    color: $neutral600;
    text-decoration: none;
  }
  &.copyBtn {
    color: $neutral800;
    font-weight: 400;
    line-height: 15px;
    background-image: url('/images/copySmall_netural600.svg');
    text-decoration: none;
    text-decoration-line: none;
  }
}
.warn {
  color: $warning200;
  font-weight: 700;
  margin-left: 12px;
}
.content {
  padding-top: 13px;
  padding-bottom: 13px;
  color: $neutral600;
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
}
