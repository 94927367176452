@import '@standby/common-ui/color.scss';

.document {
  padding: 36px 50px;
  width: 1040px;
  .title {
    color: $neutral800;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }
  .contentBox {
    display: flex;
    margin-top: 16px;
    .documentBox {
      position: relative;
      .moreDocument {
        position: absolute;
        right: 27px;
        width: 160px;
        height: 42px;
        background-image: url('/images/moreDocument.svg');
        background-repeat: no-repeat;
        color: $main500;
        font-size: 16px;
        font-weight: 500;
        line-height: 20.8px;
        &.scroll {
          animation: fadeout 0.9s ease-out;
          @keyframes fadeout {
            100% {
              opacity: 0;
            }
          }
        }
      }
      width: 680px;
      height: 959px;
      border: 1px solid $neutral400;
      border-radius: 4px;
      .content {
        width: 677px;
        height: 959px;
        overflow-y: scroll;
        .iframeDiv {
          width: 100%;
          height: calc(100% / 0.6);
          transform: scale(0.6);
          transform-origin: top left;
          .iframeDocument {
            width: 1100px;
          }
        }
      }
    }
    .documentOption {
      margin-left: 20px;
      width: 240px;
      .optionSeparate {
        margin-top: 18px;
        padding-top: 18px;
        border-top: 1px solid $neutral300;
        &:first-child {
          margin-top: 0;
          padding-top: 0;
          border-top: none;
        }
        .optionBox {
          margin-top: 24px;
          &:first-child {
            margin-top: 0;
          }
          .optionTitle {
            color: $neutral900;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.3;
            .toolTip {
              position: relative;
              top: 2px;
            }
            .optionTitleExplain {
              position: relative;
              margin-top: 4px;
              padding-left: 10px;
              width: 260px;
              color: $neutral600;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.3;
              &:before {
                content: '*';
                display: inline-block;
                position: absolute;
                top: 3px;
                left: 0;
              }
            }
          }
          .option {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            align-items: start;
            .radio {
              width: fit-content;
              margin-left: 16px;
              &:first-child {
                margin-left: 0;
              }
              &.marginTop {
                margin-top: 4px;
              }
              &.wrap {
                &:nth-child(2n + 1) {
                  margin-left: 0;
                }
              }
            }
            .select {
              margin-top: 7px;
            }
            .datepicker {
              margin-top: 7px;
              input {
                width: 240px;
              }
            }
            .inputText {
              margin-top: 7px;
            }
            .fileUpload {
              cursor: pointer;
              margin-top: 7px;
              margin-right: 100px;
              padding: 10px 16px;
              padding-left: 36px;
              width: 140px;
              height: 36px;
              color: $neutral800;
              font-size: 13px;
              font-weight: 400;
              line-height: 13px;
              letter-spacing: -0.28px;
              border: 1px solid $neutral400;
              border-radius: 4px;
              background-image: url('/images/upload_icon_neutral800.svg');
              background-position: left 16px center;
              background-repeat: no-repeat;
            }
            .uploadFileList {
              margin-top: 12px;
              .file {
                position: relative;
                margin-top: 6px;
                &:first-child {
                  margin-top: 0;
                }
                padding: 10px 28px;
                width: 240px;
                border: 1px solid $neutral300;
                border-radius: 2px;
                color: $neutral700;
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: -0.56px;
                background-image: url('/images/uploadFile_icon_neutral700.svg');
                background-position: left 8px center;
                background-repeat: no-repeat;
                .deleteBtn {
                  position: absolute;
                  transform: translateY(-50%);
                  right: 8px;
                  width: 16px;
                  height: 16px;
                  background-image: url('/images/closeBtn_small_icon.svg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-color: transparent;
                }
              }
            }
          }
          .btnArea {
            margin-top: 24px;
            width: 100%;
            .nextBtn {
              margin: 0 auto;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
