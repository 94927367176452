@import '@standby/common-ui/color.scss';

.telephoneTipModal {
  padding: 16px 24px 24px 24px;
  .info {
    display: inline-block;
    color: $main500;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    padding: 4px 6px;
    background-color: $main000;
    border-radius: 2px;
  }
  ol {
    color: $neutral800;
    padding-left: 17px;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    margin: 0;
    > li {
      margin-top: 6px;
    }
    ul {
      color: $neutral700;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      > li {
        margin-top: 6px;
        position: relative;
        padding-left: 12px;
        &:before {
          width: 3px;
          height: 3px;
          border-radius: 3px;
          content: '';
          position: absolute;
          top: 8px;
          left: 2px;
          display: inline-block;
          background-color: $neutral600;
        }
      }
    }
  }
}
