@import '@standby/common-ui/color.scss';

.cancelReg {
  padding: 36px 50px;
  width: 620px;
  .ex {
    color: $neutral600;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: -0.28px;
    margin-bottom: 36px;
    &::before {
      content: '* ';
      position: relative;
      top: 3px;
    }
  }
  .row {
    display: flex;
    &:not(:first-child) {
      margin-top: 16px;
    }
    > .title {
      color: $neutral800;
      font-size: 14px;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.56px;
      width: 78px;
      position: relative;
      top: 6px;
    }
    > .content {
      flex: 1;
      .inputText {
        width: 160px;
        height: 28px;
        border-radius: 4px;
        border: 1px solid $neutral400;
        background: $neutral100;
        color: $neutral800;
        font-size: 13px;
        font-weight: 400;
        padding-left: 8px;
      }
      .textarea {
        border-radius: 4px;
        border: 1px solid $neutral400;
        background: $neutral100;
        padding: 10px;
        height: 120px;
        font-size: 13px;
        font-weight: 400;
        line-height: 130%;
        width: 100%;
        resize: none;
        margin-bottom: 4px;
      }
      .checkSubMessage {
        color: $neutral600;
        font-size: 12px;
        font-weight: 400;
        line-height: 130%;
        margin-top: 3px;
        margin-left: 26px;
      }
    }
  }
  .btnArea {
    text-align: center;
    margin-top: 24px;
    button {
      width: 100px;
      height: 30px;
      border-radius: 4px;
      background: $main400;
      color: $neutral100;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
