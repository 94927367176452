@import '@standby/common-ui/color.scss';

.expired {
  padding: 0 25px;
  padding-top: 84px;
  width: 100%;
  .logo {
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid $neutral300;
  }
  .content {
    margin-top: 80px;
    text-align: center;
    .title {
      color: $neutral800;
      font-size: 24px;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: -0.5px;
    }
    .explain {
      margin-top: 20px;
      color: $neutral700;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: -0.3px;
    }
  }
}
