@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/companyInfoTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.contentBox {
  position: relative;
  margin-top: 24px;
  padding: 0;
  .floatBtnArea {
    position: absolute;
    top: 12px;
    right: 20px;
    display: flex;
    .changeBankbookBtn,
    .addBankbookBtn {
      width: 147px;
      height: 31px;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.56px;
      &.addBankbookBtn {
        margin-left: 8px;
      }
    }
  }
  .content {
    margin-top: 54px;
    border-top: 1px solid $neutral300;
    padding: 36px 50px;
    padding-bottom: 24px;
    .row {
      display: flex;
      margin-top: 36px;
      &:first-child {
        margin-top: 0;
      }
      .title {
        width: 160px;
        flex-shrink: 0;
        color: $neutral900;
        font-size: 16px;
        font-weight: 500;
        .explain {
          color: $neutral600;
          font-weight: 400;
        }
      }
      .rowContent {
        width: 460px;
        .documentBox {
          margin-top: 12px;
          &:first-child {
            margin-top: 0;
          }
          .businessRegistrationInfo {
            margin-top: 3px;
            padding-left: 26px;
            color: $main500;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.28px;
          }
          .bankList {
            margin-top: 10px;
            padding-left: 26px;
            .bankbooksRadio {
              display: flex;
              justify-content: space-between;
              width: 378px;
              gap: 30px;
              .bankName {
                flex-shrink: 0;
              }
              .accountUsage {
                color: $neutral500;
              }
            }
          }
          .emptyBankList {
            position: relative;
            padding-left: 36px;
            margin-top: 6px;
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.14px;
            &:before {
              content: '*';
              display: inline-block;
              position: absolute;
              left: 26px;
              top: 3px;
            }
          }
        }
        .table {
          margin-top: 16px;
          &:nth-child(1) {
            margin-top: 0;
          }
          &.lastTable {
            border-bottom: 1px solid $neutral300;
          }
          width: 100%;
          border-top: 1px solid $neutral300;
          thead {
            tr {
              td {
                position: relative;
                padding: 10px 8px 11px;
                color: $neutral800;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                background-color: $neutral200;
                border-bottom: 1px solid $neutral300;
                .removeBtn {
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  width: 16px;
                  height: 16px;
                  background-image: url('/images/closeBtn_small_icon.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 16px 0 18px;
                color: $neutral800;
                font-size: 16px;
                font-weight: 400;
                line-height: 16px;
                &.checkBoxTd {
                  display: flex;
                  .check {
                    &:nth-child(2) {
                      margin-left: 18px;
                    }
                    &:nth-child(3) {
                      margin-left: 16px;
                    }
                  }
                }
                .emailInput,
                .phoneNumberInput {
                  margin-top: 10px;
                }
              }
            }
          }
        }
        .addPersonBox {
          margin-top: 24px;
          .addBtn {
            font-size: 15px;
            font-weight: 400;
          }
          .explain {
            position: relative;
            padding-left: 10px;
            margin-top: 8px;
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            &:before {
              content: '*';
              display: inline-block;
              position: absolute;
              top: 3px;
              left: 0;
            }
          }
          .freeViewBtn {
            margin-top: 8px;
            padding-right: 14px;
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: -0.28px;
            text-decoration: underline;
            background-color: transparent;
            background-image: url('/images/freeViewBtn_icon.svg');
            background-repeat: no-repeat;
            background-position: right 0 top 1px;
          }
        }
      }
    }
    .btnArea {
      margin-top: 36px;
      padding-top: 24px;
      border-top: 1px solid $neutral300;
      .sendBtn {
        margin: 0 auto;
        width: 300px;
      }
    }
  }
}

.alert {
  li {
    margin-top: 14px;
    position: relative;
    padding-left: 9px;
    color: $neutral700;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 0;
      width: 3px;
      height: 3px;
      background-color: $neutral700;
      border-radius: 50%;
    }
  }
}
