@import '@standby/common-ui/color.scss';

.registerPopup {
  padding: 24px 48px 36px;
  width: 556px;
  .title {
    padding-top: 40px;
    color: $neutral900;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    background-image: url('/images/loud_icon.svg');
    background-repeat: no-repeat;
    background-position: top;
  }
  .companyInfoBox {
    margin-top: 16px;
    padding: 11px 12px;
    width: 100%;
    border: 1px solid $neutral400;
    border-radius: 4px;
    background-color: $neutral100;
    .companyInfo {
      padding-left: 46px;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: -2px;
        left: 0;
        width: 39px;
        height: 39px;
        background-image: url('/images/company_icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
      .companyTitle {
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
      .companyAddress {
        margin-top: 6px;
        color: $neutral800;
        font-size: 13px;
        font-weight: 400;
        line-height: 1;
      }
    }
  }
  .btnArea {
    margin-top: 20px;
    .squareBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}
