@import '@standby/common-ui/color.scss';
.flexBox {
  display: flex;
  width: calc(100% - 144px);
  .item {
    flex: 1;
    > div:not(:first-child) {
      margin-top: 8px;
    }
    .subText {
      color: $neutral600;
    }
    .shareholders {
      color: $neutral800;
      font-size: 13px;
      font-weight: 400;
      line-height: 100%;
      li {
        line-height: 120%;
        &:not(:first-child) {
          margin-top: 6px;
        }
        display: flex;
        .number {
          min-width: 12px;
        }
        .state {
          color: $main500;
        }
        .warn {
          color: $warning200;
        }
        .name {
          width: 160px;
          margin-right: 5px;
        }
      }
    }
  }
}
.content {
  padding-top: 12px;
  padding-bottom: 12px;
}
