@import '@standby/common-ui/color.scss';

.mainTitle {
  margin-top: 90px;
  color: $neutral900;
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  .bold {
    color: $primary800;
  }
}
.mainContent {
  .registraionRow {
    display: flex;
    margin-top: 36px;
    .registrationTitle {
      padding-top: 18px;
      width: 210px;
      flex-shrink: 0;
      color: $neutral600;
      font-size: 18px;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.02em;
      border-top: 1px solid $neutral600;
      .bold {
        color: $neutral900;
      }
    }
    .registrationContent {
      margin-left: 36px;
      padding-top: 20px;
      width: 794px;
      border-top: 1px solid $neutral300;
      img {
        width: 736px;
      }
      .checkInfo {
        margin-top: 20px;
        border-radius: 8px;
        background: $neutral200;
        padding: 13px 0 13px 36px;
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: -0.28px;
        background-image: url('/images/checkInfo_icon_primary800.svg');
        background-repeat: no-repeat;
        background-position: top 14px left 16px;
        .bold {
          font-weight: 700;
        }
      }
    }
  }
}
