@import '@standby/common-ui/color.scss';

.pageTitle {
  background-image: url('/images/companyInfoTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.documentBox {
  margin-top: 24px;
  padding: 48px 0;
  text-align: center;
  background-color: $neutral200;
  border: 1px solid $neutral300;
  .title {
    color: $neutral900;
    font-size: 22px;
    font-weight: 500;
    line-height: 22px;
    .bold {
      color: $main500;
    }
  }
  .explain {
    margin-top: 12px;
    color: $neutral800;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.5px;
    .bold {
      color: $main500;
    }
  }
  .documentSendBtn {
    margin-top: 24px;
    width: 460px;
    height: 83px;
    border: 1px solid $main400;
    border-radius: 8px;
    background-color: $neutral100;
    box-shadow: 1px 1px 2px 1px rgba(153, 153, 153, 0.16);
    .text {
      padding-left: 34px;
      color: $main500;
      font-size: 20px;
      font-weight: 400;
      line-height: 26px;
      background-repeat: no-repeat;
      background-image: url('/images/bankbook_icon.svg');
      background-position: left top 1px;
    }
  }
}

.contentBox {
  position: relative;
  margin-top: 24px;
  padding: 0;
  .floatBtnArea {
    position: absolute;
    top: 12px;
    right: 20px;
    display: flex;
    .changeBankbookBtn,
    .addBankbookBtn,
    .updateBRBtn,
    .sednBRBtn {
      width: 147px;
      height: 31px;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: -0.56px;
      &.addBankbookBtn,
      &.sednBRBtn {
        margin-left: 8px;
      }
    }
  }
  .content {
    margin-top: 54px;
    border-top: 1px solid $neutral300;
    padding: 36px 50px;
    .businessBox {
      display: flex;
      .downloadBox {
        width: 460px;
        .imgDownload {
          position: relative;
          .downloadBtn {
            position: absolute;
            bottom: 28px;
            left: 36px;
            width: 380px;
          }
        }
        .updatedAt {
          margin-top: 5px;
          position: relative;
          padding-left: 10px;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.3;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            top: 4px;
            left: 0;
          }
        }
      }
      .informationBox {
        margin-left: 36px;
        .title {
          color: $neutral900;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
        }
        .infoBox {
          display: flex;
          justify-content: space-between;
          padding: 10px 0 12px;
          border-top: 1px solid $neutral300;
          &:nth-child(2) {
            margin-top: 16px;
          }
          &:nth-child(5) {
            border-bottom: 1px solid $neutral300;
          }
          width: 452px;
          .infoTitle,
          .info {
            color: $neutral700;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            letter-spacing: -0.28px;
            &.infoTitle {
              position: relative;
              padding-left: 30px;
              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                width: 16px;
                height: 16px;
                top: 0;
                left: 8px;
              }
              &.businessRegistrationNumber {
                &:before {
                  background-image: url('/images/사업자등록번호_icon.svg');
                }
              }
              &.establishedAt {
                &:before {
                  background-image: url('/images/법인설립일_icon.svg');
                }
              }
              &.businessStartAt {
                &:before {
                  background-image: url('/images/개업연월일_icon.svg');
                }
              }
              &.businessYear {
                &:before {
                  background-image: url('/images/사업연도_icon.svg');
                }
              }
            }
            &.info {
              font-weight: 500;
            }
          }
        }
      }
    }
    .businessPurposeBox {
      display: flex;
      justify-content: space-between;
      margin-top: 36px;
      .businessItems,
      .businessTypes {
        width: 452px;
        .businessItemsTitle,
        .businessTypesTitle {
          margin-bottom: 8px;
          color: $neutral700;
          font-size: 16px;
          font-weight: 400;
          line-height: 1;
        }
        .listCard {
          margin-top: 8px;
          width: 100%;
          display: inline-block;
          text-align: left;
          background-color: $neutral100;
          box-shadow: 1px 1px 2px 1px rgba(153, 153, 153, 0.16);
          border: 1px solid $neutral300;
          border-left: 4px solid $neutral300;
          border-radius: 4px;
          padding: 10px;
          padding-left: 8px;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: -0.28px;
          &.important {
            border-left: 4px solid $main200;
          }
        }
      }
    }
    .businessFAQBox {
      .faq {
        margin-top: 24px;
        &:first-child {
          margin-top: 0;
        }
        .question {
          position: relative;
          padding-left: 26px;
          color: $primary800;
          font-size: 18px;
          font-weight: 400;
          line-height: 1;
          letter-spacing: -0.3px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            background-size: cover;
            background-image: url('/images/faq_icon.svg');
            background-repeat: no-repeat;
          }
        }
        .answer {
          margin-top: 10px;
          padding-left: 26px;
          color: $neutral800;
          font-size: 16px;
          font-weight: 400;
          list-style: 1.4;
          letter-spacing: -0.32px;
          .arrowIcon {
            display: inline-block;
            width: 12px;
            height: 11px;
            background-image: url('/images/faq_arrow_icon.svg');
            background-repeat: no-repeat;
          }
        }
      }
    }

    .bankbook {
      .title {
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
      }
      .bankbookInfoBox {
        margin-top: 13px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .bankbookBox {
          width: 452px;
          height: 197px;
          border: 1px solid $neutral400;
          border-radius: 4px;
          &:nth-child(n + 3) {
            margin-top: 25px;
          }
          .bank {
            padding: 12px 19px;
            width: 100%;
            height: 50px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background-color: $neutral200;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            .accountUsage {
              color: $neutral500;
              font-size: 15px;
              font-weight: 500;
            }
            .bankName {
              flex-shrink: 0;
              position: relative;
              padding-left: 34px;
              color: $neutral800;
              font-size: 18px;
              font-weight: 400;
              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                width: 26px;
                height: 26px;
                background-repeat: no-repeat;
                background-image: url('/images/bankLogo_large.svg');
              }
              &.신한은행:before {
                background-position: left 0 top 0;
              }
              &.국민은행:before {
                background-position: left -142px top 0;
              }
              &.기업은행:before {
                background-position: left -284px top 0;
              }
              &.농협은행:before {
                background-position: left -426px top 0;
              }
              &.우리은행:before {
                background-position: left 0 top -44px;
              }
              &.하나은행:before {
                background-position: left -142px top -44px;
              }
              &.산업은행:before {
                background-position: left -284px top -44px;
              }
              &.수협은행:before {
                background-position: left -426px top -44px;
              }
              &.신협은행:before {
                background-position: left 0 top -88px;
              }
              &.부산은행:before {
                background-position: left -142px top -88px;
              }
              &.전북은행:before {
                background-position: left -284px top -88px;
              }
              &.광주은행:before {
                background-position: left -426px top -88px;
              }
              &.대구은행:before {
                background-position: left 0 top -132px;
              }
              &.새마을금고:before {
                background-position: left -142px top -132px;
              }
              &.SC은행:before {
                background-position: left -284px top -132px;
              }
              &.경남은행:before {
                background-position: left -426px top -132px;
              }
              &.한국씨티은행:before {
                background-position: left 0 top -176px;
              }
              &.우체국:before {
                background-position: left -142px top -176px;
              }
              &.카카오뱅크:before {
                background-position: left -284px top -176px;
              }
              &.토스뱅크:before {
                background-position: left -426px top -176px;
              }
              &.케이뱅크:before {
                background-position: left 0 top -220px;
              }
              &.SBI저축은행:before {
                background-position: left -142px top -220px;
              }
              &.산림조합:before {
                background-position: left -284px top -220px;
              }
            }
          }
          .bankInfo {
            padding: 14px 20px 20px;
            .nameBox {
              display: flex;
              color: $neutral900;
              font-size: 14px;
              font-weight: 400;
              line-height: 14px;
              .title {
                width: 64px;
                flex-shrink: 0;
              }
            }
            .accountBox {
              margin-top: 5px;
              display: flex;
              justify-content: space-between;
              color: $neutral900;
              font-size: 14px;
              font-weight: 400;
              line-height: 14px;
              .infoBox {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                .info {
                  position: relative;
                  display: flex;
                  width: 100%;
                  .title {
                    width: 64px;
                    flex-shrink: 0;
                  }
                }
                .toastMessageBtnBox {
                  position: relative;
                  .toastMessage {
                    position: absolute;
                    top: -33px;
                    left: 50%;
                    transform: translateX(-50%);
                  }
                  > button {
                    width: 93px;
                    height: 24px;
                    border: 1px solid $neutral400;
                    border-radius: 2px;
                    background-color: $neutral100;
                    color: $main300;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 12px;
                    letter-spacing: -0.24px;
                  }
                }
              }
            }
            .btnArea {
              display: flex;
              justify-content: space-between;
              margin-top: 28px;
              .toastMessageBtnBox {
                position: relative;
                .toastMessage {
                  position: absolute;
                  top: -33px;
                  left: 50%;
                  transform: translateX(-50%);
                }
                .accountInfoCopyBtn {
                  width: 202px;
                  height: 38px;
                  background-color: $neutral100;
                  border: 1px solid $main400;
                  border-radius: 4px;
                  color: $main500;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 14px;
                  letter-spacing: -0.56px;
                }
              }
              .bankbookDownloadBtn {
                width: 202px;
                height: 38px;
                background-color: $main400;
                border-radius: 4px;
                color: $neutral100;
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                letter-spacing: -0.56px;
                .text {
                  padding-left: 17px;
                  background-image: url('/images/bankbook_download_icon.svg');
                  background-repeat: no-repeat;
                  background-position: left 0 center;
                }
              }
            }
          }
        }
        .addBankBook {
          width: 452px;
          height: 197px;
          border: 1px solid $main500;
          border-radius: 4px;
        }
      }
      .table {
        width: 100%;
        thead {
          tr {
            th {
              text-align: left;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 10px 8px;
              font-size: 15px;
              .bankName {
                position: relative;
                padding-left: 30px;
                &:before {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: 1px;
                  left: 0;
                  width: 22px;
                  height: 22px;
                  background-repeat: no-repeat;
                  background-image: url('/images/bankLogo_medium.svg');
                }
                &.신한은행:before {
                  background-position: left 0 top 0;
                }
                &.국민은행:before {
                  background-position: left -142px top 0;
                }
                &.기업은행:before {
                  background-position: left -284px top 0;
                }
                &.농협은행:before {
                  background-position: left -426px top 0;
                }
                &.우리은행:before {
                  background-position: left 0 top -44px;
                }
                &.하나은행:before {
                  background-position: left -142px top -44px;
                }
                &.산업은행:before {
                  background-position: left -284px top -44px;
                }
                &.수협은행:before {
                  background-position: left -426px top -44px;
                }
                &.신협은행:before {
                  background-position: left 0 top -88px;
                }
                &.부산은행:before {
                  background-position: left -142px top -88px;
                }
                &.전북은행:before {
                  background-position: left -284px top -88px;
                }
                &.광주은행:before {
                  background-position: left -426px top -88px;
                }
                &.대구은행:before {
                  background-position: left 0 top -132px;
                }
                &.새마을금고:before {
                  background-position: left -142px top -132px;
                }
                &.SC은행:before {
                  background-position: left -284px top -132px;
                }
                &.경남은행:before {
                  background-position: left -426px top -132px;
                }
                &.한국씨티은행:before {
                  background-position: left 0 top -176px;
                }
                &.우체국:before {
                  background-position: left -142px top -176px;
                }
                &.카카오뱅크:before {
                  background-position: left -284px top -176px;
                }
                &.토스뱅크:before {
                  background-position: left -426px top -176px;
                }
                &.케이뱅크:before {
                  background-position: left 0 top -220px;
                }
                &.SBI저축은행:before {
                  background-position: left -142px top -220px;
                }
                &.산림조합:before {
                  background-position: left -284px top -220px;
                }
              }
              .accountNumber {
                text-decoration: none;
                color: $neutral800;
                font-size: 15px;
              }
              .accountNumberCopyMessage {
                top: -35px;
              }
              &.accountInfo {
                padding-right: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .accountUsage {
                  color: $neutral800;
                  font-weight: 400;
                  line-height: 18px;
                }
                .btnArea {
                  display: flex;
                  .toastMessageBtnBox {
                    position: relative;
                    .toastMessage {
                      position: absolute;
                      top: -33px;
                      left: 50%;
                      transform: translateX(-50%);
                    }
                    .accountInfoCopyBtn {
                      width: 108px;
                      height: 36px;
                      background-color: $neutral100;
                      border: 1px solid $main400;
                      border-radius: 4px;
                      color: $main500;
                      font-size: 13px;
                      font-weight: 400;
                      line-height: 14px;
                      letter-spacing: -0.56px;
                    }
                  }
                  .bankbookDownloadBtn {
                    margin-left: 4px;
                    width: 145px;
                    height: 36px;
                    background-color: $main400;
                    border-radius: 4px;
                    color: $neutral100;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 14px;
                    letter-spacing: -0.56px;
                    .text {
                      padding-left: 17px;
                      background-image: url('/images/bankbook_download_icon.svg');
                      background-repeat: no-repeat;
                      background-position: left 0 center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.alertContent {
  margin-top: 12px !important;
  li {
    margin-top: 2px;
    position: relative;
    padding-left: 9px;
    color: $neutral700;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 8px;
      left: 0;
      width: 3px;
      height: 3px;
      background-color: $neutral700;
      border-radius: 50%;
    }
  }
}
