@use '@standby/dike_ui/design-token/color.module.scss' as color;

.blogContentList {
  margin-top: 52px;
  > .content {
    width: 1256px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .corpTip {
      width: 410px;
      .tipList {
        margin-top: 12px;
        a {
          display: flex;
          flex-direction: column;
          width: 100%;
          border: 1px solid color.$grayScale200;
          border-radius: 8px;
          padding: 12px 16px 12px 60px;
          overflow: hidden;
          position: relative;
          background-repeat: no-repeat;
          background-position: top 16px left 16px;
          transition: 0.2s;
          &:not(:first-child) {
            margin-top: 8px;
          }
          &:hover {
            box-shadow: 4px 4px 10px 0px rgba(50, 56, 74, 0.25);
          }
          &:nth-child(1) {
            background-image: url('/images/corpTopIcon/corpTopIcon_1.svg?1');
          }
          &:nth-child(2) {
            background-image: url('/images/corpTopIcon/corpTopIcon_2.svg?1');
          }
          &:nth-child(3) {
            background-image: url('/images/corpTopIcon/corpTopIcon_3.svg?1');
          }
          &:nth-child(4) {
            background-image: url('/images/corpTopIcon/corpTopIcon_4.svg?1');
          }
          &:nth-child(5) {
            background-image: url('/images/corpTopIcon/corpTopIcon_5.svg?1');
          }
          > .title {
            color: color.$grayScale800;
          }
          > .tip {
            color: color.$grayScale700;
          }
        }
      }
    }
    .guide {
      width: 814px;
      .guideList {
        display: inline-block;
        width: 100%;
        margin-top: 8px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 11px;
        row-gap: 10px;
        a {
          width: 264px;
          height: 116px;
          background-color: color.$grayScale100;
          border-radius: 8px;
          padding-left: 20px;
          transition: 0.2s;
          position: relative;
          display: flex;
          flex-direction: column;
          .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            background-color: color.$grayScale000;
            width: 32px;
            height: 32px;
            border-radius: 4px;
            left: 20px;
            top: 16px;
            background-position: center;
            background-repeat: no-repeat;
          }
          &:hover {
            box-shadow: 4px 4px 10px 0px rgba(50, 56, 74, 0.25);
          }
          &:nth-child(3n + 1) {
            margin-left: 0;
          }
          &:nth-child(-n + 3) {
            margin-top: 0;
          }
          .title {
            margin-top: 56px;
            color: color.$grayScale800;
          }
          .subText {
            color: color.$grayScale700;
          }
          &:nth-child(1) {
            background-color: color.$primary50;
            .title {
              color: color.$primary400;
            }
          }
          &:nth-child(2) {
            background-color: color.$siGreen100;
            .title {
              color: color.$siGreen800;
            }
          }
        }
      }
    }
  }
}
