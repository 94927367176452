@import '@standby/common-ui/color.scss';
.pageTitle {
  background-image: url('/images/agendaTitle_icon.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 30px;
}

.listTitle {
  margin-top: 45px;
  width: 100%;
  border-bottom: 1px solid $neutral400;
  color: $neutral900;
  font-size: 15px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.285px;
  padding-bottom: 8px;
  display: flex;
  > div {
    &:nth-child(1) {
      width: 49px;
      text-align: right;
    }
    &:nth-child(2) {
      padding-left: 12px;
      width: 188px;
    }
    &:nth-child(3) {
      width: 250px;
    }
    &:nth-child(4) {
      width: 110px;
      text-align: center;
    }
    &:nth-child(5) {
      width: 180px;
      text-align: center;
    }
    &.createdAt {
      width: 110px;
      text-align: center;
    }
    &.cancelAgenda {
      text-align: center;
      flex: 1;
    }
    &.agreements {
      text-align: center;
      flex: 1;
      padding-right: 20px;
      padding-left: 5px;
    }
  }
}
.agendaStart {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  .advertisement {
    width: 564px;
    border-radius: 6px;
    border: 1px solid $neutral300;
  }
  .start {
    width: 452px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    .startTitle {
      color: $neutral800;
      font-size: 24px;
      font-weight: 500;
      line-height: 150%;
      text-align: center;
      margin-top: 20px;
      .important {
        color: $main500;
      }
    }
    .startContent {
      width: 452px;
      height: 215px;
      border-radius: 6px;
      border: 1px solid $main400;
      background-color: $neutral100;
      box-shadow: 1px 1px 2px 1px rgba(153, 153, 153, 0.16);
      background-image: url('/images/agendaStartIcon.svg');
      background-repeat: no-repeat;
      background-position: top 25px center;
      text-align: center;
      transition: 0.2s;
      &:hover {
        background-color: $main000;
      }
      .explanation {
        color: $main500;
        font-size: 18px;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -0.72px;
        padding-top: 79px;
      }
      .explanationSub {
        color: $neutral700;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        margin-top: 6px;
        &::before {
          content: '* ';
          position: relative;
          top: 3px;
        }
      }
      .likeBtn {
        width: 402px;
        display: inline-block;
        border-radius: 30px;
        background: $main400;
        text-align: center;
        color: $neutral100;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        margin-top: 16px;
        margin-bottom: 25px;
        padding: 16px 0;
      }
    }
  }
}
.agendaList {
  font-size: 14px;
  color: $neutral800;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  letter-spacing: -0.266px;
  > li {
    margin-top: 10px;
    border: 1px solid $neutral300;
    display: flex;
    border-radius: 4px;
    border-left: 4px solid $main200;
    padding: 10px 0;
    align-items: center;
    &.completed {
      border-left-color: $neutral300;
    }
    > div {
      &:nth-child(1) {
        width: 44px;
        text-align: center;
        position: relative;
        &::after {
          content: '';
          width: 1px;
          background-color: $neutral300;
          height: 16px;
          display: inline-block;
          position: absolute;
          right: 0;
          top: 2px;
        }
      }
      &:nth-child(2) {
        padding-left: 12px;
        width: 188px;
      }
      &:nth-child(3) {
        width: 250px;
        a {
          color: $main300;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.266px;
          text-decoration-line: underline;
        }
      }
      &:nth-child(4) {
        width: 110px;
        text-align: center;
      }
      &:nth-child(5) {
        width: 180px;
        text-align: center;
        display: flex;
        justify-content: center;
        > div {
          color: $main300;
          font-size: 13px;
          font-weight: 400;
          line-height: 100%;
          background-color: $main000;
          border-radius: 2px;
          padding: 4px;
          &:not(:first-child) {
            margin-left: 6px;
          }
        }
      }
      &.createdAt {
        width: 110px;
        text-align: center;
      }
      &.cancelAgenda {
        text-align: center;
        flex: 1;
        button {
          background-color: transparent;
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -0.28px;
          text-decoration-line: underline;
          background-image: url('/images/arrowRight_neutral600.svg');
          padding-right: 14px;
          background-repeat: no-repeat;
          background-position: top 1px right;
        }
      }
      &.agreements {
        text-align: right;
        flex: 1;
        display: flex;
        justify-content: center;
        padding-right: 20px;
        padding-left: 5px;
        > div {
          color: $sub300;
          text-align: center;
          font-size: 13px;
          font-weight: 400;
          line-height: 110%;
          width: 92px;
          padding-top: 3px;
          padding-bottom: 5px;
          background-color: $sub50;
          &:not(:first-child) {
            margin-left: 6px;
          }
        }
      }
    }
  }
}
.empty {
  text-align: center;
  height: 120px;
  color: $neutral800;
  font-size: 16px;
  font-weight: 400;
  background-image: url('/images/loudCircle_main400.svg');
  background-repeat: no-repeat;
  background-position: top 24px center;
  &.completed {
    color: $neutral500;
    background-image: url('/images/exclamationMarkCircle_netural_400.svg');
  }
  span {
    line-height: 150%;
    margin-top: 82px;
    display: inline-block;
  }
}
.contentBox {
  margin-top: 24px;
}
.confirmWarn {
  color: $warning200;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  margin-top: 10px;
}
