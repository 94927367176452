@import '@standby/common-ui/color.scss';

.companyInfo {
  margin-left: 20px;
  > .number {
    display: flex;
    > .numberBox {
      width: 245px;
      height: 163px;
      border-radius: 12px;
      position: relative;
      > .boxIcon {
        position: absolute;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: $neutral100;
        top: 20px;
        left: 20px;
        background-repeat: no-repeat;
        background-position: center;
      }
      &.businessNumber {
        background-color: $main200;
        > .boxIcon {
          background-image: url('/images/dashboardFileIcon_1.svg');
        }
      }
      &.companyNumber {
        background-color: $sub100;
        margin-left: 20px;
        > .boxIcon {
          background-image: url('/images/businessIcon_green.svg');
        }
      }
      > .title {
        color: $neutral100;
        font-weight: 400;
        letter-spacing: -0.5px;
        font-size: 18px;
        line-height: 100%;
        position: absolute;
        top: 23px;
        left: 56px;
      }
      > .copyWrap {
        position: absolute;
        left: 20px;
        bottom: 28px;
        display: flex;
        color: $neutral100;
        font-size: 22px;
        font-weight: 500;
        line-height: 22px;
        // .copyBtn{
        //     background-image: url('/images/copyBig_white.svg');
        //     color: $neutral100;
        //     font-weight: 400;
        //     line-height: 100%;
        //     font-family: 'Roboto';
        //     font-size: 22px;
        //     text-decoration: none;
        //     padding-right: 21px;
        //     background-position: top 1px right;
        // }
        // .copyMessage{
        //     top: -35px;
        //     right: -46px;
        //     div{
        //         background-color: $neutral100;
        //     }
        // }
        // > .emptyText{
        //     color: $neutral100;
        //     line-height: 100%;
        //     font-weight: 400;
        //     font-size: 16px;
        //     margin-bottom: 3px;
        // }
        .toastMessageBtnBox {
          position: relative;
          .toastMessage {
            position: absolute;
            top: -33px;
            left: 50%;
            transform: translateX(-50%);
          }
          .copyBtn {
            color: $neutral100;
            font-size: 22px;
            font-weight: 500;
            line-height: 22px;
            text-decoration: underline;
            background-color: transparent;
          }
          .copyIcon {
            position: relative;
            top: 2px;
            margin-left: 5px;
            width: 16px;
            height: 19px;
            background-color: transparent;
            background-image: url('/images/copyBig_white.svg');
          }
        }
      }
    }
  }
  > .yearInfo {
    width: 100%;
    height: 96px;
    border: 1px solid $neutral300;
    border-radius: 12px;
    margin-top: 20px;
    padding: 0 20px;
    > .row {
      display: flex;
      justify-content: space-between;
      height: 50%;
      > .item {
        width: 227px;
        border-bottom: 1px solid $neutral300;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > .title {
          padding-left: 22px;
          background-repeat: no-repeat;
          background-position: left top 4px;
          &.companyIcon {
            background-image: url('/images/companyIcon_gray.svg');
          }
          &.homeIcon {
            background-image: url('/images/homeIcon_gray.svg');
          }
          &.bagIcon {
            background-image: url('/images/bagIcon_gray.svg');
          }
        }
        .text {
          font-size: 16px;
          font-weight: 400;
          color: $neutral800;
          letter-spacing: -0.5px;
          &.empty {
            color: $neutral600;
            font-weight: 400;
          }
        }
        .copyText {
          text-decoration: none;
          background-position: right 1px top 2px;
        }
        .copyMessage {
          div {
            background-color: $neutral100;
          }
        }
      }
      &:nth-last-child(1) {
        > .item {
          border-bottom: none;
        }
      }
    }
  }
}
