@import '@standby/common-ui/color.scss';
.agendaDashboard {
  min-height: 100%;
  background-color: #ebedef;
  min-width: 1920px;
  padding-bottom: 10px;
  .agendaBody {
    margin: 16px 24px;
    .horizontal {
      display: flex;
      justify-content: space-between;
      &:not(:first-child) {
        margin-top: 8px;
      }
      > .item {
        border-radius: 4px;
        box-shadow: 1px 1px 2px 0px rgba(153, 153, 153, 0.4);
        display: flex;
        flex: 1;
        &:not(:first-child) {
          margin-left: 8px;
        }
        > .title {
          width: 160px;
          background-color: $neutral500;
          text-align: center;
          font-size: 14px;
          font-weight: 700;
          line-height: 120%;
          color: $neutral100;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        > .content {
          background-color: $neutral100;
          border-radius: 0px 4px 4px 0px;
          flex: 1;
          padding-left: 16px;
          padding-right: 16px;
          display: flex;
          align-items: center;
          .importantText {
            color: $main500;
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
.comment {
  color: $neutral600;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.26px;
  &:before {
    content: '* ';
    position: relative;
    top: 3px;
  }
}
.downloadBtn {
  position: relative;
  padding-left: 19px;
  color: $main300;
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: underline;
  background-color: transparent;
  background-image: url('/images/download_icon.svg');
  background-repeat: no-repeat;
  background-position: left top -1px;
  &:hover {
    text-decoration: underline;
  }
}
.likeLinkBtn {
  background-color: transparent;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.26px;
  text-decoration: underline;
  color: $main300;
  background-image: url('/images/arrowRight_main300.svg');
  background-repeat: no-repeat;
  background-position: right top 4px;
  padding-right: 14px;
}
.circleItem {
  display: flex;
  .circleTitle {
    color: $neutral800;
    font-size: 13px;
    font-weight: 700;
    line-height: 100%;
    padding-left: 6px;
    position: relative;
    width: 130px;
    &::before {
      content: '';
      position: absolute;
      top: 6px;
      left: 0;
      display: inline-block;
      width: 2px;
      height: 2px;
      background-color: $neutral700;
      border-radius: 2px;
    }
  }
  .circleContent {
    color: $neutral800;
    font-size: 13px;
    font-weight: 400;
    line-height: 100%; /* 13px */
  }
}
