@import '@standby/common-ui/color.scss';

.securityContent {
  width: 100%;
  padding: 0 25px;
  padding-top: 84px;
  .logo {
    text-align: center;
    padding-bottom: 30px;
    border-bottom: 1px solid $neutral300;
  }
  .content {
    .form {
      .information {
        .notAccessible {
          margin-top: 80px;
          text-align: center;
          .documentType {
            color: $neutral800;
            font-size: 24px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: -0.5px;
          }
          .explain {
            margin-top: 20px;
            color: $neutral700;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: -0.3px;
          }
        }
        .agreed {
          margin-top: 64px;
          text-align: center;
          .title {
            color: $neutral800;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: -0.5px;
          }
          .explain {
            margin-top: 8px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.3px;
          }
          .info {
            margin-top: 12px;
            color: $neutral700;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: -0.3px;
          }
        }
        .normal {
          margin-top: 30px;
          .companyName {
            color: $main500;
            font-size: 22px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: -0.5px;
            text-align: center;
          }
          .checkDocumentType {
            margin-top: 4px;
            color: $neutral800;
            font-size: 22px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: -0.5px;
            text-align: center;
          }
          .explain {
            margin-top: 10px;
            color: $neutral700;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.3px;
            text-align: center;
          }
          .inputRow {
            margin-top: 29px;
            .label {
              margin-bottom: 6px;
              color: $neutral600;
              font-size: 13px;
              font-weight: 400;
              line-height: 13px;
            }
          }
          .btnArea {
            margin-top: 36px;
            .nextBtn {
              width: 100%;
            }
          }
        }
      }
    }
    .download {
      margin-top: 42px;
      text-align: center;
      color: $neutral800;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: -0.5px;
    }
  }
}
