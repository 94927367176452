@use '@standby/dike_ui/design-token/color.module.scss' as color;

.loginContent {
  width: 100%;
  .logoWrap {
    text-align: center;
    .logo {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .mainTitle {
    margin-bottom: 48px;
    text-align: center;
    color: color.$grayScale800;
  }
  .passwordBox {
    margin-top: 20px;
  }
  .mainBtn {
    margin-top: 48px;
    width: 100%;
  }
  .btnWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    .btnList {
      display: flex;
      gap: 18px;
      a {
        position: relative;
        &:not(:first-child)::before {
          content: '';
          display: inline-block;
          width: 2px;
          height: 12px;
          background-color: color.$grayScale200;
          position: absolute;
          left: -8px;
          top: 5px;
        }
      }
      .linkedBtn {
        color: color.$grayScale700;
      }
    }
  }
}
