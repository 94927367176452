@import '@standby/common-ui/color.scss';

.modalContent {
  padding: 26px;
  width: 489px;
  .title {
    margin-top: 2px;
    padding-left: 26px;
    color: $neutral900;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    letter-spacing: -0.28px;
    background-image: url('/images/exclamationMark_main300.svg');
    background-repeat: no-repeat;
    background-position: top 2px left;
  }
  .content {
    margin-top: 20px;
    padding: 20px 36px;
    width: 100%;
    background-color: $neutral200;
    ul {
      li {
        margin-top: 12px;
        &:first-child {
          margin-top: 0;
        }
        position: relative;
        padding-left: 9px;
        color: $neutral800;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 9px;
          left: 0;
          width: 3px;
          height: 3px;
          background-color: $neutral800;
          border-radius: 50%;
        }
        .type {
          color: $neutral800;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
        }
        .info {
          display: block;
          color: $warning100;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: -0.28px;
        }
      }
    }
  }
  .explain {
    position: relative;
    padding-left: 10px;
    margin-top: 6px;
    color: $neutral700;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.28px;
    .bold {
      font-weight: 500;
    }
    &:before {
      content: '*';
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
  .btnArea {
    margin-top: 20px;
    text-align: right;
    .positiveBtn,
    .negativeBtn {
      width: 66px;
      height: 30px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      list-style: 14px;
      letter-spacing: -0.26px;
      border: 1px solid $neutral400;
      border-radius: 4px;
      background-color: $neutral100;
      &.negativeBtn {
        margin-left: 8px;
      }
    }
  }
}
