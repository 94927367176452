@import '@standby/common-ui/color.scss';

.securityContent {
  width: 100%;
  height: 100vh;
  background-color: $primary900;
  padding-top: 141px;
  .securityContentBox {
    margin: 0 auto;
    padding: 60px 50px 56px;
    width: 560px;
    min-height: 492px;
    background-color: $neutral100;
    border-radius: 20px;
    .logo {
      text-align: center;
      padding-bottom: 30px;
      border-bottom: 1px solid $neutral300;
    }
  }
  .content {
    .form {
      .information {
        .notAccessible {
          margin-top: 80px;
          text-align: center;
          .documentType {
            color: $neutral800;
            font-size: 24px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: -0.5px;
          }
          .explain {
            margin-top: 20px;
            color: $neutral700;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: -0.3px;
          }
        }
        .agreed {
          margin-top: 60px;
          text-align: center;
          .title {
            color: $neutral800;
            font-size: 24px;
            font-weight: 400;
            line-height: 31px;
            letter-spacing: -0.5px;
          }
          .explain {
            margin-top: 8px;
            font-size: 20px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: -0.5px;
          }
          .info {
            margin-top: 16px;
            color: $neutral700;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: -0.3px;
          }
          .btnArea {
            margin-top: 80px;
            .checkBtn {
              width: 100%;
            }
          }
        }
        .normal {
          margin-top: 30px;
          .companyName {
            color: $main500;
            font-size: 22px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: -0.5px;
            text-align: center;
          }
          .checkDocumentType {
            margin-top: 4px;
            color: $neutral800;
            font-size: 22px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: -0.5px;
            text-align: center;
          }
          .explain {
            margin-top: 10px;
            color: $neutral700;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.3px;
            text-align: center;
          }
          .inputRow {
            margin-top: 29px;
            .label {
              margin-bottom: 6px;
              color: $neutral600;
              font-size: 13px;
              font-weight: 400;
              line-height: 13px;
            }
          }
          .btnArea {
            margin-top: 36px;
            .nextBtn {
              width: 100%;
            }
          }
        }
      }
    }
    .download {
      margin-top: 30px;
      text-align: center;
      color: $neutral800;
      font-size: 22px;
      font-weight: 400;
      line-height: 28.6px;
      letter-spacing: -0.5px;
    }
  }
}
