@import '@standby/common-ui/color.scss';

.table {
  thead {
    tr {
      th {
        &:nth-child(1) {
          padding: 3px 4px 3px 35px;
        }
        &:nth-child(2) {
          padding: 3px 4px 3px 4px;
          font-size: 13px;
        }
        &:nth-child(3) {
          padding: 3px 6px 3px 4px;
        }
        &:nth-child(4) {
          padding: 3px 8px 3px 6px;
          text-align: left;
        }
        &:nth-child(5) {
          padding: 3px 4px 3px 8px;
        }
        &:nth-child(6) {
          padding: 3px 6px 3px 4px;
          text-align: right;
        }
        &:nth-child(7) {
          padding: 3px 8px 3px 6px;
          text-align: right;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        &:nth-child(1) {
          padding: 3px 4px 3px 8px;
        }
        &:nth-child(2) {
          padding: 3px 4px 3px 4px;
        }
        &:nth-child(3) {
          padding: 3px 6px 3px 4px;
        }
        &:nth-child(4) {
          padding: 3px 8px 3px 6px;
          text-align: center;
          .direct {
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
          }
        }
        .stockName {
          padding: 3px 4px 3px 8px;
        }
        .stockNumber {
          padding: 3px 6px 3px 4px;
        }
        .stockRate {
          padding: 3px 8px 3px 6px;
        }
      }
    }
  }
}
