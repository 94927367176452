@import '@standby/common-ui/color.scss';

.step1 {
  padding: 50px;
  padding-top: 48px;
  width: 960px;
  text-align: center;
  .title {
    color: $neutral800;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    .important {
      color: $main300;
    }
  }
  .steps {
    display: flex;
    width: 780px;
    margin: 0 auto;
    margin-top: 24px;
    .stepWrap {
      flex: 1;
      display: inline-flex;
      justify-content: center;
      &:nth-child(1) {
        .step {
          padding-left: 58px;
          background-image: url('/images/step1_main200_large.svg');
        }
      }
      &:nth-child(2) {
        .step {
          padding-left: 62px;
          background-image: url('/images/step2_main200_large.svg');
        }
      }
      &:nth-child(3) {
        .step {
          padding-left: 62px;
          background-image: url('/images/step3_main200_large.svg');
        }
      }
      .step {
        background-repeat: no-repeat;
        background-position: top left;
        min-height: 78px;
        text-align: left;
        .stepTitle {
          margin-top: 15px;
          color: $neutral800;
          font-size: 18px;
          font-weight: 500;
          line-height: 21px;
          .bold {
            color: $main300;
          }
        }
        .stepContent {
          margin-top: 8px;
          color: $neutral700;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
  .explain {
    position: relative;
    display: inline-block;
    padding-left: 10px;
    margin-top: 16px;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.28px;
    &:before {
      content: '*';
      display: inline-block;
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
  .btnArea {
    margin-top: 36px;
    display: flex;
    justify-content: space-between;
    .directbtn {
      padding: 24px;
      width: 420px;
      border-radius: 6px;
      border: 1px solid $neutral400;
      background-color: transparent;
      box-shadow: 1px 1px 2px 1px rgba(153, 153, 153, 0.16);
      .btnTitle {
        padding-top: 62px;
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.56px;
        background-image: url('/images/online_acceptance_direct_icon.svg');
        background-repeat: no-repeat;
        background-position: top center;
      }
      .btnText {
        margin-top: 42px;
        padding: 16px 0;
        border: 1px solid $main400;
        border-radius: 30px;
        color: $main500;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
      }
      &:active {
        background-color: $main000;
        .btnText {
          color: $neutral100;
          background-color: $main400;
        }
      }
    }
    .standbyBtn {
      position: relative;
      padding: 24px;
      width: 420px;
      border-radius: 6px;
      border: 1px solid $main400;
      background-color: transparent;
      box-shadow: 1px 1px 2px 1px rgba(153, 153, 153, 0.16);
      &:before {
        content: '추천';
        display: inline-block;
        position: absolute;
        top: -1px;
        right: -1px;
        padding: 6px 9px;
        background-color: $main400;
        border-radius: 0 6px;
        color: $neutral100;
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
      }
      ul {
        padding-top: 56px;
        display: inline-block;
        text-align: left;
        background-image: url('/images/online_acceptance_standby_icon.svg');
        background-repeat: no-repeat;
        background-position: top center;
        li {
          margin-top: 4px;
          &:first-child {
            margin-top: 0;
          }
          position: relative;
          padding-left: 8px;
          color: $neutral700;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: -0.56px;
          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 8px;
            left: 0;
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background-color: $neutral600;
          }
        }
      }
      .btnText {
        margin-top: 22px;
        padding: 16px 0;
        background-color: $main400;
        border-radius: 30px;
        color: $neutral100;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
      }
      &:active {
        background-color: $main000;
      }
    }
  }
}

.direct {
  padding: 36px 50px;
  width: 1040px;
  .title {
    color: $neutral800;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }
  .table {
    margin-top: 16px;
    width: 100%;
    border: 1px solid $neutral300;
    thead {
      tr {
        th {
          color: $neutral100;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          background-color: $neutral600;
          &:nth-child(1) {
            padding: 9px 14px 9px 15px;
            text-align: center;
            border-right: 1px solid $neutral300;
            .agreeAll {
              input + span {
                border-color: $neutral300;
                background-color: $neutral100;
              }
              input + span + span {
                margin-left: 4px;
                color: $neutral100;
              }
            }
          }
          &:nth-child(2) {
            padding: 10px 10px 12px 36px;
            text-align: left;
          }
          &:nth-child(3) {
            padding: 10px 15px 12px 10px;
            text-align: center;
          }
          &:nth-child(4) {
            padding: 10px 10px 12px 15px;
            text-align: center;
          }
          &:nth-child(5) {
            padding: 10px 4px 12px 10px;
            text-align: left;
          }
          &:nth-child(6) {
            padding: 10px 6px 12px 4px;
            text-align: right;
          }
          &:nth-child(7) {
            padding: 10px 9px 12px 6px;
            text-align: right;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid $neutral300;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.28px;
          &.checkbox {
            padding: 9px 0;
            border-right: 1px solid $neutral300;
            text-align: center;
            padding-left: 15px;
            span {
              margin-left: 0;
            }
          }
          &:nth-child(2) {
            padding: 10px 10px 12px 8px;
            text-align: left;
            font-weight: 500;
            .index {
              margin-right: 4px;
              display: inline-block;
              width: 28px;
            }
          }
          &:nth-child(3) {
            padding: 10px 15px 12px 10px;
            text-align: center;
          }
          &:nth-child(4) {
            padding: 10px 10px 12px 15px;
            text-align: center;
          }
          &.stockName {
            padding: 10px 4px 12px 10px;
            text-align: left;
          }
          &.stockNumber {
            padding: 10px 6px 12px 4px;
            text-align: right;
          }
          &.stockRate {
            padding: 10px 9px 12px 6px;
            text-align: right;
          }
          [data-status='발송전'],
          [data-status='발송중'] {
            color: $secon200;
          }
          [data-status='발송완료'] {
            color: $secon200;
            line-height: 16px;
          }
          [data-status='발송실패'] {
            color: $warning200;
            padding-right: 21px;
            background-repeat: no-repeat;
            background-position: right center;
            background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_negative.svg');
          }
        }
      }
    }
  }
  .btnArea {
    margin-top: 36px;
    .submitBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}

.document {
  padding: 36px 50px;
  width: 1040px;
  .title {
    color: $neutral800;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
  }
  .contentBox {
    display: flex;
    margin-top: 16px;
    .documentBox {
      width: 680px;
      height: 959px;
      border: 1px solid $neutral400;
      border-radius: 4px;
      .content {
        width: 100%;
        height: calc(100% / 0.618);
        transform: scale(0.618);
        transform-origin: top left;
        .iframeDocument {
          width: 1100px;
          height: 100%;
        }
      }
    }
    .documentOption {
      margin-left: 20px;
      width: 240px;
      color: 16px;
      font-weight: 400;
      line-height: 20px;
      .btnArea {
        margin-top: 24px;
        width: 100%;
        .nextBtn {
          margin: 0 auto;
          width: 100%;
        }
      }
    }
  }
}
