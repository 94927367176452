@import '@standby/common-ui/color.scss';
.executiveHeader {
  background-color: $neutral200;
  border-bottom: 1px solid $neutral300;
  border-top: 1px solid $neutral300;
  height: 36px;
  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 36px;
    color: $neutral700;
  }
}
.executivesList {
  width: 100%;
  text-align: left;
  .executiveRow {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid $neutral300;
    padding: 10px 0;
    .personInfo {
      margin-left: 8px;
      margin-top: 7px;
      .name {
        color: $neutral800;
        font-weight: 500;
        font-size: 16px;
        letter-spacing: -0.5px;
        padding-left: 24px;
        background-position: left top 1px;
        background-repeat: no-repeat;
        line-height: 19px;
        width: 130px;
        padding-right: 6px;
        flex-shrink: 0;
        word-wrap: break-word;
        &[data-position='대표이사'] {
          background-image: url('/images/executive/executive_대표이사_icon.svg');
        }
        &[data-position='사내이사'] {
          background-image: url('/images/executive/executive_사내이사_icon.svg');
        }
        &[data-position='사외이사'] {
          background-image: url('/images/executive/executive_사외이사_icon.svg');
        }
        &[data-position='기타'] {
          background-image: url('/images/executive/executive_기타_icon.svg');
        }
        &[data-position='감사'] {
          background-image: url('/images/executive/executive_감사_icon.svg');
        }
      }
    }
    .infoRow {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &:not(:first-child) {
        margin-top: 6px;
      }
      .position {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: -0.5px;
        color: $neutral800;
        margin-top: 4px;
        width: 120px;
      }
      .date {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.5px;
        line-height: 100%;
        color: $neutral800;
        margin-top: 9px;
      }
      .registrationRequired {
        position: absolute;
        top: 8px;
        left: -24px;
      }
      .newPosition {
        position: relative;
        width: 288px;
      }
      .gauge {
        display: flex;
      }
      .gaugeWrap {
        margin-top: 14px;
        margin-left: 12px;
        > .gauge {
          margin-left: auto;
          width: 196px;
          height: 6px;
          border-radius: 1px;
          background-color: $neutral200;
          position: relative;
          .fill {
            position: absolute;
            height: 2px;
            left: 2px;
            top: 2px;
            .text {
              width: 67px;
              height: 16px;
              border-radius: 8px;
              position: absolute;
              right: -67px;
              top: -7px;
              color: $neutral100;
              font-size: 11px;
              font-weight: 400;
              letter-spacing: -0.5px;
              text-align: center;
              line-height: 15px;
            }
          }
          &[data-tag='임기만료'] {
            .fill,
            .text {
              background-color: $warning100;
            }
          }
          &[data-tag='임기여유'] {
            .fill,
            .text {
              background-color: $sub100;
            }
          }
          &[data-tag='종료임박'] {
            .fill,
            .text {
              background-color: $secon200;
            }
          }
        }
      }
    }
  }
  .separateInput {
    background-color: transparent;
    font-size: 14px;
    color: $neutral600;
    text-decoration: underline;
    line-height: 18.2px;
    font-weight: 400;
    padding-right: 16px;
    background-repeat: no-repeat;
    background-position: right top 4px;
    &.true {
      background-image: url('/images/arrow_close_netural600.svg');
      margin-left: 39px;
    }
    &.false {
      background-image: url('/images/arrow_open_netural600.svg');
      margin-left: 72px;
    }
  }
}
.endNotify {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.28px;
  color: $neutral700;
  line-height: 14px;
  margin-top: 4px;
  img {
    position: relative;
    top: 4px;
  }
  .iconText {
    color: $warning200;
    margin-right: 6px;
    margin-left: 4px;
  }
}
.changeAddressList {
  li:not(:first-child) {
    margin-top: 9px;
  }
  label {
    display: inline-block;
  }
}
.tooltipContent {
  padding: 13px 16px 16px 16px;
  width: 460px;
  .exTitle {
    color: $main500;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    background-color: $main000;
    padding: 4px 6px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 6px;
  }
  .exContent {
    font-size: 14px;
    line-height: 18.2px;
    font-weight: 400;
    color: $neutral700;
    padding-left: 8px;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &::before {
      content: '';
      display: inline-block;
      background-color: $neutral600;
      border-radius: 3px;
      width: 3px;
      height: 3px;
      position: absolute;
      top: 7px;
      left: 0;
    }
  }
}

.newPerson {
  width: 780px;
  margin-bottom: 16px;
  margin-top: 16px;
  .row {
    &:not(:first-child) {
      margin-top: 16px;
    }
    &:nth-last-child(1) {
      padding-bottom: 16px;
      border-bottom: 1px solid $neutral300;
    }
    .firstRow {
      position: relative;
      display: flex;
      justify-content: space-between;
    }
    .seconRow {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
    }
  }
  .newTitle {
    background-color: $neutral200;
    height: 37px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding-left: 8px;
    border-top: 1px solid $neutral300;
    border-bottom: 1px solid $neutral300;
    justify-content: space-between;
    padding-right: 10px;
    margin-bottom: 11px;
    .rowDelete {
      width: 16px;
      height: 16px;
    }
  }
}
.newRow {
  width: 460px;
  padding: 14px 16px;
  height: 48px;
  text-align: left;
  border: 1px solid $main400;
  border-radius: 6px;
  transition: 0.3s;
  font-weight: 400;
  &:hover {
    background-color: $main000;
  }
}
