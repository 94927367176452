@import '@standby/common-ui/color.scss';

.tooltipContent {
  padding: 13px 16px 16px 16px;
  width: 460px;
  .exTitle {
    color: $main500;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    background-color: $main000;
    padding: 4px 6px;
    border-radius: 2px;
    display: inline-block;
    margin-bottom: 6px;
    letter-spacing: -0.2px;
  }
  .exContent {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    color: $neutral700;
    letter-spacing: -0.2px;
    margin-top: 6px;
    padding-left: 8px;
    position: relative;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    &::before {
      content: '';
      display: inline-block;
      background-color: $neutral600;
      border-radius: 3px;
      width: 3px;
      height: 3px;
      position: absolute;
      top: 7px;
      left: 0;
    }
  }
}

.articleBox {
  margin-top: 16px;
  width: 100%;
  .articleTitle {
    position: relative;
    padding: 11px 8px;
    color: $neutral800;
    font-size: 15px;
    font-weight: 400;
    background-color: $neutral200;
    border-top: 1px solid $neutral500;
    border-bottom: 1px solid $neutral300;
    .closeBtn {
      position: absolute;
      top: 14px;
      right: 10px;
    }
  }
  .inputText,
  .textArea {
    margin-top: 10px;
  }
  .textArea {
    width: 100%;
    div,
    textArea {
      width: 100%;
    }
  }
}

.addBtn {
  margin-top: 16px;
  padding: 14px 16px;
  width: 460px;
  height: 48px;
  border: 1px solid $main400;
  border-radius: 6px;
  text-align: left;
  transition: 0.3s;
  &:hover {
    background-color: $main000;
  }
}
