@import '@standby/common-ui/color.scss';

.btnArea {
  text-align: center;
  .agreeBtn {
    margin: 0 auto;
    width: 300px;
  }
  .btnExplain {
    position: relative;
    padding-left: 10px;
    display: inline-block;
    margin-top: 8px;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.01em;
    &:before {
      content: '*';
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
}
