@import '@standby/common-ui/color.scss';

.sealSelect {
  .selectGroup {
    > .title {
      border-top: 1px solid $neutral300;
      padding-top: 18px;
      margin-top: 18px;
      color: $neutral900;
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
    }
    > .content {
      .radioGroup {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        column-gap: 16px;
        row-gap: 5px;
        label {
          width: fit-content;
        }
      }
      .item {
        margin-top: 12px;
        &.marginNone {
          margin-top: 0;
        }
        .itemTitle {
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 130%;
          margin-bottom: 4px;
        }
        .advertisementWrap {
          display: flex;
          flex-flow: column;
          .advertisement {
            border-radius: 4px;
            border: 1px solid $neutral300;
            width: 100%;
          }
          .advertisementBtn {
            margin-top: 8px;
            width: 100%;
          }
        }
        .sealList {
          width: 100%;
          li {
            margin-top: 4px;
          }
        }
      }
    }
  }
}
