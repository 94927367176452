@import '@standby/common-ui/color.scss';

.top {
  margin-top: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
  .title {
    color: $primary800;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
  }
  .btnGroup {
    .btn {
      color: $main500;
      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.42px;
      height: 32px;
      padding: 0 16px;
      border: 1px solid $main400;
      border-radius: 4px;
      background-color: $neutral100;
      &.color {
        color: $neutral100;
        background-color: $main500;
      }
      & + .btn {
        margin-left: 8px;
      }
    }
  }
}
.status {
  height: 155px;
  background-image: url('/images/internetAssociate_result_back.svg');
  padding-top: 28px;
  padding-left: 40px;
  margin-bottom: 48px;
  .tag {
    color: $main500;
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
    padding: 4px 6px;
    border-radius: 2px;
    background-color: $main000;
    display: inline-block;
  }
  .statusTitle {
    color: $neutral100;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-top: 16px;
  }
  .statusInfo {
    color: $main000;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    margin-top: 4px;
  }
}
.circuitList {
  margin-bottom: 24px;
  .total {
    border-radius: 8px;
    background: $main000;
    padding: 20px 28px;
    display: flex;
    justify-content: space-between;
    > li {
      width: 320px;
      height: 89px;
      flex-shrink: 0;
      border-radius: 8px;
      background: $neutral100;
      box-shadow: 2px 2px 10px 0px rgba(76, 73, 229, 0.2);
      padding-left: 18px;
      padding-top: 16px;
      position: relative;
      .name {
        color: $main400;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
      }
      .line {
        color: $neutral800;
        font-size: 22px;
        font-weight: 700;
        line-height: 150%;
      }
      &[data-type='INTERNET'] {
        &::after {
          background-image: url('/images/INTERNET_icon_main400.svg');
        }
      }
      &[data-type='TELEPHONE'] {
        &::after {
          background-image: url('/images/TELEPHONE_icon_main400.svg');
        }
      }
      &[data-type='ETC'] {
        &::after {
          background-image: url('/images/ETC_icon_main400.svg');
        }
      }
      &:after {
        display: inline-block;
        content: '';
        width: 57px;
        height: 57px;
        border-radius: 12px;
        background-color: $main000;
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translateY(-50%);
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
.productsList {
  margin-bottom: 48px;
  width: 100%;
  th {
    text-align: left;
  }
}
.mainContent {
  .row {
    .title {
      color: $primary900;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      position: relative;
    }
    .sub {
      margin-top: 8px;
      color: $neutral800;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    &:not(:first-child) {
      margin-top: 48px;
    }
    &.rowSpaceBetween {
      display: flex;
      justify-content: space-between;
      .listContent {
        > .list {
          width: 512px;
          margin-top: 12px;
          li {
            color: $neutral700;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            padding: 9px 6px;
            border-bottom: 1px dashed $neutral300;
            em {
              font-weight: 500;
            }
            &:first-child {
              border-top: 1px dashed $neutral300;
            }
            a {
              color: $main200;
              text-decoration: underline;
            }
          }
        }
      }
      .copy {
        width: 512px;
        height: 150px;
        border-radius: 8px;
        border: 1px solid $neutral300;
        background-color: $neutral200;
        text-align: center;
        background-image: url('/images/linkBoxIcon.svg');
        background-repeat: no-repeat;
        background-position: top 18px center;
        &.telephone {
          background-image: url('/images/telephoneBoxIcon.svg');
        }
        .title {
          margin-top: 46px;
          color: $neutral700;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          em {
            color: $primary800;
          }
        }
        .copyArea {
          background-color: $neutral100;
          width: 472px;
          height: 48px;
          margin-top: 16px;
          border-radius: 4px;
          padding: 0 16px;
          border: 1px solid $neutral300;
          background-image: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-decoration-line: none;
          .copyText {
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            text-decoration: underline;
          }
          .likeBtn {
            color: $neutral100;
            font-size: 12px;
            font-weight: 500;
            border-radius: 4px;
            line-height: 18px;
            background-color: $main400;
            padding: 7px 15px;
          }
        }
        .copyMessage {
          top: -20px;
          right: 0;
        }
      }
    }
    .advertisementCards {
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      button {
        background-color: transparent;
      }
    }
  }
}
