@use '@standby/dike_ui/design-token/color.module.scss' as color;

.header {
  width: 100%;
  height: 48px;
  background-color: color.$primary900;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 24px;
  z-index: 3000;

  .buttonWrapper {
    display: flex;
    align-items: center;
    gap: 24px;

    .buttonIcon {
      background-color: transparent;
      display: flex;
      align-items: center;
      color: color.$grayScale000;
      gap: 8px;
    }
  }
}
