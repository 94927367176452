@import '@standby/common-ui/color.scss';

.modalContent {
  width: 1040px;
  padding: 36px 50px;
  .question {
    &:not(:first-child) {
      margin-top: 36px;
    }
    > .title {
      color: $neutral900;
      font-size: 16px;
      font-weight: 700;
      line-height: 100%;
      margin-bottom: 16px;
      > .titleSub {
        margin-top: 6px;
      }
    }
    > .content {
      .radio {
        width: 460px;
        &.recommend {
          position: relative;
          &::before {
            position: absolute;
            top: 0;
            right: 0;
            content: '추천';
            display: inline-block;
            width: 42px;
            height: 25px;
            background-color: $main400;
            color: $neutral100;
            font-size: 13px;
            font-weight: 400;
            line-height: 25px;
            text-align: center;
            border-bottom-left-radius: 4px;
            border-top-right-radius: 3px;
          }
        }
      }
      .table {
        text-align: left;
        .checkAll {
          position: relative;
          top: 1px;
          input + span {
            border-color: $neutral300;
            background-color: $neutral100;
          }
        }
        th {
          height: 36px;
          background-color: $neutral500;
          &,
          span {
            font-size: 14px;
            color: $neutral100;
            font-weight: 400;
          }
          &:nth-last-child(1) {
            border-right: 1px solid $neutral300;
          }
        }
        td {
          border-bottom: 1px solid $neutral300;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -0.28px;
          padding: 9px 8px;
          > .index {
            width: 28px;
            display: inline-block;
            font-weight: 500;
          }
          > .name {
            font-weight: 500;
          }
          > .position {
            margin-left: 8px;
            color: $neutral600;
          }
          > .positionBadge {
            color: $neutral100;
            font-size: 12px;
            font-weight: 400;
            line-height: 100%;
            letter-spacing: -0.24px;
            display: inline-block;
            padding: 3px 6px;
            border-radius: 2px;
            background: $main200;
            margin-left: 6px;
          }
          &:nth-child(1):not(.notFirst) {
            border-left: 1px solid $neutral300;
          }
          &:nth-last-child(1) {
            border-right: 1px solid $neutral300;
          }
        }
        th {
          padding: 0 8px;
        }
        &.executiveMembersTable {
          td:nth-child(1):not(.notFirst),
          th:nth-child(1) {
            text-align: center;
            border-right: 1px solid $neutral300;
          }
        }
        &.attendanceTable {
          margin-top: 10px;
          td:nth-child(1):not(.notFirst),
          td:nth-child(2):not(.notFirst),
          td:nth-child(3):not(.notFirst),
          th:nth-child(1),
          th:nth-child(2),
          th:nth-child(3) {
            text-align: center;
            border-right: 1px solid $neutral300;
          }
        }
      }
      .tableSum {
        height: 36px;
        background-color: $neutral200;
        border-left: 1px solid $neutral300;
        border-bottom: 1px solid $neutral300;
        border-right: 1px solid $neutral300;
        position: relative;
        .attendMeetingTotal {
          width: 77px;
          height: 100%;
          border-right: 1px solid $neutral300;
          background-color: $warning100;
          font-size: 14px;
          font-weight: 500;
          line-height: 100%;
          color: $neutral100;
          line-height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &.possible {
            background-color: $sub100;
          }
          img {
            margin-right: 4px;
          }
        }
        .totalCount,
        .totalPercent {
          position: absolute;
          top: 10px;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 100%;
        }
        .totalCount {
          right: 137px;
        }
        .totalPercent {
          right: 7px;
        }
      }
      .tooltipWrap {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        position: relative;
        z-index: 10;
        .tooltipText {
          color: $neutral700;
          font-size: 14px;
          font-weight: 400;
          line-height: 130%;
          margin-left: 4px;
        }
      }
      .tableBottom {
        margin-top: -30px;
        min-height: 24px;
        .checkAllBtnWrap {
          margin-top: 16px;
          .checkAllBtn {
            width: 300px;
            height: 42px;
            border-radius: 4px;
            border: 1px solid $neutral400;
            background-color: $neutral100;
            span {
              color: $main500;
              font-size: 14px;
              font-weight: 400;
              background-image: url('/images/checkIcon_main500.svg');
              background-repeat: no-repeat;
              background-position: top 3px left;
              padding-left: 16px;
            }
          }
        }
      }
      .noneText {
        span {
          margin-left: 0;
        }
      }
    }
  }
  .btnArea {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    position: relative;
    .prevBtn {
      position: absolute;
      top: 9px;
      left: 0;
      color: $main500;
      font-size: 16px;
      font-weight: 500;
      background-color: $neutral100;
      background-image: url('/images/leftArrow_main500.svg');
      background-repeat: no-repeat;
      padding-left: 16px;
      background-position: left top 6px;
    }
    .contentSubmitBtn {
      width: 300px;
    }
  }
  .ex {
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    &::before {
      content: '*';
      display: inline-block;
      margin-right: 2px;
      position: relative;
      top: 3px;
    }
  }
  .agenda {
    &:not(:first-child) {
      margin-top: 36px;
    }
    .agendaTitle {
      width: 460px;
      height: 50px;
      border: 1px solid $neutral300;
      border-radius: 4px;
      > .agendaIndex {
        width: 114px;
        height: 100%;
        background-color: $main000;
        color: $neutral800;
        font-size: 16px;
        font-weight: 500;
        padding-left: 38px;
        background-image: url('/images/documentIcon.svg');
        background-repeat: no-repeat;
        background-position: left 13px center;
        display: inline-flex;
        align-items: center;
      }
      > .title {
        display: inline-flex;
        align-items: center;
        color: $neutral800;
        font-size: 16px;
        font-weight: 400;
        line-height: 100%;
        padding-left: 16px;
      }
    }
  }
}
