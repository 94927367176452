@import '@standby/common-ui/color.scss';

.stockContentPopup {
  padding: 24px 36px;
  width: 1038px;
  .stock {
    color: $neutral800;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
  }
  .stockExplain {
    position: relative;
    padding-left: 10px;
    margin-top: 6px;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    &:before {
      content: '*';
      display: inline-block;
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
  .content {
    margin-top: 16px;
    padding: 14px;
    border: 1px solid $neutral400;
    border-radius: 4px;
    color: $neutral900;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
  }
}
