@use '@standby/dike_ui/design-token/color.module.scss' as color;

.corpList {
  padding-bottom: 36px;
  background-color: color.$grayScale100;
  position: relative;
  > .content {
    width: 1256px;
    margin: 0 auto;
    .cardList {
      margin-top: 20px;
      display: flex;
      gap: 12px;
      flex-wrap: wrap;
      > li {
        &:nth-child(-n + 4) {
          margin-top: 0;
        }
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
        &:nth-child(8n + 2) {
          .top {
            background-image: url('/images/userDashboard_corpBack/userDashboard_corpBack_1.svg');
          }
        }
        &:nth-child(8n + 3) {
          .top {
            background-image: url('/images/userDashboard_corpBack/userDashboard_corpBack_2.svg?1');
          }
        }
        &:nth-child(8n + 4) {
          .top {
            background-image: url('/images/userDashboard_corpBack/userDashboard_corpBack_3.svg?1');
          }
        }
        &:nth-child(8n + 5) {
          .top {
            background-image: url('/images/userDashboard_corpBack/userDashboard_corpBack_4.svg?1');
          }
        }
        &:nth-child(8n + 6) {
          .top {
            background-image: url('/images/userDashboard_corpBack/userDashboard_corpBack_5.svg?1');
          }
        }
        &:nth-child(8n + 7) {
          .top {
            background-image: url('/images/userDashboard_corpBack/userDashboard_corpBack_6.svg?1');
          }
        }
        &:nth-child(8n + 8) {
          .top {
            background-image: url('/images/userDashboard_corpBack/userDashboard_corpBack_7.svg?1');
          }
        }
        &:nth-child(8n + 9) {
          .top {
            background-image: url('/images/userDashboard_corpBack/userDashboard_corpBack_8.svg?1');
          }
        }
        &.demo {
          .top {
            background-image: url('/images/userDashboard_corpBack/userDashboard_corpBack_demo.svg?1');
            .corpName {
              color: color.$primary900 !important;
            }
          }
        }
        &.liquidation {
          .top {
            background-image: url('/images/userDashboard_corpBack/userDashboard_corpBack_liquidation.svg?1');
            .corpName {
              color: color.$grayScale800 !important;
            }
          }
        }
        .corpCard {
          width: 305px;
          min-height: 186px;
          border-radius: 8px;
          display: inline-block;
          &.companyRegistrationLink {
            border: color.$primary400 1px solid;
            background-color: color.$grayScale000;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            transition: 0.2s;
            &:hover {
              box-shadow: 0px 0px 40px 0px rgba(76, 73, 229, 0.3);
            }
            .companyRegistrationText {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 4px;
              color: color.$primary400;
              margin-top: 12px;
            }
          }
          &.corp {
            transition: 0.2s;
            > .top {
              height: 120px;
              padding: 16px 20px;
              position: relative;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              border: color.$grayScale300 1px solid;
              border-bottom: none;
              .corpName {
                color: color.$grayScale000;
              }
            }
            > .bottom {
              min-height: 66px;
              border: 1px solid color.$grayScale300;
              border-top: none;
              border-bottom-right-radius: 8px;
              border-bottom-left-radius: 8px;
              background-color: color.$grayScale000;
              transition: 0.2s;
              padding: 12px 20px;
              .demoText {
                color: color.$grayScale900;
              }
              .tagContent {
                display: flex;
                align-items: center;
                .tagWrap {
                  margin-right: 4px;
                  display: flex;
                }
                .text {
                  .mainText {
                    color: color.$grayScale900;
                    .number {
                      margin-left: 4px;
                      color: color.$siGreen300;
                    }
                  }
                }
              }
              .subText {
                display: inline-block;
                margin-top: 3px;
                color: color.$grayScale500;
              }
              .importantText {
                &[data-type='필요'] {
                  color: color.$siYellow800;
                }
                &[data-type='필수'] {
                  color: color.$siRed500;
                }
              }
            }
            &:hover {
              box-shadow: 4px 4px 10px 0px rgba(50, 56, 74, 0.25);
            }
            .tag {
              padding: 1px 4px;
              background-color: color.$siGreen100;
              display: inline-block;
              color: color.$siGreen300;
              border-radius: 2px;
              &.smallTag {
                padding: 1px 6px;
                border-radius: 17px;
              }
              &[data-type='필요'] {
                background-color: color.$siYellow800;
                color: color.$grayScale000;
              }
              &[data-type='필수'] {
                background-color: color.$siRed500;
                color: color.$grayScale000;
              }
              &[data-type='등록중'] {
                background-color: color.$siYellow100;
                color: color.$siYellow800;
              }
              &[data-type='청산'] {
                background-color: color.$grayScale700;
                color: color.$grayScale000;
              }
              &[data-type='임원'] {
                background-color: color.$grayScale200;
                color: color.$grayScale700;
              }
            }
          }
        }
      }
    }
  }
  .moreView {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    bottom: 0;
  }
}
