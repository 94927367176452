@import '@standby/common-ui/color.scss';

.aoa {
  & + .aoa {
    margin-top: 16px;
  }
  .badge {
    color: $sub300;
    font-size: 14px;
    font-weight: 500;
    background-color: $sub50;
    display: inline-block;
    line-height: 100%;
    padding: 6px;
    border-radius: 2px;
  }
  .table {
    margin-top: 11px;
    width: 940px;
    th {
      text-align: left;
      &:nth-child(1) {
        padding-left: 26px;
      }
    }
    td {
      vertical-align: top;
    }
    .origin {
      color: $neutral700;
      line-height: 1.3;
      letter-spacing: -0.02em;
      position: relative;
      padding-right: 16px;
      color: $neutral500;
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 13px;
        right: 8px;
        width: 8px;
        height: 12px;
        background-image: url('/images/icon_arrow_triangle.svg');
      }
    }
    .latest {
      color: $main300;
      .subText {
        margin-top: 10px;
        color: $neutral800;
      }
      .row {
        &:not(:first-child) {
          margin-top: 8px;
        }
      }
    }
  }
  .aoaList {
    margin-top: 10px;
    li {
      display: flex;
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      .number {
        flex-shrink: 0;
        width: 20px;
      }
      .regulationLocation {
        font-weight: 700;
      }
      .content {
        margin-left: 16px;
      }
      &:not(:first-child) {
        margin-top: 6px;
      }
    }
  }
}
