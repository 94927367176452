@import '@standby/common-ui/color.scss';

.companyFile {
  width: 510px;
  height: 279px;
  background-color: $neutral200;
  border-radius: 12px;
  padding: 20px;
  > .companyName {
    padding-left: 36px;
    background-image: url('/images/dashboardFolderIcon.svg');
    background-repeat: no-repeat;
    background-position: left top;
    .copyCompanyName {
      font-weight: 500;
      color: $neutral900;
      letter-spacing: -0.5px;
      line-height: 26px;
      font-size: 20px;
      text-decoration: none;
      padding-right: 21px;
      background-image: url('/images/copy_large_icon.svg');
      background-position: top 4px right;
    }
  }
  > .fileList {
    background-color: $neutral100;
    border-radius: 12px;
    margin-top: 20px;
    padding: 0 20px;
    > li {
      border-bottom: 1px solid $neutral300;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      height: 48px;
      align-items: center;
      &:nth-last-child(1) {
        border: none;
      }
      .title {
        font-weight: 400;
        color: $neutral800;
        padding-left: 22px;
        &.icon1 {
          background-image: url('/images/dashboardFileIcon_1.svg');
        }
        &.icon2 {
          background-image: url('/images/dashboardFileIcon_2.svg');
        }
        &.icon3 {
          background-image: url('/images/dashboardFileIcon_3.svg');
        }
        &.icon4 {
          background-image: url('/images/dashboardFileIcon_4.svg');
        }
        background-repeat: no-repeat;
        background-position: left center;
      }
      .contentText {
        font-weight: 400;
        color: $neutral500;
        margin-left: 12px;
        font-size: 14px;
      }
    }
  }
}

.downloadBtn {
  position: relative;
  padding-left: 20px;
  color: $main300;
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: underline;
  background-color: transparent;
  background-image: url('/images/download_icon.svg');
  background-repeat: no-repeat;
  background-position: left top -1px;
}
