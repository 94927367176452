@import '@standby/common-ui/color.scss';

.unissuedStockCertificate {
  .title {
    color: $neutral900;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
  }
  .contentBox {
    display: flex;
    margin-top: 16px;
    .documentBox {
      width: 680px;
      height: 962px;
      border: 1px solid $neutral400;
      border-radius: 4px;
      .content {
        width: 100%;
        height: calc(100% / 0.618);
        transform: scale(0.618);
        transform-origin: top left;
        .document {
          width: 1100px;
          height: 100%;
        }
      }
    }
    .documentOption {
      margin-left: 20px;
      width: 240px;
      .optionSeparate {
        margin-top: 18px;
        padding-top: 18px;
        border-top: 1px solid $neutral300;
        &:first-child {
          margin-top: 0;
          padding-top: 0;
          border-top: none;
        }
        &:last-child {
          margin-bottom: 18px;
          padding-bottom: 18px;
          border-bottom: 1px solid $neutral300;
        }
        .optionBox {
          margin-top: 24px;
          &:first-child {
            margin-top: 0;
          }
          .optionTitle {
            color: $neutral900;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.3;
            .toolTip {
              position: relative;
              top: 2px;
            }
            .optionTitleExplain {
              position: relative;
              margin-top: 4px;
              padding-left: 10px;
              width: 260px;
              color: $neutral600;
              font-size: 14px;
              font-weight: 400;
              line-height: 1.3;
              &:before {
                content: '*';
                display: inline-block;
                position: absolute;
                top: 3px;
                left: 0;
              }
            }
          }
          .option {
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            align-items: start;
            .radio {
              width: fit-content;
              margin-left: 16px;
              &:first-child {
                margin-left: 0;
              }
              &.marginTop {
                margin-top: 4px;
              }
              &.wrap {
                &:nth-child(2n + 1) {
                  margin-left: 0;
                }
              }
            }
            .select {
              margin-top: 7px;
            }
            .datepicker {
              margin-top: 7px;
              input {
                width: 240px;
              }
            }
            .inputText {
              margin-top: 7px;
            }
          }
        }
      }
    }
  }
  .btnArea {
    margin-top: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid $neutral300;
    padding-top: 24px;
    padding-bottom: 24px;
    .downloadBtn,
    .submitBtn {
      display: inline-block;
      width: 300px;
      &.submitBtn {
        margin-left: 20px;
      }
    }
  }
}
