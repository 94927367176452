@import '@standby/common-ui/color.scss';

.titleSubInfo {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  color: $neutral600;
  margin-top: 6px;
  &::before {
    content: '*';
    position: relative;
    top: 3px;
    margin-right: 2px;
  }
}
.table {
  width: 460px;
  th,
  td {
    text-align: left;
  }
  .origin {
    position: relative;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: calc(50% - 6px);
      right: 8px;
      width: 8px;
      height: 12px;
      background-image: url('/images/icon_arrow_triangle.svg');
    }
  }
}
