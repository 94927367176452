@use '@standby/dike_ui/design-token/color.module.scss' as color;

.sealHome {
  .sealContent {
    display: flex;
    flex-flow: column;
    margin-top: 16px;
    margin-bottom: 70px;
  }
  .banner {
    margin-top: 24px;
  }
  .sealListWrap {
    margin-top: 48px;
    .titleWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sealList {
      margin-top: 12px;
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
      .seal {
        width: 336px;
        border: 1px solid color.$grayScale200;
        border-radius: 8px;
        &.corpopate {
          .preview {
            .badge {
              color: color.$primary400;
              background-color: color.$primary50;
            }
          }
        }
        .preview {
          position: relative;
          height: 128px;
          .badge {
            color: color.$grayScale700;
            padding: 1px 4px;
            border-radius: 2px;
            background-color: color.$grayScale100;
            position: absolute;
            top: 16px;
            left: 16px;
            z-index: 1;
          }
          .description {
            position: absolute;
            top: 12px;
            right: 16px;
          }
          .previewImg {
            max-width: 80px;
            max-height: 80px;
            width: auto;
            height: auto;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .addSeal {
        width: 336px;
        height: 198px;
        border: 1px solid color.$primary400;
        border-radius: 8px;
        padding: 0 16px 12px 16px;
        display: flex;
        align-items: end;
        background: url('/images/Img_법인인감등록.png') no-repeat;
        background-position: top 25px center;
        .addSealBtn {
          width: 100%;
          button {
            width: 100%;
          }
        }
      }
    }
  }
}
