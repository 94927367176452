@import '@standby/common-ui/color.scss';

.statusBox {
  display: flex;
  justify-content: space-between;
  padding: 36px 50px;
  width: 794px;
  height: 225px;
  border: 1px solid $neutral300;
  border-radius: 10px;
  .status {
    .statusExplain {
      margin-top: 16px;
      position: relative;
      padding-left: 10px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: -0.28px;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
  }
  .agency {
    .agencyTitle {
      padding-left: 22px;
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      background-image: url('~@standby/common-ui/components/asset/Icon/icon_question_hover_active.svg');
      background-repeat: no-repeat;
      background-position: left top;
    }
    .changeRegistrationBtn {
      margin-top: 12px;
      width: 330px;
      height: 120px;
      background-color: transparent;
      border: 1px solid $main400;
      border-radius: 8px;
      box-shadow: 1px 1px 3px 1px rgba(102, 102, 102, 0.2);
      .text {
        display: inline-block;
        padding-left: 42px;
        color: $main500;
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        background-image: url('/images/documentBag_icon.svg');
        background-repeat: no-repeat;
        background-position: left top;
      }
      .explain {
        margin-top: 4px;
        color: $neutral700;
        font-size: 13px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: -0.26px;
      }
    }
  }
}
