@import '@standby/common-ui/color.scss';

.otherPage {
  .pageTitle {
    background-image: url('/images/companyInfoTitle_icon.svg');
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 30px;
  }
  .imgBox {
    position: relative;
    .explain {
      position: absolute;
      top: 136px;
      left: 50%;
      transform: translateX(-50%);
      color: $neutral100;
      font-size: 22px;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
      text-shadow: 2px 2px 6px rgba(68, 68, 68, 0.8);
      .subExplain {
        margin-top: 6px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: -0.32px;
      }
    }
  }
}
.registerPage {
  margin-top: 76px;
  padding: 48px 0;
  width: 1040px;
  height: 577px;
  border: 1px solid $neutral300;
  .title {
    color: $neutral900;
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
  }
  .explain {
    margin-top: 8px;
    color: $neutral700;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.32px;
    text-align: center;
  }
  .companyInfoBox {
    margin: 0 auto;
    margin-top: 32px;
    padding: 23px 24px;
    width: 800px;
    border: 1px solid $neutral400;
    border-radius: 8px;
    background-color: $neutral100;
    .companyInfo {
      padding-left: 60px;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: -5px;
        left: 0;
        width: 48px;
        height: 48px;
        background-image: url('/images/company_icon.svg');
        background-repeat: no-repeat;
        background-size: cover;
      }
      .companyTitle {
        color: $neutral800;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
      }
      .companyAddress {
        margin-top: 7px;
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
      }
    }
  }
  .animationArea {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 36px;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    transition: background-color 0.5s ease-in-out;
    .animationImg {
      width: 160px;
      height: 160px;
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }
  }
  .imgArea {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 36px;
    width: 240px;
    height: 240px;
    border-radius: 50%;
    background-color: $neutral200;
  }
  @keyframes fadein {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    20% {
      opacity: 1;
      transform: none;
    }
  }
  .animationSubTitle {
    margin-top: 20px;
    color: $neutral900;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.5px;
    text-align: center;
    animation: fadein 4s ease-in infinite;
  }
  .subTitle {
    margin-top: 20px;
    color: $neutral900;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: -0.5px;
    text-align: center;
  }
}
