@import '@standby/common-ui/color.scss';

.addAccount {
  padding: 50px;
  padding-top: 36px;
  width: 850px;
  .form {
    .row {
      margin-top: 24px;
      &:first-child {
        margin-top: 0;
      }
      .title {
        color: $neutral900;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        .option {
          font-weight: 400;
          color: $neutral500;
        }
      }
      .content {
        margin-top: 16px;
        .bankContent {
          position: relative;
          &.open {
            margin-bottom: 384px;
          }
          .bankBoxOpenBtn {
            width: 220px;
            height: 42px;
            border: 1px solid $neutral400;
            border-radius: 4px;
            background-color: $neutral100;
            color: $main500;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            .text {
              padding-right: 16px;
              background-image: url('/images/bankChooseBtn_icon.svg');
              background-repeat: no-repeat;
              background-position: right center;
              &.open {
                background-image: url('/images/bankChooseBtn_icon_open.svg');
              }
            }
          }
          .chooseBankBtn {
            padding: 10px 14px;
            width: 220px;
            height: 42px;
            border: 1px solid $neutral400;
            border-radius: 4px;
            background-color: $neutral100;
            color: $neutral800;
            font-size: 14px;
            font-weight: 400;
            line-height: 14px;
            background-image: url('/images/bankChooseBtn_icon.svg');
            background-repeat: no-repeat;
            background-position: right 14px center;
            text-align: left;
            .text {
              position: relative;
              padding-left: 28px;
              &:before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                left: 0;
                width: 22px;
                height: 22px;
                background-repeat: no-repeat;
                background-image: url('/images/bankLogo_medium.svg');
              }
              &.신한은행:before {
                background-position: left 0 top 0;
              }
              &.국민은행:before {
                background-position: left -142px top 0;
              }
              &.기업은행:before {
                background-position: left -284px top 0;
              }
              &.농협은행:before {
                background-position: left -426px top 0;
              }
              &.우리은행:before {
                background-position: left 0 top -44px;
              }
              &.하나은행:before {
                background-position: left -142px top -44px;
              }
              &.산업은행:before {
                background-position: left -284px top -44px;
              }
              &.수협은행:before {
                background-position: left -426px top -44px;
              }
              &.신협은행:before {
                background-position: left 0 top -88px;
              }
              &.부산은행:before {
                background-position: left -142px top -88px;
              }
              &.전북은행:before {
                background-position: left -284px top -88px;
              }
              &.광주은행:before {
                background-position: left -426px top -88px;
              }
              &.대구은행:before {
                background-position: left 0 top -132px;
              }
              &.새마을금고:before {
                background-position: left -142px top -132px;
              }
              &.SC은행:before {
                background-position: left -284px top -132px;
              }
              &.경남은행:before {
                background-position: left -426px top -132px;
              }
              &.한국씨티은행:before {
                background-position: left 0 top -176px;
              }
              &.우체국:before {
                background-position: left -142px top -176px;
              }
              &.카카오뱅크:before {
                background-position: left -284px top -176px;
              }
              &.토스뱅크:before {
                background-position: left -426px top -176px;
              }
              &.케이뱅크:before {
                background-position: left 0 top -220px;
              }
              &.SBI저축은행:before {
                background-position: left -142px top -220px;
              }
              &.산림조합:before {
                background-position: left -284px top -220px;
              }
            }
          }
          .bankListBox {
            position: absolute;
            margin-top: 12px;
            padding: 18px;
            width: 100%;
            height: 348px;
            border: 1px solid $neutral300;
            visibility: hidden;
            opacity: 0;
            &.open {
              visibility: visible;
              opacity: 1;
            }
            .bankLabel {
              display: inline-block;
              margin-left: 12px;
              &:nth-child(4n + 1) {
                margin-left: 0;
              }
              &:nth-child(n + 5) {
                margin-top: 12px;
              }
              padding: 12px 20px 8px;
              width: 169px;
              height: 42px;
              border: 1px solid $neutral400;
              border-radius: 4px;
              color: $neutral800;
              font-size: 14px;
              font-weight: 400;
              line-height: 14px;
              &.checked {
                border: 1px solid $main400;
                color: $main400;
              }
              .radio {
                position: absolute;
                top: 0;
                left: 0;
                z-index: -10000;
                width: 0;
                height: 0;
              }
              .bankName {
                position: relative;
                padding-left: 28px;
                &:before {
                  content: '';
                  display: inline-block;
                  position: absolute;
                  top: -3px;
                  left: 0;
                  width: 22px;
                  height: 22px;
                  background-repeat: no-repeat;
                  background-image: url('/images/bankLogo_medium.svg');
                }
                &.신한은행:before {
                  background-position: left 0 top 0;
                }
                &.국민은행:before {
                  background-position: left -142px top 0;
                }
                &.기업은행:before {
                  background-position: left -284px top 0;
                }
                &.농협은행:before {
                  background-position: left -426px top 0;
                }
                &.우리은행:before {
                  background-position: left 0 top -44px;
                }
                &.하나은행:before {
                  background-position: left -142px top -44px;
                }
                &.산업은행:before {
                  background-position: left -284px top -44px;
                }
                &.수협은행:before {
                  background-position: left -426px top -44px;
                }
                &.신협은행:before {
                  background-position: left 0 top -88px;
                }
                &.부산은행:before {
                  background-position: left -142px top -88px;
                }
                &.전북은행:before {
                  background-position: left -284px top -88px;
                }
                &.광주은행:before {
                  background-position: left -426px top -88px;
                }
                &.대구은행:before {
                  background-position: left 0 top -132px;
                }
                &.새마을금고:before {
                  background-position: left -142px top -132px;
                }
                &.SC은행:before {
                  background-position: left -284px top -132px;
                }
                &.경남은행:before {
                  background-position: left -426px top -132px;
                }
                &.한국씨티은행:before {
                  background-position: left 0 top -176px;
                }
                &.우체국:before {
                  background-position: left -142px top -176px;
                }
                &.카카오뱅크:before {
                  background-position: left -284px top -176px;
                }
                &.토스뱅크:before {
                  background-position: left -426px top -176px;
                }
                &.케이뱅크:before {
                  background-position: left 0 top -220px;
                }
                &.SBI저축은행:before {
                  background-position: left -142px top -220px;
                }
                &.산림조합:before {
                  background-position: left -284px top -220px;
                }
              }
            }
          }
        }
        .tooltipContent {
          padding: 10px 20px 16px;
          width: 752px;
          .method1,
          .method2 {
            &.method2 {
              margin-top: 16px;
            }
            .title {
              display: flex;
              align-items: center;
              .number {
                padding: 4px 6px;
                width: 49px;
                height: 26px;
                background-color: $main000;
                border-radius: 2px;
                color: $main500;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
              }
              .text {
                margin-left: 5px;
                color: $neutral700;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
              }
            }
            .method1Example {
              margin-top: 6px;
              display: flex;
              justify-content: space-between;
              .title {
                color: $neutral600;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
              }
              .img {
                margin-top: 6px;
              }
            }
            .bankbookImg {
              margin-top: 8px;
            }
          }
        }
      }
    }
    .btnArea {
      margin-top: 24px;
      .submitBtn {
        margin: 0 auto;
        width: 300px;
      }
    }
  }
}
