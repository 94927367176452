@use '@standby/dike_ui/design-token/color.module.scss' as color;
@use '@src/common/standby-color.scss' as standbyColor;

.sideButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-height: 28px;
  color: color.$grayScale000;
  text-align: left;
  background-color: transparent;
  transition: 0.1s;
  line-height: 100%;
  padding-left: 24px;

  &.buttonHover {
    cursor: url('/images/sidebar_alert.png'), pointer;
    padding-left: 0;
  }

  &.sideButtonGap {
    padding-left: 24px;
  }

  &:hover,
  &.active {
    color: color.$grayScale000;
    font-weight: 400;
    background-color: color.$primary400;
    border-radius: 4px;
  }
  &:hover:not(.active) {
    background-color: standbyColor.$shCg900;
  }

  &.publicLink {
    &:after {
      content: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
