@import '@standby/common-ui/color.scss';

.timer {
  background-color: transparent;
  display: inline-flex;
  color: $neutral100;
  text-align: center;
  font-size: 20px;
  font-weight: 900;
  line-height: 140%;
  align-items: center;
  .time {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 44px;
    .timeCell {
      display: inline-block;
      line-height: 40px;
      border-radius: 4px;
      width: 34px;
      height: 40px;
      flex-shrink: 0;
      color: $neutral100;
      background-color: rgba(255, 255, 255, 0.2);
      position: relative;
      &:before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 34px;
        height: 20px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 0px 0px 4px 4px;
        bottom: 0;
        left: 0;
      }
    }
  }
}
