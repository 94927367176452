@import '@standby/common-ui/color.scss';

.fileUpload {
  border: 1px solid $neutral300;
  background: $neutral200;
  margin-top: 36px;
  padding-bottom: 48px;
  text-align: center;
}
.title {
  color: $neutral800;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-top: 48px;
  em {
    color: $main500;
  }
}
.sub {
  color: $neutral700;
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin-top: 20px;
  margin-bottom: 28px;
}

.file {
  .fileGuide {
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding: 12px 16px;
    width: 460px;
    height: 59px;
    border: 1px solid $main400;
    border-radius: 6px;
    background-color: $neutral100;
    text-align: left;
    &:hover {
      background-color: $main000;
    }
    .fileTitle {
      position: relative;
      padding-left: 24px;
      color: $main500;
      font-size: 16px;
      font-weight: 400;
      line-height: 1;
      &:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 20px;
        background-image: url('~@standby/common-ui/components/asset/Icon/file_upload_positive.svg');
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    .fileExplain {
      margin-top: 3px;
      padding-left: 24px;
      color: $neutral600;
      font-size: 12px;
      font-weight: 400;
      line-height: 1.3;
    }
  }
}
