@use '@standby/dike_ui/design-token/color.module.scss' as color;

.FindPasswordContent {
  width: 100%;
  .logoWrap {
    text-align: center;
    .logo {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .mainTitle {
    color: color.$grayScale800;
    text-align: center;
  }
  .inputEmail {
    margin-top: 48px;
  }
  .infoBox {
    width: 100%;
    margin-top: 4px;
  }
  .mainBtn {
    margin-top: 48px;
    width: 100%;
  }
}
