@import '@standby/common-ui/color.scss';

.rNumberModal {
  padding: 26px;
  width: 490px;
  .title {
    background-image: url('/images/exclamationMark_main300.svg');
    background-repeat: no-repeat;
    font-size: 16px;
    line-height: 22.4px;
    letter-spacing: -0.176px;
    font-weight: 500;
    color: $neutral900;
    padding-left: 26px;
    background-position: top 2px left;
  }
  .confirmList {
    width: 385px;
    background-color: $neutral200;
    margin: 0 auto;
    margin-top: 20px;
    padding-left: 25px;
    padding-top: 21px;
    padding-bottom: 21px;
    li {
      position: relative;
      line-height: 130%;
      padding-left: 8px;
      &:not(:first-child) {
        margin-top: 11px;
      }
      .main {
        color: $neutral800;
        font-size: 16px;
        font-weight: 700;
      }
      .arrow {
        color: $neutral800;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.28px;
        margin: 0 3px;
      }
      .sub {
        color: $warning100;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: -0.28px;
      }
      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 9px;
        left: 0;
        width: 3px;
        height: 3px;
        border-radius: 3px;
        background-color: $neutral700;
      }
    }
  }
  .btnArea {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    button {
      border-radius: 4px;
      border: 1px solid $neutral400;
      background: $neutral100;
      color: $neutral600;
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.266px;
      width: 66px;
      height: 30px;
      & + button {
        margin-left: 8px;
      }
    }
  }
}
