@import '@standby/common-ui/color.scss';

.detailInfos {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  .box {
    border-radius: 8px;
    border: 1px solid $neutral300;
    .itemTitle {
      background-repeat: no-repeat;
      background-position: left center;
      color: $neutral800;
      font-size: 18px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.72px;
      padding-left: 32px;
      display: flex;
      align-items: center;
      .info {
        color: $neutral600;
        font-size: 14px;
        font-weight: 400;
        line-height: 130%;
        margin-left: 8px;
      }
      &.rightBtn {
        justify-content: space-between;
      }
      &[data-type='상담정보'] {
        background-image: url('/images/main500_icon_24/icon_pencil.svg');
      }
      &[data-type='인터넷'] {
        background-image: url('/images/main500_icon_24/icon_internet.svg');
      }
      &[data-type='전화'] {
        background-image: url('/images/main500_icon_24/icon_telephone.svg');
      }
      &[data-type='TV'] {
        background-image: url('/images/main500_icon_24/icon_tv.svg');
      }
      .aLink {
        font-weight: 400;
        letter-spacing: -0.26px;
        font-size: 13px;
        position: relative;
        display: inline-block;
        color: $neutral600;
        text-decoration: underline;
        line-height: 12px;
        padding-right: 12px;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 1px;
          right: 0;
          width: 12px;
          height: 12px;
          background-image: url('/images/arrow_icon_neutral600.svg');
          background-size: cover;
          background-repeat: no-repeat;
        }
        &:hover {
          text-decoration: underline;
        }
      }
      .tooltipBtn {
        display: block;
        padding: 7px 12px;
        height: 34px;
        border: 1px solid $neutral300;
        border-radius: 18px;
        background-color: $neutral100;
        color: $neutral600;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
        > span {
          display: inline-block;
          padding-left: 22px;
          background-image: url('/images/companyRegistraion_info_question_icon.svg');
          background-repeat: no-repeat;
        }
      }
    }
  }
  > .detail {
    width: 740px;
    .box {
      padding: 24px 28px;
    }
    .subCategories {
      &.empty {
        background-color: $neutral200;
        height: 243px;
        &:before {
          content: '상품을 선택하세요.';
          background-image: url('/images/Img_EmptyBox.svg');
          display: inline-block;
          width: 100%;
          padding-top: 120px;
          background-repeat: no-repeat;
          background-position: top center;
          text-align: center;
          color: $neutral800;
          font-size: 18px;
          font-weight: 500;
          line-height: 150%;
          margin-top: 24px;
        }
      }
      .tip {
        color: $neutral700;
        font-size: 13px;
        font-weight: 400;
        line-height: 36px;
        height: 36px;
        background-color: $main000;
        padding-left: 40px;
        border-radius: 4px;
        background-image: url('/images/lightTip_20.svg');
        background-position: left 12px center;
        background-repeat: no-repeat;
        margin-bottom: 24px;
        em {
          font-weight: 700;
        }
      }
      .items {
        .item {
          padding: 24px 0;
          &:nth-child(1) {
            padding-top: 0;
          }
          border-bottom: 1px dashed $neutral400;
          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
          .radioGroup {
            display: flex;
            justify-content: space-between;
            margin-top: 8px;
            .radioItem {
              width: 220px;
              height: 112px;
              border-radius: 8px;
              border: 1px solid $neutral300;
              padding: 12px 16px;
              position: relative;
              overflow: hidden;
              .badge {
                position: absolute;
                display: inline-block;
                color: $neutral100;
                font-size: 13px;
                font-weight: 400;
                line-height: 100%;
                background-color: $main400;
                padding: 6px 9px;
                border-radius: 0px 4px;
                top: 0;
                right: 0;
              }
              &.checked {
                border-color: $main400;
                .option {
                  color: $main500;
                }
              }
              .explanation {
                color: $neutral500;
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;
              }
              .option {
                color: $neutral800;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
                margin-top: 2px;
              }
              .cost {
                color: $neutral700;
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                margin-top: 12px;
                padding-top: 8px;
                border-top: 1px solid $neutral200;
              }
              span {
                position: initial;
                width: 100%;
                margin-left: 0;
                &::before,
                &::after {
                  display: none;
                }
              }
            }
          }
          .quantityWrap {
            margin-top: 8px;
            border-radius: 8px;
            border: 1px solid $main300;
            display: flex;
            .itemInfo {
              width: 530px;
              border-right: 1px solid $neutral400;
              padding: 12px 16px;
              position: relative;
              .explanation {
                color: $neutral500;
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;
              }
              .option {
                margin-top: 2px;
                color: $main500;
                font-size: 16px;
                font-weight: 500;
                line-height: 150%;
              }
              .cost {
                color: $neutral700;
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                position: absolute;
                right: 16px;
                bottom: 12px;
              }
            }
            .quantity {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              .number {
                width: 41px;
                text-align: center;
                position: relative;
                .toastMessage {
                  position: absolute;
                  top: -37px;
                  right: 10px;
                  padding: 6px 7px;
                  color: $neutral800;
                  font-size: 11px;
                  font-weight: 400;
                  line-height: 14.3px;
                  letter-spacing: -0.22px;
                  border: 1px solid $main200;
                  border-radius: 2px;
                  background-color: $neutral100;
                  z-index: 500;
                  white-space: nowrap;
                  &:before {
                    content: '';
                    position: absolute;
                    bottom: -10px;
                    right: 4px;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $main200;
                    border-bottom: 5px solid transparent;
                  }
                  &:after {
                    content: '';
                    position: absolute;
                    bottom: -8px;
                    right: 5px;
                    width: 0;
                    height: 0;
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid $neutral100;
                    border-bottom: 4px solid transparent;
                  }
                  em {
                    font-weight: 700;
                  }
                  animation: fadeout 3s ease;
                  opacity: 0;
                  @keyframes fadeout {
                    0% {
                      opacity: 0;
                    }
                    25% {
                      opacity: 1;
                    }
                    75% {
                      opacity: 1;
                    }
                    100% {
                      opacity: 0;
                    }
                  }
                }
              }
            }
          }
          .checkList {
            li {
              margin-top: 12px;
              .won {
                color: $neutral500;
              }
            }
          }
        }
      }
    }
    .consultInfo {
      margin-top: 16px;
      .itemTitle {
        margin-bottom: 12px;
      }
      .inputRows {
        margin-bottom: 16px;
        .inputTitle {
          margin-bottom: 4px;
          color: $neutral800;
          font-size: 15px;
          font-weight: 500;
          line-height: 22px;
          display: inline-block;
          &.required::after {
            color: $warning200;
            content: '*';
          }
        }
        .textarea {
          min-height: 91px;
          resize: none;
        }
      }
      .termsList {
        margin-top: 8px;
        padding: 18px 20px;
        border-radius: 4px;
        background-color: $neutral200;
        li {
          margin-top: 11px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:first-child {
            margin-top: 0;
          }
          .textBtn {
            color: $neutral400;
          }
          .radioText {
            color: $neutral600;
            font-size: 14px;
            .notRequired {
              color: $neutral400;
            }
          }
        }
      }
    }
  }
  .estimate {
    width: 280px;
    > .detail {
      padding: 16px 20px;
      > .selected {
        border-bottom: 1px dashed $neutral400;
        padding-bottom: 16px;
        margin-bottom: 19px;
        > .title {
          color: $neutral800;
          font-size: 16px;
          font-weight: 500;
          line-height: 150%;
          letter-spacing: -0.64px;
        }
        > .selectedList {
          li {
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: -0.6px;
            font-size: 15px;
            margin-top: 8px;
            > .title {
              color: $neutral800;
            }
            > .content {
              color: $neutral600;
            }
          }
        }
      }
      > .price {
        > .priceRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .title {
            color: $neutral800;
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.64px;
            .sub {
              color: $neutral500;
              font-size: 12px;
              line-height: 150%;
              letter-spacing: -0.48px;
            }
          }
          .price {
            color: $main400;
            font-size: 18px;
            font-weight: 500;
            line-height: 150%;
            letter-spacing: -0.72px;
            .moreHide {
              opacity: 0;
            }
          }
        }
        > .detailPrice {
          color: $neutral600;
          text-align: right;
          font-size: 12px;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.56px;
          margin-top: 2px;
          margin-bottom: 15px;
        }
      }
      .submit {
        margin-top: 24px;
        width: 100%;
      }
    }
    .cashback {
      margin-top: 8px;
      background-color: $main000;
      border-radius: 4px;
      padding-left: 38px;
      color: $neutral800;
      border: 1px solid $main100;
      font-size: 13px;
      font-weight: 500;
      line-height: 35px;
      background-image: url('/images/exclamationMark_main400.svg');
      background-repeat: no-repeat;
      background-position: left 12px center;
      height: 36px;
    }
  }
}
