@import '@standby/common-ui/color.scss';

@keyframes squareMoving {
  from {
    transform: translateY(13px);
  }
  to {
  }
}
.joinBackground {
  background: linear-gradient(
    180deg,
    #07174e 0%,
    #0a3075 12.81%,
    #1b61ad 29.61%,
    #5099dc 46.56%,
    #6ac5f8 62.37%,
    #a0e2ff 74.85%,
    #f0fafe 90.77%,
    #fff 100%
  );
  position: absolute;
  height: 794px;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
  .firecracker {
    position: absolute;
    min-width: 1600px;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    img {
      transition:
        opacity 2s ease-out,
        transform 1s;
      opacity: 0.3;
      position: absolute;
      transform: scale(0);
      &:nth-child(1) {
        top: 130px;
        left: 0;
      }
      &:nth-child(2) {
        top: 220px;
        left: 370px;
      }
      &:nth-child(3) {
        top: 50px;
        left: 450px;
      }
      &:nth-child(4) {
        top: 90px;
        left: 470px;
      }
      &:nth-child(5) {
        top: 170px;
        right: 520px;
      }
      &:nth-child(6) {
        top: 20px;
        right: 430px;
      }
      &:nth-child(7) {
        top: 320px;
        right: 330px;
      }
      &:nth-child(8) {
        top: 80px;
        right: 0px;
      }
    }
  }
  .square {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 330px;
    div {
      position: absolute;
      top: 200px;
      left: 0px;
      opacity: 0;
      transform-origin: center;
      transition:
        top 0.7s ease-in-out,
        left 0.7s ease-in-out,
        opacity 1s;
      &:nth-child(1) {
        width: 9px;
        height: 9px;
        background-color: #fff;
        transform: rotate(100deg);
        animation: squareMoving 0.7s infinite;
        animation-direction: alternate;
      }
      &:nth-child(2) {
        width: 9px;
        height: 9px;
        background-color: #0086b8;
        transform: rotate(150deg);
        animation: squareMoving 1.3s infinite;
        animation-direction: alternate;
      }
      &:nth-child(3) {
        width: 14px;
        height: 14px;
        background-color: #00b4e3;
        transform: rotate(80deg);
        animation: squareMoving 1.2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(4) {
        width: 9px;
        height: 9px;
        background-color: #0086b8;
        transform: rotate(200deg);
        animation: squareMoving 1s infinite;
        animation-direction: alternate;
      }
      &:nth-child(5) {
        width: 6px;
        height: 6px;
        background-color: #fff0a3;
        transform: rotate(150deg);
        animation: squareMoving 1.2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(6) {
        width: 6px;
        height: 6px;
        background-color: #a3f9ff;
        transform: rotate(-120deg);
        animation: squareMoving 1.6s infinite;
        animation-direction: alternate;
      }
      &:nth-child(7) {
        width: 9px;
        height: 9px;
        background-color: #fff;
        transform: rotate(130deg);
        animation: squareMoving 2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(8) {
        width: 9px;
        height: 9px;
        background-color: #0086b8;
        transform: rotate(100deg);
        animation: squareMoving 0.7s infinite;
        animation-direction: alternate;
      }
      &:nth-child(9) {
        width: 9px;
        height: 9px;
        background-color: #00b4e3;
        transform: rotate(120deg);
        animation: squareMoving 0.9s infinite;
        animation-direction: alternate;
      }
      &:nth-child(10) {
        width: 14px;
        height: 14px;
        background-color: #fff0a3;
        transform: rotate(80deg);
        animation: squareMoving 1.2s infinite;
        animation-direction: alternate;
      }
      &:nth-child(11) {
        width: 6px;
        height: 6px;
        background-color: #a3f9ff;
        transform: rotate(180deg);
        animation: squareMoving 0.7s infinite;
        animation-direction: alternate;
      }
    }
  }
  &.action {
    .firecracker {
      img {
        opacity: 1;
        transform: scale(1);
        &:nth-child(2) {
          transition-delay: 0.3s;
        }
        &:nth-child(5) {
          transition-delay: 0.6s;
        }
        &:nth-child(7) {
          transition-delay: 0.9s;
        }
        &:nth-child(3) {
          transition-delay: 1.2s;
        }
        &:nth-child(4) {
          transition-delay: 1.6s;
        }
        &:nth-child(1) {
          transition-delay: 1.5s;
        }
        &:nth-child(8) {
          transition-delay: 1.2s;
        }
      }
    }
    .square {
      div {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        transition-delay: 2.5s;
        &:nth-child(1) {
          top: -170px;
          left: -500px;
        }
        &:nth-child(2) {
          top: -280px;
          left: -450px;
        }
        &:nth-child(3) {
          top: -200px;
          left: -400px;
        }
        &:nth-child(4) {
          top: -130px;
          left: -280px;
        }
        &:nth-child(5) {
          top: -310px;
          left: -170px;
        }
        &:nth-child(6) {
          top: -290px;
          left: 160px;
        }
        &:nth-child(7) {
          top: -240px;
          left: 190px;
        }
        &:nth-child(8) {
          top: -110px;
          left: 210px;
        }
        &:nth-child(9) {
          top: -50px;
          left: 290px;
        }
        &:nth-child(10) {
          top: -130px;
          left: 360px;
        }
        &:nth-child(11) {
          top: -250px;
          left: 400px;
        }
      }
    }
  }
}
.join {
  width: 620px;
  margin: 0 auto;
  padding-bottom: 60px;
  text-align: center;
  .joinTitleImg {
    margin-top: 60px;
  }
  .joinForm {
    .logo {
      padding-top: 12px;
      text-align: center;
      padding-bottom: 30px;
      border-bottom: 1px solid $neutral300;
      margin-bottom: 30px;
    }
    width: 100%;
    background-color: $neutral100;
    border-radius: 8px;
    margin-top: 45px;
    box-shadow: 1px 1px 6px 4px rgba(153, 153, 153, 0.2);
    padding: 36px 80px;
    padding-bottom: 0;
    text-align: left;
    .information {
      padding-bottom: 24px;
      border-bottom: 1px solid $neutral300;
      .inputRow {
        display: flex;
        align-items: top;
        margin-top: 12px;
        &:nth-child(1) {
          margin-top: 0;
        }
        .inputLabel {
          width: 110px;
          text-align: left;
          display: inline-block;
          color: $neutral900;
          font-size: 16px;
          font-weight: 400;
          line-height: 42px;
        }
        .helpmeInfo {
          margin-top: 8px;
          width: 130px;
          &.email {
            width: 350px;
          }
        }
        .inputs {
          flex-grow: 1;
          .fixBtn {
            display: inline-block;
            margin-left: 8px;
            width: 72px;
          }
        }
        .passwordEx {
          text-align: left;
          margin-top: 4px;
          li {
            color: $neutral600;
            font-size: 14px;
            font-weight: 400;
            position: relative;
            line-height: 18.2px;
            padding-left: 9px;
            &:before {
              content: '*';
              display: inline-block;
              position: absolute;
              left: 0;
              top: 4px;
            }
          }
        }
      }
    }
    .agree {
      padding-top: 24px;
      .agreeAll {
        span {
          font-size: 15px;
          color: $main500;
        }
      }
      .agreeInside {
        margin-top: 6px;
        margin-left: 25px;
        .agreeAllEx {
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          position: relative;
          line-height: 18.2px;
          padding-left: 9px;
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 4px;
          }
          margin-bottom: 16px;
        }
        .agreeRow {
          margin-top: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .textBtn {
            color: $neutral600;
          }
          .agreeCheckbox {
            span {
              color: $neutral700;
            }
          }
        }
      }
      .btnArea {
        .joinBtn {
          width: 100%;
          margin-top: 30px;
        }
        padding-bottom: 48px;
      }
    }
  }
}
.agreeContent {
  padding: 16px 20px 20px 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: $neutral900;
}
.cancelPopup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(130, 131, 136, 0.6);
  > .popup {
    background-color: $neutral100;
    width: 460px;
    height: 162px;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-image: url('/images/loudSpeakerIcon.svg');
    background-repeat: no-repeat;
    background-position: center top 36px;

    > .title {
      color: $neutral900;
      font-size: 16px;
      font-weight: 400;
      line-height: 100%;
      margin-top: 76px;
    }
    > .content {
      color: $neutral600;
      line-height: 130%;
      letter-spacing: -0.5px;
      font-weight: 400;
      font-size: 14px;
      margin-top: 10px;
    }
  }
}
