@import '@standby/common-ui/color.scss';

.executiveModal {
  padding: 26px;
  width: 490px;
  > .title {
    color: $neutral900;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: -0.176px;
  }
  .table {
    width: 100%;
    margin-top: 15px;
    tr.samePerson {
      td {
        padding-bottom: 4px;
      }
    }
    tr.samePerson + tr.samePerson {
      td {
        border-top: none;
        padding-top: 4px;
        padding-bottom: 10px;
      }
    }
    th {
      text-align: left;
    }
    .new {
      position: relative;
      background-image: url('/images/triangle_neutral600.svg');
      background-repeat: no-repeat;
      background-position: right center;
      .name {
        display: flex;
        color: $neutral500;
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        letter-spacing: -0.28px;
        position: relative;
        top: -1px;
        .number {
          width: 18px;
          display: inline-block;
        }
      }
    }
    .origin {
      .info {
        display: flex;
        align-items: center;
        .tags {
          display: inline-flex;
          flex-flow: column;
          .tag {
            &:not(:first-child) {
              margin-top: 2px;
            }
            width: 63px;
            height: 18px;
            border-radius: 2px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: $neutral100;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.24px;
            &[data-changetype='임기연장'],
            &[data-changetype='직급변경'],
            &[data-changetype='집주소변경'] {
              background-color: $sub100;
            }
            &[data-changetype='사임_퇴임'] {
              background-color: $warning100;
            }
            &[data-changetype='변경없음'] {
              background-color: $neutral500;
            }
            &[data-changetype='신규'] {
              background-color: $main200;
            }
          }
        }
        .name {
          margin-left: 6px;
          font-size: 14px;
          font-weight: 400;
          color: $neutral800;
          letter-spacing: -0.28px;
          position: relative;
          top: -1px;
          &.erase {
            text-decoration: line-through;
            color: $warning200;
          }
        }
      }
      .position {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        letter-spacing: -0.24px;
        color: $neutral500;
        margin-top: 2px;
        .before {
          padding-right: 14px;
          background-image: url('/images/before_neutral600.svg');
          background-repeat: no-repeat;
          background-position: right 1px top 3px;
        }
        .after {
          color: $sub300;
        }
      }
    }
  }

  .confirm {
    .title {
      background-image: url('/images/exclamationMark_main300.svg');
      background-repeat: no-repeat;
      font-size: 16px;
      line-height: 22.4px;
      letter-spacing: -0.176px;
      font-weight: 500;
      color: $neutral900;
      padding-left: 26px;
      background-position: top 2px left;
      margin-top: 22px;
    }
    .content {
      min-height: 70px;
      padding-left: 26px;
      padding-right: 35px;
      padding-top: 12px;
      color: $neutral700;
      font-size: 14px;
      line-height: 18.2px;
      font-weight: 400;
    }
    .buttonArea {
      display: flex;
      justify-content: flex-end;
      button {
        width: 66px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid $neutral400;
        background-color: $neutral100;
        font-size: 14px;
        font-weight: 500;
        color: $neutral600;
        letter-spacing: -0.266px;
        &.color {
          border: none;
          background-color: $main400;
          color: $neutral100;
        }
        & + button {
          margin-left: 8px;
        }
      }
    }
  }
}
