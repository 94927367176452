@use '@standby/dike_ui/design-token/color.module.scss' as color;

.container {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .header {
    width: 688px;
    height: 40px;
    background: color.$grayScale800;

    border-radius: 8px 8px 0px 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: color.$grayScale000;
    }
  }

  .canvas {
    width: 688px;
    height: 456px;
    border-radius: 0px 0px 8px 8px;
  }
}
