@import '@standby/common-ui/color.scss';

.unissuedStockCertificate {
  .title {
    color: $neutral900;
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    .explain {
      position: relative;
      padding-left: 10px;
      margin-top: 6px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      &:before {
        content: '*';
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 0;
      }
    }
  }
  .emailInfo {
    margin-top: 16px;
    display: flex;
    .name {
      margin-top: 11px;
      width: 160px;
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: -0.02em;
    }
  }
  .btnArea {
    margin-top: 36px;
    padding-bottom: 24px;
    width: 100;
    border-top: 1px solid $neutral300;
    .sendEmailBtn {
      margin: 0 auto;
      margin-top: 24px;
      width: 300px;
    }
  }
}
