@import '@standby/common-ui/color.scss';
.content {
  padding-top: 13px;
  padding-bottom: 13px;
}
.requirements {
  color: $neutral800;
  font-size: 13px;
  font-weight: 400;
  line-height: 130%; /* 16.9px */
  margin-top: 6px;
}
