@use '@standby/dike_ui/design-token/color.module.scss' as color;
@use '@src/common/standby-color.scss' as standbyColor;

.sideLink {
  width: 100%;
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 4px 0;
  padding-left: 24px;
  gap: 4px;
  width: 100%;
  transition: 0.1s;
  color: color.$grayScale000;

  &:hover,
  &.active {
    color: color.$grayScale000;
    background-color: color.$primary400;
    border-radius: 4px;
  }
  &:hover:not(.active) {
    background-color: standbyColor.$shCg900;
  }
}

.boxTag,
.tag {
  display: inline-flex;
  align-items: center;
  padding: 1px 6px;
  height: 16px;
  background-color: color.$grayScale000;
  border-radius: 9px;
  margin-right: 4px;

  &.new {
    color: color.$siGreen300;
    background-color: color.$siGreen100;
  }

  &.ongoing {
    color: color.$siYellow800;
    background-color: color.$siYellow100;
  }

  &.complete {
    color: color.$primary800;
    position: relative;
    padding-left: 14px;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 4px;
      left: 4px;
      width: 8px;
      height: 9px;
      background-image: url('/images/complete_check_icon.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  &.registerComplete {
    color: color.$primary300;
    position: relative;
    padding-left: 14px;

    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 4px;
      left: 4px;
      width: 8px;
      height: 9px;
      background-image: url('/images/completeRegister_check_icon.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  &.waiting {
    color: color.$primary300;
  }

  &.guide {
    color: color.$grayScale800;
  }
}

.alertContent {
  margin-top: 12px;
}

.sideButton {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  min-height: 25px;
  color: color.$grayScale000;
  text-align: left;
  background-color: transparent;
  transition: 0.1s;
  line-height: 100%;

  &.buttonCursor {
    cursor: not-allowed;
  }

  &:hover,
  &.active {
    color: color.$siGreen300;
    font-weight: 400;
    background-color: color.$primary400;
    border-radius: 2px;
  }

  &.publicLink {
    &:after {
      content: none;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
