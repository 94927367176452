@import '@standby/common-ui/color.scss';

.modalContent {
  padding: 36px 50px;
  width: 1040px;
  .title {
    color: $neutral800;
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
  }
  .table {
    margin-top: 17px;
    width: 100%;
    thead {
      tr {
        th {
          color: $neutral700;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.28px;
          background-color: $neutral200;
          border-top: 1px solid $neutral300;
          border-bottom: 1px solid $neutral300;
          &:nth-child(1) {
            padding: 10px 6px 12px 36px;
            text-align: left;
          }
          &:nth-child(2) {
            padding: 10px 6px 12px 6px;
          }
          &:nth-child(3) {
            padding: 10px 6px 12px 6px;
          }
          &:nth-child(4) {
            padding: 10px 6px 12px 6px;
            text-align: left;
          }
          &:nth-child(5) {
            padding: 10px 6px 12px 6px;
            text-align: right;
          }
          &:nth-child(6) {
            padding: 10px 6px 12px 6px;
            text-align: right;
          }
          &:nth-child(7) {
            padding: 10px 8px 12px 6px;
            text-align: left;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid $neutral300;
          color: $neutral800;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          &:nth-child(1) {
            padding: 12px 6px 12px 8px;
            text-align: left;
            .index {
              display: inline-block;
              width: 24px;
            }
            .name {
              display: inline-block;
              margin-left: 4px;
              font-weight: 700;
            }
          }
          &:nth-child(2) {
            padding: 12px 6px 12px 6px;
          }
          &:nth-child(3) {
            padding: 12px 6px 12px 6px;
          }
          &.stockName {
            padding: 12px 6px 12px 6px;
            text-align: left;
          }
          &.paymentAmount {
            padding: 12px 6px 12px 6px;
            text-align: right;
          }
          &.stockAmount {
            padding: 12px 6px 12px 6px;
            text-align: right;
          }
          &.issuePrice {
            padding: 12px 8px 12px 6px;
            text-align: right;
          }
          .datepicker {
            input {
              width: 100px;
            }
          }
          .same {
            cursor: pointer;
            margin-top: 6px;
            width: 100px;
            height: 26px;
            background-color: transparent;
            color: $main500;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            letter-spacing: -0.24px;
            border: 1px solid $neutral400;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .btnArea {
    margin-top: 36px;
    .submitBtn {
      margin: 0 auto;
      width: 300px;
    }
  }
}
