@import '@standby/common-ui/color.scss';

.documentContent {
  padding: 36px 50px;
  width: 100%;
  border: 1px solid $neutral300;
  border-radius: 16px;
  display: inline-flex;
  flex-wrap: wrap;
  .documentBox {
    width: 329px;
    .title {
      color: $neutral900;
      font-size: 16px;
      font-weight: 400;
      line-height: 16px;
    }
    .date {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      color: $neutral600;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.28px;
    }
    .changeBtn {
      padding-right: 14px;
      color: $main300;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.28px;
      text-decoration: underline;
      background-color: transparent;
      background-image: url('/images/arrow_icon.svg');
      background-repeat: no-repeat;
      background-position: right top 1px;
    }
    .documentImgBox {
      position: relative;
      margin-top: 16px;
      .thumbnailFile {
        position: relative;
        margin-top: 12px;
        width: 328px;
        height: 200px;
        border: 1px solid $neutral500;
        border-radius: 4px;
        overflow: hidden;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 328px;
          height: 200px;
          border-radius: 4px;
          background-color: rgba(153, 153, 153, 0.2);
        }
        .img {
          width: 100%;
        }
      }
      .exampleFile {
        position: relative;
        margin-top: 12px;
        width: 328px;
        height: 200px;
        border: 1px solid $neutral500;
        border-radius: 4px;
        overflow: hidden;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
          width: 328px;
          height: 200px;
          border-radius: 4px;
          background-color: rgba(153, 153, 153, 0.6);
        }
        .exampleImg {
          width: 100%;
        }
      }
      .downloadBtn,
      .chooseBtn {
        position: absolute;
        bottom: 21px;
        left: 24px;
        width: 282px;
        height: 38px;
      }
    }
    &:nth-child(2n) {
      margin-left: 32px;
    }
    &:nth-child(n + 3) {
      margin-top: 36px;
    }
    .error {
      margin-top: 6px;
      color: $warning200;
      font-size: 13px;
      font-weight: 400;
      line-height: 16.9px;
    }
  }
}
