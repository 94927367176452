@use '@standby/dike_ui/design-token/color.module.scss' as color;

.container {
  position: relative;
  height: 100%;

  .sectionTitle {
    margin-top: 24px;
  }

  .content {
    display: flex;
    gap: 16px;
    margin-top: 16px;
    margin-bottom: 24px;

    .main {
      width: 688px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .side {
      min-width: 336px;
    }
  }

  .footer {
    position: fixed;
    left: 108px;
    bottom: 0px;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px 0px;

    border-top: 1px solid color.$grayScale300;
    background: color.$grayScale000;

    button {
      width: 336px;
      padding: 12px 16px;
    }
  }
}
