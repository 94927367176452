@import '@standby/common-ui/color.scss';
.MemoWriteModal {
  width: 1040px;
  padding: 0 50px;
  .toolbox {
    display: flex;
    padding-top: 36px;
    margin-bottom: 12px;
    .boldBtn {
      width: 28px;
      height: 28px;
      background-color: $neutral100;
      border: 1px solid $neutral400;
      box-sizing: border-box;
      color: $main500;
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      border-radius: 4px;
      &.active {
        border: none;
        color: $neutral100;
        background-color: $main400;
      }
    }
    .templates {
      flex: 1;
      margin-left: 25px;
      position: relative;
      display: flex;
      &::before {
        content: '';
        position: absolute;
        display: inline-block;
        width: 1px;
        height: 28px;
        background-color: $neutral300;
        left: -12px;
        top: 0;
      }
      > .title {
        color: $neutral800;
        font-size: 14px;
        font-weight: 700;
        line-height: 100%;
        position: relative;
        top: 7px;
      }
      > .btns {
        flex: 1;
        position: relative;
        top: 1px;
        margin-left: 12px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        button {
          color: $neutral600;
          font-size: 12px;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -0.24px;
          border-radius: 20px;
          border: $neutral400 1px solid;
          background: $neutral100;
          padding: 6px 8px;
          transition: 0.3s;
          &:hover {
            border-color: $main400;
            color: $main500;
          }
          &:disabled {
            border-color: $neutral300;
            background-color: $neutral200;
            color: $neutral500;
          }
        }
      }
    }
  }
  .contentEdit {
    border: 1px solid $neutral300;
    border-radius: 4px;
    height: 240px;
    padding: 10px;
    overflow-x: auto;
    background: $neutral100;
    color: $neutral800;
    font-size: 13px;
    font-weight: 400;
    line-height: 155%;
    b {
      font-weight: 700;
    }
    &::-webkit-scrollbar {
      width: 16px; /* 스크롤바의 너비 */
    }

    &::-webkit-scrollbar-thumb {
      height: 30%; /* 스크롤바의 길이 */
      background: $neutral400; /* 스크롤바의 색상 */
      background-clip: content-box;
      border: 6px solid transparent;

      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: $neutral100; /*스크롤바 뒷 배경 색상*/
    }
  }
  .btnArea {
    margin-top: 24px;
    margin-bottom: 34px;
    text-align: center;
    button + button {
      margin-left: 8px;
    }
  }
}
