@import '@standby/common-ui/color.scss';

.businessRegistration {
  .mainTitle {
    margin-top: 90px;
    color: $neutral900;
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    .bold {
      color: $primary800;
    }
  }
  .registraionRow {
    display: flex;
    margin-top: 36px;
    .registrationTitle {
      padding-top: 18px;
      width: 210px;
      flex-shrink: 0;
      color: $neutral600;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.3;
      letter-spacing: -0.02em;
      border-top: 1px solid $neutral600;
      .bold {
        color: $neutral900;
      }
      .registrationSubTitle {
        margin-top: 8px;
        color: $neutral600;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        .small {
          font-size: 14px;
          letter-spacing: -0.05em;
        }
      }
    }
    .registrationContent {
      margin-left: 36px;
      padding-top: 20px;
      width: 794px;
      border-top: 1px solid $neutral300;
    }
  }
}
