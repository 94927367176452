@import '@standby/common-ui/color.scss';

.agenda {
  padding-left: 16px;
  padding-right: 16px;
  &:first-child {
    margin-top: 14px;
  }
  &:not(:first-child) {
    margin-top: 10px;
  }
  &.opened {
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    > .title {
      color: $main500;
    }
    &:nth-child(even) {
      background-color: $neutral200;
    }
    .badge {
      color: $neutral100 !important;
      background-color: $main100 !important;
    }
  }
  > .title {
    color: $neutral800;
    font-size: 13px;
    font-weight: 400;
    line-height: 100%;
    display: flex;
    &.opened {
      color: $main500;
    }
    .number {
      width: 18px;
    }
    .text {
      .important {
        font-weight: 700;
      }
      margin-right: 6px;
    }
    .badge {
      color: $neutral600;
      font-size: 11px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.22px;
      padding: 2px 6px;
      background-color: $neutral200;
      border-radius: 2px;
      & + .badge {
        margin-left: 2px;
      }
    }
  }
  .info {
    padding-left: 18px;
    margin-top: 12px;
  }
}
.content {
  padding: 0 !important;
  .agendas {
    width: 100%;
  }
}
.moreViewBtn {
  margin-top: 14px;
  margin-bottom: 14px;
  display: block;
  margin-left: 16px;
  background-color: transparent;
  &.opened {
    margin-top: 20px;
    margin-bottom: 20px;
    > span {
      background-image: url('/images/moreView_open.svg');
    }
  }
  > span {
    padding-right: 16px;
    color: $neutral600;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    text-decoration: underline;
    background-image: url('/images/moreView_close.svg');
    background-repeat: no-repeat;
    background-position: right center;
  }
}
.downloadBtn {
  position: relative;
  padding-left: 19px;
  color: $main300;
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
  text-decoration: underline;
  background-color: transparent;
  background-image: url('/images/download_icon.svg');
  background-repeat: no-repeat;
  background-position: left top -1px;
}
.agendaList {
  color: $neutral800;
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
  li {
    position: relative;
    padding-left: 6px;
    &:not(:first-child) {
      margin-top: 8px;
    }
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      top: 6px;
      left: 0;
      border-radius: 2px;
      width: 2px;
      height: 2px;
      background-color: $neutral700;
    }
  }
}
